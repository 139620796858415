<template>
  <div class="grid grid-cols-6 gap-[10px]">
    <div
      v-for="card in grantCardsData"
      :key="card"
      class="text-blue-grey-900 bg-white standard-elevation-0-dark flex flex-col items-center gap-2 p-6">
      <component :is="card.icon" class="h-[45px]" />
      <div class="body-1">{{ card.title }}</div>
      <template v-if="typeof data[card.key] === 'object'">
        <b>
          {{ data[card.key].value }}
          <v-tooltip :text="data[card.key].tooltip" location="bottom">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#607d8b"
                icon="mdi-information-outline" />
            </template>
          </v-tooltip>
        </b>
      </template>
      <template v-else>
        <b>{{ data[card.key] }}</b>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grantCardsData: [
        {
          icon: 'blueprint-icon',
          title: 'Gemeinden',
          key: 'gemeinde_amount',
        },
        {
          icon: 'blueprint-icon',
          title: 'Gemarkungen',
          key: 'gemarkungen_amount',
        },
        {
          icon: 'profile-icon',
          title: 'Kunden',
          key: 'clients_amount',
        },
        {
          icon: 'road-icon',
          title: 'Trassenmeter',
          key: 'route_meter',
        },
        {
          icon: 'arrow-divided-icon',
          title: 'PoP Standorte',
          key: 'pop_amount',
        },
        {
          icon: 'new-construction-shape',
          title: 'Hausanschlüsse',
          key: 'home_connections_amount',
        },
        {
          icon: 'euro-shape',
          title: 'Invest',
          key: 'investment_amount_formatted',
        },
        {
          icon: 'euro-shape',
          title: 'Eigenmittel',
          key: 'own_capital',
        },
        {
          icon: 'euro-shape',
          title: 'Fördermittel',
          key: 'subsidies',
        },
        {
          icon: 'euro-shape',
          title: 'Erlöse',
          key: 'revenues',
        },
        {
          icon: 'percentage-shape',
          title: 'Förderquote',
          key: 'funding_rate',
        },
        {
          icon: 'euro-shape',
          title: 'je Hausanschluss',
          key: 'investment_amount_per_home_connection',
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
