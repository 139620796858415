import {
  LAYER_KEY__CONSTRUCTION_PLAN,
  LAYER_KEY__CONSTRUCTION_PLAN_BORDER,
} from '../layers/construction';

export const SWITCH_ID__CONSTRUCTION_PLAN = 'LC_CONSTRUCTION_PLAN';

export default {
  title: 'Betrachungsebenen Bau',
  items: [
    {
      title: 'Bebauungspläne',
      onLayers: [
        LAYER_KEY__CONSTRUCTION_PLAN,
        LAYER_KEY__CONSTRUCTION_PLAN_BORDER,
      ],
      switchId: SWITCH_ID__CONSTRUCTION_PLAN,
      itemClass: 'switch',
    },
  ],
};
