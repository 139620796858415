import { axios } from '@/utils/axiosHelper';

export default {
  GET_GRANT_APPLICATIONS: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/funding/applications/',
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_GRANT_APPLICATIONS', resp.data['records']);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
};
