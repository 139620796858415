<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.85188 8.03694L6.13868 0.714302C6.02893 0.498615 5.86496 0.31817 5.66435 0.192329C5.46375 0.0664876 5.23408 0 5 0C4.76592 0 4.53625 0.0664876 4.33565 0.192329C4.13504 0.31817 3.97107 0.498615 3.86132 0.714302L0.14812 8.03694C0.0448508 8.24126 -0.00598298 8.46998 0.000560618 8.70086C0.00710422 8.93174 0.0708025 9.15693 0.185463 9.35453C0.300123 9.55212 0.461844 9.71541 0.654904 9.82852C0.847963 9.94162 1.06579 10.0007 1.28721 9.99999H8.71279C8.93421 10.0007 9.15204 9.94162 9.3451 9.82852C9.53815 9.71541 9.69988 9.55212 9.81454 9.35453C9.9292 9.15693 9.9929 8.93174 9.99944 8.70086C10.006 8.46998 9.95515 8.24126 9.85188 8.03694ZM5 8.69564C4.87635 8.69564 4.75548 8.65739 4.65266 8.58573C4.54985 8.51407 4.46972 8.41221 4.4224 8.29304C4.37508 8.17387 4.3627 8.04274 4.38682 7.91623C4.41095 7.78972 4.47049 7.67351 4.55792 7.5823C4.64536 7.4911 4.75676 7.42898 4.87803 7.40382C4.99931 7.37865 5.12501 7.39157 5.23925 7.44093C5.35349 7.49029 5.45113 7.57388 5.51983 7.68113C5.58852 7.78838 5.62519 7.91447 5.62519 8.04346C5.62519 8.21643 5.55932 8.38231 5.44208 8.50462C5.32483 8.62693 5.16581 8.69564 5 8.69564ZM5.41679 6.52172H4.58321L4.37481 3.04344H5.62519L5.41679 6.52172Z"
      fill="#FF9800" />
  </svg>
</template>
