<template>
  <div class="personal-data-container">
    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <div>
        <span class="text-black text-[13px]">Username</span>
        <v-text-field
          v-model="username"
          data-cy="username-input"
          class="w-[350px]"
          density="compact"
          bg-color="white"
          variant="outlined" />
      </div>
      <div>
        <span class="text-black text-[13px]">Email Addresse</span>
        <v-text-field
          v-model="email"
          class="w-[350px]"
          :rules="[rules.validEmail]"
          density="compact"
          bg-color="white"
          variant="outlined"
          :persistent-clear="true"
          :error="error"
          :error-messages="errorMessages" />
        <span class="text-black text-[13px]">Profilbild</span>
        <file-input class="w-[350px]" @emit-image="updateImage" />
      </div>
      <infra-button :disabled="isSignInDisabled" :on-click="submit" class="mt-0"
        >Speichern
      </infra-button>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      image: null,
      isFormValid: true,
      error: false,
      errorMessages: '',
      isSignInDisabled: false,
      username: this.$store.getters['auth/profil'].username,
      email: this.$store.getters['auth/profil'].email,
      rules: {
        validEmail: (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Bitte gültige Email-Adresse angeben',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['profil']),
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isSignInDisabled = true;
        if (this.image) {
          if (this.image.size > 1000000) {
            this.$store.commit('layout/SHOWTOAST', {
              color: 'error',
              message:
                'Tut uns leid! Das Profilbild sollte nicht größer als 1 MB sein.',
              timeout: 0,
              multiLine: true,
              outlined: true,
            });
          }
        }
        this.update(this.username, this.email.toLowerCase(), this.image);
        this.isSignInDisabled = false;
      }
    },
    updateImage(variable) {
      this.image = variable;
    },
    update(username, email, imageFile) {
      const accountForm = new FormData();
      accountForm.append('file', imageFile);
      accountForm.append('username', username);
      accountForm.append('email', email);
      this.$store.dispatch('auth/PATCH_PROFILE_DATA', {
        accountForm,
      });
    },
  },
};
</script>

<style lang="scss">
.personal-data-container {
}
</style>
