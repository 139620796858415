<template>
  <v-chart
    ref="stackedBar"
    class="min-h-[300px]"
    :style="
      customMinHeight
        ? namesCount !== 0
          ? 'height:' + namesCount * customMinHeight + 'px'
          : ''
        : ''
    "
    :option="option"
    autoresize />
</template>

<script>
export default {
  props: {
    chartData: {},
    chartTitle: {
      type: String,
      default: null,
    },
    multiply: {
      type: Number,
      default: null,
    },
    categoryOrder: {
      default: null,
    },
    barWidth: {
      type: Number,
      default: 35,
    },
    showXAxisLabel: {
      type: Boolean,
      default: true,
    },
    gridSpace: {
      type: String,
      default: '100px',
    },
    customMinHeight: {
      type: Number,
      default: 35,
    },
    xAxisName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      namesCount: 0,
    };
  },
  computed: {
    option() {
      const aggregateData = {};

      this.chartData.forEach((item) => {
        const gemeindeName = item.gemeinde_name;
        if (!aggregateData[gemeindeName]) {
          aggregateData[gemeindeName] = {};
        }

        item.mev.forEach((mevItem) => {
          const mevClass = mevItem.mev_class;
          if (!aggregateData[gemeindeName][mevClass]) {
            aggregateData[gemeindeName][mevClass] = {
              count: 0,
              mev_class_name: this.categoryOrder[mevClass],
              mev_class: mevClass,
            };
          }
          aggregateData[gemeindeName][mevClass].count += mevItem.count;
        });
      });

      const names = Object.keys(aggregateData).sort((b, a) => {
        // sort names alphabetically
        return a.localeCompare(b);
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.namesCount = names.length;

      const mevClasses = [
        ...new Set(
          this.chartData.flatMap((item) =>
            item.mev.map((mevItem) => mevItem.mev_class),
          ),
        ),
      ];

      const series = mevClasses
        .map((mevClass) => {
          return {
            name: this.categoryOrder[mevClass],
            type: 'bar',
            stack: 'stack',
            label: {
              show: false,
            },
            cursor: 'default',
            data: names.map(
              (name) => aggregateData[name][mevClass]?.count || 0,
            ),
            barWidth: this.barWidth,
            color: this.getColor(mevClass),
          };
        })
        .filter((e) => e.name);

      return {
        title: {
          text: this.chartTitle,
          textStyle: {
            color: 'black',
            textAlign: 'center',
            lineHeight: 24,
            fontFamily: 'Borna, sans-serif',
            fontWeight: 600,
            fontSize: '1rem',
          },
          left: 'center',
          top: '25px',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: (params) => {
            let result = '';
            params.forEach((item) => {
              // filter 0 values
              if (item.value === 0) {
                return;
              }
              result += `${item.marker} ${
                item.seriesName
              }: ${item.value.toLocaleString('de-DE')}<br/>`;
            });
            return result;
          },
        },
        legend: {
          data: mevClasses
            .map((mevClass) => this.categoryOrder[mevClass])
            .filter((e) => e),
          bottom: 20,
          itemWidth: 12,
          itemHeight: 12,
        },
        grid: {
          top: '0px',
          bottom: this.gridSpace,
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: this.showXAxisLabel,
            formatter: (value) => {
              return value.toLocaleString('de-DE');
            },
          },
          ...(this.xAxisName && { name: this.xAxisName }),
          ...(this.xAxisName && { nameLocation: 'center' }),
          ...(this.xAxisName && { nameGap: 30 }),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: names,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: series,
      };
    },
  },
  mounted() {
    // check if chartData has changed, if yes, reload the chart
    this.$watch(
      'chartData',
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.$refs.stackedBar.chart.clear();
          this.$refs.stackedBar.chart.setOption(this.option);
        }
      },
      { deep: true },
    );
  },
  methods: {
    getColor(mevClass) {
      let color;
      switch (mevClass) {
        case 1:
          color = '#64B5F6';
          break;
        case 2:
          color = '#37474F';
          break;
        case 3:
          color = '#8BC34A';
          break;
        case 4:
          color = '#2E7D32';
          break;
        case 5:
          color = '#E1BEE7';
          break;
        case 6:
          color = '#8E24AA';
          break;
        case 7:
          color = '#FF9800';
          break;
        default:
          color = '#000';
      }
      return color;
    },
  },
};
</script>
