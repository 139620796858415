<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 13C19.8137 13 22.5 10.3137 22.5 7C22.5 3.68629 19.8137 1 16.5 1C13.1863 1 10.5 3.68629 10.5 7C10.5 10.3137 13.1863 13 16.5 13Z"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M16.5 18C13.0522 18 9.74558 19.3696 7.30761 21.8076C4.86964 24.2456 3.5 27.5522 3.5 31H29.5C29.5 27.5522 28.1304 24.2456 25.6924 21.8076C23.2544 19.3696 19.9478 18 16.5 18Z"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
