function constructFeatureURL(feature, layerConfig) {
  const filterByProperty = feature.properties[layerConfig.key_name];
  const name = layerConfig.router_name;
  const appName = layerConfig.app_name;
  return `/api/${appName}/${name}/${filterByProperty}`;
}

export default function onLeftClick(map, e, data, click, getLayersToClick) {
  click.resetFeatureState();
  click.iconLayers.forEach((el) => {
    map.U.removeSource(el);
  });

  const features = map.queryRenderedFeatures(e.point, {
    layers: getLayersToClick,
  });

  const feature = features[0];

  if (feature) {
    const layerID = feature.layer.id;
    const layerConfig = data.$store.state.map.layerConfigs[layerID];
    const layerChanged = data.$store.state.map.layerID !== layerID;

    if (layerChanged) data.showSidebar(false);

    data.$store
      .dispatch('map/GET_FEATURE', {
        layerID,
        url: constructFeatureURL(feature, layerConfig.layerConfig),
      })
      .then(() => {
        if (layerConfig.onClick) {
          layerConfig.onClick(click, feature, data);
        }
        data.showSidebar(true);
      });

    click.setFeatureState([feature], true);
  }
  if (typeof feature === 'undefined') data.showSidebar(false);
}
