<template>
  <div
    class="min-w-fit max-w-[320px] sticky left-0 z-20 construction-data-table-wrapper mb-[14px]">
    <!-- table header-->
    <div
      class="items-center sticky top-0 bg-blue-grey-50 border-y border-t-0 pl-1 gap-3 h-[24px] text-blue-grey-700 table-wrapper-custom"
      style="
        display: grid;
        grid-template-columns: 75px 240px;
        border-bottom: 2px solid white;
      ">
      <div class="subtitle-4">Gemeinden</div>
    </div>

    <!-- table body -->
    <div
      class="max-h-[500px] overflow-y-auto table-body-scroll-target scrollbar-none table-wrapper-custom"
      @wheel="handleWheelEvent">
      <div v-for="(item, index) in data" :key="item" class="table-item-custom">
        <!-- meilensteine-->
        <div
          v-if="hasAnyDate(item)"
          style="border-color: white !important"
          class="flex items-center border-b border-white cursor-pointer gap-0.5 pl-1 h-[24px] bg-blue-grey-25 caption-3 overflow-hidden whitespace-normal table-wrapper-custom-child"
          @click="toggleMilestones(index)">
          <v-icon
            icon="mdi-chevron-right"
            class="text-xs transition-all"
            :class="{
              'rotate-90':
                openMilestonesIndices.includes(index) && showMilestones,
            }" />
          {{ item.gemeinde_name }}
        </div>
        <div
          v-if="openMilestonesIndices.includes(index) && showMilestones"
          class="transition-all">
          <div v-for="mapConfig in dataMap" :key="mapConfig.key">
            <div
              v-if="hasCategoryDates(mapConfig, item)"
              :key="mapConfig.key"
              style="border-color: #f0f2f3 !important"
              class="flex items-center border-b gap-0.5 pl-1 h-[24px] bg-white caption-3 overflow-hidden whitespace-normal">
              {{ mapConfig.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionDataTablePis',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    dataMap: {
      type: Array,
      default: () => [],
    },
    scrollTarget: {
      type: Number,
      default: 0,
    },
    richtlinien: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  emits: ['activeIndex', 'showMilestones', 'child-scroll'],
  data() {
    return {
      showMilestones: true,
      openMilestonesIndices: [0],
    };
  },
  watch: {
    openMilestonesIndices: {
      handler(val) {
        this.$emit('activeIndex', val);
      },
      immediate: true,
    },
    showMilestones: {
      handler(val) {
        this.$emit('showMilestones', val);
      },
      immediate: true,
    },
    scrollTarget: {
      handler(val) {
        this.updateScrollPosition(val);
      },
    },
  },
  mounted() {
    const container = document.querySelector('.table-body-scroll-target');
    container.addEventListener('scroll', this.onScroll);
  },

  // Don't forget to remove the event listener on destroy
  beforeUnmount() {
    const container = document.querySelector('.table-body-scroll-target');
    container.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    getValueFromKeyTree(rootKey, keyTree, dataObj) {
      let obj = dataObj[rootKey];
      for (let key of keyTree) {
        if (!isNaN(key) && !this.richtlinien.includes(parseInt(key)))
          return null;
        if (typeof obj === 'object' && key in obj) {
          obj = obj[key];
        } else {
          throw Error(`key ${key} does not exist in object ${obj}`);
        }
      }
      return obj;
    },
    hasAnyDate(dataObj) {
      for (let mapConfig of this.dataMap) {
        for (let dateConfig of mapConfig.dates) {
          let dateCount = 0;
          for (let dateValue of dateConfig.values) {
            if (
              this.getValueFromKeyTree(
                mapConfig.key,
                dateValue.keyTree,
                dataObj,
              )
            ) {
              dateCount++;
              if (dateCount === 2) return true;
            }
          }
        }
      }
      return false;
    },
    hasCategoryDates(mapConfig, dataObj) {
      for (let dateConfig of mapConfig.dates) {
        let dateCount = 0;
        for (let dateValue of dateConfig.values) {
          if (
            this.getValueFromKeyTree(mapConfig.key, dateValue.keyTree, dataObj)
          ) {
            dateCount++;
            if (dateCount === 2) return true;
          }
        }
      }
      return false;
    },

    getKeyName(item) {
      let resultKeys = [];

      function checkParentKey(parentKey, grandParentKey) {
        if (this.richtlinien.includes(Number(parentKey))) {
          return grandParentKey;
        }
        return parentKey;
      }

      function scanObject(obj, parentKey = null, grandParentKey = null) {
        for (const key in obj) {
          if (obj[key] && typeof obj[key] === 'object') {
            scanObject.call(this, obj[key], key, parentKey);
          } else {
            // Assuming the date format is 'YYYY-MM-DD'
            if (/^\d{4}-\d{2}-\d{2}$/.test(obj[key])) {
              const relevantKey = checkParentKey.call(
                this,
                parentKey,
                grandParentKey,
              );
              if (!resultKeys.includes(relevantKey)) {
                resultKeys.push(relevantKey);
              }
            }
          }
        }
      }

      scanObject.call(this, item);

      const richtlinienSet = new Set(this.richtlinien);

      resultKeys = resultKeys.filter((item) => {
        const numItem = Number(item);
        return isNaN(numItem) || richtlinienSet.has(numItem);
      });

      return resultKeys.map((key) => this.translateKey(key));
    },

    translateKey(key) {
      const keyTranslations = {
        mev: 'Markterkundungsverfahren (MEV)',
        cooperation_agreement: 'Kooperationsvereinbarung',
        grant_applications: 'Förderantrag',
        tenders_mains_operation: 'Ausschreibung Netzbetrieb',
        tenders_general_contractor: 'Ausschreibung GÜ',
        planning_construction_general_contractor: 'Planung und Bau (GÜ)',
      };
      return keyTranslations[key] || key;
    },

    onScroll(event) {
      const scrollTop = event.target.scrollTop;
      this.$emit('child-scroll', scrollTop);
    },
    updateScrollPosition(scrollTop) {
      const container = this.$el.querySelector('.table-body-scroll-target');
      container.scrollTop = scrollTop;
    },
    handleWheelEvent(event) {
      const containerHeight = this.$el.querySelector(
        '.table-body-scroll-target',
      ).offsetHeight;
      if (containerHeight >= 500) {
        event.stopPropagation();
      }
    },
    toggleMilestones(index) {
      const idx = this.openMilestonesIndices.indexOf(index);
      if (idx === -1) {
        this.openMilestonesIndices.push(index);
      } else {
        this.openMilestonesIndices.splice(idx, 1);
      }
    },
    taskPopoverText(item) {
      return (
        item.ready_house_connections.toLocaleString('de-DE') +
        ' Hausanschlussverträge\n' +
        item.ready_house_connections.toLocaleString('de-DE') +
        ' Hausanschlussverträge'
      );
    },
    progressPopoverText(item) {
      return (item['ist/soll'] * 100).toFixed(0) + '% von 240.000€ verbaut';
    },
    progressColor(data) {
      if (data < 0.45) {
        return '#BC0606';
      } else if (data < 0.75) {
        return '#FF9800';
      } else {
        return '#4CAF50';
      }
    },
    getTerminData(data) {
      switch (data) {
        case 1:
          return 'bg-[#00BCD4]';
        case 2:
          return 'bg-[#00B979]';
        case 3:
          return 'bg-[#FF9800]';
        default:
          return 'bg-[#BC0606]';
      }
    },
  },
};
</script>

<style lang="scss">
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.table-wrapper-custom {
  border-right: 4px solid;
  @apply border-blue-grey-10;
}

.table-item-custom {
  &:last-child {
    .table-wrapper-custom-child {
      border-bottom-width: 0px !important;
    }
  }
}
</style>
