<template>
  <label
    :class="{
      'p-[8px] min-h-[32px] body-2': small,
      'p-[10px] min-h-[40px] body-1': !small,
      'text-slate-300': disabled,
      'hover:bg-blue-grey-100 hover:text-gray-900 cursor-pointer': !disabled,
    }"
    class="flex bg-blue-grey-25 items-center list-item-custom w-full">
    <input
      :disabled="disabled"
      type="checkbox"
      class="appearance-none"
      :checked="checkedData.includes(itemData.id)"
      @change="changeSelection(itemData.id)" />
    <span class="checkmark mr-3"></span>
    <span class="pt-[2px]">{{ itemData.name }}</span>
    <span class="ml-auto">
      <slot name="append-icon" />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Array, String, Number, Object],
      required: true,
    },
    checkedData: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      default: null,
    },
    itemsData: {
      type: Object,
      default: null,
    },
    itemsDataKey: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: 'value',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {};
  },
  computed: {},
  methods: {
    changeSelection(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Create a custom checkbox */
.checkmark {
  height: 20px;
  width: 20px;
  background-color: white;
  position: relative;
  @apply border border-blue-grey-300 rounded-sm;
}

/* When the checkbox is checked, add a blue background */
.list-item-custom input:checked ~ .checkmark {
  @apply bg-infra-highlight-700 border-none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.list-item-custom input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.list-item-custom .checkmark:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
}
</style>
