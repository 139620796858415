<template>
  <div
    ref="popoverItem"
    :class="directions"
    :style="{ background: background }"
    class="absolute standard-elevation-1 z-10 p-1 rounded-[2px] whitespace-pre body-2 overflow-hidden w-max popover-item">
    <span v-if="text">{{ text }}</span>
    <!--    make a template slot -->
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    directions: {
      type: String,
      default: 'top-2/3 left-1/2',
    },
    background: {
      type: String,
      default: '#fff',
    },
  },
  emits: ['dimensions'],
  computed: {
    getDimensions() {
      const width = this.$refs.popoverItem.clientWidth;
      const height = this.$refs.popoverItem.clientHeight;
      return { width: width, height: height };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('dimensions', this.getDimensions);
    });
  },
};
</script>
