<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3536 8.14645C14.5488 8.34171 14.5488 8.65829 14.3536 8.85355L9.85355 13.3536C9.65829 13.5488 9.34171 13.5488 9.14645 13.3536C8.95118 13.1583 8.95118 12.8417 9.14645 12.6464L12.7929 9H2C1.72386 9 1.5 8.77614 1.5 8.5C1.5 8.22386 1.72386 8 2 8H12.7929L9.14645 4.35355C8.95118 4.15829 8.95118 3.84171 9.14645 3.64645C9.34171 3.45118 9.65829 3.45118 9.85355 3.64645L14.3536 8.14645Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
