<template>
  <drop-down-multiple-grouped
    class="p-3 bg-infra-highlight-25 w-100"
    :items="itemData"
    placeholder="Lose auswählen"
    :max-preview-items="1"
    :small="true"
    data-key="name"
    value-key="value"
    label="Ausgewählte Lose"
    item-type-all="Lose"
    @selected-items="updateFilter" />
</template>
<script>
import {
  LAYER_KEY__TENDER_EXPANSION,
  LAYER_KEY__TENDER_EXPANSION_BORDER,
  LAYER_KEY__TENDER_ROUTE,
} from '@/configs/layers/tenders';
import MultiDropDownGrouped from '@/components/storybook/src/stories/multiDropDownGrouped/multiDropDownGrouped.vue';
import { mapGetters } from 'vuex';
import {
  SWITCH_ID__TENDER_EXPANSION,
  SWITCH_ID__TENDER_ROUTE,
} from '@/configs/sidebar/tenders';
import DropDownMultipleGrouped from '@/components/storybook/src/stories/dropDownMultipleGrouped/DropDownMultipleGrouped.vue';

export default {
  name: 'TenderSelect',
  components: { DropDownMultipleGrouped },
  data() {
    return {
      itemData: [],
    };
  },
  computed: {
    ...mapGetters('map', ['getSwitchStates']),
  },
  mounted() {
    this.$store.dispatch('map/GET_TENDER_CLUSTERS').then((res) => {
      this.itemData = [
        {
          name: 'Alle Lose',
          value: 'all',
          checked: true,
          items: res.map((e) => {
            return {
              name: e.name,
              value: e.name,
              expanded: false,
              checked: true,
              items: e.items
                .sort((a, b) => a.lot_nr - b.lot_nr)
                .map((item) => {
                  return {
                    ...item,
                    name: `Los ${item.lot_nr}`,
                    value: item.id,
                    checked: true,
                  };
                }),
            };
          }),
        },
      ];
    });
  },
  methods: {
    getActiveLotIds(itemsData) {
      let lotIds = [];
      for (let item of itemsData) {
        // if item is lot and is checked, add it to result array
        if ('lot_nr' in item && 'id' in item && item.checked) {
          lotIds.push(item.id);
        }
        // if item has children, look for lots there
        if (
          'items' in item &&
          Array.isArray(item.items) &&
          item.items.length > 0
        ) {
          lotIds = [...lotIds, ...this.getActiveLotIds(item.items)];
        }
      }
      return lotIds;
    },
    updateFilter(itemsData, autoTrigger) {
      if (
        !autoTrigger &&
        !this.getSwitchStates[SWITCH_ID__TENDER_EXPANSION] &&
        !this.getSwitchStates[SWITCH_ID__TENDER_ROUTE]
      ) {
        this.$store.commit('map/CHANGE_SWITCH', {
          switches: [SWITCH_ID__TENDER_EXPANSION],
          active: true,
        });
      }
      const layerKeys = [
        LAYER_KEY__TENDER_EXPANSION,
        LAYER_KEY__TENDER_EXPANSION_BORDER,
        LAYER_KEY__TENDER_ROUTE,
      ];
      let filter = null;
      // if all have been selected, simply send true
      if (itemsData.length && itemsData[0].checked) {
        filter = ['boolean', true];
      } else {
        const activeLotIds = this.getActiveLotIds(itemsData);
        // if no lots have been selected, send false
        if (activeLotIds.length === 0) {
          filter = ['boolean', false];
        }
        // otherwise map lot ids to mapbox filter syntax
        else {
          filter = [
            'any',
            ...activeLotIds.map((lotId) => {
              return ['==', ['get', 'lot_id'], lotId];
            }),
          ];
        }
      }
      this.$store.commit('map/SET_ADDITIONAL_FILTERS', {
        filter,
        layerKeys,
      });
    },
  },
};
</script>
