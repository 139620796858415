<template>
  <template v-for="item in itemsData" :key="item[valueKey]">
    <div v-if="!('hidden' in item) || !item.hidden" class="flex">
      <label
        :class="{
          'p-2 min-h-[32px] body-2': small,
          'p-3 min-h-[40px] body-1': !small,
        }"
        :style="
          levelAdjustments[level].indent
            ? `padding-left: ${levelAdjustments[level].indent}px !important;`
            : null
        "
        class="flex items-center hover:bg-subtle w-full cursor-pointer">
        <input
          :value="item.checked"
          :checked="item.checked"
          class="appearance-none"
          type="checkbox"
          :name="item[valueKey]"
          @click="selectItem(item)" />
        <span class="checkmark mr-3"></span>
        <span :class="levelAdjustments[level].fontClass">{{
          dataKey ? item[dataKey] : item
        }}</span>
      </label>
      <div v-if="'expanded' in item" class="ml-auto align-self-center">
        <v-icon
          v-if="!item.expanded"
          icon="mdi-plus"
          @click="(e) => expandItem(item, true, e)" />
        <v-icon
          v-if="item.expanded"
          icon="mdi-minus"
          @click="(e) => expandItem(item, false, e)" />
      </div>
    </div>
    <drop-down-multiple-grouped-list
      v-if="hasMoreSubItems(item) && (!('expanded' in item) || item.expanded)"
      :small="small"
      :items-data="item.items"
      :level-adjustments="levelAdjustments"
      :level="level + 1"
      :expandable="'expanded' in item"
      :expanded="'expanded' in item && item.expanded"
      :value-key="valueKey"
      :data-key="dataKey"
      @select="checkAllChildrenActive(item)" />
  </template>
</template>

<script>
export default {
  name: 'DropDownMultipleGroupedList',
  props: {
    itemsData: {
      type: Array,
      required: true,
    },
    dataKey: {
      type: String,
      default: null,
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    small: {
      type: Boolean,
      default: false,
    },
    levelAdjustments: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    expandable: {
      type: Boolean,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['select'],
  methods: {
    hasMoreSubItems(item) {
      return (
        'items' in item && Array.isArray(item.items) && item.items.length > 0
      );
    },
    selectItem(item, newVal = null) {
      newVal = newVal === null ? !item.checked : newVal;
      item.checked = newVal;
      let root = item;
      if (this.hasMoreSubItems(root)) {
        for (let subItem of root.items) {
          this.selectItem(subItem, newVal);
        }
      }
      this.$emit('select');
    },
    expandItem(item, newVal, event) {
      item.expanded = newVal;
      event.stopPropagation();
    },
    checkAllChildrenActive(item) {
      let allActive = true;
      for (let subItem of item.items) {
        if (subItem.checked === false) {
          allActive = false;
        }
      }
      item.checked = allActive;
      this.$emit('select');
    },
  },
};
</script>
