export default [
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: [
      'all',
      ['==', ['geometry-type'], 'LineString'],
      ['!=', ['get', 'mode'], 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#0692BC',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: [
      'all',
      ['==', ['geometry-type'], 'Polygon'],
      ['!=', ['get', 'mode'], 'static'],
    ],
    paint: {
      'fill-color': '#0692BC',
      'fill-outline-color': '#0692BC',
      'fill-opacity': 0.1,
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: [
      'all',
      ['==', ['geometry-type'], 'Polygon'],
      ['!=', ['get', 'mode'], 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#0692BC',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: [
      'all',
      ['==', ['get', 'meta'], 'vertex'],
      ['==', ['geometry-type'], 'Point'],
      ['!=', ['get', 'mode'], 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#0692BC',
    },
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: [
      'all',
      ['==', ['get', 'meta'], 'vertex'],
      ['==', ['geometry-type'], 'Point'],
      ['!=', ['get', 'mode'], 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#ffffff',
    },
  },
];
