<template>
  <div class="relative">
    <div
      class="flex items-center h-[32px] w-full bg-white rounded-[6px] border-blue-grey-100 border"
      :class="{ 'rounded-b-none': searchResults.length && input.trim() }">
      <input
        v-model="input"
        type="text"
        :placeholder="selectedItem ? selectedItem.name : placeholder"
        class="p-[8px] focus:outline-none w-full body-2"
        :class="{ 'placeholder:text-black': selectedItem }" />
      <div class="flex justify-center items-center gap-1">
        <div
          v-if="input || selectedItem"
          class="cursor-pointer mb-[1px] pr-[5px]"
          @click="
            selectedItem = null;
            input = null;
          ">
          <v-icon>mdi-close</v-icon>
        </div>
        <div
          v-if="!input && !selectedItem"
          class="max-w-[32px] pointer-events-none pr-[8px]">
          <ZoomIcon />
        </div>
      </div>
    </div>
    <!-- search results-->
    <div
      v-if="searchResults.length && input.trim()"
      class="bg-white absolute z-30 top-[32px] w-full rounded-b-[6px] border border-t-0 border-blue-grey-100 overflow-hidden max-h-[288px] overflow-y-auto">
      <div
        v-for="result in searchResults"
        :key="result"
        class="px-[8px] py-2 hover:bg-gray-100 cursor-pointer body-2"
        @click="
          selectedItem = result;
          input = null;
        ">
        {{ result.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ZoomIcon from '../../assets/icons/ZoomIcon.vue';

export default {
  components: { ZoomIcon },
  props: {
    placeholder: {
      type: String,
      default: 'Suchbegriff',
    },
    data: {
      type: Array,
      default: () => [
        {
          id: 1,
          name: 'Test',
        },
        {
          id: 2,
          name: 'Test2',
        },
        {
          id: 3,
          name: 'Test3',
        },
      ],
    },
  },
  emits: ['selectedItem'],
  data() {
    return {
      input: null,
      selectedItem: null,
    };
  },
  computed: {
    searchResults() {
      if (!this.input) return [];
      return this.data.filter((item) =>
        item.name.toLowerCase().includes(this.input.toLowerCase().trim()),
      );
    },
  },
  watch: {
    selectedItem(val) {
      this.$emit('selectedItem', val);
    },
  },
};
</script>
