<template>
  <!-- Switch-List -->
  <div class="flex-column px-2 bg-infra-highlight-25">
    <div class="flex items-center">
      <v-switch
        v-if="
          !menuItem.modelNameForCASL || $can('view', menuItem.modelNameForCASL)
        "
        v-model="active"
        class="w-full my-0 py-0 bg-infra-highlight-25 flex items-center font-medium"
        hide-details="auto"
        :disabled="
          (menuItem.modelNameForCASL &&
            !$can('view', menuItem.modelNameForCASL)) ||
          loading
        "
        :loading="loading"
        inset
        dense>
        <template #label>
          <span class="body-3">
            {{ menuItem.title }}
          </span>
        </template>
      </v-switch>
    </div>

    <!-- Sub-Radio-List -->
    <div v-if="menuItem.radioGroups" class="w-full">
      <transition
        v-for="radioGroup in menuItem.radioGroups"
        :key="radioGroup.index"
        name="reveal">
        <v-list-item
          v-if="active"
          class="p-0"
          style="padding-inline-start: 0 !important">
          <v-radio-group
            v-model="radioGroup.activated"
            hide-details="auto"
            class="sub-radio-list-group pb-2">
            <v-radio
              v-for="(item, index) in radioGroup.radios"
              :key="index"
              :value="index"
              @change="emitRadioAction(item)">
              <template #label>
                <span class="body-3">
                  {{ item.title }}
                </span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-list-item>
      </transition>
    </div>
    <!-- / Sub-Radio-List -->
  </div>
  <!-- / Switch-List -->
</template>

<script>
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
import { mapGetters } from 'vuex';

export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('map', ['getSwitchStates']),
    active: {
      get() {
        return this.getSwitchStates[this.menuItem.switchId];
      },
      set(val) {
        const switches = [this.menuItem.switchId];
        this.$store.commit('map/CHANGE_SWITCH', { switches, active: val });
      },
    },
  },
  watch: {
    active(val) {
      const s = this.menuItem;
      if (s.onLayers) this.emitLayerVisibility(s.onLayers, val);
      if (s.turnOffSwitches && val)
        this.emitChangeSwitch(s.turnOffSwitches, false);
      if (s.onSwitches) this.emitChangeSwitch(s.onSwitches, val);
    },
  },

  methods: {
    emitLayerVisibility(onLayers, active) {
      this.$store.commit('map/SET_LAYER_VIS', { onLayers, active });
    },
    emitRadioAction(item) {
      if (item.layerState) {
        this.$store.commit('map/SET_LAYER_STATE', {
          layerKeys: item.layerKeys,
          layerState: item.layerState,
        });
      }
      if (item.filter) {
        this.$store.commit('map/SET_FILTER', {
          layerKeys: item.layerKeys,
          filter: item.filter,
        });
      }
    },
    emitChangeSwitch(switches, active) {
      this.$store.commit('map/CHANGE_SWITCH', { switches, active });
    },
  },
};
</script>

<style lang="scss">
.switchery-label-custom {
  //styleName: Standard/Body3•14px•400;
  //font-family: AvenirNext;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: left;
  color: black;
}
</style>
