<template>
  <div class="flex flex-col gap-3">
    <h6 class="text-infra-highlight-800">
      Ordnen Sie Ihrer Zeichnung bitte nun ihre Notiz zu.
    </h6>
    <!--    make an text input field which is 100px height and 100% wide -->
    <textarea
      v-model="content"
      class="w-full h-[100px] rounded-[6px] border border-blue-grey-100 p-[10px] focus-visible:outline-blue-grey-200 resize-none"
      placeholder="Notiz hinzufügen" />
    <!--    textarea without the thing in the right bottom corner to change the size-->
    <button-el text="Absenden" @click="submitTicket()" />
  </div>
</template>
<script>
import { step2submitData } from '../../../ticketHelper';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Step2Ticket',
  props: {
    successToast: {
      type: String,
    },
  },
  data() {
    return {
      content: '',
    };
  },
  computed: {
    ...mapGetters('ticket', ['getDrawAsMultiFeatures', 'getGemeindeActiveAgs']),
    ...mapState('draw', ['selectedFeatures']),
  },
  methods: {
    submitTicket() {
      const multilinestring = this.getDrawAsMultiFeatures.multilinestring;
      const multipolygon = this.getDrawAsMultiFeatures.multipolygon;
      const ticketData = {
        content: this.content,
        gemeinde: this.getGemeindeActiveAgs,
        address_points: this.selectedFeatures,
      };
      if (multilinestring) {
        ticketData.multilinestring = multilinestring;
      }
      if (multipolygon) {
        ticketData.multipolygon = multipolygon;
      }

      step2submitData('ticket/POST_NOTE', ticketData, this.successToast, this);
    },
  },
};
</script>
