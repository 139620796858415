<template>
  <v-dialog v-model="mapActionsDialog" width="auto">
    <template #activator="{ props }">
      <div
        class="search-bar-icon bg-infra-highlight-25 hover:bg-infra-highlight-50 rounded-r-[8px]"
        v-bind="props">
        <img class="w-[16px]" src="@/assets/svg/grid-view.svg" alt="grid" />
      </div>
    </template>

    <div class="flex flex-col standard-elevation-2">
      <div
        class="flex flex-col justify-center items-start bg-infra-highlight-500 p-[20px] rounded-t-[4px]">
        <the-label label="Bitte Gemeinde auswählen:" class="text-white" />
        <div class="flex items-center w-full gap-[10px]">
          <search-bar-item
            placeholder="Gemeindenamen eingeben"
            :data="gemeinden"
            class="w-full"
            @selected-item="setGemeinde($event)" />
        </div>
      </div>
      <div class="grid grid-cols-3 bg-white rounded-b-[4px] overflow-hidden">
        <v-card
          v-for="item in ticketProcesses"
          :key="item"
          aria
          :disabled="
            item.disabled ||
            getGemeindeActive === null ||
            !$can('add', item.modelNameForCASL)
          "
          :class="item.selected ? 'bg-infra-background' : ''"
          class="relative mx-auto w-[150px] flex flex-col whitespace-pre-line shadow-none h-[145px] rounded-none items-center py-[25px] px-[16px] hover:bg-infra-background cursor-pointer gap-[20px]"
          @click="setMapActions(item)">
          <component
            :is="item.icon"
            :fill="item.selected ? '#79909D' : '#B0BEC5'"
            class="min-w-[44px] min-h-[44px]" />
          <div class="body-2 text-center">{{ item.title }}</div>
        </v-card>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { flyTo } from '@/mapbox/main';
import { draw } from '@/features/draw/draw';

export default {
  data() {
    return {
      mapActionsDialog: false,
    };
  },
  computed: {
    ...mapState('ticket', ['ticketProcesses']),
    ...mapGetters('ticket', ['getActiveProcess', 'getGemeindeActive']),
    ...mapGetters('auth', ['gemeinden']),
  },
  watch: {
    mapActionsDialog(val) {
      this.$store.commit('map/SET_MAP_ACTIONS_DIALOG_IS_OPEN', val);
      this.$store.commit('search/RESET_PLACES', null);
    },
    getActiveProcess() {
      this.$store.commit('ticket/SET_DRAW_FEATURES', []);
      this.$store.commit('ticket/SET_TICKET_STEP', 1);
      this.$store.commit('ticket/SET_VALIDATION_STEP', 1);
      this.$store.commit('ticket/SET_SHOW_VALIDATION_TICKET', false);
      this.$store.commit('ticket/SET_DRAW_SELECTED_IDS', []);
      draw.deleteAll();
    },
  },
  methods: {
    setMapActions(item) {
      this.$store.commit('ticket/SET_TICKET_PROCESS', item.id);
      this.mapActionsDialog = false;
    },

    setGemeinde(item) {
      this.$store.commit('ticket/SET_GEMEINDE_ACTIVE', item);
      // fly to gemeindeActive
      if (item) {
        const config = {
          zoom: 16.9,
          lng: this.getGemeindeActive.centroid[0],
          lat: this.getGemeindeActive.centroid[1],
        };
        flyTo(config);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mapActionsDialog {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
