<template>
  <div class="flex flex-col gap-3">
    <h6 class="text-infra-highlight-800">
      Ordnen Sie Ihrer Zeichnung bitte nun ihre MEV-Notiz zu.
    </h6>
    <DropDown
      v-model="category"
      :items-data="getMevValidationOption"
      items-data-key="display_name"
      :initial-selection="true"
      :small="true"
      :rules="{ required: true }"
      label="Kategorie"
      :no-min-height="true" />
    <div class="flex flex-col">
      <the-label label="Weitere Bemerkungen" />
      <textarea
        v-model="content"
        class="w-full h-[100px] body-2 rounded-[6px] border border-blue-grey-100 p-[10px] focus-visible:outline-blue-grey-200 resize-none"
        placeholder="Bitte eingeben" />
    </div>
    <div class="flex flex-col gap-[4px]">
      <h6 class="text-infra-highlight-800">So geht es weiter</h6>
      <div class="body-2">
        In einem nächsten Schritt werden wir Ihre Angaben prüfen und Sie bei
        Bedarf zur Klärung des Sachverhalts direkt kontaktieren.
      </div>
    </div>
    <button-el text="Absenden" @click="submitTicket()" />
  </div>
</template>
<script>
import { step2submitData } from '../../../ticketHelper';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Step2Mev',
  props: {
    successToast: {
      type: String,
    },
  },
  data() {
    return {
      content: '',
      category: null,
    };
  },
  computed: {
    ...mapGetters('ticket', [
      'getDrawAsMultiFeatures',
      'getGemeindeActiveAgs',
      'getMevValidationOption',
    ]),
    ...mapState('draw', ['selectedFeatures']),
  },
  methods: {
    submitTicket() {
      const multilinestring = this.getDrawAsMultiFeatures.multilinestring;
      const multipolygon = this.getDrawAsMultiFeatures.multipolygon;
      const ticketData = {
        content: this.content,
        gemeinde: this.getGemeindeActiveAgs,
        address_points: this.selectedFeatures,
        category: this.category,
      };
      if (multilinestring) {
        ticketData.multilinestring = multilinestring;
      }
      if (multipolygon) {
        ticketData.multipolygon = multipolygon;
      }

      step2submitData(
        'ticket/POST_MEV_VALIDATION',
        ticketData,
        this.successToast,
        this
      );
    },
  },
};
</script>
