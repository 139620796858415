<template>
  <div v-if="subtitle.value !== '0' && long">
    <h6 class="text-infra-highlight-800">{{ subtitle.label }}:</h6>
    <div class="body-3 text-black">{{ subtitle.value }}</div>
  </div>
  <div v-else-if="subtitle.value !== '0'" class="flex gap-1 items-center">
    <h6 class="text-infra-highlight-800">{{ subtitle.label }}:</h6>
    <div class="body-3 text-black">{{ subtitle.value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    subtitle: {
      type: Object,
      default: () => {},
    },
    long: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
