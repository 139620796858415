<template>
  <single-page>
    <div class="flex p-4 w-full flex-col">
      <div class="flex items-center justify-start mb-5">
        <div class="flex items-center">
          <div class="w-20 h-20 mr-4 relative">
            <img src="@/assets/svg/foerderantraege.svg" />
          </div>
        </div>
        <div class="text-[32px]">Dokumente</div>
      </div>
      <div class="border-b-2">
        <skeleton-loader-list v-if="isLoading" />
        <template v-else>
          <v-col cols="12" xs="6" md="4">
            <v-select
              v-model="selectedFolder"
              :items="folders"
              label="Ordner"
              variant="outlined"
              density="compact"
              item-title="name"
              item-value="name" />
          </v-col>
          <v-data-table
            :headers="headers"
            :items="selectedFolderItems"
            :on-click:row="expandVideo"
            :sort-by="[{ key: 'name', order: 'asc' }]"
            :custom-key-sort="customDateSort"
            density="compact"
            class="pb-3"
            item-key="id"
            items-per-page="10">
            <!-- eslint-disable-next-line -->
            <template #item.type="{ item }">
              {{
                item.ext_type
                  ? item.ext_type.toUpperCase()
                  : item.type.toUpperCase()
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.last_edited="{ item }">
              {{ dayjs(item.last_edited).format('DD.MM.YYYY') }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <v-btn
                v-if="item.type === 'external'"
                icon="mdi-play"
                variant="text"
                color="blue"
                @click="showExternal(item)" />
              <v-btn
                v-else
                icon="mdi-download"
                variant="text"
                color="blue"
                @click="download(item)" />
            </template>
          </v-data-table>
        </template>
      </div>
    </div>
    <v-dialog v-model="showExternalDialog">
      <v-card
        class="p-2 flex justify-center align-center align-self-center"
        style="height: 375px; width: 660px">
        <div v-show="isExternalLoading">
          <v-progress-circular color="blue" indeterminate />
        </div>
        <iframe
          v-show="!isExternalLoading"
          class="player"
          width="640px"
          height="360px"
          :src="showExternalDialog"
          allow="autoplay; encrypted-media"
          allowfullscreen
          @load="isExternalLoading = false" />
      </v-card>
    </v-dialog>
  </single-page>
</template>

<script>
import SkeletonLoaderList from '@/components/global/skeletonLoaderList.vue';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

export default {
  components: { SkeletonLoaderList },
  data() {
    return {
      selectedFolder: null,
      selectedFolderItems: [],
      isLoading: true,
      showExternalDialog: false,
      isExternalLoading: false,
      folders: [],
      headers: [
        {
          align: 'start',
          key: 'name',
          sortable: true,
          title: 'Datei',
        },
        {
          align: 'start',
          key: 'last_edited',
          title: 'Letztes Update',
          sortable: true,
          width: 180,
        },
        {
          align: 'start',
          key: 'type',
          title: 'Dateityp',
          sortable: true,
          width: 150,
        },
        {
          align: 'center',
          key: 'actions',
          title: 'Aktionen',
          sortable: false,
          width: 150,
        },
      ],
      customDateSort: {
        last_edited: (a, b) => {
          const aCreatedDate = dayjs(a);
          const bCreatedDate = dayjs(b);
          if (aCreatedDate < bCreatedDate) return -1;
          if (aCreatedDate > bCreatedDate) return 1;
          return 0;
        },
      },
    };
  },
  watch: {
    selectedFolder(newFolder) {
      this.selectedFolderItems = this.folders.find(
        (f) => f.name === newFolder
      ).files;
    },
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('dashboard/GET_DOCUMENTS').then((files) => {
      const folders = files
        .filter((f) => f.type === 'folder')
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      this.folders = folders;
      if (this.folders.length) {
        this.selectedFolder = folders[0].name;
        this.selectedFolderItems = folders[0].files;
      }
      this.isLoading = false;
    });
  },
  methods: {
    dayjs,
    showExternal(item) {
      this.isExternalLoading = true;
      this.showExternalDialog = item.source;
    },
    download(item) {
      const reqObj = {
        displayName: item.name,
        fileName: item.value,
        fileExt: item.type,
        directory: this.folders.find((f) => f.name === this.selectedFolder)
          .value,
      };
      this.$store.dispatch('dashboard/DOWNLOAD_FILE', reqObj);
    },
  },
};
</script>
