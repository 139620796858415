<template>
  <div v-if="!isTableLoading">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="search"
          single-line
          variant="outlined"
          append-inner-icon="mdi-magnify"
          placeholder="Bitte Suchbegriff eingeben"
          density="compact"
          hide-details />
      </v-col>
    </v-row>
    <v-row>
      <v-container style="max-width: 100%" class="p-3 shadow-xl">
        <v-card class="m-0.5 p-2" elevation="3">
          <v-row>
            <v-col cols="12" sm="3">
              <v-label class="text-xs"> Aktion </v-label>
              <v-select
                v-model="selectedAction"
                placeholder="Bitte auswählen"
                density="compact"
                variant="outlined"
                :items="actions" />
            </v-col>
            <v-col cols="12" sm="5">
              <v-btn
                :disabled="!selected.length || !selectedAction"
                :loading="isActionLoading"
                style="margin-top: 21px; text-transform: unset"
                class="float-left"
                height="43"
                append-icon="mdi-arrow-right"
                color="blue"
                @click="execute">
                Ausführen
              </v-btn>
              <div class="pl-4 pt-8 float-left">
                {{ selected.length }} von {{ getInvitations.length }} ausgewählt
              </div>
            </v-col>
            <v-col class="text-end pt-8" cols="12" sm="4">
              <v-btn
                height="43"
                variant="outlined"
                @click="showNewInvitationForm">
                Neue Einladung
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="getInvitations"
              :search="search"
              :show-select="true"
              :on-click:row="showInvitationEditForm"
              :custom-key-sort="customDateSort"
              data-cy="invitations-table"
              density="compact"
              class="pb-3"
              item-key="id"
              items-per-page="10">
              <!-- eslint-disable-next-line -->
              <template #item.accepted="{ item }">
                <div class="flex align-content-center justify-center w-100">
                  <img
                    v-if="item.accepted"
                    src="@/assets/svg/check-green.svg" />
                  <c-remove v-else fill="red" width="16" height="16" />
                </div>
              </template>
            </v-data-table>
          </v-row>
        </v-card>
      </v-container>
    </v-row>
  </div>
  <div v-else>
    <skeleton-loader-list />
  </div>
  <v-dialog v-model="selectedInvitationId" width="700" persistent>
    <v-card class="p-5">
      <v-form v-if="!isDetailLoading">
        <!-- email -->
        <v-label class="text-xs">* E-Mail</v-label>
        <v-text-field
          v-model="email"
          :error="invalidEmail"
          :error-messages="invalidEmail"
          :disabled="selectedInvitationId !== 'new'"
          variant="outlined"
          density="compact"
          @focus="touchedEmail = true" />
        <h4 class="pt-5">Berechtigungen</h4>
        <!-- is staff -->
        <v-label class="text-xs">
          Bestimmt, ob der User Zugriff auf das User-Management hat.
        </v-label>
        <v-checkbox
          v-model="isStaff"
          density="compact"
          label="Staff Status"
          hide-details />
        <!-- role -->
        <v-label class="text-xs"> * Benutzerrolle </v-label>
        <v-select
          v-model="selectedGroup"
          :error="invalidGroup"
          :error-messages="invalidGroup"
          item-title="name"
          item-value="id"
          placeholder="Bitte auswählen"
          density="compact"
          variant="outlined"
          :items="getGroups"
          @focus="touchedGroup = true" />
        <!-- gemeinde permissions -->
        <v-label class="text-xs">
          (*) Sämtliche Gemeinden eines Landkreises, auf die der User Zugriff
          erhalten soll (optional, ersetzt Gemeindeauswahl)
        </v-label>
        <v-autocomplete
          v-model="landkreise"
          :items="getLandkreise"
          :multiple="true"
          :error="invalidGemeindenOrLandkreise"
          :error-messages="invalidGemeindenOrLandkreise"
          item-value="kreis_id"
          item-title="name"
          density="compact"
          label="Nach Landkreisen suchen"
          variant="outlined"
          @focus="touchedGemeindeOrLandkreis = true">
          <template #prepend-item>
            <v-list-item
              height="40"
              class="pt-5"
              density="compact"
              @click="() => {}">
              <v-checkbox
                v-model="allLandkreiseSelected"
                label="Alle"
                @click="selectAllLandkreise" />
            </v-list-item>
          </template>
          <template #selection="{ item, index }">
            <span v-if="index < 2">{{ item.title }}</span>
            <span v-if="index < 1 && landkreise.length > 1">,&nbsp;</span>
            <span v-if="index === 2"
              >&nbsp;und {{ landkreise.length - 2 }} weitere&nbsp;</span
            >
          </template>
        </v-autocomplete>
        <v-label class="text-xs">
          * Gemeinden, auf die der User Zugriff erhalten soll (erforderlich,
          kann durch Landkreisbündelung ersetzt werden)
        </v-label>
        <v-autocomplete
          v-model="gemeinden"
          :items="getGemeinden"
          :multiple="true"
          :error="invalidGemeindenOrLandkreise"
          :error-messages="invalidGemeindenOrLandkreise"
          item-value="ags"
          item-title="name"
          density="compact"
          label="Nach Gemeinden suchen"
          variant="outlined">
          <template #prepend-item>
            <v-list-item
              height="40"
              class="pt-5"
              density="compact"
              @click="() => {}">
              <v-checkbox
                v-model="allGemeindenSelected"
                label="Alle"
                @click="selectAllGemeinden" />
            </v-list-item>
          </template>
          <template #selection="{ item, index }">
            <span v-if="index < 2">{{ item.title }}</span>
            <span v-if="index < 1 && gemeinden.length > 1">,&nbsp;</span>
            <span v-if="index === 2"
              >&nbsp;und {{ gemeinden.length - 2 }} weitere&nbsp;</span
            >
          </template>
        </v-autocomplete>
        <br />
        <!-- buttons -->
        <v-btn
          class="float-left"
          variant="flat"
          @click="emailPreviewOpen = true">
          E-Mail Vorschau
        </v-btn>
        <v-btn
          :loading="isUpdating"
          :disabled="invalid"
          class="float-right ml-3"
          variant="flat"
          color="blue"
          @click="updateOrCreateInvitation">
          Speichern
        </v-btn>
        <v-btn
          class="float-right"
          variant="outlined"
          @click="selectedInvitationId = null">
          Abbrechen
        </v-btn>
      </v-form>
      <skeleton-loader-list v-if="isDetailLoading" />
    </v-card>
  </v-dialog>
  <v-dialog v-model="emailPreviewOpen">
    <!-- eslint-disable-next-line -->
    <v-card id="email-preview" class="p-4" v-html="emailPreviewHtml" />
    <!--      <email-preview :email="email" />-->
    <!--    </v-card>-->
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonLoaderList from '@/components/global/skeletonLoaderList.vue';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

export default {
  components: { SkeletonLoaderList },
  data() {
    return {
      actions: [{ title: 'Ausgewählte Einladung löschen', value: 'delete' }],
      selected: [],
      selectedAction: null,
      selectedInvitationId: null,
      email: '',
      touchedEmail: false,
      isStaff: false,
      landkreise: [],
      gemeinden: [],
      touchedGemeindeOrLandkreis: false,
      selectedGroup: null,
      touchedGroup: false,
      isTableLoading: true,
      isDetailLoading: true,
      isUpdating: false,
      isActionLoading: false,
      emailPreviewOpen: false,
      search: '',
      emailStyle: {},
      headers: [
        {
          align: 'start',
          key: 'email',
          sortable: true,
          title: 'E-Mail',
        },
        {
          align: 'start',
          key: 'created',
          sortable: true,
          title: 'Einladungsdatum',
          width: 200,
        },
        {
          align: 'center',
          key: 'accepted',
          sortable: true,
          title: 'Status der Einladung',
          width: 192,
        },
      ],
      customDateSort: {
        created: (a, b) => {
          dayjs.extend(CustomParseFormat);
          const aCreatedDate = dayjs(a, 'DD.MM.YYYY HH:mm:ss', 'de');
          const bCreatedDate = dayjs(b, 'DD.MM.YYYY HH:mm:ss', 'de');
          if (aCreatedDate < bCreatedDate) return -1;
          if (aCreatedDate > bCreatedDate) return 1;
          return 0;
        },
      },
    };
  },
  computed: {
    ...mapGetters('dashboard', [
      'getInvitations',
      'getGemeinden',
      'getLandkreise',
      'getGroups',
      'getEmailPreview',
    ]),
    ...mapGetters('auth', ['profil']),
    allLandkreiseSelected() {
      return this.landkreise.length === this.getLandkreise.length;
    },
    allGemeindenSelected() {
      return this.gemeinden.length === this.getGemeinden.length;
    },
    invalid() {
      return (
        this.invalidEmail ||
        this.invalidGemeindenOrLandkreise ||
        this.invalidGroup
      );
    },
    invalidEmail() {
      if (this.touchedEmail && !this.email) {
        return 'Darf nicht leer sein.';
      }
      if (this.touchedEmail && !/.+@.+\..+/.test(this.email)) {
        return 'Muss eine gültige E-Mail Adresse sein.';
      }
      return false;
    },
    invalidGemeindenOrLandkreise() {
      if (
        this.touchedGemeindeOrLandkreis &&
        !this.landkreise.length &&
        !this.gemeinden.length
      ) {
        return 'Mindestens eine Gemeinde oder ein Landkreis muss ausgewählt werden';
      }
      return false;
    },
    invalidGroup() {
      if (this.touchedGroup && !this.selectedGroup) {
        return 'Bitte wählen Sie eine User-Gruppe aus';
      }
      return false;
    },
    emailPreviewHtml() {
      if (this.getEmailPreview) {
        let emailPreviewText = this.getEmailPreview;
        emailPreviewText = emailPreviewText.replace('{{ expires_in }}', '14');
        emailPreviewText = emailPreviewText.replace(
          '{{ email }}',
          'neuer-user@domain.com'
        );
        emailPreviewText = emailPreviewText.replace('{{ invite_link }}', '/');
        return emailPreviewText;
      }
      return '';
    },
  },
  mounted() {
    this.isTableLoading = true;
    this.$store.dispatch('dashboard/GET_INVITATIONS').then(() => {
      this.isTableLoading = false;
    });
  },
  methods: {
    execute() {
      this.isActionLoading = true;
      if (this.selectedAction === 'delete') {
        this.$store
          .dispatch('dashboard/DELETE_INVITATIONS', this.selected)
          .then(() => {
            this.selected = [];
            this.isActionLoading = false;
            this.isTableLoading = true;
            this.$store.dispatch('dashboard/GET_INVITATIONS').then(() => {
              this.isTableLoading = false;
            });
          });
      }
    },
    showInvitationEditForm(e, item) {
      if (item.item.accepted) return;
      this.landkreise = [];
      this.selectedInvitationId = item.item.id;
      this.isDetailLoading = true;
      this.$store
        .dispatch('dashboard/GET_INVITATION_DETAILS', item.item.id)
        .then((data) => {
          this.email = data.email;
          this.isStaff = data.is_staff;
          this.gemeinden = data.gemeinden;
          this.selectedGroup = data.groups;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isDetailLoading = false;
        });
    },
    showNewInvitationForm() {
      this.landkreise = [];
      this.gemeinden = [];
      this.email = '';
      this.selectedGroup = null;
      this.isStaff = false;
      this.selectedInvitationId = 'new';
      this.isDetailLoading = false;
      this.touchedEmail = false;
      this.touchedGroup = false;
      this.touchedGemeindeOrLandkreis = false;
    },
    updateOrCreateInvitation() {
      const data = {
        landkreise: this.landkreise,
        gemeinden: this.gemeinden,
        is_staff: this.isStaff,
        email: this.email,
        group: this.selectedGroup,
      };
      this.isUpdating = true;
      if (this.selectedInvitationId === 'new') {
        this.$store
          .dispatch('dashboard/CREATE_INVITATION', data)
          .then(() => {
            this.selectedInvitationId = null;
            this.isTableLoading = true;
            this.$store.dispatch('dashboard/GET_INVITATIONS').then(() => {
              this.isTableLoading = false;
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isUpdating = false;
          });
      } else {
        this.$store
          .dispatch('dashboard/SAVE_INVITATION', {
            id: this.selectedInvitationId,
            data,
          })
          .then(() => {
            this.selectedInvitationId = null;
            this.isTableLoading = true;
            this.$store.dispatch('dashboard/GET_INVITATIONS').then(() => {
              this.isTableLoading = false;
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isUpdating = false;
          });
      }
    },
    selectAllLandkreise() {
      if (this.allLandkreiseSelected) {
        this.landkreise = [];
      } else {
        this.landkreise = this.getLandkreise.map((l) => l.kreis_id);
      }
    },
    selectAllGemeinden() {
      if (this.allGemeindenSelected) {
        this.gemeinden = [];
      } else {
        this.gemeinden = this.getGemeinden.map((g) => g.ags);
      }
    },
  },
};
</script>

<style>
#email-preview p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
#email-preview span {
  display: contents;
}
#email-preview * {
  all: unset;
  font: small/1.5 Arial, Helvetica, sans-serif;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-variant-alternates: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-variant-position: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: small;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
}
#email-preview a {
  color: blue;
  text-decoration: underline;
}
#email-preview hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border: unset;
  border-style: inset;
  border-width: 1px;
  width: 100%;
}
</style>
