<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 4.16667H4.16667V2.5C3.25 2.5 2.5 3.25 2.5 4.16667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM10.8333 2.5H9.16667V4.16667H10.8333V2.5ZM15.8333 2.5V4.16667H17.5C17.5 3.25 16.75 2.5 15.8333 2.5ZM4.16667 17.5V15.8333H2.5C2.5 16.75 3.25 17.5 4.16667 17.5ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM7.5 2.5H5.83333V4.16667H7.5V2.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM15.8333 10.8333H17.5V9.16667H15.8333V10.8333ZM15.8333 17.5C16.75 17.5 17.5 16.75 17.5 15.8333H15.8333V17.5ZM15.8333 7.5H17.5V5.83333H15.8333V7.5ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM12.5 4.16667H14.1667V2.5H12.5V4.16667ZM5.83333 14.1667H14.1667V5.83333H5.83333V14.1667ZM7.5 7.5H12.5V12.5H7.5V7.5Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
