<template>
  <div class="chart-bars flex items-center relative h-[24px]">
    <div
      v-for="(subItem, index) in groupedMilestones"
      :key="subItem"
      :data-duration="subItem.formattedDate + '-' + subItem.formattedDate"
      class="absolute flex justify-center h-full chart-bar-item"
      :style="inactive ? 'opacity: 0.3;' : ''">
      <div
        class="milestone-wrapper flex justify-center items-center"
        @mouseleave="hideMilestone">
        <div class="relative">
          <component
            :is="subItem.data.length === 1 ? 'triangle-icon' : 'div'"
            ref="toolTipActivator"
            :class="
              subItem.data.length === 1
                ? ''
                : 'rounded-full w-3 h-3 bg-[#6D4C41]'
            "
            :fill="
              subItem.data.length === 1 && getMilestoneData(subItem.data[0].key)
                ? getMilestoneData(subItem.data[0].key)[1]
                : null
            "
            @mouseenter="setMilestone(index)">
          </component>
          <ToolTipAdvanced
            v-if="dateItemIndex === index"
            v-model="showPopOver"
            fixed="all"
            :offset-y="16"
            :offset-x="-3">
            <template #content>
              <div
                v-if="subItem.data.length === 1"
                :style="{
                  backgroundColor: getMilestoneData(subItem.data[0].key)[1],
                }"
                class="caption-2 text-white standard-elevation-1 p-1 rounded whitespace-pre body-2 w-max">
                {{ getMilestoneData(subItem.data[0].key)[0] }}
              </div>
              <div
                v-else
                class="flex flex-col gap-1 bg-white standard-elevation-1 z-10 p-1 rounded whitespace-pre body-2">
                <div
                  v-for="(item, index2) in subItem.data"
                  :key="index2"
                  class="flex justify-start items-center">
                  <div class="caption-3 flex justify-between gap-2 w-full">
                    <div class="flex">
                      <triangle-icon :fill="getMilestoneData(item.key)[1]" />
                      {{ getMilestoneData(item.key)[0] }}
                    </div>
                    <div>{{ formatDate(item.value) }}</div>
                  </div>
                </div>
              </div>
            </template>
          </ToolTipAdvanced>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TriangleIcon from '../../../assets/icons/triangle-icon.vue';
import PopOverItem from '../components/popOverItem.vue';
import ToolTipAdvanced from '../../toolTipAdvanced/ToolTipAdvanced.vue';

export default {
  name: 'MilestoneItem',
  components: { PopOverItem, TriangleIcon, ToolTipAdvanced },
  props: {
    milestoneData: {
      type: Object,
      default: () => {},
    },
    bgColor: {
      type: String,
      default: 'bg-black',
    },
    interval: {
      type: String,
      default: 'day',
    },
    intervalData: {
      type: Object,
      default: () => {},
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateItemIndex: null,
      showPopOver: false,
    };
  },
  computed: {
    groupedMilestones() {
      // Map the entries to the required format
      const formattedMilestones = Object.entries(this.milestoneData)
        .filter(([key, value]) => value)
        .map(([key, value]) => ({
          key,
          value,
          formattedDate: this.convertShortDate(value),
        }));

      // Reduce to group by the formattedDate and sort data by value
      return formattedMilestones.reduce((acc, item) => {
        const foundItem = acc.find(
          (x) => x.formattedDate === item.formattedDate,
        );

        if (foundItem) {
          foundItem.data.push(item);
          foundItem.data.sort((a, b) => new Date(a.value) - new Date(b.value)); // sort data by value
        } else {
          acc.push({ formattedDate: item.formattedDate, data: [item] });
        }

        return acc;
      }, []);
    },
  },
  methods: {
    convertShortDate(date) {
      if (this.interval === 'months') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.label;
            }
          });
        });
        return shortCut;
      } else if (this.interval === 'weeks') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.value + '.' + item.year;
            }
          });
        });
        return shortCut;
      } else {
        return date.split('-').reverse().join('.');
      }
    },
    getMilestoneData(milestone) {
      switch (milestone) {
        case 'date_project_start':
          return ['Projektstart', '#338BA8'];
        case 'date_retrieval':
          return ['Abruf', '#67B7D1'];
        case 'date_execution_planning_end':
          return ['Fertigstellung Ausführungsplanung', '#338BA8'];
        case 'date_begin_construction':
          return ['Beginn Bauphase', '#4CAF50'];
        case 'date_end_construction':
          return ['Ende Bauphase', '#4CAF50'];
        case 'date_project_end':
          return ['Projektende', '#009688'];
        // case 'genehmigungsplanung':
        //   return ['Genehmigungsplanung', '#FF9800'];
        // case 'ausfuehrungsplanung':
        //   return ['Ausführungsplanung', '#FFC107'];
        // case 'freigabe_ausfuehrung':
        //   return ['Freigabe Ausführungsplanung', '#CDDC39'];
        // case 'abnahme_bau':
        //   return ['Abnahme Bauleistungen', '#4CAF50'];
        // case 'uebergabe_nb':
        //   return ['Übergabe Netzbetreiber', '#009688'];
      }
    },
    formatDate(date) {
      return date.split('-').reverse().join('.');
    },
    setMilestone(index) {
      this.dateItemIndex = index;
      this.showPopOver = true;
    },
    hideMilestone() {
      this.showPopOver = false;
      this.dateItemIndex = null;
    },
  },
};
</script>
