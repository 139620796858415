import {
  LAYER_KEY__DRAFT_POP,
  LAYER_KEY__DRAFT_NVT,
  LAYER_KEY__DRAFT_NETWORK,
} from '../layers/infrastructureDraft';

export const SWITCH_ID__DRAFT_ALL = 'LC_DRAFT_ALL';
export const SWITCH_ID__DRAFT_POP = 'LC_DRAFT_POP';
export const SWITCH_ID__DRAFT_NVT = 'LC_DRAFT_NVT';
export const SWITCH_ID__DRAFT_NETWORK = 'LC_DRAFT_NETWORK';

export default {
  title: 'Grobplanung',
  modelNameForCASL: 'networkdistributor',
  items: [
    {
      label: 'Phasen',
      itemClass: 'selectFilter',
      layers: [
        LAYER_KEY__DRAFT_NETWORK,
        LAYER_KEY__DRAFT_POP,
        LAYER_KEY__DRAFT_NVT,
      ],
      items: [
        { title: 'Alle Flecken', filter: ['in', 'phase', 3, 4] },
        { title: 'Hellgraue Flecken', filter: ['==', ['get', 'phase'], 3] },
        { title: 'Dunkelgraue Flecken', filter: ['==', ['get', 'phase'], 4] },
      ],
      get default() {
        return this.items[0];
      },
      customFunction(data, value) {
        // the value is the full mapbox-filter
        // we get a list of phases by slicing
        // the operator (==, in) and the attribute ('phase') away
        data.$store.commit('map/SET_PHASE_FILTER', value.slice(2));
        data.$store.commit('map/SET_SHOW_DATA_PANEL', false);
      },
    },
    {
      active: false,
      title: 'Alle anzeigen',
      onSwitches: [
        SWITCH_ID__DRAFT_POP,
        SWITCH_ID__DRAFT_NVT,
        SWITCH_ID__DRAFT_NETWORK,
      ],
      switchId: SWITCH_ID__DRAFT_ALL,
      itemClass: 'switch',
    },
    {
      active: false,
      title: 'Netz',
      onLayers: [LAYER_KEY__DRAFT_NETWORK],
      switchId: SWITCH_ID__DRAFT_NETWORK,
      itemClass: 'switch',
    },
    {
      active: false,
      title: 'Netzverteiler (NVT)',
      onLayers: [LAYER_KEY__DRAFT_NVT],
      switchId: SWITCH_ID__DRAFT_NVT,
      itemClass: 'switch',
    },
    {
      active: false,
      title: 'Point of Presence (POP)',
      onLayers: [LAYER_KEY__DRAFT_POP],
      switchId: SWITCH_ID__DRAFT_POP,
      itemClass: 'switch',
    },
  ],
};
