<template>
  <!--outer container-->
  <div
    class="absolute left-0 top-0 w-full h-full bg-black bg-opacity-[32%] z-50">
    <!-- content container-->
    <div
      class="absolute modalContainerCustom top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-[80%] w-[90%] bg-white px-3 pb-3 pt-20 rounded-[8px] overflow-hidden">
      <div class="p-3 absolute top-0 left-0 w-full flex flex-col gap-[10px]">
        <div class="flex items-center justify-between w-full">
          <h4 class="text-infra-highlight-400">Shortcuts</h4>
          <v-icon
            class="transform rotate-45"
            style="color: #001257"
            icon="$plus"
            @click="closeThis()" />
        </div>
        <h6 class="content-center flex flex-wrap text-infra-highlight-800">
          <slot name="title"></slot>
        </h6>
      </div>
      <!-- content slot-->
      <div class="h-full overflow-y-scroll">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeThis() {
      this.$store.commit('SET_FULLSCREEN_MODAL', {
        visible: false,
        config: {},
      });
    },
    closeModalFromOutside(e) {
      if (!e.target.classList.contains('modalContainerCustom')) {
        this.closeThis();
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
