<template>
  <div class="w-full flex h-screen p-8">
    <div class="bg-white p-8 w-full text-center">
      <v-img
        src="/static/images/404-illustration.svg"
        max-height="400"
        max-width="400"
        contain
        class="mx-auto"
      />
      <div class="text-xl mt-10">Uuups, wie sind wir denn hier hin gekommen?</div>
      <div class="mt-3 mb-6">Tut uns leid, die angefragte Seite existiert nicht!</div>
      <v-btn to="/" block large color="primary">Zurück zur Startseite</v-btn>
    </div>
  </div>
</template>
