<template>
  <v-chart ref="chart" :option="option" class="h-96" autoresize />
</template>

<script>
// import { mapGetters } from 'vuex';

export default {
  name: 'BarChartStackedVertical',
  props: {
    chartData: {},
    chartTitle: {
      type: String,
      default: '',
    },
    multiply: {
      type: Number,
      default: null,
    },
    customMinHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    option() {
      const rawData = this.chartData;

      const years = rawData.map((d) => d.year);

      const seriesData = rawData.reduce((acc, d) => {
        d.data.forEach((ht) => {
          const existingSeries = acc.find((s) => s.name === ht.name);
          if (existingSeries) {
            existingSeries.data.push(
              this.multiply ? ht.value * this.multiply : ht.value
            );
          } else {
            acc.push({
              name: ht.name,
              type: 'bar',
              stack: 'total',
              data: [this.multiply ? ht.value * this.multiply : ht.value],
              itemStyle: {
                color: ht.color,
              },
              barWidth: 50,
            });
          }
        });
        return acc;
      }, []);
      return {
        title: {
          text: this.chartTitle,
          textStyle: {
            color: 'black',
            textAlign: 'center',
            lineHeight: 25,
            fontFamily: 'Avenir Next, sans-serif',
            fontWeight: 500,
            fontSize: 18,
          },
          left: 'center',
          top: '25px',
        },
        grid: { bottom: '15%', top: '20%', containLabel: true },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: (params) => {
            let result = '';
            params.forEach((item) => {
              // filter 0 values
              if (item.value === 0) {
                return;
              }
              result += `${item.marker} ${item.seriesName}: ${Math.floor(
                item.value
              ).toLocaleString('de-DE')}<br/>`;
            });
            return result;
          },
        },
        legend: {
          // show only items in the legend that are visible in the chart
          data: seriesData
            .filter((s) => s.data.some((d) => d > 0))
            .map((s) => s.name),
          bottom: 0,
          itemWidth: 12,
          itemHeight: 12,
        },
        xAxis: {
          type: 'category',
          data: years,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            formatter: (value) => {
              return value.toLocaleString('de-DE');
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: seriesData,
      };
    },
  },
  mounted() {
    // check if chartData has changed, if yes, reload the chart
    this.$watch(
      'chartData',
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.$refs.chart.chart.clear();
          this.$refs.chart.chart.setOption(this.option);
        }
      },
      { deep: true }
    );
  },
};
</script>
