<template>
  <div class="flex gap-[6px] pl-2 h-8">
    <chip-bar-item
      v-if="data.progress_tech !== null"
      :dark-theme="true"
      icon="CompassIcon"
      tooltip-text="Gesamtbaufortschritt der Netztechnik in Bau gemäß Bauleistungserfassung des GÜ"
      :single-text="data.progress_tech" />
    <chip-bar-item
      v-if="data.progress_connection !== null"
      :dark-theme="true"
      icon="new-construction-shape"
      tooltip-text="Gesamtbaufortschritt der Hausanschlüsse in Bau gemäß Bauleistungserfassung des GÜ"
      :single-text="data.progress_connection" />
    <chip-bar-item
      v-if="data.progress_route !== null"
      :dark-theme="true"
      icon="journey-shape"
      tooltip-text="Gesamtbaufortschritt der Trassen in Bau gemäß Bauleistungserfassung des GÜ"
      :single-text="data.progress_route" />
    <chip-bar-item
      v-if="data.progress_invest !== null"
      :dark-theme="true"
      icon="EuroShape"
      tooltip-text="Gesamtbaukostenfortschritt gemäß Bauleistungserfassung des GÜ"
      :single-text="data.progress_invest" />
    <chip-bar-item
      v-if="data.milestones_in_planung !== null"
      text-left="In Planung"
      :mile-stone-count="data.milestones_in_planung"
      :mile-stones-total="data.milestones_total" />
    <chip-bar-item
      v-if="data.milestones_planungsfreigabe !== null"
      text-left="Planungsfreigabe"
      :mile-stone-count="data.milestones_planungsfreigabe"
      :mile-stones-total="data.milestones_total" />
    <chip-bar-item
      v-if="data.milestones_in_bau !== null"
      text-left="In Bau"
      :mile-stone-count="data.milestones_in_bau"
      :mile-stones-total="data.milestones_total" />
    <chip-bar-item
      v-if="data.milestones_bauabnahme !== null"
      text-left="Bauabnahme"
      :mile-stone-count="data.milestones_bauabnahme"
      :mile-stones-total="data.milestones_total" />
  </div>
</template>

<script>
import ChipBarItem from './chipBarItem.vue';
export default {
  components: { ChipBarItem },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
