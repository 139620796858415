<template>
  <div class="w-full flex flex-col gap-2 p-3">
    <!-- existing comments-->
    <h6 class="text-infra-highlight-800">
      {{ commentsHeaderText }}
    </h6>
    <div class="body-2 max-h-20 overflow-y-auto">
      <p
        v-for="(comment, i) in propertyData.comments.value"
        :key="i"
        class="pt-2">
        {{ comment }}
      </p>
    </div>
    <!-- add comment-->
    <h6 class="text-infra-highlight-800">Neuer Kommentar</h6>
    <textarea
      v-model="content"
      class="w-full h-[100px] rounded-[6px] body-2 border border-blue-grey-100 p-[10px] focus-visible:outline-blue-grey-200 resize-none"
      placeholder="Bitte hier ein neues Kommentar eintragen" />
    <button-el :disabled="!content" text="Absenden" @click="postComment" />
  </div>
</template>

<script>
export default {
  props: {
    propertyData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    commentsHeaderText() {
      if (this.propertyData.comments.value.length === 0) {
        return 'Noch keine Kommentare';
      } else if (this.propertyData.comments.value.length === 1) {
        return '1 vorhandener Kommentar';
      } else {
        return `${this.propertyData.comments.value.length} vorhandene Kommentare`;
      }
    },
  },
  methods: {
    postComment() {
      this.$store
        .dispatch(this.propertyData.actionOnPost, {
          comment: this.content,
          successMessage: this.propertyData.successMessage,
          failureMessage: this.propertyData.failureMessage,
          id: this.propertyData.entityId.value,
          followUpCommit: this.propertyData.followUpCommit,
        })
        .then(() => {
          this.content = '';
        });
    },
  },
};
</script>
