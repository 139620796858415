import { LAYER_KEY__EXPANSIONPLANNING } from '../layers/expansionplanning';

export const SWITCH_ID__EXPANSIONPLANNING = 'LC_EXPANSIONPLANNING';

export default {
  title: 'Ausbauplanung',
  items: [
    {
      title: 'Weiße Flecken',
      onLayers: [LAYER_KEY__EXPANSIONPLANNING],
      switchId: SWITCH_ID__EXPANSIONPLANNING,
      itemClass: 'switch',
    },
  ],
};
