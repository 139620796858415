<template>
  <div
    v-show="showSearch === true && getResultsLength !== 0"
    :style="{ width: getMapActionsDialogIsOpen ? '310px' : '320px' }"
    :class="position"
    class="absolute search-results-custom rounded-[8px] box-border h-auto bg-white px-2 py-2 z-30">
    <div
      v-for="results in getResults"
      :key="results.id"
      class="py-2 border-b last:border-0 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
      @click="
        flyToCenter(results.center[0], results.center[1]);
        closeSearchbar();
      ">
      <div class="px-2">
        <h3 class="text-sm font-medium">{{ results.text }}</h3>
        <p class="text-xs" style="margin: 0">{{ results.place_name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { flyTo, setMarker } from '@/mapbox/main';

export default {
  name: 'SearchResults',
  props: {
    position: {
      type: String,
      default: 'top-[65px] left-[-90px]',
    },
  },
  data() {
    return {
      map: null,
    };
  },
  methods: {
    flyToCenter: (lng, lat) => {
      const config = {
        zoom: 16.9,
        lng,
        lat,
      };
      flyTo(config);
      // setMarker([lng, lat]);
    },
    closeSearchbar() {
      this.$store.commit('search/RESET_PLACES', null);
    },
  },
  computed: {
    ...mapGetters({
      getResults: 'search/getResults',
      showSearch: 'search/showSearch',
      getSearchbarWidth: 'search/getSearchbarWidth',
    }),
    ...mapGetters('map', ['getMapActionsDialogIsOpen']),
    getResultsLength() {
      if (this.getResults !== null) {
        return this.getResults.length;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-results-custom {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
