export default class Click {
  constructor(map, stateKey, iconLayers) {
    this.map = map;
    this.stateIsTrue = [];
    this.stateKey = stateKey;
    this.iconLayers = iconLayers;
  }

  #constructFeatureObject = (feature) => ({
    source: feature.source,
    sourceLayer: feature.sourceLayer,
    id: feature.id,
  });

  /**
   * get feature state of particular feature rendered on Map
   * @param  {object} feature The feature to retrieve states from
   * @return {object}         the states
   */
  getFeatureState(feature) {
    const featureID = this.#constructFeatureObject(feature);
    return this.map.getFeatureState(featureID);
  }

  setFeatureState(features, state) {
    if (Array.isArray(features)) {
      features.forEach((e) => {
        const featureID = this.#constructFeatureObject(e);
        this.map.setFeatureState(featureID, { [this.stateKey]: state });
        this.stateIsTrue.push(featureID);
      });
    } else {
      throw new Error('input must be an Array');
    }
  }

  resetFeatureState() {
    this.setFeatureState(this.stateIsTrue, false);
    this.stateIsTrue.splice(0, this.stateIsTrue.length);
  }

  reverseFeatureState(feature) {
    const state = this.getFeatureState(feature);
    this.setFeatureState([feature], !state[this.stateKey]);
  }

  setForeignFeatureState(layers, filter) {
    const allLayers = this.map.getStyle().layers;
    layers.forEach((l) => {
      const layer = allLayers.find((e) => e.id === l);
      const { source } = layer;
      const sourceLayer = layer['source-layer'];
      const features = this.map.querySourceFeatures(source, {
        sourceLayer,
        filter,
      });
      const featuresFormat = features.map((e) => ({
        id: e.id,
        source,
        sourceLayer,
      }));
      this.setFeatureState(featuresFormat, true);
    });
  }

  changeIconOnClick(feature, changeIcon) {
    this.map.U.addGeoJSON(changeIcon.name, feature.toJSON());
    this.map.addLayer({
      id: changeIcon.name,
      type: 'symbol',
      source: changeIcon.name,
      layout: {
        visibility: 'visible',
        'icon-image': changeIcon.iconImage,
        'icon-size': changeIcon.iconSize,
      },
    });
    this.map.moveLayer(changeIcon.name);
  }

  getFeatureOnClick(point, data) {
    const selection = data.$store.getters['draw/getGeometrySelection'];
    if (typeof selection === 'undefined') return;
    const features = this.map.queryRenderedFeatures(point, {
      layers: [selection],
    });
    if (features.length > 0) {
      const feature = features[0];
      // retrieve id-key from layerconfig key
      data.$store.commit('draw/SELECT_FEATURE', feature.properties.pk);
      this.reverseFeatureState(feature);
    }
  }
}
