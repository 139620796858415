<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6665 14.4999L11.1665 5.33325"
      :stroke="fill"
      stroke-miterlimit="10" />
    <path d="M13.167 16.3333H6.16699" :stroke="fill" stroke-miterlimit="10" />
    <path
      d="M12.8333 2H11.5C10.9477 2 10.5 2.44771 10.5 3V4.33333C10.5 4.88562 10.9477 5.33333 11.5 5.33333H12.8333C13.3856 5.33333 13.8333 4.88562 13.8333 4.33333V3C13.8333 2.44772 13.3856 2 12.8333 2Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M5.33333 14.5H4C3.44772 14.5 3 14.9477 3 15.5V16.8333C3 17.3856 3.44771 17.8333 4 17.8333H5.33333C5.88562 17.8333 6.33333 17.3856 6.33333 16.8333V15.5C6.33333 14.9477 5.88562 14.5 5.33333 14.5Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M16.3333 14.5H15C14.4477 14.5 14 14.9477 14 15.5V16.8333C14 17.3856 14.4477 17.8333 15 17.8333H16.3333C16.8856 17.8333 17.3333 17.3856 17.3333 16.8333V15.5C17.3333 14.9477 16.8856 14.5 16.3333 14.5Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
