<template>
  <div class="loading-spinner w-fit">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2.5px solid white;
  border-top-color: #01799d;
  border-radius: 50%;
  animation: spin 1.25s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
