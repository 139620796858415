<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.30078 15.6497L5.50911 17.4997L6.85911 16.2164L9.16745 9.88307C8.60078 9.74141 8.10078 9.45807 7.69245 9.06641L5.30078 15.6497Z"
      :fill="fill" />
    <path
      d="M12.3109 9.06641C11.9026 9.45807 11.3943 9.74141 10.8359 9.88307L13.1443 16.2164L14.4943 17.4997L14.7109 15.6497L12.3109 9.06641Z"
      :fill="fill" />
    <path
      d="M12.5 6.66667C12.5 5.58333 11.8 4.66667 10.8333 4.31667V2.5H9.16667V4.31667C8.2 4.66667 7.5 5.58333 7.5 6.66667C7.5 8.05 8.61667 9.16667 10 9.16667C11.3833 9.16667 12.5 8.05 12.5 6.66667ZM10 7.5C9.54167 7.5 9.16667 7.125 9.16667 6.66667C9.16667 6.20833 9.54167 5.83333 10 5.83333C10.4583 5.83333 10.8333 6.20833 10.8333 6.66667C10.8333 7.125 10.4583 7.5 10 7.5Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
