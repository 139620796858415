<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2777_29950)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.834 1.32251L32.1673 5.92251V31.6665H0.833984V16.3332H6.16732V10.9999H21.5007V29.6665H30.1673V7.41057L18.834 4.01057V8.99987H16.834V1.32251ZM19.5007 29.6665V12.9999H8.16732V29.6665H19.5007ZM6.16732 29.6665V18.3332H2.83398V29.6665H6.16732ZM26.834 10.9999V26.3332H24.834V10.9999H26.834ZM11.5007 16.3332H16.1673V18.3332H11.5007V16.3332ZM11.5007 20.3332H16.1673V22.3332H11.5007V20.3332ZM11.5007 24.3332H16.1673V26.3332H11.5007V24.3332Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_2777_29950">
        <rect width="32" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
