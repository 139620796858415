<template>
  <v-row>
    <v-col cols="12" sm="12" md="6">
      <v-text-field
        v-model="search"
        single-line
        variant="outlined"
        append-inner-icon="mdi-magnify"
        placeholder="Bitte Suchbegriff eingeben"
        density="compact"
        hide-details />
    </v-col>
  </v-row>
  <v-row>
    <v-container style="max-width: 100%" class="p-3 shadow-xl">
      <v-card class="m-0.5 p-2" elevation="3">
        <v-row>
          <v-data-table
            id="permissionstable"
            :headers="headers"
            :items="permissions"
            :search="search"
            density="compact"
            class="pb-3"
            items-per-page="100">
            <template #bottom> </template>
            <!-- eslint-disable-next-line -->
            <template #item.functionality="{ item }">
              {{ item.functionality }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.adminUser="{ item }">
              <permission-entry
                :checkmark="item.adminUser.checkmark"
                :hover-text="item.adminUser.hoverText" />
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.powerUser="{ item }">
              <permission-entry
                :checkmark="item.powerUser.checkmark"
                :hover-text="item.powerUser.hoverText" />
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.normalUser="{ item }">
              <permission-entry
                :checkmark="item.normalUser.checkmark"
                :hover-text="item.normalUser.hoverText" />
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.minimalUser="{ item }">
              <permission-entry
                :checkmark="item.minimalUser.checkmark"
                :hover-text="item.minimalUser.hoverText" />
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.restrictedUser="{ item }">
              <permission-entry
                :checkmark="item.restrictedUser.checkmark"
                :hover-text="item.restrictedUser.hoverText" />
            </template>
          </v-data-table>
        </v-row>
      </v-card>
    </v-container>
  </v-row>
</template>

<script>
import PermissionEntry from '@/views/profile/permissionEntry.vue';

export default {
  components: { PermissionEntry },
  data() {
    return {
      headers: [
        {
          align: 'start',
          key: 'functionality',
          sortable: true,
          title: 'Funktionalität',
        },
        {
          align: 'center',
          key: 'adminUser',
          sortable: false,
          title: 'LBD Admin',
          width: 100,
        },
        {
          align: 'center',
          key: 'powerUser',
          sortable: false,
          title: 'OEW PowerUser',
          width: 100,
        },
        {
          align: 'center',
          key: 'normalUser',
          sortable: false,
          title: 'Normal User',
          width: 100,
        },
        {
          align: 'center',
          key: 'minimalUser',
          sortable: false,
          title: 'Minimal User',
          width: 100,
        },
        {
          align: 'center',
          key: 'restrictedUser',
          sortable: false,
          title: 'Restricted User',
          width: 100,
        },
      ],
      search: '',
      permissions: [
        {
          functionality: 'User-Management',
          adminUser: {
            checkmark: true,
            hoverText:
              'User anlegen, einladen, bearbeiten, (de-)aktivieren und löschen',
          },
          powerUser: {
            checkmark: true,
            hoverText: 'User einladen, bearbeiten und (de-)aktivieren',
          },
          normalUser: { checkmark: false, hoverText: '' },
          minimalUser: { checkmark: false, hoverText: '' },
          restrictedUser: { checkmark: false, hoverText: '' },
        },
        {
          functionality: 'Geografische Zuordnung',
          adminUser: { checkmark: true, hoverText: 'Alles' },
          powerUser: { checkmark: true, hoverText: 'Alles' },
          normalUser: { checkmark: true, hoverText: 'Gebietsspezifisch' },
          minimalUser: { checkmark: true, hoverText: 'Gebietsspezifisch' },
          restrictedUser: { checkmark: true, hoverText: 'Gebietsspezifisch' },
        },
        {
          functionality: 'Datenerfassung',
          adminUser: {
            checkmark: true,
            hoverText:
              'Notizen, Neubaugebiete, Mitverlegungen, MEV-Validierung, Adressvalidierung',
          },
          powerUser: {
            checkmark: true,
            hoverText:
              'Notizen, Neubaugebiete, Mitverlegungen, MEV-Validierung, Adressvalidierung',
          },
          normalUser: {
            checkmark: true,
            hoverText:
              'Notizen, Neubaugebiete, Mitverlegungen, MEV-Validierung, Adressvalidierung',
          },
          minimalUser: { checkmark: true, hoverText: 'Notizen' },
          restrictedUser: { checkmark: true, hoverText: 'Notizen' },
        },
        {
          functionality: 'Dashboard',
          adminUser: {
            checkmark: true,
            hoverText:
              'Projektinformationssystem, MEV, Förderantrag, Planung und Bau GÜ',
          },
          powerUser: {
            checkmark: true,
            hoverText:
              'Projektinformationssystem, MEV, Förderantrag, Planung und Bau GÜ',
          },
          normalUser: {
            checkmark: true,
            hoverText: 'Projektinformationssystem, MEV, Planung und Bau GÜ',
          },
          minimalUser: { checkmark: false, hoverText: '' },
          restrictedUser: { checkmark: false, hoverText: '' },
        },
        {
          functionality: 'Feature Förderanträge',
          adminUser: { checkmark: true, hoverText: '' },
          powerUser: { checkmark: true, hoverText: '' },
          normalUser: { checkmark: false, hoverText: '' },
          minimalUser: { checkmark: false, hoverText: '' },
          restrictedUser: { checkmark: false, hoverText: '' },
        },
        {
          functionality: 'Feature Digitale Bausteuerung',
          adminUser: { checkmark: true, hoverText: '' },
          powerUser: { checkmark: true, hoverText: '' },
          normalUser: { checkmark: false, hoverText: '' },
          minimalUser: { checkmark: false, hoverText: '' },
          restrictedUser: { checkmark: false, hoverText: '' },
        },
        {
          functionality: 'Kartenansicht',
          adminUser: { checkmark: true, hoverText: '' },
          powerUser: { checkmark: true, hoverText: '' },
          normalUser: { checkmark: true, hoverText: '' },
          minimalUser: { checkmark: true, hoverText: '' },
          restrictedUser: { checkmark: true, hoverText: '' },
        },
        {
          functionality: 'Kartenlayer - Adresskulisse',
          adminUser: {
            checkmark: true,
            hoverText: 'Gebäude, Betrachtungsebene Bau, Ausbauplanung',
          },
          powerUser: {
            checkmark: true,
            hoverText: 'Gebäude, Betrachtungsebene Bau, Ausbauplanung',
          },
          normalUser: {
            checkmark: true,
            hoverText: 'Gebäude und Betrachtungsebene Bau',
          },
          minimalUser: {
            checkmark: true,
            hoverText: 'Gebäude und Betrachtungsebene Bau',
          },
          restrictedUser: {
            checkmark: true,
            hoverText: 'Gebäude und Betrachtungsebene Bau',
          },
        },
        {
          functionality: 'Kartenlayer - Infrastruktur',
          adminUser: { checkmark: true, hoverText: 'Grobplanung' },
          powerUser: { checkmark: true, hoverText: 'Grobplanung' },
          normalUser: { checkmark: true, hoverText: 'Grobplanung' },
          minimalUser: { checkmark: true, hoverText: 'Grobplanung' },
          restrictedUser: { checkmark: false, hoverText: '' },
        },
        {
          functionality: 'Kartenlayer - Auswertung',
          adminUser: {
            checkmark: true,
            hoverText:
              'MEV, Ausschreibungen, Baufortschritt, Datenerfassung und Betrachtungsebene',
          },
          powerUser: {
            checkmark: true,
            hoverText:
              'MEV, Ausschreibungen, Baufortschritt, Datenerfassung und Betrachtungsebene',
          },
          normalUser: {
            checkmark: true,
            hoverText:
              'MEV, Ausschreibungen, Baufortschritt, Datenerfassung und Betrachtungsebene',
          },
          minimalUser: {
            checkmark: true,
            hoverText: 'Datenerfassung (Notizen) und Betrachtungsebene',
          },
          restrictedUser: {
            checkmark: true,
            hoverText: 'Datenerfassung (Notizen) und Betrachtungsebene',
          },
        },
        {
          functionality: 'Kartenlayer - Shortcuts',
          adminUser: {
            checkmark: true,
            hoverText: 'Notizen, Bauabschnitt, Neubaugebiet, Mitverlegung',
          },
          powerUser: {
            checkmark: true,
            hoverText: 'Notizen, Bauabschnitt, Neubaugebiet, Mitverlegung',
          },
          normalUser: {
            checkmark: true,
            hoverText: 'Notizen, Bauabschnitt, Neubaugebiet, Mitverlegung',
          },
          minimalUser: { checkmark: true, hoverText: 'Notizen' },
          restrictedUser: { checkmark: true, hoverText: 'Notizen' },
        },
      ],
    };
  },
};
</script>
