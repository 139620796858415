<template>
  <div class="w-full h-screen relative flex">
    <!-- OEW Sub-Navi -->
    <main-menu
      v-if="preloaded"
      class="show-panel2"
      :class="{ visible: showPanel2 && loaded }"
      :container-height="windowHeight" />
    <!-- OEW Sub-Navi END -->
    <div class="mapView h-full relative border-l w-full">
      <!-- Loading screen  -->
      <loading-screen v-if="!loaded"></loading-screen>
      <!-- /Loading screen -->
      <base-map v-if="loaded" :show-panel2="showPanel2" class="h-full" />

      <modal-right-screen
        v-show="modalRightScreen.visible"
        :show-slider="showSlider">
      </modal-right-screen>

      <!-- gehe zu shortcuts-->
      <modal-full-screen v-show="modalFullScreen.visible">
        <template #title>
          {{ modalFullScreen.title }}
        </template>
        <template #content>
          <div class="flex flex-col h-full standard-elevation-1 rounded-[4px]">
            <!-- filter-->
            <div
              class="border-b border-blue-grey-50 flex justify-start items-center">
              <div class="flex flex-col p-[10px] w-1/2">
                <InputEl
                  v-model="shortCutsFilter"
                  :small="true"
                  label="Filter"
                  custom-background="bg-infra-highlight-25"
                  :placeholder="tableOptions.filterPlaceholder" />
              </div>
            </div>
            <vuetify-data-table
              :show-expand="false"
              :headers="tableOptions.headers"
              :item-data="filteredShortCutsTableData"
              :open-page-options-to-top="true"
              :show-total="true">
              <template #functions="item">
                <div class="flex justify-end pr-2">
                  <map-icon
                    fill="#00A1D1"
                    class="cursor-pointer"
                    @click="actionShortCuts(item)" />
                </div>
              </template>
            </vuetify-data-table>
          </div>
        </template>
      </modal-full-screen>

      <!-- map control options-->
      <div v-if="loaded">
        <control-bar @toggle-panel-2="showPanel2 = !showPanel2" />
        <map-actions />
        <actions-draw-screen
          v-if="getActiveProcess.selected"
          :show-layer-panel="showPanel2" />
        <validation-ticket-wrapper v-if="getShowValidationTicket" />
        <legend-controls />
        <map-controls v-bind="$attrs" />
      </div>
    </div>
  </div>
</template>

<script>
import { axios } from '@/utils/axiosHelper';
import { mapGetters, mapState } from 'vuex';
import { flyTo } from '@/mapbox/main';

export default {
  data() {
    return {
      showModalRight: false,
      showModalFullScreen: false,
      showPanel2: true,
      loaded: false,
      preloaded: false,
      windowHeight: window.innerHeight,
      tableOptions: { headers: [], data: [] },
      layerOnClick: null,
      shortCutsFilter: null,
      triggerModalSearchReset: false,
    };
  },
  computed: {
    ...mapState('map', ['showSlider']),
    ...mapGetters(['modalFullScreen', 'modalRightScreen']),
    ...mapGetters('ticket', ['getActiveProcess', 'getShowValidationTicket']),

    filteredShortCutsTableData() {
      if (this.shortCutsFilter) {
        const filterInput = this.shortCutsFilter.toLowerCase();

        return this.tableOptions.data.filter((item) => {
          for (const header of this.tableOptions.headers) {
            const key = header.key;

            if (key === 'functions') {
              continue; // Skip filtering for the "functions" key
            }

            // Skip filtering if the key doesn't exist in the item
            if (!Object.prototype.hasOwnProperty.call(item, key)) {
              continue;
            }

            const value = item[key].toString().toLowerCase();

            // Check for a match
            if (value.includes(filterInput)) {
              return true; // Include the item in the filtered results
            }
          }
          // No match found, exclude the item from the filtered results
          return false;
        });
      } else {
        return this.tableOptions.data;
      }
    },
  },
  watch: {
    modalFullScreen(val) {
      this.triggerModalSearchReset = !this.triggerModalSearchReset;
      this.tableOptions.headers = [];
      this.tableOptions.data = [];
      this.tableOptions.filterPlaceholder = '';
      const config = val.config;
      if (config.activateOnSelect) {
        this.layerOnClick = config.activateOnSelect;
      }
      if (config.fromState) {
        this.tableOptions.headers = config.headers;
        this.tableOptions.data =
          this.$store.state[config.fromState.namespace][config.fromState.state];
        this.tableOptions.filterPlaceholder = config.filterPlaceholder;
      } else if (config.fromURL) {
        axios({
          url: config.fromURL,
          method: 'GET',
        }).then((resp) => {
          this.tableOptions.headers = config.headers;
          this.tableOptions.data = resp.data;
          this.tableOptions.filterPlaceholder = resp.filterPlaceholder;
        });
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.loaded = false;
    const actions = [
      this.$store.dispatch('auth/GET_PERMITTED_GEMEINDEN'),
      this.$store.dispatch('auth/GET_PERMITTED_LANDKREISE'),
    ];
    Promise.all(actions).then(() => {
      this.loaded = true;
    });
  },
  beforeMount() {
    this.preloaded = false;
    this.$store
      .dispatch('map/GET_AVAILABLE_TENDER_YEARS', {
        canView: this.$can('view', 'tenderlot'),
      })
      .finally(() => {
        this.preloaded = true;
      });
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.$store.commit('map/SET_CONTAINER_HEIGHT', window.innerHeight - 170);
    },

    actionShortCuts(item) {
      const coords = item.item.centroid;
      if (typeof coords === 'undefined') {
        console.warn('Centroid not defined in Tabledata');
        return;
      }
      this.$store.commit('map/CHANGE_SWITCH', {
        switches: this.layerOnClick,
        active: true,
      });
      flyTo({ zoom: 16.9, lng: coords[0], lat: coords[1] });
      this.$store.commit('SET_FULLSCREEN_MODAL', {
        visible: false,
        config: {},
      });
    },
  },
};
</script>

<style scoped lang="scss">
.show-panel2 {
  overflow: hidden;
  max-height: 0;
}

.visible {
  max-height: 100vh;
}
</style>
