import cookie from 'vue-cookies';

export default {
  mapView: {
    mapConstraints: {
      container: 'map',
      style: 'mapbox://styles/mapchargelbd/clkgrzyn8003p01pk7olwgxgk',
      pitch: 0,
      maxZoom: 16.9,
      minZoom: 6,
      center: [10.451526, 51.165691],
      language: 'de',
      transformRequest: (url) => {
        if (url.includes(window.TILESERVER_URL)) {
          return {
            url,
            headers: { 'X-CSRFToken': cookie.get('csrftoken') },
            credentials: 'include',
          };
        }
        return {
          url,
        };
      },
    },
  },
};
