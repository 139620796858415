<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 0.5H1.5V15.5H14.5V0.5Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4.5 3.5H11.5"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4.5 6.5H11.5"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4.5 9.5H11.5"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4.5 12.5H7.5"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
