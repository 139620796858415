import {
  LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
  LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
  LAYER_KEY__VIEWING_LEVEL_GEMARKUNG_BORDER,
  LAYER_KEY__VIEWING_LEVEL_GEMEINDE_BORDER,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER,
} from '../layers/viewingLevel';

export const SWITCH_ID__VIEWING_LEVEL_GEMARKUNG = 'LC_GEMARKUNG';
export const SWITCH_ID__VIEWING_LEVEL_GEMEINDE = 'LC_GEMEINDE';
export const SWITCH_ID__VIEWING_LEVEL_LANDKREIS = 'LC_LANDKREIS';

export default {
  title: 'Betrachtungsebene',
  items: [
    {
      active: false,
      title: 'Gemarkung',
      onLayers: [
        LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
        LAYER_KEY__VIEWING_LEVEL_GEMARKUNG_BORDER,
      ],
      turnOffSwitches: [
        SWITCH_ID__VIEWING_LEVEL_GEMEINDE,
        SWITCH_ID__VIEWING_LEVEL_LANDKREIS,
      ],
      switchId: SWITCH_ID__VIEWING_LEVEL_GEMARKUNG,
      itemClass: 'switch',
    },
    {
      active: true,
      title: 'Gemeinde',
      onLayers: [
        LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
        LAYER_KEY__VIEWING_LEVEL_GEMEINDE_BORDER,
      ],
      turnOffSwitches: [
        SWITCH_ID__VIEWING_LEVEL_GEMARKUNG,
        SWITCH_ID__VIEWING_LEVEL_LANDKREIS,
      ],
      switchId: SWITCH_ID__VIEWING_LEVEL_GEMEINDE,
      itemClass: 'switch',
    },
    {
      active: false,
      title: 'Landkreis',
      onLayers: [
        LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
        LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER,
      ],
      turnOffSwitches: [
        SWITCH_ID__VIEWING_LEVEL_GEMEINDE,
        SWITCH_ID__VIEWING_LEVEL_GEMARKUNG,
      ],
      switchId: SWITCH_ID__VIEWING_LEVEL_LANDKREIS,
      itemClass: 'switch',
    },
  ],
};
