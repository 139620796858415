<template>
  <div>
    <the-label :label="label" />
    <div ref="dropdownContainer" class="relative w-full flex">
      <div
        class="flex justify-between text-infra-blue border-blue-grey-100 items-center bg-white rounded-[6px] w-full cursor-pointer border"
        :class="{
          'border-red-500': inputIsEmpty,
          'rounded-b-none outline-b outline-infra-background border-blue-grey-300':
            showDropdown,
          'h-[32px] p-[8px]': small,
          'h-[40px] p-[10px]': !small,
        }"
        @click="toggleDropdown">
        <div
          class="text-black overflow-hidden whitespace-nowrap"
          :class="{
            'text-opacity-100': showDropdown,
            'text-opacity-60': !showDropdown,
            'body-1': !small,
            'body-2': small,
          }">
          {{
            currentSelection.length === 0
              ? placeholder
              : currentSelection.map((e) => itemsData[e]).join(', ')
          }}
        </div>
        <div class="flex gap-2 items-center">
          <c-remove
            v-if="noPlaceholder ? false : currentSelection !== null"
            class="w-5 h-5"
            :fill="removeIconFill"
            @click.stop="clearSelection" />
          <enlarge-icon :fill="enlargeIconFill" />
        </div>
      </div>
      <div
        v-if="showDropdown"
        class="absolute z-10 left-0 w-full bg-white py-1 rounded-b-[6px] dropdown-animation border border-blue-grey-300 border-t-0"
        :class="{ 'pr-1': showGap, 'top-[40px]': !small, 'top-[32px]': small }">
        <div
          ref="dropDownList"
          class="overflow-y-auto"
          :style="{
            'max-height': `${maxHeightDropdown}px`,
          }"
          @wheel.stop>
          <div
            v-for="(item, index) in itemsData"
            :key="index"
            class="flex items-center hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            :class="{
              'mr-1': showGap,
              'p-[8px] min-h-[32px] body-2': small,
              'p-[10px] min-h-[40px] body-1': !small,
            }"
            role="menuitem"
            @click="selectItem(index)">
            <div class="flex-grow-1">
              {{ item }}
            </div>
            <div>
              <input
                :value="currentSelection.includes(index)"
                :checked="currentSelection.includes(index)"
                type="checkbox"
                :name="item" />
            </div>
          </div>
        </div>
      </div>
      <span
        v-if="errorText !== null"
        class="absolute bottom-[-18px] text-xs text-red-500">
        {{ errorText }}
      </span>
    </div>
  </div>
</template>

<script>
import TheLabel from '../label/TheLabel.vue';
import CRemove from '../../assets/icons/c-remove.vue';
import EnlargeIcon from '../../assets/icons/enlarge-icon.vue';

export default {
  name: 'MultiDropDown',
  components: { EnlargeIcon, CRemove, TheLabel },
  props: {
    itemsData: {
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Bitte Einträge auswählen',
    },
    resetSelection: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noPlaceholder: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
    enlargeIconFill: {
      type: String,
      default: '#013545',
    },
    removeIconFill: {
      type: String,
      default: '#013545',
    },
  },
  emits: ['selectedItems'],
  data() {
    return {
      showDropdown: false,
      currentSelection: [],
      maxHeightDropdown: 150,
      showGap: true,
      inputIsEmpty: false,
      errorText: null,
    };
  },
  watch: {
    currentSelection: {
      deep: true,
      handler() {
        this.$emit(
          'selectedItems',
          this.currentSelection.map((e) => this.itemsData[e]),
        );
      },
    },
    resetSelection() {
      if (this.resetSelection) {
        this.currentSelection = [];
      }
    },
    itemsData() {
      this.currentSelection = [];
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
      this.dropdownListHeight();
    },
    closeDropdown(event) {
      if (!this.$refs.dropdownContainer.contains(event.target)) {
        this.showDropdown = false;
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    selectItem(index) {
      if (this.currentSelection.includes(index)) {
        this.currentSelection.splice(this.currentSelection.indexOf(index), 1);
      } else {
        this.currentSelection.push(index);
      }
      this.inputOps();
    },
    clearSelection() {
      this.currentSelection = [];
      this.showDropdown = false;
      this.inputOps();
    },

    dropdownListHeight() {
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.showGap = !(
            this.$refs.dropDownList.offsetHeight < this.maxHeightDropdown
          );
        });
      }
    },

    inputOps() {
      if (this.currentSelection.length === 0 && this.isRequired) {
        this.inputIsEmpty = true;
        this.errorText = 'Pflichtfeld';
      } else {
        this.inputIsEmpty = false;
        this.errorText = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
