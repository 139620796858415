<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_73_1717)">
      <path
        d="M9.16683 3.33329C9.50016 2.33329 10.4168 1.66663 11.5002 1.66663C12.9168 1.66663 14.0002 2.74996 14.0002 4.16663C14.0002 5.58329 12.9168 6.66663 11.5002 6.66663H0.833496"
        :stroke="fillColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M4.16683 16.6666C4.50016 17.6666 5.41683 18.3333 6.50016 18.3333C7.91683 18.3333 9.00016 17.2499 9.00016 15.8333C9.00016 14.4166 7.91683 13.3333 6.50016 13.3333H0.833496"
        :stroke="fillColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.3335 13.3333C14.6668 14.3333 15.5835 15 16.6668 15C18.0835 15 19.1668 13.9167 19.1668 12.5C19.1668 11.0833 18.0835 10 16.6668 10H0.833496"
        :stroke="fillColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_73_1717">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'WindIcon',
  props: {
    fillColor: {
      type: String,
      required: true,
      default: '#ffffff',
    },
  },
};
</script>
