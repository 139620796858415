<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 15C19.5 15.1326 19.5527 15.2598 19.6464 15.3536C19.7402 15.4473 19.8674 15.5 20 15.5H20.5C21.1048 15.5002 21.6991 15.6573 22.225 15.956C22.7508 16.2546 23.1901 16.6847 23.5 17.204V4C23.5 3.53991 23.4093 3.08434 23.2331 2.65934C23.0568 2.23434 22.7985 1.84826 22.4729 1.52318C22.1473 1.19811 21.7609 0.940413 21.3356 0.764846C20.9103 0.589278 20.4546 0.499281 19.9945 0.500004C19.8628 0.501453 19.7371 0.55477 19.6445 0.648381C19.5519 0.741993 19.5 0.868344 19.5 1V15Z"
      :fill="fill" />
    <path
      d="M20.5 16.5H19.8885C19.5202 16.5 19.1671 16.3537 18.9067 16.0933C18.6463 15.8329 18.5 15.4798 18.5 15.1115V3.5H1C0.867392 3.5 0.740215 3.55268 0.646447 3.64645C0.552678 3.74021 0.5 3.86739 0.5 4V21C0.5 21.1326 0.552678 21.2598 0.646447 21.3536C0.740215 21.4473 0.867392 21.5 1 21.5H20.5C21.163 21.5 21.7989 21.2366 22.2678 20.7678C22.7366 20.2989 23 19.663 23 19C23 18.337 22.7366 17.7011 22.2678 17.2322C21.7989 16.7634 21.163 16.5 20.5 16.5ZM16 18C16 18.1326 15.9473 18.2598 15.8536 18.3536C15.7598 18.4473 15.6326 18.5 15.5 18.5H7.5C7.36739 18.5 7.24021 18.4473 7.14645 18.3536C7.05268 18.2598 7 18.1326 7 18V16C7 15.8674 7.05268 15.7402 7.14645 15.6464C7.24021 15.5527 7.36739 15.5 7.5 15.5C7.63261 15.5 7.75979 15.5527 7.85355 15.6464C7.94732 15.7402 8 15.8674 8 16V17.5H15V7.5H10.5V12C10.5 12.1326 10.4473 12.2598 10.3536 12.3536C10.2598 12.4473 10.1326 12.5 10 12.5H7.5C7.36739 12.5 7.24021 12.4473 7.14645 12.3536C7.05268 12.2598 7 12.1326 7 12C7 11.8674 7.05268 11.7402 7.14645 11.6464C7.24021 11.5527 7.36739 11.5 7.5 11.5H9.5V7.5H4V18C4 18.1326 3.94732 18.2598 3.85355 18.3536C3.75979 18.4473 3.63261 18.5 3.5 18.5C3.36739 18.5 3.24021 18.4473 3.14645 18.3536C3.05268 18.2598 3 18.1326 3 18V7C3 6.86739 3.05268 6.74021 3.14645 6.64645C3.24021 6.55268 3.36739 6.5 3.5 6.5H15.5C15.6326 6.5 15.7598 6.55268 15.8536 6.64645C15.9473 6.74021 16 6.86739 16 7V18Z"
      :fill="fill" />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
