<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1455_73403)">
      <path
        d="M2.66667 4.66667C3.77124 4.66667 4.66667 3.77124 4.66667 2.66667C4.66667 1.5621 3.77124 0.666672 2.66667 0.666672C1.5621 0.666672 0.666672 1.5621 0.666672 2.66667C0.666672 3.77124 1.5621 4.66667 2.66667 4.66667Z"
        :fill="fill"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M15.3333 11.3333H11.3333V15.3333H15.3333V11.3333Z"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M2.66667 7.33334V10.6667C2.66667 12.1393 3.86067 13.3333 5.33334 13.3333H8.66667"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
    </g>
    <defs>
      <clipPath id="clip0_1455_73403">
        <rect width="16" height="16" :fill="fill" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
