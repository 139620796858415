<template>
  <div v-if="!showDetails" class="panel-content-row-wrapper-container">
    <div class="panel-content-row-wrapper">
      <div class="other-cols-wrapper">
        <div class="panel-content-item">Inflation</div>
        <div class="panel-content-item">Risikoaufschlag</div>
        <div class="panel-content-item">Diskontsatz (11/2020)</div>
      </div>
      <div></div>
      <div class="other-cols-wrapper">
        <button-el
          size="m"
          icon="arrow-down"
          :primary="false"
          text="Details prüfen"
          @click="showDetails = true" />
        <InputEl
          :small="true"
          input-type="float"
          :placeholder="20"
          :rules="{ required: true }"
          custom-background="bg-infra-highlight-25"
          suffix="%" />
        <InputEl
          :small="true"
          input-type="float"
          :placeholder="-0.72"
          :rules="{ required: true }"
          custom-background="bg-infra-highlight-25"
          suffix="%" />
      </div>
    </div>
  </div>
  <details-pop-up v-else @toggle-pop-up="showDetails = $event" />
</template>
<script>
export default {
  data() {
    return {
      showDetails: false,
    };
  },
};
</script>
