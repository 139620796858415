<template>
  <div class="flex align-content-center justify-center w-100">
    <div v-if="checkmark">
      <img src="@/assets/svg/check-green.svg" />
      <v-tooltip v-if="hoverText" activator="parent" location="end">
        <div class="subtitle-2 text-white">
          <slot name="text" />
          {{ hoverText }}
        </div>
      </v-tooltip>
    </div>
    <c-remove v-else fill="red" width="16" height="16" />
  </div>
</template>

<script>
export default {
  props: {
    checkmark: {
      type: Boolean,
      required: true,
    },
    hoverText: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
