<template>
  <div class="flex items-center gap-4 p-3">
    <component :is="shownData.iconComponent" />
    <h5 class="text-infra-highlight-800">{{ shownData.text }}</h5>
  </div>
</template>

<script>
export default {
  props: {
    propertyData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shownData() {
      return this.propertyData.items.find((e) => e.ifValue === e.value);
    },
  },
};
</script>
