<template>
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0833 2.75C12.4101 2.75 5.33333 7.81221 5.33333 16.5C5.33333 18.8179 6.18243 21.4775 7.53074 24.2162C8.87246 26.9416 10.6711 29.6711 12.4854 32.109C14.2975 34.544 16.1118 36.6704 17.474 38.1886C18.1546 38.9472 18.7212 39.5527 19.1166 39.9676C19.3144 40.175 19.4692 40.3347 19.5741 40.442C19.6265 40.4957 19.6665 40.5362 19.693 40.563L19.7226 40.5929L19.7297 40.6L20.3797 41.25L19.0833 42.5464L18.4352 41.8982L19.0833 41.25C18.4352 41.8982 18.4352 41.8982 18.4352 41.8982L18.4316 41.8946L18.4228 41.8857L18.3895 41.8522C18.3605 41.8229 18.3181 41.7799 18.2631 41.7236C18.1532 41.6111 17.9929 41.4459 17.7896 41.2326C17.383 40.806 16.8037 40.1869 16.1094 39.4129C14.7215 37.8661 12.8692 35.6956 11.0146 33.2035C9.16221 30.7143 7.2942 27.8865 5.88593 25.026C4.48423 22.1788 3.5 19.2238 3.5 16.5C3.5 6.62529 11.5812 0.916667 19.0833 0.916667C26.5854 0.916667 34.6667 6.62529 34.6667 16.5C34.6667 17.4625 34.5442 18.4503 34.3254 19.4466L34.1288 20.342L32.3381 19.9487L32.5348 19.0534C32.7303 18.1632 32.8333 17.3085 32.8333 16.5C32.8333 7.81221 25.7566 2.75 19.0833 2.75Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0833 12.8333C17.0583 12.8333 15.4167 14.475 15.4167 16.5C15.4167 18.525 17.0583 20.1667 19.0833 20.1667C21.1084 20.1667 22.75 18.525 22.75 16.5C22.75 14.475 21.1084 12.8333 19.0833 12.8333ZM13.5833 16.5C13.5833 13.4624 16.0458 11 19.0833 11C22.1209 11 24.5833 13.4624 24.5833 16.5C24.5833 19.5376 22.1209 22 19.0833 22C16.0458 22 13.5833 19.5376 13.5833 16.5Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.8797 31.1667L31.9167 36.1297L28.787 33L30.0833 31.7036L31.9167 33.537L35.5833 29.8703L36.8797 31.1667Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.8333 25.6667C28.7832 25.6667 25.5 28.9499 25.5 33C25.5 37.0501 28.7832 40.3333 32.8333 40.3333C36.8834 40.3333 40.1667 37.0501 40.1667 33C40.1667 28.9499 36.8834 25.6667 32.8333 25.6667ZM23.6667 33C23.6667 27.9374 27.7707 23.8333 32.8333 23.8333C37.8959 23.8333 42 27.9374 42 33C42 38.0626 37.8959 42.1667 32.8333 42.1667C27.7707 42.1667 23.6667 38.0626 23.6667 33Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
