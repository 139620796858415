<template>
  <div class="flex flex-col gap-3">
    <h6 class="text-infra-highlight-800">Angaben zur Mitverlegung</h6>
    <div class="flex flex-wrap gap-[10px]">
      <the-label label="Maßnahmenname (automatisch generiert)" />
      <span class="text-sm font-medium w-full" style="margin-top: -4px">
        {{ measurementName }}
      </span>
      <InputEl
        ref="ticketInput1"
        v-model="ticketData.optional.ticket_name"
        :small="true"
        label="Titel aus vorhandener Maßnahmendokumentation"
        class="w-full" />
      <multi-drop-down
        :is-required="false"
        :small="true"
        class="w-full"
        placeholder="Bitte auswählen"
        :items-data="['Backbone', 'Gas', 'Kanal', 'Strom', 'Wärme', 'Wasser']"
        label="Betroffene Gewerke"
        @selected-items="
          (e) => (ticketData.optional.affected_industries = e)
        " />
      <InputEl
        ref="ticketInput3"
        v-model="ticketData.required.measure_area"
        :small="true"
        :rules="{ required: true }"
        class="w-full"
        label="Maßnahmenbereich (Straße, Bezirk etc.)*" />
      <div class="flex gap-[10px]">
        <date-picker
          v-model="ticketData.required.construction_date_start"
          label="Baubeginn*"
          :small="true" />
        <date-picker
          v-model="ticketData.required.construction_date_end"
          label="Bauende*"
          :small="true" />
      </div>
      <InputEl
        ref="ticketInput6"
        v-model="ticketData.optional.responsible_construction_company"
        :small="true"
        label="Verantwortliches Bauunternehmen"
        class="w-full" />
      <div class="flex flex-col w-full">
        <the-label label="Bemerkung" />
        <textarea
          class="w-full h-[100px] rounded-[6px] body-2 border border-blue-grey-100 p-[10px] focus-visible:outline-blue-grey-200 resize-none"
          placeholder="Bitte eingeben"
          @input="ticketData.optional.remark = $event.target.value" />
      </div>
      <div class="flex flex-col w-full">
        <the-label label="Upload" />
        <UploadField
          v-model="ticketData.optional.upload"
          :small="true"
          :allow-multiple="true" />
      </div>
      <div class="bg-blue-grey-50 p-[10px] rounded-[8px] w-full flex flex-col">
        <button-el
          v-if="!showStreetInput"
          text="Hausanschlüsse auswählen"
          :primary="false"
          icon="add"
          :less-padding="true"
          @click="addAddressPoints()" />
        <div v-if="showStreetInput" class="flex flex-col gap-[10px]">
          <the-label
            label="Initial kann einmalig durch Verzeichnen eines Polygons mehrere Adresspunkte ausgewählt werden." />
          <the-label
            label="Danach kann mittels Rechtsklick einzelne Adresspunkte an- und abgewählt werden." />
          <button-el
            text="Hausanschlüsse entfernen"
            icon="remove"
            :critical="true"
            :primary="false"
            :less-padding="true"
            @click="removeAddressPoints()" />
        </div>
      </div>
    </div>

    <button-el
      :disabled="!formIsValid"
      text="Datenerfassung speichern"
      @click="submitTicket()" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { LAYER_KEY__ADDRESS_POINTS } from '@/configs/layers/buildings';
import { step2submitData } from '../../../ticketHelper';
import { addMainGeometry } from '@/features/draw/draw';
import dayjs from 'dayjs';
import { SWITCH_ID__ADDRESS_POINTS } from '@/configs/sidebar/buildings';

export default {
  name: 'Step2CoLaying',
  props: {
    successToast: {
      type: String,
    },
  },
  data() {
    return {
      ticketData: {
        required: {
          construction_date_start: null,
          construction_date_end: null,
          measure_area: null,
        },
        optional: {},
      },
      showStreetInput: false,
    };
  },
  computed: {
    formIsValid() {
      return Object.values(this.ticketData.required).every(
        (item) => item || item === false || item === 0
      );
    },
    ...mapGetters('ticket', [
      'getDrawAsMultiFeatures',
      'getGemeindeActiveAgs',
      'getGemeindeActive',
    ]),
    ...mapState('draw', ['selectedFeatures']),
    measurementName() {
      return `${this.getGemeindeActive.name}${
        this.ticketData.required.measure_area
          ? ' ' + this.ticketData.required.measure_area
          : ''
      }`;
    },
  },
  methods: {
    addAddressPoints() {
      this.showStreetInput = true;
      this.$store.commit('map/CHANGE_SWITCH', {
        switches: [SWITCH_ID__ADDRESS_POINTS],
        active: true,
      });
      this.$store.commit('draw/SET_GEOMETRY_SELECTION', {
        layer: LAYER_KEY__ADDRESS_POINTS,
        active: true,
      });
      addMainGeometry('polygon');
    },
    removeAddressPoints() {
      this.showStreetInput = false;
      this.$store.commit('draw/RESET_GEOMETRY_SELECTION');
    },
    submitTicket() {
      const multilinestring = this.getDrawAsMultiFeatures.multilinestring;
      let formTicketData = new FormData();
      const totalTicketData = {
        ...this.ticketData.required,
        ...this.ticketData.optional,
        measurement_name: this.measurementName,
      };

      for (const [key, val] of Object.entries(totalTicketData)) {
        if (key === 'upload') {
          if (totalTicketData.upload) {
            for (let i = 0; i < totalTicketData.upload.length; i++) {
              formTicketData.append(`upload[${i}]`, totalTicketData.upload[i]);
            }
          }
        } else {
          formTicketData.append(key, val);
        }
      }

      formTicketData.set(
        'construction_date_start',
        dayjs(this.ticketData.required.construction_date_start).format(
          'YYYY-MM-DD'
        )
      );
      formTicketData.set(
        'construction_date_end',
        dayjs(this.ticketData.required.construction_date_end).format(
          'YYYY-MM-DD'
        )
      );
      formTicketData.append('gemeinde', this.getGemeindeActiveAgs);
      if (multilinestring) {
        formTicketData.append(
          'multilinestring',
          JSON.stringify(multilinestring)
        );
      }

      step2submitData(
        'ticket/POST_COLAYING',
        formTicketData,
        this.successToast,
        this
      );
    },
  },
};
</script>
