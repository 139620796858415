<template>
  <div class="relative">
    <div
      class="border relative overflow-hidden rounded-[6px] flex items-center"
      :class="{
        'h-[32px]': small,
        'h-[40px]': !small,
        'border-blue-grey-100 focus:border-blue-grey-300': !errorText?.length,
        'border-red-500 focus:border-red-500': errorText?.length > 0,
        [backgroundColor]: true,
      }">
      <label
        class="cursor-pointer w-full h-full flex items-center"
        :class="[
          { 'p-[8px]': small, 'p-[10px]': !small },
          modelValue === null ? labelColor : '',
        ]">
        <span v-if="!selectedFile" class="text-neutral">
          {{ label ? label : genericPlaceHolder }}
        </span>
        <input
          :id="id ? id : uniqueId"
          ref="inputElement"
          type="file"
          :name="name"
          :multiple="allowMultiple"
          style="display: none"
          @change="handleFileUpload" />
        <span
          v-if="selectedFile && !allowMultiple"
          class="flex items-center w-full justify-between">
          <span class="whitespace-nowrap">{{ selectedFile.name }}</span>
        </span>
        <span
          v-if="selectedFile && allowMultiple"
          class="flex items-center w-full justify-between">
          <span class="body-2 whitespace-nowrap">
            {{
              selectedFile.length === 1
                ? selectedFile[0].name
                : `${selectedFile.length} Dateien`
            }}
          </span>
        </span>
      </label>
      <span
        :class="`flex items-center absolute ${backgroundColor} gap-1 h-full p-[8px] ml-auto cursor-pointer`"
        style="right: 0">
        <IconWrapper v-if="selectedFile" icon="close" @click="clearSelection" />
        <IconWrapper
          icon="file_upload"
          type="filled"
          @click="() => inputElement.click()" />
      </span>
    </div>
    <span
      v-if="errorText"
      class="absolute bottom-[-17px] right-0 text-xxs text-red-500 whitespace-nowrap">
      {{ errorText }}
    </span>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { useValidation } from '../../composables/formElementValidation';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  modelValue: {
    type: [File, Array],
    default: () => null,
  },
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  labelColor: {
    type: String,
    default: '',
  },
  small: {
    type: Boolean,
    default: false,
  },
  allowMultiple: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Object,
    default: null,
  },
  // Needed for to work with a HTML label element
  id: {
    type: String,
    default: '',
  },
  backgroundColor: {
    type: String,
    default: 'bg-white',
  },
});

const emit = defineEmits(['update:modelValue']);

const genericPlaceHolder = `Datei${props.allowMultiple ? '(en)' : ''} auswählen`;
const registerValidator = inject('registerValidator', null);
const unregisterValidator = inject('unregisterValidator', null);
const uniqueId = Math.random().toString(36).substring(2, 11);
const isMounted = ref(false);
const inputElement = ref(null);

const selectedFile = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
const validators = computed(() => mapValidationRules(props.rules));

const { isValid, errorText, checkValidationRules, mapValidationRules } =
  useValidation();

function handleFileUpload(event) {
  const files = event.target.files;
  if (props.allowMultiple) {
    selectedFile.value = [...files];
  } else {
    const file = files[0];
    if (file) {
      selectedFile.value = file;
    }
  }
}

function clearSelection() {
  selectedFile.value = props.allowMultiple ? [] : null;
}

function clearHTMLFileInput() {
  // Needs to be done to avoid issues when the user wants to select the same file as before
  // There is no change triggered if the file-input element isn't cleared, because
  // it remembers the old file. This is relevant for dynamic forms.
  inputElement.value = '';
}

function validate(setErrorText, input) {
  checkValidationRules(validators.value, input, setErrorText);
  return isValid.value;
}

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  if (unregisterValidator) {
    unregisterValidator(uniqueId);
  }
});

watch(
  () => props.modelValue,
  (newVal) => {
    if (registerValidator) {
      registerValidator(uniqueId, validate(isMounted.value, newVal));
    } else {
      validate(isMounted.value, newVal);
    }
    clearHTMLFileInput();
  },
  { deep: true },
);
</script>
