<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.9165 3.33333C4.9165 2.8731 5.2896 2.5 5.74984 2.5H13.2498L16.5832 6.66667V16.6667C16.5832 17.1269 16.2101 17.5 15.7498 17.5H5.74984C5.2896 17.5 4.9165 17.1269 4.9165 16.6667V3.33333ZM15.7498 7.5L12.4165 3.33333V7.5H15.7498ZM14.0832 10H7.4165V9.16667H14.0832V10ZM7.4165 12.5H14.0832V11.6667H7.4165V12.5ZM7.4165 15H14.0832V14.1667H7.4165V15Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
