<template>
  <div class="custom-chip-group bg-infra-highlight-25">
    <perfect-scrollbar
      class="flex p-[10px] overflow-hidden items-center gap-x-[10px]">
      <div
        v-for="(chip, index) in getProperties.chips"
        :key="chip.title"
        class="whitespace-nowrap text-white px-4 cursor-pointer custom-chip overline-3"
        :class="
          chip.active ? 'bg-infra-highlight-700' : 'bg-infra-highlight-400'
        "
        @click="toggleActive(index)">
        {{ chip.title }}
      </div>
    </perfect-scrollbar>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import resizeUnitsMethod from '@/mixins/resizeUnitsMethod.vue';

export default {
  mixins: [resizeUnitsMethod],
  props: {
    propertyData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('map', ['getProperties', 'hasResized']),
    ...mapState('map', ['layerID']),
    currentExpandable() {
      return this.propertyData.chips.findIndex((chip) => chip.active === true);
    },
  },
  watch: {
    currentExpandable() {
      this.$store.commit('map/SET_HAS_RESIZED', false);
    },
  },
  methods: {
    toggleActive(index) {
      const layerID = this.layerID;
      this.$store.commit('map/SET_CHIP_ACTIVE_CHANGE', { index, layerID });
      // only run resizeUnits once
      if (this.hasResized) return;
      this.resizeUnits();
      this.$nextTick(() => {
        this.$store.commit('map/SET_HAS_RESIZED', true);
      });
    },
  },
};
</script>

<style lang="scss">
.custom-chip-group {
  border-top: 1px solid #c5cbe0;
  border-bottom: 1px solid #c5cbe0;
  display: flex;
  align-items: center;

  .custom-chip {
    border-radius: 100px !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    display: flex;
    align-items: center;
  }

  .ps__rail-y {
    display: none !important;
  }

  .ps__rail-x {
    margin-left: 12px !important;
    background-color: transparent !important;
    opacity: 1 !important;

    .ps__thumb-x {
      height: 6px !important;
      margin-bottom: 3px;
      @apply bg-infra-lines cursor-pointer;
    }
  }
}
</style>
