<template>
  <div
    v-if="getResults === null"
    class="oew_subnavi nav-drawer bg-white rounded-[8px] left-5 top-[85px] h-fit w-[320px] absolute z-10">
    <PerfectScrollbar
      :style="{
        maxHeight: `${getContainerHeight + 60}px`,
      }">
      <v-list nav dense class="main-menu p-0 pt-2.5 bg-transparent">
        <div
          v-for="(category, index) in processedNavigationConfig"
          :key="index"
          class="">
          <h4 class="text-infra-highlight-500 px-3">{{ category.title }}</h4>

          <!-- MenuItem-List -->
          <div
            v-for="(section, index) in getFilteredSections(category.sections)"
            :key="index"
            class="font-medium flex flex-col mt-5">
            <h5 v-if="section.title" class="text-infra-highlight-800 px-3 mb-2">
              {{ section.title }}
            </h5>
            <nav-menu :menu="section.items" />
          </div>
          <!-- /MenuItem-List -->
        </div>
      </v-list>
    </PerfectScrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  computed: {
    ...mapState('map', ['navigationConfig']),
    ...mapGetters('search', ['getResults']),
    ...mapGetters('map', ['getContainerHeight']),
    processedNavigationConfig() {
      let processedConfig = this.navigationConfig;
      for (let item of processedConfig[0].sections) {
        for (let subItem of item.items) {
          if ('replace' in subItem) {
            if (subItem.replace.multiple) {
              subItem.items = JSON.parse(
                this.$store.getters[
                  subItem.replace.storageGetterForReplacement
                ].reduce(
                  (prev, cur, curI) =>
                    prev.replaceAll(subItem.replace.toReplace + curI, cur),
                  JSON.stringify(subItem.items)
                )
              );
            } else {
              subItem.items = JSON.parse(
                JSON.stringify(subItem.items),
                this.$store.getters[subItem.replace.storageGetterForReplacement]
              );
            }
          }
        }
      }
      return processedConfig;
    },
  },
  methods: {
    getFilteredSections(sections) {
      // if no items within section after applying casl filter, disregard section
      return sections.filter(
        (section) =>
          section.items.filter(
            (item) =>
              !(
                item.modelNameForCASL &&
                !this.$can('view', item.modelNameForCASL)
              )
          ).length !== 0
      );
    },
  },
};
</script>
