<template>
  <div class="flex flex-col gap-2 pt-2">
    <div class="flex justify-between items-center">
      <chip-bar :data="data.subheadline" />

      <div class="flex gap-2 items-center justify-between relative mr-2">
        <interval-switch @interval="selectedInterval = $event" />
        <year-nodge v-if="selectedInterval !== 'months'" :year="yearData" />
      </div>
    </div>
    <div
      class="w-full bg-white flex overflow-y max-h gantt-chart-custom advanced">
      <construction-data-table :data="data.sections" />
      <!-- gantt chart START-->
      <div class="chart-wrapper overflow-x-auto overflow-y-hidden">
        <!-- header with dates-->
        <table class="sticky top-0 bg-white">
          <tbody class="chart-values relative flex">
            <tr v-if="selectedInterval === 'days'" class="flex border-y">
              <td
                v-for="date in combinedIntervalDates.allDates"
                :key="date"
                :data-intervaldata="date"
                :class="{
                  'bg-infra-highlight-25':
                    isWeekend(date) && currentIntervalName !== date,
                  'bg-white': !isWeekend(date) && currentIntervalName !== date,
                  currentDateInterval: currentIntervalName === date,
                }"
                class="date-item">
                {{ convertHeaderDate(date) }}
              </td>
            </tr>
            <tr v-else-if="selectedInterval === 'weeks'" class="flex border-y">
              <td
                v-for="date in calendarWeeks"
                :key="date"
                :data-intervaldata="date.value + '.' + date.year"
                :class="{
                  currentDateInterval:
                    currentIntervalName === date.value + '.' + date.year,
                }"
                class="date-item">
                {{ 'KW ' + date.value }}
              </td>
            </tr>
            <tr v-else-if="selectedInterval === 'months'" class="flex border-y">
              <td
                v-for="date in calendarMonths"
                :key="date"
                :data-intervaldata="date.label"
                class="date-item whitespace-break-spaces text-center leading-[9px]"
                :class="{
                  currentDateInterval: currentIntervalName === date.label,
                }">
                {{
                  date.label.split('\n')[0] +
                  '\n' +
                  date.label.split('\n')[1].slice(-2)
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="relative">
          <div
            :style="{ left: calculateCurrentDateLinePosition() + 'px' }"
            class="current-date-line h-full z-10 absolute w-1 bg-blue-grey-700"></div>
          <!-- table background-->
          <table class="absolute left-0 w-full">
            <tbody v-for="dataItem in data.sections" :key="dataItem">
              <tr
                v-if="
                  dataItem.milestones &&
                  (selectedInterval === 'days' ||
                    selectedInterval === 'weeks' ||
                    selectedInterval === 'months')
                "
                class="chart-row border-b flex w-fit">
                <td
                  v-for="date in selectedInterval === 'days'
                    ? combinedIntervalDates.allDates
                    : selectedInterval === 'weeks'
                      ? Object.keys(calendarWeeks)
                      : Object.keys(calendarMonths)"
                  :key="date"
                  :class="{
                    'bg-infra-highlight-25':
                      isWeekend(date) && selectedInterval === 'days',
                    'bg-white': !isWeekend(date) && selectedInterval === 'days',
                  }"
                  style="
                    border-right: 1px solid #e5e7eb !important;
                    height: 23px !important;
                  "
                  class="flex min-w-[27px]"></td>
              </tr>
              <tr
                v-if="
                  selectedInterval === 'days' ||
                  selectedInterval === 'weeks' ||
                  selectedInterval === 'months'
                "
                class="chart-row border-b flex w-fit">
                <td
                  v-for="date in selectedInterval === 'days'
                    ? combinedIntervalDates.allDates
                    : selectedInterval === 'weeks'
                      ? Object.keys(calendarWeeks)
                      : Object.keys(calendarMonths)"
                  :key="date"
                  :class="{
                    'bg-infra-highlight-25':
                      isWeekend(date) && selectedInterval === 'days',
                    'bg-white': !isWeekend(date) && selectedInterval === 'days',
                  }"
                  style="
                    border-right: 1px solid #e5e7eb !important;
                    height: 23px !important;
                  "
                  class="flex min-w-[27px]"></td>
              </tr>
            </tbody>
          </table>
          <div
            v-for="(item, index) in data.sections"
            :key="item"
            class="flex flex-col justify-center z-20 relative"
            :class="item.milestones ? 'h-[48px]' : 'h-[24px]'">
            <!-- milestones-->
            <milestone-item
              v-if="item.milestones"
              :interval="selectedInterval"
              :interval-data="
                selectedInterval === 'weeks'
                  ? calendarWeeks
                  : selectedInterval === 'months'
                    ? calendarMonths
                    : null
              "
              :inactive="item.termin[0] === 1"
              :milestone-data="item.milestones" />

            <!-- phases-->
            <div
              :class="{ 'no-overlap': !checkDateOverlap(durationData[index]) }"
              class="chart-bars grid-flow-row auto-rows-fr gap-[2px] items-center h-[24px] relative">
              <!-- planung-->
              <phase-item
                v-if="
                  durationData[index].planung.start &&
                  durationData[index].planung.end
                "
                :interval="selectedInterval"
                :interval-data="
                  selectedInterval === 'weeks'
                    ? calendarWeeks
                    : selectedInterval === 'months'
                      ? calendarMonths
                      : null
                "
                :inactive="item.termin[0] === 1"
                :duration-data="durationData[index].planung"
                bg-color="bg-[#338BA8]" />

              <!-- genehmigungsplanung-->
              <phase-item
                v-if="
                  durationData[index].genehmigungsplanung.start &&
                  durationData[index].genehmigungsplanung.end
                "
                :interval="selectedInterval"
                :interval-data="
                  selectedInterval === 'weeks'
                    ? calendarWeeks
                    : selectedInterval === 'months'
                      ? calendarMonths
                      : null
                "
                :inactive="item.termin[0] === 1"
                :duration-data="durationData[index].genehmigungsplanung"
                bg-color="bg-orange-500" />

              <!-- ausfuehrungsplanung-->
              <phase-item
                v-if="
                  durationData[index].ausfuehrungsplanung.start &&
                  durationData[index].ausfuehrungsplanung.end
                "
                :interval="selectedInterval"
                :interval-data="
                  selectedInterval === 'weeks'
                    ? calendarWeeks
                    : selectedInterval === 'months'
                      ? calendarMonths
                      : null
                "
                :inactive="item.termin[0] === 1"
                :duration-data="durationData[index].ausfuehrungsplanung"
                bg-color="bg-amber-500" />

              <!-- bau-->
              <phase-item
                v-if="
                  durationData[index].bau.start && durationData[index].bau.end
                "
                :interval="selectedInterval"
                :interval-data="
                  selectedInterval === 'weeks'
                    ? calendarWeeks
                    : selectedInterval === 'months'
                      ? calendarMonths
                      : null
                "
                :inactive="item.termin[0] === 1"
                :duration-data="durationData[index].bau"
                bg-color="bg-green-500" />

              <!-- bauabschluss -->
              <phase-item
                v-if="
                  durationData[index].bauabschluss.start &&
                  durationData[index].bauabschluss.end
                "
                :interval="selectedInterval"
                :interval-data="
                  selectedInterval === 'weeks'
                    ? calendarWeeks
                    : selectedInterval === 'months'
                      ? calendarMonths
                      : null
                "
                :inactive="item.termin[0] === 1"
                :duration-data="durationData[index].bauabschluss"
                bg-color="bg-teal-500" />
            </div>
          </div>
        </div>
      </div>
      <!-- gantt chart END-->
    </div>
  </div>
</template>

<script>
import ChipBar from './components/chipBarGroup.vue';
import PhaseItem from './components/phaseItem.vue';
import MilestoneItem from './components/milestoneItem.vue';
import ConstructionDataTable from './components/constructionDataTable.vue';
import IntervalSwitch from './components/intervalSwitch.vue';
import YearNodge from './components/yearNodge.vue';

export default {
  components: {
    YearNodge,
    IntervalSwitch,
    ConstructionDataTable,
    MilestoneItem,
    PhaseItem,
    ChipBar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedInterval: null,
      yearData: [],
      monthLabels: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    };
  },
  computed: {
    durationData() {
      return this.data.sections.map((obj) => {
        return {
          planung: {
            start: obj.intervals.planung_start,
            end: obj.intervals.planung_end,
            name: 'Planung',
          },
          bau: {
            start: obj.intervals.bau_start,
            end: obj.intervals.bau_end,
            name: 'Bau',
          },
          genehmigungsplanung: {
            start: obj.intervals.genehmigungsplanung_start,
            end: obj.intervals.genehmigungsplanung_end,
            name: 'Genehmigungsplanung',
          },
          ausfuehrungsplanung: {
            start: obj.intervals.ausfuehrungsplanung_start,
            end: obj.intervals.ausfuehrungsplanung_end,
            name: 'Ausführungsplanung',
          },
          bauabschluss: {
            start: obj.intervals.bauabschluss_start,
            end: obj.intervals.bauabschluss_end,
            name: 'Bauabschluss',
          },
        };
      });
    },

    currentIntervalName() {
      const currentDate = new Date();
      let intervalName;

      switch (this.selectedInterval) {
        case 'days':
          intervalName = currentDate
            .toLocaleDateString('en-CA')
            .split('-')
            .reverse()
            .join('.');
          break;

        case 'weeks':
          intervalName =
            this.getCurrentWeek(currentDate) + '.' + currentDate.getFullYear();
          break;

        case 'months':
          intervalName =
            this.monthLabels[currentDate.getMonth()] +
            '\n' +
            currentDate.getFullYear();
          break;

        default:
          intervalName = '';
      }

      return intervalName;
    },

    calendarWeeks() {
      const weeks = {};

      this.combinedIntervalDates.allDates.forEach((dateStr) => {
        const [day, month, year] = dateStr.split('.');
        const date = new Date(year, month - 1, day);
        const weekNumber = this.getISOWeekNumber(date);

        // Determine the correct year for the week
        let adjustedYear = date.getFullYear();
        if (date.getMonth() === 11 && weekNumber === 1) {
          adjustedYear++;
        }
        if (date.getMonth() === 0 && weekNumber >= 52) {
          adjustedYear--;
        }

        const weekKey = `${weekNumber}-${adjustedYear}`;

        if (weekKey in weeks) {
          weeks[weekKey].push(dateStr);
        } else {
          weeks[weekKey] = [dateStr];
        }
      });

      // Remove the 53rd week for years that do not have it
      Object.keys(weeks).forEach((weekKey) => {
        const [weekNumber, year] = weekKey.split('-').map(Number);
        if (weekNumber === 53 && !this.is53WeekYear(year)) {
          delete weeks[weekKey];
        }
      });
      // test

      // Sort the week keys in ascending order
      const sortedWeekKeys = Object.keys(weeks).sort((a, b) => {
        const [weekA, yearA] = a.split('-');
        const [weekB, yearB] = b.split('-');
        const yearOrder = parseInt(yearA) - parseInt(yearB);
        return yearOrder !== 0 ? yearOrder : parseInt(weekA) - parseInt(weekB);
      });

      // Create an array of objects with sorted weeks
      return sortedWeekKeys.map((weekKey, index) => {
        const [weekNumber, year] = weekKey.split('-');
        const weekValue = weekNumber;
        const weekData = weeks[weekKey];

        return {
          value: parseInt(weekValue),
          year: parseInt(year),
          data: weekData,
        };
      });
    },

    calendarMonths() {
      const months = {};

      this.combinedIntervalDates.allDates.forEach((dateStr) => {
        const [day, month, year] = dateStr.split('.');
        const monthKey = `${this.monthLabels[parseInt(month) - 1]}\n${year}`;

        if (monthKey in months) {
          months[monthKey].push(dateStr);
        } else {
          months[monthKey] = [dateStr];
        }
      });

      // Sort the month keys in ascending order
      const sortedMonthKeys = Object.keys(months).sort((a, b) => {
        const [monthA, yearA] = a.split('\n');
        const [monthB, yearB] = b.split('\n');

        if (yearA !== yearB) {
          return parseInt(yearA) - parseInt(yearB);
        } else {
          return (
            this.monthLabels.indexOf(monthA) - this.monthLabels.indexOf(monthB)
          );
        }
      });

      // Create an array of objects with sorted months
      return sortedMonthKeys.map((monthKey) => {
        const [month, year] = monthKey.split('\n');
        const formattedMonth = `${year}-${
          this.monthLabels.indexOf(month) + 1
        }`.padStart(2, '0');
        return {
          value: `${formattedMonth}-${year}`,
          label: monthKey,
          data: months[monthKey],
        };
      });
    },

    combinedIntervalDates() {
      let earliestDate = null;
      let latestDate = null;

      this.data.sections.forEach((obj) => {
        const milestones = obj.milestones;
        const intervals = obj.intervals;

        if (milestones) {
          Object.values(milestones).forEach((date) => {
            if (!earliestDate || date < earliestDate) {
              earliestDate = date;
            }

            if (!latestDate || date > latestDate) {
              latestDate = date;
            }
          });
        }

        if (intervals) {
          Object.values(intervals).forEach((date) => {
            if (!earliestDate || date < earliestDate) {
              earliestDate = date;
            }

            if (!latestDate || date > latestDate) {
              latestDate = date;
            }
          });
        }
      });

      const allDates = [];
      const currentDate = new Date(earliestDate);
      const endDate = new Date(latestDate);

      while (
        new Date(currentDate.toDateString()) <= new Date(endDate.toDateString())
      ) {
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        allDates.push(formattedDate);

        currentDate.setDate(currentDate.getDate() + 1);
      }

      return {
        earliestDate,
        latestDate,
        allDates,
      };
    },
  },
  watch: {
    selectedInterval(val) {
      this.yearData = [];
      if (val !== null) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  mounted() {
    const scrollElement = document.querySelector('.chart-wrapper');
    scrollElement.addEventListener('scroll', this.visibleHeaderItems);

    window.addEventListener('resize', this.createChart);
  },
  methods: {
    visibleHeaderItems() {
      const constructionDataTableInfo = document.querySelector(
        '.construction-data-table-wrapper',
      );
      const chartWrapper = document.querySelector('.chart-wrapper');
      const headerItems = chartWrapper.querySelectorAll(
        '.chart-values .date-item',
      );

      // Filter the header items that are visible within the chart wrapper
      const visibleItems = Array.from(headerItems).filter((item) => {
        const rect = item.getBoundingClientRect();
        return (
          rect.left >= 0 + constructionDataTableInfo.offsetWidth &&
          rect.right <=
            chartWrapper.offsetWidth +
              constructionDataTableInfo.offsetWidth +
              22
        );
      });

      // Extract the unique years from the visible header items
      const uniqueYears = new Set();
      if (this.selectedInterval === 'weeks') {
        visibleItems.forEach((item) => {
          const intervalData = item.dataset.intervaldata;
          const year = intervalData.split('.')[1];
          uniqueYears.add(year);
        });
      } else if (this.selectedInterval === 'days') {
        visibleItems.forEach((item) => {
          const intervalData = item.dataset.intervaldata;
          const year = intervalData.split('.')[2];
          uniqueYears.add(year);
        });
      }

      // Convert the set of unique years to an array
      this.yearData = Array.from(uniqueYears);
    },

    getCurrentWeek(date) {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    },

    is53WeekYear(year) {
      const firstDayOfYear = new Date(year, 0, 1).getDay();
      const isLeapYear = new Date(year, 1, 29).getMonth() === 1;
      return firstDayOfYear === 4 || (isLeapYear && firstDayOfYear === 3); // 4 = Thursday, 3 = Wednesday
    },

    getISOWeekNumber(date) {
      const dateCopy = new Date(date);
      dateCopy.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      dateCopy.setDate(dateCopy.getDate() + 3 - ((dateCopy.getDay() + 6) % 7));
      // January 4 is always in week 1.
      const week1 = new Date(dateCopy.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return (
        1 +
        Math.round(
          ((dateCopy.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7,
        )
      );
    },

    calculateCurrentDateLinePosition() {
      const currentDate = new Date();
      const earliestDate = new Date(this.combinedIntervalDates.earliestDate);
      let intervalCount;

      if (this.selectedInterval === 'days') {
        intervalCount = Math.floor(
          (currentDate - earliestDate) / (1000 * 60 * 60 * 24),
        );
      } else if (this.selectedInterval === 'weeks') {
        intervalCount = this.calculateWeeksBetween(earliestDate, currentDate);
      } else if (this.selectedInterval === 'months') {
        intervalCount = this.calculateMonthsBetween(earliestDate, currentDate);
      }

      const cellWidth = 27; // Width of each cell in px
      return intervalCount * cellWidth + cellWidth / 2 - 2;
    },

    calculateWeeksBetween(startDate, endDate) {
      // Get the first day of the year for the start date
      const startYear = startDate.getFullYear();
      const firstDayOfYear = new Date(startYear, 0, 1);
      let firstWeekStart = new Date(firstDayOfYear);

      // Determine the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = firstDayOfYear.getDay();

      // Adjust to the first Monday of the year
      if (dayOfWeek !== 1) {
        // If Jan 1 is not Monday, move to next Monday
        firstWeekStart.setDate(
          firstDayOfYear.getDate() + (dayOfWeek === 0 ? 1 : 8 - dayOfWeek),
        );
      }

      // Calculate the start week
      const startWeek = Math.ceil(
        ((startDate - firstWeekStart) / (1000 * 60 * 60 * 24) + 1) / 7,
      );

      // Repeat the process for the end date
      const endYear = endDate.getFullYear();
      const firstDayOfEndYear = new Date(endYear, 0, 1);
      let firstWeekEnd = new Date(firstDayOfEndYear);
      const dayOfWeekEnd = firstDayOfEndYear.getDay();

      if (dayOfWeekEnd !== 1) {
        firstWeekEnd.setDate(
          firstDayOfEndYear.getDate() +
            (dayOfWeekEnd === 0 ? 1 : 8 - dayOfWeekEnd),
        );
      }

      const endWeek = Math.ceil(
        ((endDate - firstWeekEnd) / (1000 * 60 * 60 * 24) + 1) / 7,
      );

      // Calculate the difference in weeks
      return endWeek + (endYear - startYear) * 52 - startWeek;
    },

    calculateMonthsBetween(startDate, endDate) {
      return (
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        endDate.getMonth() -
        startDate.getMonth()
      );
    },

    convertShortDate(date) {
      const intervalData =
        this.selectedInterval === 'weeks'
          ? this.calendarWeeks
          : this.selectedInterval === 'months'
            ? this.calendarMonths
            : null;

      if (this.selectedInterval === 'months') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.data;
            }
          });
        });
        return shortCut;
      } else if (this.selectedInterval === 'weeks') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = { week: item.value, year: item.year };
            }
          });
        });
        return shortCut;
      } else {
        return date.split('-').reverse().join('.');
      }
    },

    checkDateOverlap(data) {
      const sortedPhases = Object.values(data)
        .filter((phase) => phase.start && phase.end)
        .sort((a, b) => new Date(a.start) - new Date(b.start));

      // Check for any start date that is less than or equal to the previous phase's end date
      for (let i = 1; i < sortedPhases.length; i++) {
        if (sortedPhases.length === 1) {
          return false;
        }
        if (this.selectedInterval === 'days') {
          if (
            new Date(sortedPhases[i].start) <= new Date(sortedPhases[i - 1].end)
          ) {
            return true;
          }
        } else if (this.selectedInterval === 'weeks') {
          const startWeek = this.convertShortDate(sortedPhases[i].start);
          const endWeek = this.convertShortDate(sortedPhases[i - 1].end);

          if (startWeek.year === endWeek.year) {
            if (startWeek.week <= endWeek.week) {
              return true;
            }
          } else if (startWeek.year < endWeek.year) {
            return true;
          }
        } else if (this.selectedInterval === 'months') {
          const getStartMonth = new Date(sortedPhases[i - 1].end).getMonth();
          const getStartYear = new Date(sortedPhases[i - 1].end).getFullYear();

          const getEndMonth = new Date(sortedPhases[i].start).getMonth();
          const getEndYear = new Date(sortedPhases[i].start).getFullYear();

          return (
            getStartYear > getEndYear ||
            (getStartYear === getEndYear && getEndMonth <= getStartMonth)
          );
        }
      }

      return false;
    },

    isWeekend(date) {
      date = date.split('.').reverse().join('-');
      const day = new Date(date).getDay();
      return day === 0 || day === 6; // Sunday is 0, Saturday is 6
    },
    convertHeaderDate(date) {
      const [day, month, year] = date.split('.');
      return `${day}.${month}`;
    },

    createChart() {
      this.visibleHeaderItems();
      const days = document.querySelectorAll('.chart-values .date-item');
      const tasks = document.querySelectorAll('.chart-bars .chart-bar-item');
      const daysArray = [...days];

      tasks.forEach((el) => {
        const duration = el.dataset.duration.split('-');
        const startDay = duration[0];
        const endDay = duration[1];

        let left = 0;
        let width = 0;

        const filteredStartDay = daysArray.find(
          (day) => day.dataset.intervaldata === startDay,
        );
        left = filteredStartDay?.offsetLeft || 0;

        const filteredEndDay = daysArray.find(
          (day) => day.dataset.intervaldata === endDay,
        );
        width = filteredEndDay
          ? filteredEndDay.offsetLeft + filteredEndDay.offsetWidth - left
          : 0;

        // apply css
        el.style.left = `${left}px`;
        el.style.width = `${width}px`;
      });
    },
  },
};
</script>

<style lang="scss">
.chart-wrapper .chart-bars .chart-bar-item {
  position: absolute;
}

.gantt-chart-custom {
  border-color: #eceff1 !important;

  &.advanced {
    .chart-bars:not(.no-overlap) {
      display: grid;
      padding-top: 2px;
      padding-bottom: 3px;
    }
    .chart-bars.no-overlap {
      .chart-bar-item {
        position: absolute !important;
        height: 19px;
        margin-top: 2px;
      }
    }
  }

  .date-item {
    font-weight: 600;
    font-size: 8px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.04em;
    color: #607d8b !important;
    height: 22px !important;
    border-right: 1px solid #e5e7eb !important;
    width: 27px;
    &.currentDateInterval {
      @apply bg-blue-grey-700 relative;
      color: white !important;
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 26px;
        height: 2px;
        @apply bg-blue-grey-700;
      }
    }
  }

  td {
    border: 1px !important;
  }
}
</style>
