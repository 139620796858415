<template>
  <div class="flex flex-col w-full items-center py-5 pointer-events-none">
    <h5
      :style="titleCustomSize ? { 'font-size': titleCustomSize + 'px' } : {}"
      :class="customMarginBottom">
      {{ title }}
    </h5>
    <v-chart
      class="h-full w-full"
      :class="customPadding"
      :style="{ 'min-height': customMinHeight }"
      :option="option"
      autoresize />
  </div>
</template>

<script>
import { sum } from 'lodash';

export default {
  props: {
    chartData: {
      type: Array,
      default: () => [],
      deep: true,
    },
    titleInside: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    chartUnit: {
      type: String,
      default: '',
    },
    chartTop: {
      type: String,
      default: '',
    },
    titleTop: {
      type: String,
      default: 'center',
    },
    titleInsideLH: {
      type: Number,
      default: 0,
    },
    titleCustomSize: {
      type: Number,
      default: null,
    },
    multiply: {
      type: Number,
      default: null,
    },
    customMarginBottom: {
      type: String,
      default: '-mb-5',
    },
    customPadding: {
      type: String,
      default: '',
    },
    customMinHeight: {
      type: String,
      default: '250px',
    },
    customRadius: {
      type: Array,
      default: () => ['35%', '45%'],
    },
  },
  computed: {
    filterChartData() {
      return this.chartData.filter(
        (item) => item.value !== 0 || item.value !== 0.0
      );
    },
    option() {
      const sumValues = this.filterChartData
        .map((e) => e.value)
        .reduce((pv, cv) => pv + cv, 0);
      return {
        title: {
          text:
            `{a|${sum(
              this.chartData.map((item) =>
                Math.round(
                  this.multiply ? item.value * this.multiply : item.value
                )
              )
            ).toLocaleString(navigator.language)}}` +
            `{b| ${this.chartUnit}` +
            '\n' +
            `${this.titleInside}}`,
          left: 'center',
          top: this.titleTop,
          textStyle: {
            color: 'black',
            lineHeight: 15,
            fontFamily: 'Avenir Next, sans-serif',
            rich: {
              a: {
                fontWeight: 'bold',
                fontSize: 12,
                lineHeight: this.titleInsideLH,
              },
              b: {
                fontSize: 12,
                color: 'black',
                padding: [2, 0, 0, 0],
                fontFamily: 'Avenir Next, sans-serif',
                fontWeight: 500,
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: this.customRadius,
            top: this.chartTop,
            data: this.filterChartData,
            emphasis: {
              scale: false,
            },
            label: {
              formatter: function (value) {
                const valueFormatted = value.value;
                const formattedPercentage = (
                  (valueFormatted / sumValues) *
                  100
                ).toLocaleString('de-DE');
                const roundedPercentage =
                  formattedPercentage !== '100'
                    ? formattedPercentage.slice(
                        0,
                        formattedPercentage.indexOf(',') + 2
                      )
                    : formattedPercentage;
                return (
                  `{a|${value.name}}` +
                  '\n' +
                  `{b|${
                    roundedPercentage.endsWith('0') &&
                    roundedPercentage !== '100'
                      ? roundedPercentage.slice(0, roundedPercentage.length - 2)
                      : roundedPercentage
                  }%}`
                );
              },
              rich: {
                a: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: 'black',
                  fontWeight: 500,
                  fontFamily: 'Avenir Next, sans-serif',
                  align: 'center',
                  style: 'normal',
                },
                b: {
                  fontSize: 12,
                  color: 'black',
                  fontFamily: 'Avenir Next, sans-serif',
                  align: 'center',
                  fontWeight: 500,
                },
              },
            },
            color: this.filterChartData.map((item) => item.color),
          },
        ],
      };
    },
  },
};
</script>
