<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_49_7926)">
      <path
        d="M3.875 1.25H1.375C1.02982 1.25 0.75 1.52982 0.75 1.875V3.125C0.75 3.47018 1.02982 3.75 1.375 3.75H3.875C4.22018 3.75 4.5 3.47018 4.5 3.125V1.875C4.5 1.52982 4.22018 1.25 3.875 1.25Z"
        :fill="fill" />
      <path
        d="M20.125 16.25H17.625C17.2798 16.25 17 16.5298 17 16.875V18.125C17 18.4702 17.2798 18.75 17.625 18.75H20.125C20.4702 18.75 20.75 18.4702 20.75 18.125V16.875C20.75 16.5298 20.4702 16.25 20.125 16.25Z"
        :fill="fill" />
      <path
        d="M11.375 6.25H3.875C3.52982 6.25 3.25 6.52982 3.25 6.875V8.125C3.25 8.47018 3.52982 8.75 3.875 8.75H11.375C11.7202 8.75 12 8.47018 12 8.125V6.875C12 6.52982 11.7202 6.25 11.375 6.25Z"
        :fill="fill" />
      <path
        d="M17.625 11.25H7.625C7.27982 11.25 7 11.5298 7 11.875V13.125C7 13.4702 7.27982 13.75 7.625 13.75H17.625C17.9702 13.75 18.25 13.4702 18.25 13.125V11.875C18.25 11.5298 17.9702 11.25 17.625 11.25Z"
        :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
