<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1075_35675)">
      <path
        d="M8.5 16.0004C8.20074 16.0003 7.91375 15.8797 7.70217 15.665L4.31677 12.2309C4.11122 12.015 3.99747 11.7259 4.00004 11.4258C4.00261 11.1256 4.12129 10.8385 4.33052 10.6263C4.53975 10.4141 4.82278 10.2937 5.11866 10.2911C5.41454 10.2885 5.69959 10.4038 5.91243 10.6123L8.5 13.2371L11.0876 10.6123C11.3004 10.4038 11.5855 10.2885 11.8813 10.2911C12.1772 10.2937 12.4603 10.4141 12.6695 10.6263C12.8787 10.8385 12.9974 11.1256 13 11.4258C13.0025 11.7259 12.8888 12.015 12.6832 12.2309L9.29783 15.665C9.08625 15.8797 8.79926 16.0003 8.5 16.0004Z"
        :fill="fill" />
      <path
        d="M11.8735 5.70912C11.5747 5.70905 11.2881 5.58871 11.0769 5.37455L8.4932 2.75624L5.90953 5.37455C5.69703 5.58255 5.4124 5.69765 5.11697 5.69504C4.82154 5.69244 4.53893 5.57235 4.33002 5.36064C4.12111 5.14893 4.00261 4.86253 4.00004 4.56314C3.99748 4.26374 4.11105 3.9753 4.3163 3.75994L7.69658 0.334325C7.90788 0.120257 8.19442 0 8.4932 0C8.79197 0 9.07852 0.120257 9.28982 0.334325L12.6701 3.75994C12.8276 3.91963 12.9349 4.12308 12.9784 4.34454C13.0218 4.56601 12.9995 4.79557 12.9142 5.00419C12.829 5.21281 12.6846 5.39113 12.4993 5.5166C12.3141 5.64207 12.0963 5.70907 11.8735 5.70912Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_1075_35675">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '16px',
    },
    height: {
      type: [Number, String],
      default: '16px',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
