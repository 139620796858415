<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_94_372)">
      <path
        d="M10 5V10H15"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M2.47176 19.0583L1.86426 14.2275L6.60759 15.5491"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M1.86384 14.2275C2.8965 16.2168 4.62225 17.7589 6.7148 18.5621C8.80734 19.3653 11.1217 19.374 13.2202 18.5865C15.3187 17.7991 17.056 16.27 18.1036 14.2885C19.1511 12.3069 19.4364 10.0102 18.9054 7.83264C18.3744 5.65504 17.064 3.74744 15.2219 2.47053C13.3798 1.19361 11.1336 0.635873 8.90818 0.902762C6.68272 1.16965 4.63216 2.24267 3.14421 3.91895C1.65626 5.59522 0.834042 7.75858 0.833008 9.99998"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_94_372">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
