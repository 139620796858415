export default [
  // Gesamtstatus card
  { id: 0, colors: { bg: '#37474F' }, active: true },
  // Status cards
  {
    id: 1,
    disabled: true,
    colors: {
      bg: '#E64A19',
      bottom: '#BF360C',
      progressWrapper: '#FBE9E7',
      progressBar: '#FF9E80',
      popper: '#BF360C',
    },
    noLink: true,
    milestoneGroups: [
      {
        name: 'Abschluss Vereinbarung',
        milestones: [],
      },
    ],
    communityCount: 0,
  },
  {
    id: 2,
    colors: {
      bg: '#FF8F00',
      bottom: '#FF6F00',
      progressWrapper: '#FFF8E1',
      progressBar: '#FFAB00',
      popper: '#FF6F00',
    },
    grouped: true,
    milestoneGroups: [
      {
        name: 'Start',
        milestones: [],
      },
      {
        name: 'Ende',
        milestones: [],
      },
      {
        name: 'Veröffentlichung Ergebnisse',
        milestones: [],
      },
    ],
    communityCount: 0,
  },
  {
    id: 3,
    disabled: ['view', 'invitation'],
    colors: {
      bg: '#9E9D24',
      bottom: '#827717',
      progressWrapper: '#F9FBE7',
      progressBar: '#C0CA33',
      popper: '#827717',
    },
    grouped: true,
    milestoneGroups: [
      {
        name: 'Antrag Bund gestellt',
        milestones: [],
      },
      {
        name: 'Antrag Land gestellt',
        milestones: [],
      },
      {
        name: 'Vorläufige Bewilligung Bund',
        milestones: [],
      },
      {
        name: 'Vorläufige Bewilligung Land',
        milestones: [],
      },
      {
        name: 'Endgültige Bewilligung Bund',
        milestones: [],
      },
      {
        name: 'Endgültige Bewilligung Land',
        milestones: [],
      },
    ],
    communityCount: 0,
  },
  {
    id: 4,
    disabled: true,
    colors: {
      bg: '#388E3C',
      bottom: '#1B5E20',
      progressWrapper: '#E8F5E9',
      progressBar: '#00C853',
      popper: '#1B5E20',
    },
    milestoneGroups: [
      {
        name: 'Bekanntmachung',
        milestones: [],
      },
      {
        name: 'Teilnahmewettbewerb',
        milestones: [],
      },
      {
        name: 'Abgabe Erstangebot',
        milestones: [],
      },
      {
        name: 'Verhandlungsverfahren',
        milestones: [],
      },
      {
        name: 'Abgabe finales Angebot',
        milestones: [],
      },
      {
        name: 'Zuschlagserteilung NB',
        milestones: [],
      },
    ],
    communityCount: 0,
  },
  {
    id: 5,
    disabled: true,
    colors: {
      bg: '#00796B',
      bottom: '#004D40',
      progressWrapper: '#E0F2F1',
      progressBar: '#00BFA5',
      popper: '#004D40',
    },
    milestoneGroups: [
      {
        name: 'Bekanntmachung',
        milestones: [],
      },
      {
        name: 'Abgabe Erstangebot',
        milestones: [],
      },
      {
        name: 'Verhandlungsverfahren',
        milestones: [],
      },
      {
        name: 'Abgabe finales Angebot',
        milestones: [],
      },
      {
        name: 'Zuschlagserteilung',
        milestones: [],
      },
    ],
    communityCount: 0,
  },
  {
    id: 6,
    colors: {
      bg: '#0097A7',
      bottom: '#006064',
      progressWrapper: '#E0F7FA',
      progressBar: '#00B8D4',
      popper: '#006064',
    },
    milestoneGroups: [
      {
        name: 'Auftaktgespräch GÜ',
        milestones: [],
      },
      {
        name: 'Erstgespräch Kommune',
        milestones: [],
      },
      {
        name: 'Abrufe',
        milestones: [],
      },
      {
        name: 'Beginn Planungsphase',
        milestones: [],
      },
      {
        name: 'Beginn Bauphase',
        milestones: [],
      },
      {
        name: 'Ende Planungsphase',
        milestones: [],
      },
      {
        name: 'Ende Bauphase',
        milestones: [],
      },
    ],
    communityCount: 0,
  },
];
