<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2019_156142)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 1C1.72386 1 1.5 1.22386 1.5 1.5V14.5C1.5 14.7761 1.72386 15 2 15H15C15.2761 15 15.5 14.7761 15.5 14.5V1.5C15.5 1.22386 15.2761 1 15 1H2ZM0.5 1.5C0.5 0.671573 1.17157 0 2 0H15C15.8284 0 16.5 0.671573 16.5 1.5V14.5C16.5 15.3284 15.8284 16 15 16H2C1.17157 16 0.5 15.3284 0.5 14.5V1.5ZM5.64645 6.14645C5.84171 5.95118 6.15829 5.95118 6.35355 6.14645L10 9.79289L13.6464 6.14645C13.8417 5.95118 14.1583 5.95118 14.3536 6.14645C14.5488 6.34171 14.5488 6.65829 14.3536 6.85355L10.3536 10.8536C10.1583 11.0488 9.84171 11.0488 9.64645 10.8536L6 7.20711L3.35355 9.85355C3.15829 10.0488 2.84171 10.0488 2.64645 9.85355C2.45118 9.65829 2.45118 9.34171 2.64645 9.14645L5.64645 6.14645Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_2019_156142">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
