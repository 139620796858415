import { cloneDeep } from 'lodash';
import layers from '@/configs/layers';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import sidebar from '@/configs/sidebar';
import dayjs from 'dayjs';

const state = {
  richtlinie: 2022,
  phase: [2, 3],
  showSlider: false,
  layerConfigs: cloneDeep(layers),
  navigationConfig: cloneDeep(sidebar),
  switchModel: {},
  FlyTo: {
    zoom: 12,
    lng: 10.4515,
    lat: 51.1656,
  },
  filters: {},
  additionalFilters: {},
  // holds main results that are not changing over time
  mainResult: null,
  layerID: null,
  propertyDataIsLoading: false,
  hasResized: false,
  satelliteIsActiv: false,
  containerHeight: 500,
  showDataPanel: false,
  mapActionsDialogIsOpen: false,
  availableTenderYears: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
