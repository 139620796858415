<template>
  <div>
    <!-- ADMIN-PANEL-ICON -->
    <svg
      v-show="name === 'adminPanel'"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
    >
      <g transform="translate(1 1)">
        <line class="admin-panel_a" y2="3" transform="translate(11)" />
        <line
          class="admin-panel_a"
          x2="2.121"
          y2="2.121"
          transform="translate(3.222 3.222)"
        />
        <line class="admin-panel_a" x2="3" transform="translate(0 11)" />
        <line
          class="admin-panel_a"
          x1="3.586"
          y2="3.586"
          transform="translate(12.414 6)"
        />
        <line class="admin-panel_a" x1="3" transform="translate(19 11)" />
        <circle
          class="admin-panel_a"
          cx="2"
          cy="2"
          r="2"
          transform="translate(9 9)"
        />
        <path
          class="admin-panel_b"
          d="M3.515,21a11,11,0,1,1,16.97,0Z"
          transform="translate(-1 -3)"
        />
      </g>
    </svg>
    <!-- /ADMIN-PANEL-ICON -->

    <!-- MAP-ICON -->
    <svg
      v-show="name === 'map'"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path class="map_a" d="M19,5h4V23H1V5H5" />
      <path class="map_a" d="M5,18l3-3,5,4,6-6" />
      <path
        class="map_a"
        d="M16,5c0,2.469-4,6-4,6S8,7.469,8,5a4,4,0,0,1,8,0Z"
      />
      <circle class="map_b" cx="1" cy="1" r="1" transform="translate(11 4)" />
    </svg>
    <!-- /MAP-ICON -->

    <!-- ARCHIVE-ICON -->
    <svg
      v-show="name === 'archive'"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g transform="translate(-1 -1)">
        <path
          class="archive_a"
          d="M6,2H18l4,4V20a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6Z"
        />
        <line class="archive_b" y1="9" transform="translate(12 9)" />
        <path class="archive_a" d="M8,14l4,4,4-4" />
        <line class="archive_b" x2="20" transform="translate(2 6)" />
      </g>
    </svg>
    <!-- /ARCHIVE-ICON -->

    <!-- SUPPORT-ICON -->
    <svg
      v-show="name === 'support'"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        class="support_a"
        cx="11"
        cy="11"
        r="11"
        transform="translate(1 1)"
      />
      <circle
        class="support_b"
        cx="1"
        cy="1"
        r="1"
        transform="translate(11 17)"
      />
      <path
        class="support_a"
        d="M9.853,6.56c1.98-.875,4.542-.771,5.417.645s.271,3.063-1.229,4.334S12,13.5,12,14.5"
      />
    </svg>
    <!-- /SUPPORT-ICON -->
  </div>
</template>

<script>
export default {
  name: "PrimaryNavIcon",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
/* ADMIN-PANEL-ICON */
.admin-panel_a,
.admin-panel_b {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.admin-panel_b {
  stroke-linecap: square;
}

/* MAP-ICON */
.map_a {
  fill: none;
  stroke: currentColor;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.map_b {
  fill: currentColor;
}

/* ARCHIVE_ICON */
.archive_a,
.archive_b {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.archive_a {
  stroke-linecap: square;
}

/* SUPPORT-ICON */
.support_a {
  fill: none;
  stroke: currentColor;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.support_b {
  fill: currentColor;
}
</style>
