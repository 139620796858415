<template>
  <div class="dashboard-cards-wrapper">
    <div v-if="cardsMeta[0].active" class="min-h-[44px] pointer-events-none">
      <Transition name="fade" mode="out-in">
        <DashboardCardSingle
          v-if="!currentCard.active"
          :data="currentCard"
          :is-active="true" />
      </Transition>
    </div>
    <div
      class="grid grid-cols-6 gap-2.5"
      :class="{
        'hub-card-active': hubCardActive,
      }">
      <div
        v-for="card in cards"
        :key="card.id"
        :style="currentCard.id !== card.id ? '' : 'display: none'">
        <DashboardCardSingle
          v-if="currentCard.id !== card.id"
          :data="card"
          :is-disabled="
            Array.isArray(card.disabled)
              ? !$can(...card.disabled)
              : card.disabled
          "
          @click="!card.disabled && onCardClick(card.id)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const cards = computed(() => store.getters['dashboard/getAggregatedCardsData']);
const cardsMeta = computed(() => store.getters['dashboard/getDashboardCards']);
const hubCardActive = computed(
  () => store.getters['dashboard/getHubCardActive']
);

const onCardClick = (id) => {
  store.dispatch('dashboard/UPDATE_CARD_STATUS', id);
};

const currentCard = computed(() =>
  cardsMeta.value.find((card) => card.id === router.currentRoute.value.meta.id)
);
</script>

<style lang="scss">
.dashboard-cards-wrapper {
  max-height: 2000px;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  @apply flex flex-col gap-2.5;

  &.hub-card-active {
    max-height: 154px;
  }
}
</style>
