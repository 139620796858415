<template>
  <div
    class="flex w-full justify-center subtitle-2 items-center gap-2 h-fit"
    :class="customClasses">
    <span
      >© {{ new Date().getFullYear() }} LBD-Beratungsgesellschaft mbH
      Berlin</span
    >
    <span>|</span>
    <div class="flex gap-2 items-center">
      <a href="https://www.lbd.de/datenschutz/" target="_blank">Datenschutz</a>
      <a href="https://www.lbd.de/impressum/" target="_blank">Impressum</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    customMargin: {
      type: String,
      default: 'my-5',
    },
    customTextColor: {
      type: String,
      default: 'text-infra-gray-highlight',
    },
  },
  computed: {
    customClasses() {
      return `${this.customMargin} ${this.customTextColor}`;
    },
  },
};
</script>
