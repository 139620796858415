<template>
  <div class="ma-auto w-96 py-8">
    <v-card class="text-center rounded-[8px] p-5">
      <v-img :src="logoURL" contain max-height="110" class="my-8"></v-img>

      <!-- sign in form -->
      <v-card-text class="login-form pb-0">
        <div v-if="loginError" class="mb-3">
          <span class="text-red">
            Das Passwort oder die E-Mail-Adresse ist nicht korrekt. Bitte geben
            Sie Ihre Zugangsdaten erneut ein. Falls Sie Ihr Passwort vergessen
            haben, können Sie es zurücksetzen.
          </span>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex flex-col align-start">
            <span class="text-black text-[13px]">E-Mail-Adresse</span>
            <v-text-field
              v-model="username"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              name="username"
              density="compact"
              bg-color="white"
              variant="outlined"
              hide-details
              class="text-black w-full"
              @keyup.enter="submit"
              @change="resetErrors"></v-text-field>
          </div>

          <div class="flex flex-col align-start">
            <span class="text-black text-[13px]">Passwort</span>
            <v-text-field
              v-model="password"
              :append-inner-icon="
                showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'
              "
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              :error="error"
              :error-messages="errorMessages"
              name="password"
              density="compact"
              bg-color="white"
              variant="outlined"
              hide-details
              class="w-full"
              @change="resetErrors"
              @keyup.enter="submit"
              @click:append-inner="showPassword = !showPassword">
            </v-text-field>
            <div class="text-xs ml-auto w-fit mt-1">
              <router-link
                to="/forgot-password"
                class="text-black opacity-50 hover:opacity-100">
                {{ $t('login.forgot') }}
              </router-link>
            </div>
          </div>

          <div class="mx-auto mb-8 mt-1">
            <button-el
              :disabled="isSignInDisabled"
              data-cy="loginButton"
              :text="$t('login.button')"
              @click="submit" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Sign In Page Component
|---------------------------------------------------------------------
|
| Sign in template for user authentication into the application
|
*/

import { axiosInstance } from '@/utils/axiosHelper';

export default {
  data() {
    return {
      isSignInDisabled: false,
      isFormValid: true,
      username: '',
      password: '',
      error: false,
      errorMessages: '',
      loginError: false,
      showPassword: false,
      rules: {
        required: (value) => (value && Boolean(value)) || 'Pflichtfeld',
      },
    };
  },
  computed: {
    logoURL() {
      return new URL('@/assets/img/logo_two_lines.svg', import.meta.url).href;
    },
  },
  methods: {
    submit() {
      this.isSignInDisabled = true;
      this.signIn(this.username.toLowerCase(), this.password);
      this.isSignInDisabled = false;
    },
    signIn(username, password) {
      this.$store
        .dispatch('auth/LOGIN', { username, password })
        .then(() => {
          const redirectUrl = this.$route.query.from || '/';
          this.$router.push(redirectUrl);
          this.error = false;
          this.loginError = false;
        })
        .catch(() => {
          this.error = true;
          this.loginError = true;
        });
    },
    resetErrors() {
      this.error = false;
      this.errorMessages = '';
    },
  },
};
</script>

<style lang="scss">
.login-form,
.forgot-password-page {
  .v-field__input > input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset !important;
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .v-field__input:-webkit-autofill:hover {
    box-shadow: 0 0 0 50px white inset !important;
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .v-field.v-field--active.v-field--appended:hover {
    .v-field__overlay {
      opacity: 0.18 !important;
    }
  }

  .v-field__overlay {
    opacity: 0.13 !important;
  }

  .v-field__append-inner {
    padding-left: 10px !important;
  }
}
</style>
