<template>
  <div>
    <TheLabel :label="label" :for-input="id" />
    <textarea
      :id="id"
      v-model="model"
      :placeholder="placeholder"
      :class="`
      ${backgroundColor} placeholder:text-neutral placeholder:text-base
      text-black w-full border border-blue-grey-200 rounded-[6px] p-[10px]
      outline-none
    `"></textarea>
  </div>
</template>

<script setup>
import TheLabel from '../label/TheLabel.vue';

defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: null,
  },
  backgroundColor: {
    type: String,
    default: 'bg-white',
  },
});
const model = defineModel({ type: String, default: '' });
</script>
