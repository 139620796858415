<template>
  <h6 class="year-nodge subtitle-4">
    {{ year.map((item) => item).join(' / ') }}
  </h6>
</template>

<script>
export default {
  name: 'YearNodge',
  props: {
    year: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.year-nodge {
  @apply text-blue-grey-500 bg-blue-grey-25 rounded-[3px] p-[10px] pb-[9px];
}
</style>
