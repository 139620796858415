<script>
export default {
  methods: {
    resizeUnits() {
      this.$nextTick(() => {
        const clickedPanel = document.querySelector(
          '.v-expansion-panel--active'
        );
        if (!clickedPanel) return;
        const unitFields = clickedPanel.querySelectorAll('.unitField');
        const unitFieldWidths = [];
        unitFields.forEach((unitField) => {
          unitFieldWidths.push(unitField.offsetWidth);
        });
        const maxUnitFieldWidth = Math.max(...unitFieldWidths);

        if (maxUnitFieldWidth > 5) {
          unitFields.forEach((unitField) => {
            unitField.style = `min-width: ${
              maxUnitFieldWidth + 6
            }px; margin-left: 4px`;
          });
        } else {
          unitFields.forEach((unitField) => {
            unitField.style = `min-width: ${maxUnitFieldWidth}px`;
          });
        }
      });
    },
  },
};
</script>
