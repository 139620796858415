import center from '@turf/center';
import { points } from '@turf/helpers';

export default {
  profil: (state) => state.user,
  gemeinden: (state) => state.gemeinden,
  gemeindenCenter: (state) => {
    const FC = points(state.gemeinden.map((g) => g.centroid));
    return center(FC);
  },
};
