<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2019_156133)">
      <path
        d="M15.7222 0L15.58 0.0266666L10.8333 1.86667L5.49996 0L0.486626 1.68889C0.299959 1.75111 0.166626 1.91111 0.166626 2.11556V15.5556C0.166626 15.8044 0.362182 16 0.61107 16L0.753293 15.9733L5.49996 14.1333L10.8333 16L15.8466 14.3111C16.0333 14.2489 16.1666 14.0889 16.1666 13.8844V0.444444C16.1666 0.195556 15.9711 0 15.7222 0ZM6.38885 2.19556L9.9444 3.44V13.8044L6.38885 12.56V2.19556ZM1.9444 3.07556L4.61107 2.17778V12.5778L1.9444 13.6089V3.07556ZM14.3888 12.9244L11.7222 13.8222V3.43111L14.3888 2.4V12.9244Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_2019_156133">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.166626)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
