<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    :items-per-page="10"
    class="tailwind pr-6 pl-6"
    hide-default-footer
  >
    <template
      v-for="header in headers.filter((e) => e.decimal === 0)"
      #[`item.${header.key}`]="{ item }"
    >
      {{ formatNumber(item.raw[header.key], header) }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatNumber(value, header) {
      if (typeof value === 'undefined') return '--';
      return Math.abs(value).toLocaleString('de-DE', {
        maximumFractionDigits: header.decimal,
      });
    },
  },
};
</script>
