<template>
  <v-chart :option="option" class="chartItem" autoresize />
</template>

<script>
export default {
  name: 'BarChartStacked',
  props: {
    chartData: {},
    chartTitle: {
      type: String,
      default: '',
    },
    multiply: {
      type: Number,
      default: null,
    },
    customMinHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    option() {
      const data = this.chartData;

      const transformedData = {};

      Object.keys(data).forEach((category) => {
        data[category].forEach((item) => {
          if (!transformedData[item.name]) {
            transformedData[item.name] = [];
          }
          transformedData[item.name].push(item);
        });
      });

      // Sort the series keys alphabetically
      const sortedSeriesKeys = Object.keys(transformedData).sort((a, b) =>
        a.localeCompare(b)
      );

      const categories = Object.keys(this.chartData);
      return {
        title: {
          text: this.chartTitle,
          textStyle: {
            color: 'black',
            textAlign: 'center',
            lineHeight: 24,
            fontFamily: 'Avenir Next, sans-serif',
            fontWeight: 600,
            fontSize: '1rem',
          },
          left: 'center',
          top: '25px',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: (params) => {
            let result = '';
            params.forEach((item) => {
              // filter 0 values
              if (item.value === 0) {
                return;
              }
              result += `${item.marker} ${
                item.seriesName
              }: ${item.value.toLocaleString('de-DE')}<br/>`;
            });
            return result;
          },
        },
        legend: {
          // show only items in the legend that are visible in the chart
          data: Object.keys(transformedData)
            .filter((key) => {
              return transformedData[key].some((item) => item.value > 0);
            })
            .sort((a, b) => a.localeCompare(b)),
          bottom: 20,
          itemWidth: 12,
          itemHeight: 12,
        },
        grid: { bottom: '15%', top: '15%', containLabel: true },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            formatter: (value) => {
              return value.toLocaleString('de-DE');
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: categories,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },

        series: sortedSeriesKeys.map((key) => {
          return {
            name:
              transformedData[key].length > 0
                ? transformedData[key][0].name
                : '',
            type: 'bar',
            stack: 'stack',
            data: transformedData[key].map((item) => item.value),
            itemStyle: {
              color:
                transformedData[key].length > 0
                  ? transformedData[key][0].color
                  : '',
            },
            barWidth: 35,
          };
        }),
      };
    },
  },
};
</script>
