import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import { saveAs } from 'file-saver';

import {
  LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
  LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
} from '@/configs/layers/viewingLevel';
import dayjs from 'dayjs';

const viewingKeys = [
  LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
  LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
];

export default {
  PATCH_BUILDING_PROPERTY: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: payload.url,
        method: 'PATCH',
        data: payload.data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('UPDATE_VERSION_DATA', resp.data);
          commit('SET_PROPERTY_DATA_IS_LOADING', false);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_FEATURE: ({ commit, state }, payload) =>
    new Promise((resolve, reject) => {
      commit('SET_LAYER_ID', payload.layerID);

      const params = {};
      if (viewingKeys.includes(payload.layerID)) {
        params.richtlinie = state.richtlinie;
        params.phase = state.phase;
      }

      axios({
        url: payload.url,
        method: 'GET',
        params,
      })
        .then((resp) => {
          commit('SET_FEATURE', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DOWNLOAD_MEV_FILE: ({ commit, state }, ags) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/mev/MEVResult/download/?gemeinde=${ags}`,
        method: 'GET',
        responseType: 'blob',
      })
        .then((resp) => {
          saveAs(new Blob([resp.data]), `${ags}.zip`);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  GET_DOWNLOADABLE_GEMEINDEN: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/mev/MEVResult/gemeinden/',
        method: 'GET',
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  GET_TENDER_CLUSTERS: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/tenders/Cluster`,
        method: 'GET',
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  GET_AVAILABLE_TENDER_YEARS: ({ state }, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload.canView) {
        state.maxAgeTenders = dayjs().get('year');
        resolve();
        return;
      }
      axios({
        url: '/api/tenders/oldest_date_tenders',
        method: 'GET',
      })
        .then((resp) => {
          const oldest_year = resp.data.oldest_date
            ? dayjs(resp.data.oldest_date).get('year')
            : dayjs().get('year');
          state.availableTenderYears = Array(6)
            .fill(0)
            .map((_, i) => oldest_year + i);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
