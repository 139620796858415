<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1272_51454)">
      <path
        d="M15.7333 0.79999C15.6 0.666657 15.3333 0.666656 15.1333 0.666656L11.3333 1.79999V15.2L15.5333 14C15.8 13.9333 16 13.6667 16 13.3333V1.33332C16 1.13332 15.9333 0.933323 15.7333 0.79999Z"
        :fill="fill" />
      <path d="M10 1.79999L6 0.79999V14.2L10 15.2V1.79999Z" :fill="fill" />
      <path
        d="M4.66667 0.79999L0.466667 1.99999C0.2 2.13332 0 2.39999 0 2.66666V14.6667C0 14.8667 0.0666667 15.0667 0.266667 15.2C0.4 15.2667 0.533333 15.3333 0.666667 15.3333C0.733333 15.3333 0.8 15.3333 0.866667 15.3333L4.66667 14.2V0.79999Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_1272_51454">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
