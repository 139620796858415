<template>
  <template v-for="item in itemsData" :key="item.value">
    <div
      class="flex items-center hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
      :class="{
        'mr-1': showGap,
        'p-[8px] min-h-[32px] body-2': small,
        'p-[10px] min-h-[40px] body-1': !small,
      }"
      role="menuitem"
      @click="selectItem(item)">
      <div :class="'flex-grow-1 ' + levelAdjustments[level].fontClass">
        {{
          levelAdjustments[level].prefix
            ? levelAdjustments[level].prefix + item.name
            : item.name
        }}
      </div>
      <div>
        <input
          :value="item.checked"
          :checked="item.checked"
          type="checkbox"
          :name="item.value" />
      </div>
    </div>
    <multi-drop-down-grouped-list
      v-if="hasMoreSubItems(item)"
      :small="small"
      :show-gap="showGap"
      :items-data="item.items"
      :level-adjustments="levelAdjustments"
      :level="level + 1"
      @select="checkAllChildrenActive(item)" />
  </template>
</template>

<script>
export default {
  name: 'MultiDropDownGroupedList',
  props: {
    itemsData: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showGap: {
      type: Boolean,
      default: true,
    },
    levelAdjustments: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  emits: ['select'],
  methods: {
    hasMoreSubItems(item) {
      return (
        'items' in item && Array.isArray(item.items) && item.items.length > 0
      );
    },
    selectItem(item, newVal = null) {
      newVal = newVal === null ? !item.checked : newVal;
      item.checked = newVal;
      let root = item;
      if (this.hasMoreSubItems(root)) {
        for (let subItem of root.items) {
          this.selectItem(subItem, newVal);
        }
      }
      this.$emit('select');
    },
    checkAllChildrenActive(item) {
      let allActive = true;
      for (let subItem of item.items) {
        if (subItem.checked === false) {
          allActive = false;
        }
      }
      item.checked = allActive;
      this.$emit('select');
    },
  },
};
</script>
