<template>
  <v-file-input
    v-model="imageFile"
    show-size
    accept="image/png, image/jpeg, image/bmp"
    placeholder="Bild auswählen"
    prepand-icon="mdi-camera"
    variant="outlined"
    density="compact"
    @change="$emit('emitImage', imageFile[0])"
  />
</template>

<script>
export default {
  emits: ['emitImage'],
  data: () => ({
    imageFile: [],
  }),
};
</script>
