<template>
  <v-list-item
    :disabled="
      menuItem.modelNameForCASL && !$can('view', menuItem.modelNameForCASL)
    "
    active-class="primary--text"
    class="p-3 rounded-0 switchery-modal"
    @click="showModal()">
    <v-list-item-title class="flex justify-between whitespace-normal">
      <div class="w-fit">
        {{ menuItem.title }}
      </div>
      <div class="w-4 flex flex-wrap items-center">
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1268_137542)">
            <path
              d="M4.75954 8C4.61646 8 4.48805 7.91217 4.43617 7.77883L3.25556 4.74444L0.221167 3.56383C0.0878247 3.51195 0 3.38354 0 3.24046C0 3.09582 0.0897205 2.96635 0.225149 2.91557L7.22753 0.289674C7.52899 0.176628 7.82337 0.471012 7.71032 0.772469L5.08443 7.77485C5.03364 7.91028 4.90418 8 4.75954 8ZM4.72222 6.73333L6.85556 1.14444L1.27778 3.27778L3.76667 4.23333L4.72222 6.73333Z"
              fill="#001257" />
          </g>
          <defs>
            <clipPath id="clip0_1268_137542">
              <rect width="8" height="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['show-modal'],
  methods: {
    showModal() {
      this.$store.commit('SET_FULLSCREEN_MODAL', {
        visible: true,
        ...this.menuItem,
      });
    },
  },
};
</script>
