<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5544_101588)">
      <path
        d="M8.0026 3.33301V1.33301L5.33594 3.99967L8.0026 6.66634V4.66634C10.2093 4.66634 12.0026 6.45967 12.0026 8.66634C12.0026 10.6463 10.5559 12.2863 8.66927 12.6063V13.953C11.3026 13.6263 13.3359 11.3863 13.3359 8.66634C13.3359 5.71967 10.9493 3.33301 8.0026 3.33301Z"
        :fill="fill" />
      <path
        d="M4.0013 8.66591C4.0013 7.56591 4.44797 6.56591 5.17464 5.83924L4.22797 4.89258C3.26797 5.85924 2.66797 7.19258 2.66797 8.66591C2.66797 11.3859 4.7013 13.6259 7.33464 13.9526V12.6059C5.44797 12.2859 4.0013 10.6459 4.0013 8.66591Z"
        :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
