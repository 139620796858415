<template>
  <div class="absolute top-5 right-5">
    <control-option-icon
      :icon="legendForceClosed ? 'bullet-list' : 'bullet-list-blue'"
      class="ml-auto absolute z-10 right-0"
      :class="showLegend ? 'shadow-none' : 'hover:bg-infra-background'"
      @click="forceClose" />
    <legend-map v-if="showLegend" v-bind="$attrs" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LegendControls',
  data() {
    return {
      legendForceClosed: false,
      showLegend: false,
    };
  },
  computed: {
    ...mapGetters('map', ['getLegendEntries']),
  },
  watch: {
    legendForceClosed() {
      this.handleShowLegend();
    },
    getLegendEntries() {
      this.handleShowLegend();
    },
  },
  methods: {
    handleShowLegend() {
      if (this.legendForceClosed || this.getLegendEntries.length === 0) {
        this.showLegend = false;
      } else if (this.getLegendEntries.length > 0) {
        this.showLegend = true;
      }
    },
    forceClose() {
      this.legendForceClosed = !this.legendForceClosed;
    },
  },
};
</script>
