import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

// GLOBAL COMPONENTS
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import VueCookies from 'vue-cookies';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import { abilitiesPlugin } from '@casl/vue';
import { axiosInstance } from '@/utils/axiosHelper';
import personalAvatar from '@/components/global/personalAvatar.vue';

import globalComponents from '@/plugins/globalComponents';

// PLUGINS
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';
import ECharts from './plugins/echarts';
import defineAbility from './plugins/ability';

// STYLE
import '@/assets/scss/new_theme.module.scss';
import 'material-icons/iconfont/material-icons.css';
import 'material-symbols';

let pendingAxiosCalls = 0;

// Interceptor scripts for loading bar
axiosInstance.interceptors.request.use((config) => {
  pendingAxiosCalls += 1;
  store.commit('SET_LOADING', true);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    pendingAxiosCalls -= 1;
    if (pendingAxiosCalls === 0) store.commit('SET_LOADING', false);
    if (response.data.status === 'success' && response.data.message) {
      store.commit('layout/SHOWTOAST', {
        color: 'success',
        message: response.data.message,
        timeout: 3000,
        multiLine: true,
        outlined: true,
      });
    }
    if (response.data.status === 'fail' && response.data.message) {
      store.commit('layout/SHOWTOAST', {
        color: 'warning',
        message: response.data.message,
        timeout: 3000,
        multiLine: true,
        outlined: true,
      });
    }
    return response;
  },
  (error) => {
    pendingAxiosCalls -= 1;
    if (pendingAxiosCalls === 0) store.commit('SET_LOADING', false);
    if (!error.response) {
      store.commit('layout/SHOWTOAST', {
        color: 'error',
        message:
          'Der Server kann die Anfrage aktuell nicht beantworten. Bitte versuchen Sie es später erneut.',
        timeout: 0,
        multiLine: true,
        outlined: true,
      });
    } else {
      if (error.response.status === 504) {
        store.commit('layout/SHOWTOAST', {
          color: 'error',
          message:
            'Die Operation ist zu aufwändig, tut uns Leid. Für umfassende Analysen kontaktieren Sie uns bitte.',
          timeout: 0,
          multiLine: true,
          outlined: true,
        });
      }
      if (error.response.status === 400) {
        store.commit('layout/SHOWTOAST', {
          color: 'error',
          message:
            'Die Daten konnten nicht verarbeitet werden. Bitte überprüfen Sie' +
            'ihre Eingaben oder kontaktieren Sie den support',
          timeout: 0,
          multiLine: true,
          outlined: true,
        });
      }
      if (error.response.data.message) {
        store.commit('layout/SHOWTOAST', {
          color: 'error',
          message: error.response.data.message,
          timeout: 3000,
          multiLine: true,
          outlined: true,
        });
      }
    }
    return Promise.reject(error);
  }
);

const app = createApp(App);

app.component('PersonalAvatar', personalAvatar);
app.component('VChart', ECharts);

if (!window.location.hostname.split('.').includes('localhost')) {
  const dsn =
    'https://46d68cf21e2d37c7ab7e748aa6797ba6@o1327264.ingest.us.sentry.io/4506071612784640';
  let tracePropagationTargets = ['localhost', /^\//];
  const environment = window.location.hostname.includes('stage')
    ? 'stage'
    : 'production';
  if (environment === 'prod') {
    tracePropagationTargets.push(/^https:\/\/api\.oew\.lbd\.network/);
  } else if (environment === 'stage') {
    tracePropagationTargets.push(/^https:\/\/api\.oew-stage\.lbd\.network/);
  }

  Sentry.init({
    app,
    environment,
    dsn,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: environment === 'stage' ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app
  .use(globalComponents)
  .use(i18n)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(PerfectScrollbar)
  .use(abilitiesPlugin, defineAbility, {
    useGlobalProperties: true,
  })
  .use(VueCookies, { expires: '7d' });

router.isReady().then(() => app.mount('#app'));
