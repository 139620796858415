<template>
  <div v-if="!showDetails" class="panel-content-row-wrapper-container">
    <div class="panel-content-row-wrapper">
      <div class="flex flex-col gap-2 justify-start">
        <div class="first-col-wrapper">Pachterlöse</div>
        <div class="p-3 bg-blue-grey-25 rounded flex items-center gap-2">
          <v-icon class="text-blue-grey-500">mdi-information</v-icon>
          <div class="caption-1 text-blue-grey-900">
            Pachtverträge sind von den jeweiligen Gemeinden abhängig und werden
            automatisiert zugeordnet
          </div>
        </div>
      </div>
      <div class="other-cols-wrapper">
        <div class="panel-content-item">Betrachtungszeitraum</div>
        <div class="panel-content-item">Anschlussquote</div>
        <div class="panel-content-item">Privatkunden</div>
        <div class="panel-content-item">Gewerbekunden</div>
      </div>
      <div class="other-cols-wrapper">
        <InputEl
          :small="true"
          input-type="integer"
          :placeholder="10"
          :rules="{ required: true }"
          custom-background="bg-infra-highlight-25"
          suffix="Jahre" />
        <button-el
          size="m"
          icon="arrow-down"
          :primary="false"
          text="Details prüfen"
          @click="showDetails = true" />
        <InputEl
          :small="true"
          :placeholder="15"
          :rules="{ required: true }"
          input-type="integer"
          custom-background="bg-infra-highlight-25"
          suffix="€ / Monat" />
        <InputEl
          :small="true"
          :placeholder="80"
          :rules="{ required: true }"
          input-type="integer"
          custom-background="bg-infra-highlight-25"
          suffix="€ / Monat" />
      </div>
    </div>
  </div>
  <details-pop-up
    v-else
    rate-name="Anschlussquote"
    @toggle-pop-up="showDetails = $event" />
</template>
<script>
export default {
  data() {
    return {
      showDetails: false,
    };
  },
};
</script>
