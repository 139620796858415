import { draw } from '@/features/draw/draw';

export function endTicketProcess(data) {
  draw.deleteAll();
  data.$store.commit('ticket/SET_GEMEINDE_ACTIVE', null);
  data.$store.commit('ticket/SET_GEMARKUNG_ACTIVE', null);
  data.$store.commit('draw/RESET_GEOMETRY_SELECTION');
  data.$store.commit('ticket/SET_DRAW_FEATURES', []);
  data.$store.commit('ticket/SET_TICKET_PROCESS', null);
  data.$store.commit('ticket/SET_TICKET_STEP', 1);
  data.$store.commit('ticket/SET_VALIDATION_STEP', 1);
  data.$store.commit('ticket/SET_SHOW_VALIDATION_TICKET', false);
  data.$store.commit('ticket/SET_DRAW_SELECTED_IDS', []);
  data.$store.commit('map/SET_SHOW_DATA_PANEL', false);
  draw.changeMode('simple_select');
}

export function endValidationTicket(data) {
  draw.deleteAll();
  data.$store.commit('draw/RESET_GEOMETRY_SELECTION');
  data.$store.commit('ticket/SET_DRAW_FEATURES', []);
  data.$store.commit('ticket/SET_SHOW_VALIDATION_TICKET', false);
  data.$store.commit('ticket/SET_DRAW_SELECTED_IDS', []);
  draw.changeMode('simple_select');
}

export function step2submitData(action, data, successMessage, execute) {
  execute.$store
    .dispatch(action, data)
    .then(() => {
      execute.$store.commit('layout/SHOWTOAST', {
        color: '#0692BC',
        message: successMessage,
      });
      setTimeout(() => {
        endTicketProcess(execute);
      }, 1000);
    })
    .catch(() => {
      execute.$store.commit('layout/SHOWTOAST', {
        color: '#D50000',
        message: 'Ticket konnte nicht gespeichert werden!',
      });
    });
}
