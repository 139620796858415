<template>
  <DashboardContentLayout>
    <h4 class="text-infra-highlight-700 mb-[20px]">Filterbare Detailansicht</h4>
    <DashboardFilter />
    <div class="h-full pt-5 px-1">
      <div class="flex flex-col standard-elevation-1 overflow-hidden rounded">
        <div class="grid grid-cols-4 overflow-hidden border-b-2">
          <div
            class="bg-white text-infra-highlight-800 flex flex-col items-center p-6">
            <img
              width="28"
              src="/static/images/mapIcons/map.png"
              alt="Bauabschnitte Icon" />
            <h5 class="mt-2">Aktive Bauabschnitte</h5>
            <h3 class="mt-6">
              {{ amountActiveSections }}
            </h3>
          </div>
          <div
            class="bg-white text-infra-highlight-800 flex flex-col items-center p-6">
            <img
              width="28"
              src="/static/images/mapIcons/road.png"
              alt="Trassen Icon" />
            <h5 class="mt-2">Trassenbau</h5>
            <h3 class="mt-6">
              {{ `${totalRouteProgress}%` }}
              <v-tooltip
                text="Leistungsfortschritt im Trassenbau aktiver Bauabschnitte (im Bau befindlich, vgl. Status)"
                location="top">
                <template #activator="{ props }">
                  <v-icon
                    class="mb-3"
                    v-bind="props"
                    color="#607d8b"
                    size="15"
                    icon="mdi-information-outline" />
                </template>
              </v-tooltip>
            </h3>
          </div>
          <div
            class="bg-white text-infra-highlight-800 flex flex-col items-center p-6">
            <img
              width="28"
              src="/static/images/mapIcons/compass.png"
              alt="Technik Icon" />
            <h5 class="mt-2">Technik Standorte</h5>
            <h3 class="mt-6">
              {{ `${totalTechnologyProgress}%` }}
              <v-tooltip
                text="Leistungsfortschritt im Bau von Technikstandorten (NvT und PoP) aktiver Bauabschnitte (im Bau befindlich, vgl. Status)"
                location="top">
                <template #activator="{ props }">
                  <v-icon
                    class="mb-3"
                    v-bind="props"
                    color="#607d8b"
                    size="15"
                    icon="mdi-information-outline" />
                </template>
              </v-tooltip>
            </h3>
          </div>
          <div
            class="bg-white text-infra-highlight-800 flex flex-col items-center p-6">
            <img
              width="28"
              src="/static/images/mapIcons/house_connection.png"
              alt="Hausanschlüsse Icon" />
            <h5 class="mt-2">Hausanschlüsse</h5>
            <h3 class="mt-6">
              {{ `${totalConnectionProgress}%` }}
              <v-tooltip
                text="Leistungsfortschritt in der Errichtung von Hausanschlüssen aktiver Bauabschnitte (im Bau befindlich, vgl. Status)"
                location="top">
                <template #activator="{ props }">
                  <v-icon
                    class="mb-3"
                    v-bind="props"
                    color="#607d8b"
                    size="15"
                    icon="mdi-information-outline" />
                </template>
              </v-tooltip>
            </h3>
          </div>
        </div>
        <vuetify-data-table
          :headers="headers"
          :item-data="filteredItemData"
          :open-page-options-to-top="true"
          item-value="key"
          :expand-on-row-click="true"
          :show-total="true">
          <template #status="{ item }">
            <div class="flex items-center justify-center">
              <img
                class="opacity-80"
                :src="checkmarkStatusMap[`${item.status}`]"
                alt="progress-icon" />
            </div>
          </template>
          <!--          <template #functions>-->
          <!--            <div class="flex gap-4 justify-center">-->
          <!--              <img-->
          <!--                class="opacity-40 hover:opacity-70"-->
          <!--                :src="'@/assets/svg/map-add.svg'"-->
          <!--                alt="map-add" />-->
          <!--            </div>-->
          <!--          </template>-->
          <template #expanded-row="{ item }">
            <div class="flex p-[16px] grid grid-cols-5 items-center">
              <!-- bauphase-->
              <div class="progress-date-item w-100">
                <div class="body-3 whitespace-nowrap">Projektphase (vsl.)</div>
                <div class="subtitle-2 whitespace-nowrap">
                  {{
                    new Date(
                      item.construction_phase_date_start
                    ).toLocaleDateString('de-DE', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })
                  }}
                  -
                  {{
                    new Date(
                      item.construction_phase_date_end
                    ).toLocaleDateString('de-DE', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })
                  }}
                </div>
              </div>

              <!-- progress items-->
              <div
                v-for="progress in progressItems.filter((e) => e.key)"
                :key="progress.key"
                class="flex flex-col gap-[15px] p-[16px]">
                <v-tooltip
                  :text="
                    Math.round(item.progress[progress.key].progress * 100) + '%'
                  "
                  activator="parent"
                  location="bottom" />
                <div class="body-3 text-center">{{ progress.title }}</div>
                <div
                  class="h-[11px] bg-white rounded-[2px] border border-blue-grey-50">
                  <div
                    :style="{
                      width: item.progress[progress.key].progress * 100 + '%',
                    }"
                    class="h-full bg-infra-highlight-500 rounded-[2px]"></div>
                </div>
              </div>

              <!-- übergabe netzbetreiber-->
              <div class="progress-date-item w-100">
                <div class="body-3 whitespace-nowrap">Übergabedatum:</div>
                <div class="subtitle-2">
                  {{ /*item.handover_date*/ 'Noch nicht verfügbar' }}
                </div>
              </div>
            </div>
          </template>
        </vuetify-data-table>
      </div>
    </div>
  </DashboardContentLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PlanningGeneralCoPage',
  data() {
    return {
      progressItems: [
        {
          icon: 'mdi-road-variant',
          title: 'Trassenbau',
          key: 'routes',
          selected: false,
        },
        {
          icon: 'mdi-math-compass',
          title: 'Technik Standorte',
          key: 'tech',
          selected: false,
        },
        {
          icon: 'mdi-home-city',
          title: 'Hausanschlüsse',
          key: 'connections',
          selected: false,
        },
      ],
      checkmarkStatusMap: {
        0: new URL('@/assets/svg/check-grey-empty.svg', import.meta.url),
        1: new URL('@/assets/svg/progress-icon.svg', import.meta.url),
        2: new URL('@/assets/svg/check-green.svg', import.meta.url),
      },
      headers: [
        {
          title: 'Cluster',
          key: 'cluster_name',
          width: '175px',
        },
        {
          title: 'Los',
          key: 'lot_name',
        },
        {
          title: 'Gemeinde',
          key: 'gemeinde_name',
        },
        {
          title: 'Bauabschnitt',
          key: 'section_name',
        },
        {
          title: 'GÜ',
          key: 'name_company',
        },
        {
          title: 'Status',
          key: 'status',
          align: 'center',
          sortable: true,
          width: '75px',
        },
        // {
        //   title: '',
        //   key: 'functions',
        //   sortable: false,
        //   align: 'center',
        //   width: '75px',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('dashboard', [
      'getPlanningConstructionData',
      'getSelectedSections',
    ]),
    ...mapGetters('ticket', ['getGemeindenActive']),
    // eslint-disable-next-line vue/return-in-computed-property
    filteredItemData() {
      const sectionSet = new Set(this.getSelectedSections.map((s) => s.value));
      const gemeindeSet = new Set(this.getGemeindenActive.map((g) => g.ags));
      return this.getPlanningConstructionData.filter(
        (item) =>
          sectionSet.has(item.section_id) && gemeindeSet.has(item.gemeinde_id)
      );
    },
    totalRouteProgress() {
      return (
        100 * this.getAccumulatedProgress('routes', 'total_length')
      ).toLocaleString('de-DE', {
        maximumFractionDigits: 0,
      });
    },

    totalTechnologyProgress() {
      const nvtProgress = this.getWeightedProgress('nvt', 'total_count');
      const popProgress = this.getWeightedProgress('pop', 'total_count');
      const nvtAmount = this.getTotalWeight('nvt', 'total_count');
      const popAmount = this.getTotalWeight('pop', 'total_count');
      if (nvtAmount + popAmount === 0) return 0;
      return (
        (100 * (nvtProgress + popProgress)) /
        (nvtAmount + popAmount)
      ).toLocaleString('de-DE', {
        maximumFractionDigits: 0,
      });
    },

    totalConnectionProgress() {
      return (
        100 * this.getAccumulatedProgress('connections', 'total_count')
      ).toLocaleString('de-DE', {
        maximumFractionDigits: 0,
      });
    },

    amountActiveSections() {
      return this.filteredItemData.filter((e) => e.status > 0).length;
    },
  },
  methods: {
    getWeightedProgress(category, weightingKeyName) {
      return this.filteredItemData.reduce(
        (acc, cur) =>
          acc +
          (cur.progress[category][weightingKeyName]
            ? cur.progress[category].progress *
              cur.progress[category][weightingKeyName]
            : 0),
        0
      );
    },
    getTotalWeight(category, weightingKeyName) {
      return this.filteredItemData.reduce(
        (acc, cur) =>
          acc +
          (cur.progress[category][weightingKeyName]
            ? cur.progress[category][weightingKeyName]
            : 0),
        0
      );
    },
    getAccumulatedProgress(category, weightingKeyName) {
      const progress = this.getWeightedProgress(category, weightingKeyName);
      const total = this.getTotalWeight(category, weightingKeyName);
      return total ? progress / total : 0;
    },
  },
};
</script>

<style lang="scss">
@import '@/components/storybook/src/assets/styles/index.scss';

.progress-date-item {
  @extend .standard-elevation-0;
  @apply rounded-[8px] overflow-hidden p-3 flex flex-col gap-3 w-fit h-fit text-blue-grey-700 items-center;
}
</style>
