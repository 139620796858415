<template>
  <div
    class="flex flex-col standard-elevation-1 rounded-[4px] overflow-hidden h-full">
    <!-- filter-->
    <div class="border-b border-blue-grey-50 flex justify-start items-center">
      <div class="flex flex-col p-[10px] w-1/2">
        <InputEl
          v-model="filterText"
          size="m"
          label="Filter"
          :placeholder="inputPlaceholder" />
      </div>
    </div>

    <!-- table-->
    <table>
      <thead class="bg-blue-grey-50">
        <tr class="text-left">
          <th v-for="header in headers" :key="header">
            <h5 v-if="header !== 'Actions'" class="text-blue-grey-600 p-3">
              {{ header }}
            </h5>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in paginatedFilteredData"
          :key="item.id"
          class="even:bg-blue-grey-25 odd:bg-white">
          <td v-for="(header, colIndex) in headers" :key="colIndex">
            <div
              v-if="header === 'Actions'"
              class="body-1 p-3 flex justify-center gap-2 items-center">
              <div class="cursor-pointer">
                <slot name="actions" :item="item"></slot>
              </div>

              <!-- expand-->
              <!--              <div @click="toggleRowExpansion(item.id)" class="cursor-pointer">-->
              <!--                <e-minus class="cursor-pointer" />-->
              <!--              </div>-->
            </div>
            <div v-else class="body-1 p-3">
              {{ item[header] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- select options-->
    <div
      class="flex justify-end w-full p-[10px] border-t border-blue-grey-50 mt-auto">
      <div class="max-h-[40px] flex gap-[10px]">
        <div class="flex items-center gap-[10px]">
          <select
            v-model="itemsPerPage"
            class="bg-infra-highlight-25 outline-0 cursor-pointer h-full p-[10px] body-2 text-blue-grey-900 rounded-[6px] border border-blue-grey-100"
            @change="handleItemsPerPageChange">
            <option
              v-for="option in itemsPerPageOptions"
              :key="option"
              :value="option">
              Zeige: {{ option }}
            </option>
          </select>
          <span v-if="data.length > 0" class="body-2">
            {{ startItem }} - {{ endItem }} von {{ data.length }}
          </span>
        </div>
        <div class="flex gap-[8px]">
          <div
            class="rounded-[6px] cursor-pointer bg-infra-highlight-25 h-[40px] w-[40px] flex justify-center items-center"
            @click="navigate('prev')">
            <arrow-left />
          </div>
          <div
            class="rounded-[6px] cursor-pointer bg-infra-highlight-25 h-[40px] w-[40px] flex justify-center items-center"
            @click="navigate('next')">
            <arrow-right />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeft from '../../assets/icons/arrow-left.vue';
import ArrowRight from '../../assets/icons/arrow-right.vue';
import InputEl from '../input/InputEl.vue';

export default {
  name: 'TheTable',
  components: { InputEl, ArrowRight, ArrowLeft },
  props: {
    columnWidths: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 15],
    },
    inputPlaceholder: {
      type: String,
      default: 'Suche',
    },
  },
  data() {
    return {
      itemsPerPage: 5, // Number of items per page
      currentPage: 1, // Current page
      expandedRows: [],
      filterText: '',
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedFilteredData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const filteredData = this.filteredData;

      return filteredData.slice(startIndex, endIndex);
    },
    startItem() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    endItem() {
      const endIndex = this.currentPage * this.itemsPerPage;
      return Math.min(endIndex, this.filteredData.length);
    },
    filteredData() {
      if (!this.filterText) {
        return this.data;
      }

      const filterTerm = this.filterText.toLowerCase().trim();
      return this.data.filter((item) => {
        for (const header of this.headers) {
          if (
            header !== 'Actions' &&
            String(item[header]).toLowerCase().includes(filterTerm)
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  watch: {
    filterText() {
      this.currentPage = 1;
    },
  },
  methods: {
    toggleRowExpansion(rowId) {
      const index = this.expandedRows.indexOf(rowId);
      if (index !== -1) {
        // Row is already expanded, so collapse it
        this.expandedRows.splice(index, 1);
      } else {
        // Row is not expanded, so expand it
        this.expandedRows.push(rowId);
      }
    },
    isRowExpanded(rowId) {
      return this.expandedRows.includes(rowId);
    },
    navigate(direction) {
      if (direction === 'prev') {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      } else if (direction === 'next') {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // Reset to the first page when items per page changes
    },
  },
};
</script>

<style>
/* Pagination styles */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.pagination button {
  margin: 0 4px;
  padding: 8px 12px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}

.pagination select {
  padding: 8px;
  border: none;
  background-color: #ddd;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
</style>
