<template>
  <div>
    <TheLabel :label="label" />
    <div
      ref="dropdownRef"
      class="dropdown-multiple w-full relative"
      :class="{ 'pointer-events-none': internalItemsData.length === 0 }">
      <div
        class="flex justify-between text-neutral items-center rounded-[6px] cursor-pointer border"
        :class="{
          'rounded-b-none border-2 border-b-0 border-line-active-neutral py-3':
            dropdownOpen,
          'border border-line-neutral h-10': !dropdownOpen && !small,
          'h-[30px]': !dropdownOpen && small,
          'bg-active-area': !customBackground,
          'bg-subtle': dropdownOpen,
          '!py-2': dropdownOpen && small,
          [customBackground]: customBackground,
        }"
        @click="toggleDropdown">
        <div
          v-if="items.length !== 0"
          ref="selectedItems"
          class="whitespace-nowrap overflow-hidden text-neutral w-full pr-20 pl-3"
          :class="{
            'body-1': !small,
            'body-2': small,
          }">
          <span v-if="!dropdownOpen" @click.stop="toggleDropdown">
            {{ previewText ? previewText : placeholder }}
          </span>
          <InputEl
            v-if="dropdownOpen"
            :size="small ? 'm' : 'l'"
            suffix="ZoomIcon"
            :suffix-icon="true"
            :model-value="filter"
            @click.stop=""
            @update:model-value="filter = $event" />
        </div>

        <div
          :class="{
            'bg-active-area': !customBackground,
            'bg-subtle': dropdownOpen,
            [customBackground]: customBackground,
          }"
          class="flex gap-2 items-center multi-dropdown-actions rounded-r-[6px]">
          <c-remove
            v-if="somethingSelected"
            class="w-5 h-5"
            @click.stop="clearSelection()" />
          <IconWrapper
            icon="arrow_drop_down"
            :size="24"
            :class="[{ 'rotate-180': dropdownOpen }, 'duration-300']"
            type="round" />
        </div>
      </div>
      <div
        v-if="dropdownOpen"
        class="dropdown-animation w-full rounded-b-[6px] absolute z-10 left-0 bg-default py-1 dropdown-animation border-2 border-t-0 border-line-active-neutral pr-1"
        @wheel.stop>
        <span v-if="noDataFound" :class="{ 'p-2': small, 'p-3': !small }"
          >Keine Einträge gefunden</span
        >
        <div
          v-else
          class="overflow-y-auto"
          :style="{
            'max-height': `${maxHeightDropdown}px`,
          }">
          <div class="mr-1">
            <div v-for="item in internalItemsData" :key="item[valueKey]">
              <drop-down-multiple-grouped-list
                ref="dropDownList"
                :small="small"
                :items-data="internalItemsData"
                :level-adjustments="levelAdjustments"
                :level="0"
                :expandable="'expanded' in item"
                :expanded="'expanded' in item && item.expanded"
                value-key="value"
                data-key="name"
                @select="$emit('selectedItems', internalItemsData, false)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CRemove from '../../assets/icons/c-remove.vue';
import TheLabel from '../label/TheLabel.vue';
import DropDownMultipleGroupedList from './DropDownMultipleGroupedList.vue';

export default {
  components: { DropDownMultipleGroupedList, TheLabel, CRemove },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    itemTypeAll: {
      type: String,
      default: 'Bauabschnitte',
    },
    label: {
      type: String,
      default: null,
    },
    customBackground: {
      type: String,
      default: null,
    },
    maxHeightDropdown: {
      type: Number,
      default: 200,
    },
    placeholder: {
      type: String,
      default: 'Bauabschnitte auswählen',
    },
    dataKey: {
      type: String,
      default: null,
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    levelAdjustments: {
      type: Array,
      default: () => [
        { indent: 0, fontClass: '' },
        { indent: 20, fontClass: '' },
        { indent: 30, fontClass: 'font-light' },
      ],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    maxPreviewItems: {
      type: Number,
      default: 2,
    },
  },
  emits: ['selected-items', 'update:modelValue', 'selectedValue'],
  data() {
    return {
      dropdownOpen: false,
      filter: '',
      internalItemsData: [],
      noDataFound: false,
    };
  },
  computed: {
    previewText() {
      const previewNames = this.getPreviewNames(this.internalItemsData);
      if (typeof previewNames === 'string') {
        return previewNames;
      }
      if (previewNames.length > this.maxPreviewItems) {
        return `${previewNames.slice(0, this.maxPreviewItems).join(', ')} und ${
          previewNames.length - this.maxPreviewItems
        } weitere`;
      } else {
        return `${previewNames.slice(0, this.maxPreviewItems).join(', ')}`;
      }
    },
    selectedItemValues: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    somethingSelected() {
      const lookForSelectedSubItem = (item) => {
        if (item.checked) {
          return true;
        }
        if (!this.hasMoreSubItems(item) && !item.checked) {
          return false;
        }
        return item.items.reduce((prev, cur) => {
          return (
            prev && this.hasMoreSubItems(cur) && lookForSelectedSubItem(cur)
          );
        });
      };
      for (let item of this.internalItemsData) {
        if (
          item.checked ||
          (this.hasMoreSubItems(item) && lookForSelectedSubItem(item))
        )
          return true;
      }
      return false;
    },
  },
  watch: {
    items: {
      handler(val) {
        let internalItemsData = JSON.parse(JSON.stringify(val));
        this.internalItemsData = internalItemsData;
        this.filterItemsData(this.filter);
        this.$emit('selectedItems', internalItemsData, true);
      },
      immediate: true,
      deep: true,
    },
    filter: {
      handler(val) {
        this.filterItemsData(val);
      },
    },
    modelValue: {
      handler(newVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(this.selectedItemValues)
        ) {
          this.selectedItemValues = [...newVal];
        }
      },
      immediate: true,
    },
  },
  mounted() {
    let internalItemsData = JSON.parse(JSON.stringify(this.items));
    this.internalItemsData = internalItemsData;
    this.$emit('selectedItems', internalItemsData, true);
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    filterItemsData(filter) {
      const { internalItemsData, dataKey } = this;

      const filterFormatted = (filter || '').trim().toLowerCase();

      const setSubItemsVisible = (item) => {
        if (this.hasMoreSubItems(item)) {
          for (let subItem of item.items) {
            subItem.hidden = false;
            setSubItemsVisible(subItem);
          }
        }
      };
      const applyFilter = (item, itemIndex, parentItems, parentTreeList) => {
        const itemValue = item[dataKey].trim().toLowerCase();
        if (itemValue.includes(filterFormatted)) {
          parentItems[itemIndex].hidden = false;
          setSubItemsVisible(item);

          for (let item of parentTreeList) {
            if ('expanded' in item) {
              item.expanded = true;
            }
          }

          return true;
        } else {
          if (this.hasMoreSubItems(item)) {
            const childrenRemain = item.items.reduce(
              (prev, cur, curI) =>
                prev |
                applyFilter(cur, curI, item.items, parentTreeList.concat(item)),
              false,
            );
            if (!childrenRemain) {
              parentItems[itemIndex].hidden = true;
              return false;
            } else {
              parentItems[itemIndex].hidden = false;
              return true;
            }
          } else {
            parentItems[itemIndex].hidden = true;
            return false;
          }
        }
      };

      const anythingFound = internalItemsData.reduce(
        (prev, cur, curIndex) =>
          prev |
          applyFilter(
            internalItemsData[curIndex],
            curIndex,
            internalItemsData,
            [cur],
          ),
        false,
      );

      this.noDataFound = !anythingFound;
    },
    getPreviewNames(items, topLevel = true) {
      let previewNames = [];
      let allChecked = true;
      for (let item of items) {
        if (item.checked) {
          previewNames.push(item.name);
        } else {
          allChecked = false;
        }
      }
      if (allChecked && topLevel) {
        return `Alle ${this.itemTypeAll} ausgewählt`;
      }
      for (let item of items) {
        if (!item.checked && this.hasMoreSubItems(item)) {
          previewNames = previewNames.concat(
            this.getPreviewNames(item.items, false),
          );
        }
      }
      return previewNames;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    hasMoreSubItems(item) {
      return (
        'items' in item && Array.isArray(item.items) && item.items.length > 0
      );
    },
    handleOutsideClick(event) {
      if (!this.$refs.dropdownRef.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    clearSelection() {
      const uncheckItemsAndSubItems = (item) => {
        item.checked = false;
        if (this.hasMoreSubItems(item)) {
          for (let subItem of item.items) {
            uncheckItemsAndSubItems(subItem);
          }
        }
      };
      for (let item of this.internalItemsData) {
        uncheckItemsAndSubItems(item);
      }
      this.$emit('selectedItems', this.internalItemsData, true);
    },
  },
};
</script>

<style lang="scss">
/* Hide the browser's default checkbox */

/* Create a custom checkbox */
.checkmark {
  min-height: 16px;
  min-width: 16px;
  position: relative;
  @apply border-2 border-line-active-neutral bg-default rounded-sm;
}

/* When the checkbox is checked, add a blue background */
.dropdown-multiple input:checked ~ .checkmark {
  @apply bg-button-primary-default-color1 border-none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dropdown-multiple input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.dropdown-multiple .checkmark:after {
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 12px;
  @apply border border-default;
  border-width: 0 2px 2px 0;
}

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.multi-dropdown-actions {
  position: absolute;
  right: 2px;
  height: calc(100% - 2px);
  padding-right: 6px;
  padding-left: 6px;
}
</style>
