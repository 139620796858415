import MapboxDraw from '@mapbox/mapbox-gl-draw';
import FreehandMode from 'mapbox-gl-draw-freehand-mode';
import drawLayerStyle from '@/features/draw/drawLayerStyle';
import { getZoom } from '../../mapbox/main';
import { extend } from 'lodash';
import simplify from '@turf/simplify';

const NewSimpleSelect = extend(MapboxDraw.modes.simple_select, {
  dragMove() {},
});
const NewDirectSelect = extend(MapboxDraw.modes.direct_select, {
  dragFeature() {},
});

FreehandMode.simplify = function (polygon) {
  simplify(polygon, {
    mutate: true,
    tolerance: 1 / Math.pow(2, getZoom()),
    highQuality: true,
  });
};

export const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    line_string: true,
    point: true,
    trash: true,
  },
  styles: drawLayerStyle,
  modes: Object.assign(MapboxDraw.modes, {
    draw_polygon: FreehandMode,
    simple_select: NewSimpleSelect,
    direct_select: NewDirectSelect,
  }),
});

export function addDraw(map) {
  map.addControl(draw, 'top-right');
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon'
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash'
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line'
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point'
  ).style.display = 'none';
}

export function deleteFeatures(data) {
  draw.deleteAll();
  data.$store.commit('ticket/SET_DRAW_FEATURES', draw.getAll().features);
}

export function addMainGeometry(type) {
  let typeCleaned;
  if (type === 'linestring') {
    typeCleaned = 'line';
  } else {
    typeCleaned = type;
  }
  document
    .querySelector(
      `.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_${typeCleaned}`
    )
    .click();
}
