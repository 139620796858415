<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3491_7748)">
      <path
        d="M8.61601 2.67676L1.64601 9.64576C1.58478 9.70738 1.54066 9.7839 1.51801 9.86776L0.0180066 15.3678C-0.0052043 15.4528 -0.00551516 15.5425 0.0171055 15.6277C0.0397262 15.7129 0.0844741 15.7906 0.146813 15.853C0.209152 15.9153 0.286864 15.96 0.372073 15.9827C0.457282 16.0053 0.546957 16.005 0.632007 15.9818L6.13201 14.4818C6.21587 14.4591 6.29238 14.415 6.35401 14.3538L13.323 7.38376L8.61601 2.67676Z"
        :fill="fill" />
      <path
        d="M15.561 3.02518L12.975 0.43918C12.6937 0.157973 12.3122 0 11.9145 0C11.5168 0 11.1353 0.157973 10.854 0.43918L9.677 1.61618L14.384 6.32318L15.561 5.14618C15.8422 4.86489 16.0002 4.48343 16.0002 4.08568C16.0002 3.68793 15.8422 3.30647 15.561 3.02518Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_3491_7748">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
