<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1455_73463)">
      <path
        d="M15.8334 2.96132L8.16669 0.661316V4.5H9.16669V2.00535L14.8334 3.70535V7.87971C15.2065 8.13679 15.543 8.44323 15.8334 8.78961V2.96132Z"
        :fill="fill" />
      <path
        d="M15.8334 15.2104C15.6444 15.4358 15.4358 15.6443 15.2104 15.8333H15.8334V15.2104Z"
        :fill="fill" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.78959 15.8333C8.44321 15.5429 8.13677 15.2065 7.8797 14.8333H3.83335V6.5H9.50002V7.6689C9.81344 7.4876 10.1484 7.33934 10.5 7.22889V5.5H2.83335V8.16666H0.166687V15.8333H8.78959ZM2.83335 9.16667V14.8333H1.16669V9.16667H2.83335Z"
        :fill="fill" />
      <path
        d="M13.1667 7.13685C12.8444 7.0598 12.5099 7.01397 12.1667 7.00273V5.5H13.1667V7.13685Z"
        :fill="fill" />
      <path
        d="M7.3468 10.1667H5.50002V11.1667H7.06913C7.1273 10.8199 7.22116 10.4853 7.3468 10.1667Z"
        :fill="fill" />
      <path
        d="M7.00273 12.1667H5.50002V13.1667H7.13684C7.05979 12.8443 7.01397 12.5099 7.00273 12.1667Z"
        :fill="fill" />
      <path
        d="M7.83335 8.16666H5.50002V9.16667H7.83335V8.16666Z"
        :fill="fill" />
      <path
        d="M12 8C9.8 8 8 9.8 8 12C8 14.2 9.8 16 12 16C14.2 16 16 14.2 16 12C16 9.8 14.2 8 12 8ZM13.8535 11.3535L11.8535 13.3535C11.756 13.451 11.628 13.5 11.5 13.5C11.372 13.5 11.244 13.451 11.1465 13.3535L10.1465 12.3535C9.951 12.158 9.951 11.842 10.1465 11.6465C10.342 11.451 10.658 11.451 10.8535 11.6465L11.5 12.293L13.1465 10.6465C13.342 10.451 13.658 10.451 13.8535 10.6465C14.049 10.842 14.049 11.158 13.8535 11.3535Z"
        fill="#546E7A" />
    </g>
    <defs>
      <clipPath id="clip0_1455_73463">
        <rect width="16" height="16" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
