<template>
  <div>
    <the-label :label="label" />
    <div ref="dropdownContainer" class="relative w-full flex">
      <div
        class="flex justify-between text-infra-blue border-blue-grey-100 items-center bg-white rounded-[6px] w-full cursor-pointer border"
        :class="{
          'rounded-b-none outline-b outline-infra-background border-blue-grey-300':
            showDropdown,
          'h-[32px] p-[8px]': small,
          'h-[40px] p-[10px]': !small,
        }"
        @click="toggleDropdown">
        <div
          class="text-black overflow-hidden whitespace-nowrap"
          :class="{
            'text-opacity-100': showDropdown,
            'text-opacity-60': !showDropdown,
            'body-1': !small,
            'body-2': small,
          }">
          {{ previewText ? previewText : placeholder }}
        </div>
      </div>
      <div
        v-if="showDropdown"
        class="absolute z-10 left-0 w-full bg-white py-1 rounded-b-[6px] dropdown-animation border border-blue-grey-300 border-t-0"
        :class="{ 'pr-1': showGap, 'top-[40px]': !small, 'top-[32px]': small }">
        <div
          class="overflow-y-auto"
          :style="{
            'max-height': `${maxHeightDropdown}px`,
          }"
          @wheel.stop>
          <multi-drop-down-grouped-list
            ref="dropDownList"
            :small="small"
            :show-gap="showGap"
            :items-data="internalItemsData"
            :level-adjustments="levelAdjustments"
            :level="0"
            @select="$emit('selectedItems', internalItemsData, false)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLabel from '../label/TheLabel.vue';
import MultiDropDownGroupedList from './multiDropDownGroupedList.vue';

export default {
  name: 'MultiDropDownGrouped',
  components: { MultiDropDownGroupedList, TheLabel },
  props: {
    itemsData: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Bitte Einträge auswählen',
    },
    levelAdjustments: {
      type: Array,
      default: () => [
        { fontClass: 'font-bold' },
        { fontClass: 'font-medium' },
        { fontClass: 'font-light', prefix: '- ' },
      ],
    },
    small: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    initialSelectAll: {
      type: Boolean,
      default: true,
    },
    maxPreviewItems: {
      type: Number,
      default: 2,
    },
  },
  emits: ['selectedItems'],
  data() {
    return {
      internalItemsData: [],
      showDropdown: false,
      maxHeightDropdown: 150,
      showGap: true,
    };
  },
  computed: {
    previewText() {
      const previewNames = this.getPreviewNames(this.internalItemsData);
      if (previewNames.length > this.maxPreviewItems) {
        return `${previewNames.slice(0, this.maxPreviewItems).join(', ')} und ${
          previewNames.length - this.maxPreviewItems
        } weitere`;
      } else {
        return `${previewNames.slice(0, this.maxPreviewItems).join(', ')}`;
      }
    },
  },
  watch: {
    itemsData: {
      deep: true,
      handler(newItemsData) {
        let internalItemsData = JSON.parse(JSON.stringify(newItemsData));
        for (let item of internalItemsData) {
          this.setCheckedInitialValue(item);
        }
        this.internalItemsData = internalItemsData;
        this.$emit('selectedItems', internalItemsData, true);
      },
    },
  },
  mounted() {
    let internalItemsData = JSON.parse(JSON.stringify(this.itemsData));
    for (let item of internalItemsData) {
      this.setCheckedInitialValue(item);
    }
    this.internalItemsData = internalItemsData;
    this.$emit('selectedItems', internalItemsData, true);
  },
  methods: {
    getPreviewNames(items) {
      let previewNames = [];
      for (let item of items) {
        if (item.checked) {
          previewNames.push(item.name);
        }
      }
      for (let item of items) {
        if (!item.checked && this.hasMoreSubItems(item)) {
          previewNames = previewNames.concat(this.getPreviewNames(item.items));
        }
      }
      return previewNames;
    },
    setCheckedInitialValue(item) {
      item.checked = this.initialSelectAll;
      if (this.hasMoreSubItems(item)) {
        for (let subItem of item.items) {
          subItem.checked = this.initialSelectAll;
          this.setCheckedInitialValue(subItem);
        }
      }
    },
    hasMoreSubItems(item) {
      return (
        'items' in item && Array.isArray(item.items) && item.items.length > 0
      );
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
      this.dropdownListHeight();
    },
    closeDropdown(event) {
      if (!this.$refs.dropdownContainer.contains(event.target)) {
        this.showDropdown = false;
        document.removeEventListener('click', this.closeDropdown);
      }
    },

    dropdownListHeight() {
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.showGap = !(
            this.$refs.dropDownList.offsetHeight < this.maxHeightDropdown
          );
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
