import { LAYER_KEY__BUILDINGS } from '@/configs/layers/buildings';
import {
  LAYER_KEY__DRAFT_NETWORK,
  LAYER_KEY__DRAFT_POP,
} from '@/configs/layers/infrastructureDraft';

export const LAYER_KEY__BUILTT_ROUTE = 'builttroute';
export const LAYER_KEY__BUILTT_NVT = 'builttnvt';
export const LAYER_KEY__BUILTT_POP = 'builttpop';
export const LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT =
  'builtthomeconnectionaddresspoint';
export const LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE =
  'builtthomeconnectionroute';

const legendBuiltt = (items) => {
  return {
    default: {
      name: 'Baufortschritt',
      info: 'Baufortschrittsdaten werden jeden Tag um 0:00 Uhr aktualisiert',
      unit: '',
      colorScale: [
        'rgba(255,0,0,1)',
        'rgba(255,255,0,1)',
        'rgba(144,238,144,1)',
        'rgba(73,144,88,1)',
        'rgba(1,50,32,1)',
      ],
      colorScaleTextLeft: '0%',
      colorScaleTextRight: '100%',
      items,
    },
  };
};

export default {
  [LAYER_KEY__BUILTT_ROUTE]: {
    layoutState: 'default',
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__BUILTT_ROUTE,
      sourceLayer: LAYER_KEY__BUILTT_ROUTE,
      source: LAYER_KEY__BUILTT_ROUTE,
      visibility: 'none',
    },
    style: {
      default: {
        lineColor: [
          'interpolate',
          ['linear'],
          ['get', 'progress'],
          0,
          'rgba(255,0,0,0.6)',
          0.33,
          'rgba(255,255,0,0.6)',
          0.66,
          'rgba(144,238,144,0.6)',
          1,
          'rgba(1,50,32,0.6)',
        ],
        lineWidth: 2,
      },
    },
    legend: legendBuiltt([
      {
        name: 'Trassen',
        color: '#000000',
        icon: 'mdi-minus',
      },
    ]),
  },
  [LAYER_KEY__BUILTT_NVT]: {
    layoutState: 'default',
    layerConfig: {
      type: 'symbol',
      id: LAYER_KEY__BUILTT_NVT,
      sourceLayer: LAYER_KEY__BUILTT_NVT,
      source: LAYER_KEY__BUILTT_NVT,
      visibility: 'none',
      icons: [
        {
          name: 'builttNvtDefault',
          path: '/static/images/mapIcons/netzv-level-v1-1.png',
        },
        {
          name: 'builttSymbolSelectedVerteil',
          path: '/static/images/mapIcons/netzverteiler_active.png',
        },
        {
          name: 'builttNvtNoProgress',
          path: '/static/images/mapIcons/nvt_neg_2.png',
        },
        {
          name: 'builttNvtSmallProgress',
          path: '/static/images/mapIcons/nvt_neg_1.png',
        },
        {
          name: 'builttNvtMediumProgress',
          path: '/static/images/mapIcons/nvt_pos_1.png',
        },
        {
          name: 'builttNvtLargeProgress',
          path: '/static/images/mapIcons/nvt_pos_2.png',
        },
        {
          name: 'builttNvtFinished',
          path: '/static/images/mapIcons/nvt_pos_3.png',
        },
      ],
    },
    style: {
      default: {
        layout: {
          'icon-image': [
            'case',
            ['<=', ['get', 'progress'], 0],
            'builttNvtNoProgress',
            [
              'all',
              ['>', ['get', 'progress'], 0],
              ['<=', ['get', 'progress'], 0.33],
            ],
            'builttNvtSmallProgress',
            [
              'all',
              ['>', ['get', 'progress'], 0.33],
              ['<=', ['get', 'progress'], 0.66],
            ],
            'builttNvtMediumProgress',
            [
              'all',
              ['>', ['get', 'progress'], 0.66],
              ['<', ['get', 'progress'], 1],
            ],
            'builttNvtLargeProgress',
            ['>=', ['get', 'progress'], 1],
            'builttNvtFinished',
            '',
          ],
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 15, 0.6],
          visibility: 'none',
        },
      },
    },
    legend: legendBuiltt([
      {
        name: 'Netzverteiler',
        iconPath: '/static/images/mapIcons/nvtIcon.png',
      },
    ]),
    //   {
    //   default: {
    //     name: 'Baufortschritt NVT-Standorte',
    //     unit: '',
    //     items: [
    //       {
    //         name: '0%',
    //         image: '/static/images/mapIcons/nvt_neg_2.png',
    //       },
    //       {
    //         name: '0% bis 33%',
    //         image: '/static/images/mapIcons/nvt_neg_1.png',
    //       },
    //       {
    //         name: '33% bis 66%',
    //         image: '/static/images/mapIcons/nvt_pos_1.png',
    //       },
    //       {
    //         name: '66% bis 100%',
    //         image: '/static/images/mapIcons/nvt_pos_2.png',
    //       },
    //       {
    //         name: 'Abgeschlossen',
    //         image: '/static/images/mapIcons/nvt_pos_3.png',
    //       },
    //     ],
    //   },
    // },
  },
  [LAYER_KEY__BUILTT_POP]: {
    layoutState: 'default',
    layerConfig: {
      type: 'symbol',
      id: LAYER_KEY__BUILTT_POP,
      sourceLayer: LAYER_KEY__BUILTT_POP,
      source: LAYER_KEY__BUILTT_POP,
      visibility: 'none',
      icons: [
        {
          name: 'builttPopNoProgress',
          path: '/static/images/mapIcons/pop0.png',
        },
        {
          name: 'builttPopSmallProgress',
          path: '/static/images/mapIcons/pop1.png',
        },
        {
          name: 'builttPopMediumProgress',
          path: '/static/images/mapIcons/pop2.png',
        },
        {
          name: 'builttPopLargeProgress',
          path: '/static/images/mapIcons/pop3.png',
        },
      ],
    },
    style: {
      default: {
        layout: {
          'icon-image': [
            'case',
            ['<=', ['get', 'progress'], 0],
            'builttPopNoProgress',
            [
              'all',
              ['>', ['get', 'progress'], 0],
              ['<=', ['get', 'progress'], 0.33],
            ],
            'builttPopSmallProgress',
            [
              'all',
              ['>', ['get', 'progress'], 0.33],
              ['<=', ['get', 'progress'], 0.66],
            ],
            'builttPopMediumProgress',
            [
              'all',
              ['>', ['get', 'progress'], 0.66],
              ['<', ['get', 'progress'], 1],
            ],
            'builttPopLargeProgress',
            ['>=', ['get', 'progress'], 1],
            'builttNvtFinished',
            '',
          ],
          visibility: 'none',
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.2, 15, 0.4],
          'icon-allow-overlap': true,
        },
      },
    },
    legend: legendBuiltt([
      {
        name: 'Point of Presence',
        iconPath: '/static/images/mapIcons/popIcon.png',
      },
    ]),
  },
  [LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE]: {
    layoutState: 'default',
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE,
      sourceLayer: LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE,
      source: LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE,
      visibility: 'none',
    },
    style: {
      default: {
        lineColor: [
          'interpolate',
          ['linear'],
          ['get', 'progress'],
          0,
          'rgba(255,0,0,0.6)',
          0.33,
          'rgba(255,255,0,0.6)',
          0.66,
          'rgba(144,238,144,0.6)',
          1,
          'rgba(1,50,32,0.6)',
        ],
        lineWidth: 2,
      },
    },
  },
  [LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT]: {
    layoutState: 'default',
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT,
      sourceLayer: LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT,
      source: LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT,
      visibility: 'none',
    },
    style: {
      default: {
        circleColor: [
          'interpolate',
          ['linear'],
          ['get', 'progress'],
          0,
          'rgba(255,0,0,0.6)',
          0.33,
          'rgba(255,255,0,0.6)',
          0.66,
          'rgba(144,238,144,0.6)',
          1,
          'rgba(1,50,32,0.6)',
        ],
        circleStrokeWidth: 3,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'marked'], false],
          'rgb(178,7,7)',
          'rgba(255,177,0,0)',
        ],
        circleRadius: {
          stops: [
            [1, 0],
            [8, 1],
            [9, 2],
            [14, 4],
          ],
        },
      },
    },
    legend: legendBuiltt([
      {
        name: 'Hausanschlüsse',
        color: '#000000',
        icon: 'mdi-circle',
      },
    ]),
  },
};
