<template>
  <div class="flex relative items-center rounded-[6px]">
    <input
      ref="inputField"
      v-model="currentInput"
      type="text"
      class="bg-white w-full text-[16px] placeholder:text-opacity-60 placeholder:text-black text-black rounded-[6px] h-[45px] outline-none border border-[#C5CBE0] focus:border-infra-blue px-3"
      :class="{
        'border-red-500 focus:border-red-500':
          !inputIsUnique ||
          inputIsEmpty ||
          (errorText !== null ? errorText.length : null),
      }"
      :placeholder="placeholder"
      @input="inputOps"
      @click="clickOutside"
    />
    <span
      v-if="suffix !== null"
      class="absolute right-1 text-[16px] text-black text-opacity-40 leading-[19.36px] bg-white h-[41px] flex items-center justify-end px-2 pointer-events-none"
      >{{ suffix }}</span
    >
    <span
      v-if="errorText !== null"
      class="absolute bottom-[-18px] text-xs text-red-500 whitespace-nowrap"
    >
      {{ errorText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    redRing: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: null,
    },
    checkUnique: {
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      currentInput: null,
      inputIsUnique: true,
      inputIsEmpty: false,
      errorText: null,
    };
  },

  computed: {
    formattedInputText() {
      return this.currentInput === null
        ? this.currentInput
        : this.currentInput.toLowerCase().trim().replace(/\s+/g, '');
    },

    checkUniqueToLowerCase() {
      return this.checkUnique
        ? this.checkUnique.map(function (item) {
            return item.toLowerCase().trim().replace(/\s+/g, '');
          })
        : null;
    },

    formattedInputNumber() {
      return this.type === 'float' || this.type === 'int'
        ? parseFloat(this.currentInput.replace(/,/g, '.'))
        : null;
    },
  },
  methods: {
    inputCleanUp() {
      if (this.currentInput) {
        if (this.type === 'float') {
          const input = this.currentInput;
          const regex = /^-?\d+(,\d{0,2})?$/;
          if (!regex.test(input)) {
            // Remove any non-matching characters from the input
            this.currentInput = input
              .replace(/[^0-9,]|(,)(,)|^,/g, '$1')
              .replace(/(.*?,\d{2}).*|(^|\D)(\d{4,}).*/g, '$1$3');
          }
        } else if (this.type === 'int') {
          const input = this.currentInput;
          const regex = /^[0-9]+$/;
          if (!regex.test(input)) {
            // Remove any non-matching characters from the input
            this.currentInput = input.replace(/[^\d]/g, '');
          }
        } else if (this.type === 'text') {
          const input = this.currentInput;
          const regex =
            /^(?:(?:[A-Za-z0-9]+-?)|(?:[A-Za-z0-9]*\s[A-Za-z0-9]*))+$/;

          if (!regex.test(input)) {
            this.currentInput = input.replace(/[^A-Za-z0-9\s-]/g, '');
          }
        }
      }
    },

    minMaxValue() {
      if (this.currentInput && this.type !== 'text') {
        const isInRange = this.min
          ? this.max
            ? this.formattedInputNumber >= this.min &&
              this.formattedInputNumber <= this.max
            : this.formattedInputNumber >= this.min
          : this.max
          ? this.formattedInputNumber <= this.max
          : true;

        this.errorText = isInRange
          ? ''
          : this.min
          ? this.max
            ? `Wert muss zwischen ${this.min} und ${this.max} liegen`
            : `Wert muss größer als ${this.min} sein`
          : `Wert muss kleiner als ${this.max} sein`;
        this.$emit('rangeCheck', isInRange);
      }
    },

    inputOps() {
      this.$emit('inputValue', this.currentInput);
      this.isRequired && this.checkInputEmpty(this.formattedInputText);
      this.checkUnique && this.checkInputUnique(this.formattedInputText);
      this.inputCleanUp();
      this.minMaxValue();
    },
    checkInputUnique(input) {
      if (!this.checkUniqueToLowerCase.includes(input)) {
        this.inputIsUnique = true;
        this.$emit('fieldInputUnique', true);
        if (input) {
          this.errorText = null;
        }
      } else {
        this.inputIsUnique = false;
        this.errorText = 'Name bereits vergeben';
        this.$emit('fieldInputUnique', false);
      }
    },
    checkInputEmpty(input) {
      if (!input) {
        this.inputIsEmpty = true;
        this.errorText = 'Pflichtfeld';
      } else {
        this.inputIsEmpty = false;
        this.errorText = null;
      }
    },

    clickOutside() {
      this.$refs.inputField.addEventListener('blur', () => {
        if (this.inputIsEmpty || this.inputIsUnique) {
          this.errorText = null;
          this.inputIsEmpty = false;
          this.minMaxValue();
          this.inputCleanUp();
        }
      });
    },
  },
};
</script>
