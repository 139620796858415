import ECharts from 'vue-echarts';
import { use } from 'echarts/core';

// import ECharts modules manually to reduce bundle size
import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
  TitleComponent,
  DatasetComponent,
  VisualMapComponent,
  GraphicComponent,
} from 'echarts/components';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

use([
  LegendComponent,
  TooltipComponent,
  BarChart,
  LineChart,
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  DatasetComponent,
  VisualMapComponent,
  GraphicComponent,
]);

export default ECharts;
