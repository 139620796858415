import { wktToGeoJSON } from '@terraformer/wkt';
import center from '@turf/center';
import { multiPoint } from '@turf/helpers';
import { setGeoJsonData } from '@/mapbox/main';
import { getTicketsAsFC } from './utils';

const ticketAsFeature = (geom, id, propertyKeyValPairs = {}) => ({
  type: 'Feature',
  geometry: wktToGeoJSON(geom),
  properties: { id, ...propertyKeyValPairs },
  id: id,
});

function ticketPointsAsFeature(points, id) {
  const GeoJsons = points.map((p) => wktToGeoJSON(p.geometry.split(';')[1]));
  return multiPoint(
    GeoJsons.map((g) => g.coordinates),
    { id },
    { id }
  );
}

function addTicket(tickets, ticket) {
  ticket.asFeatures = [];
  let propertyKeyVal = {};
  if ('status' in ticket) {
    propertyKeyVal.status = ticket.status;
  }
  if (ticket.multilinestring) {
    const wktWithoutSrid = ticket.multilinestring.split(';')[1];
    ticket.asFeatures.push(
      ticketAsFeature(wktWithoutSrid, ticket.id, propertyKeyVal)
    );
  }
  if (ticket.multipolygon) {
    const wktWithoutSrid = ticket.multipolygon.split(';')[1];
    ticket.asFeatures.push(
      ticketAsFeature(wktWithoutSrid, ticket.id, propertyKeyVal)
    );
  }
  if (ticket.address_points && ticket.address_points.length > 0) {
    ticket.asFeatures.push(
      ticketPointsAsFeature(ticket.address_points, ticket.id, propertyKeyVal)
    );
  }
  if (ticket.asFeatures.length > 0) {
    ticket.centroid = center(getTicketsAsFC([ticket])).geometry.coordinates;
  }
  tickets.push(ticket);
}

export default {
  SET_TICKETS: (state, payload) => {
    state[payload.keyInStore] = [];
    for (const t of payload.data) {
      addTicket(state[payload.keyInStore], t);
    }
    setGeoJsonData(
      payload.layerSource,
      getTicketsAsFC(state[payload.keyInStore])
    );
    state[`${payload.keyInStore}Loading`] = false;
  },
  ADD_TICKET: (state, payload) => {
    addTicket(state[payload.keyInStore], payload.data);
    setGeoJsonData(
      payload.layerSource,
      getTicketsAsFC(state[payload.keyInStore])
    );
  },
  REMOVE_TICKET: (state, payload) => {
    const id = payload.id;
    const index = state[payload.keyInStore].findIndex((t) => t.id === id);
    state[payload.keyInStore].splice(index, 1);
    setGeoJsonData(
      payload.layerSource,
      getTicketsAsFC(state[payload.keyInStore])
    );
  },
  CLOSE_TICKET: (state, payload) => {
    const id = payload.id;
    const index = state[payload.keyInStore].findIndex((t) => t.id === id);
    state[payload.keyInStore][index].is_closed = true;
    state[payload.keyInStore][index].status = 'Abgeschlossen';
    state[payload.keyInStore][index].asFeatures[0].properties.status =
      'Abgeschlossen';
    setGeoJsonData(
      payload.layerSource,
      getTicketsAsFC(state[payload.keyInStore])
    );
  },
  SET_DRAW_FEATURES: (state, features) => {
    state.drawFeatures = features;
  },
  SET_TICKET_PROCESS: (state, ticketProcess) => {
    state.drawFeatures = [];
    state.ticketProcesses.forEach((e) => {
      e.selected = false;
    });
    if (ticketProcess)
      state.ticketProcesses.find((e) => e.id === ticketProcess).selected = true;
  },
  SET_TICKET_STEP: (state, step) => {
    state.ticketStep = step;
  },
  SET_VALIDATION_STEP: (state, step) => {
    state.validationStep = step;
  },
  SET_GEMEINDE_ACTIVE: (state, payload) => {
    state.gemeindeActive = payload;
  },
  SET_GEMARKUNG_ACTIVE: (state, payload) => {
    state.gemarkungActive = payload;
  },
  SET_SHOW_VALIDATION_TICKET: (state, payload) => {
    state.showValidationTicket = payload;
  },
  SET_ADDRESS_VALIDATION_OPTION: (state, payload) => {
    state.addressValidationData = payload;
  },
  SET_MEV_VALIDATION_OPTION: (state, payload) => {
    state.mevValidationData = payload;
  },
  SET_DRAW_SELECTED_IDS(state, ids) {
    state.drawSelectedIds = ids;
  },
  SET_BUILTT_DATA(state, data) {
    state.builttData = data;
  },
  SET_BUILTT_LOT_DATA(state, data) {
    state.builttLotData.push(data.data);
  },
  SET_MEV_DASHBOARD_DATA(state, data) {
    state.mevDashboardData = data;
  },
  SET_MEV_GEMARKUNGEN_DATA(state, data) {
    state.mevGemarkungenData = data;
  },
  SET_RICHTLINIE(state, data) {
    state.richtlinieSelected = data;
  },
  SET_LANDKREISE(state, data) {
    const landkreisNames =
      data.map((data) => data.landkreis_name || 'Unbekannt') || [];
    state.landkreise = [...new Set(landkreisNames)];

    // set all gemeinden
    state.gemeinden =
      data.map((entry) => {
        return {
          name: entry.gemeinde_name,
          ags: entry.ags,
          landkreis_name: entry.landkreis_name,
          landkreis_id: entry.landkreis_id,
        };
      }) || [];
  },
  SET_TKU(state, data) {
    if (
      JSON.stringify(state.tku) !==
      JSON.stringify(data?.data?.fields?.companies)
    ) {
      state.tku = data?.data?.fields?.companies;
    }
  },
  SET_LANDKREISE_ACTIVE(state, data) {
    state.landkreiseActive = data;
  },
  SET_GEMEINDEN_FILTERED(state, payload) {
    const filteredGemeinden = state.gemeinden.filter((gemeinde) =>
      payload.includes(gemeinde.landkreis_name)
    );

    filteredGemeinden.sort((a, b) => a.name.localeCompare(b.name));

    state.gemeindenFiltered = filteredGemeinden;
  },
  SET_GEMEINDEN_ACTIVE(state, data) {
    state.gemeindenActive = data;
  },
  SET_TKU_ACTIVE(state, data) {
    state.tkuActive = data;
  },
};
