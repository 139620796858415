<template>
  <!-- Neubaugebiet einzeichnen-->
  <step2-development-area
    v-if="getActiveProcess.id === 'developmentArea'"
    :success-toast="successToast" />
  <!-- Mitverlegung einzeichnen-->
  <step2-co-laying
    v-if="getActiveProcess.id === 'coLaying'"
    :success-toast="successToast" />
  <!-- Notiz hinterlegen-->
  <step2-ticket
    v-if="getActiveProcess.id === 'ticket'"
    :success-toast="successToast" />
  <!-- Notiz hinterlegen-->
  <step2-validation v-if="getActiveProcess.id === 'validation'" />
  <!-- MEV Validierung -->
  <step2-mev
    v-if="getActiveProcess.id === 'mev'"
    :success-toast="successToast" />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ticket', ['getActiveProcess']),

    // eslint-disable-next-line vue/return-in-computed-property
    successToast() {
      switch (this.getActiveProcess.id) {
        case 'developmentArea':
          return 'Neubaugebiet erfolgreich erfasst!';
        case 'coLaying':
          return 'Mitverlegungsabsicht erfolgreich erfasst!';
        case 'ticket':
          return 'Ticket erfolgreich erfasst!';
        case 'mev':
          return 'Speicherung der MEV Validierung erfolgreich';
      }
    },
  },
};
</script>
