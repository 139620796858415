<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_5544_32326" fill="white">
      <path
        d="M9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V8H12.6667V12.6667Z" />
    </mask>
    <path
      d="M9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V8H12.6667V12.6667Z"
      :fill="fill" />
    <path
      d="M9.33333 3.33333H8.33333V4.33333H9.33333V3.33333ZM11.7267 3.33333L12.4338 4.04044L14.1409 2.33333H11.7267V3.33333ZM5.17333 9.88667L4.46623 9.17956L3.75912 9.88667L4.46623 10.5938L5.17333 9.88667ZM6.11333 10.8267L5.40623 11.5338L6.11333 12.2409L6.82044 11.5338L6.11333 10.8267ZM12.6667 4.27333H13.6667V1.85912L11.9596 3.56623L12.6667 4.27333ZM12.6667 6.66667H11.6667V7.66667H12.6667V6.66667ZM14 6.66667V7.66667H15V6.66667H14ZM12.6667 12.6667V13.6667H13.6667V12.6667H12.6667ZM3.33333 12.6667H2.33333V13.6667H3.33333V12.6667ZM3.33333 3.33333V2.33333H2.33333V3.33333H3.33333ZM8 3.33333V4.33333H9V3.33333H8ZM8 2H9V1H8V2ZM2 12.6667H1H2ZM14 8H15V7H14V8ZM12.6667 8V7H11.6667V8H12.6667ZM8.33333 2V3.33333H10.3333V2H8.33333ZM9.33333 4.33333H11.7267V2.33333H9.33333V4.33333ZM11.0196 2.62623L4.46623 9.17956L5.88044 10.5938L12.4338 4.04044L11.0196 2.62623ZM4.46623 10.5938L5.40623 11.5338L6.82044 10.1196L5.88044 9.17956L4.46623 10.5938ZM6.82044 11.5338L13.3738 4.98044L11.9596 3.56623L5.40623 10.1196L6.82044 11.5338ZM11.6667 4.27333V6.66667H13.6667V4.27333H11.6667ZM12.6667 7.66667H14V5.66667H12.6667V7.66667ZM15 6.66667V2H13V6.66667H15ZM12.6667 11.6667H3.33333V13.6667H12.6667V11.6667ZM4.33333 12.6667V3.33333H2.33333V12.6667H4.33333ZM3.33333 4.33333H8V2.33333H3.33333V4.33333ZM9 3.33333V2H7V3.33333H9ZM8 1H3.33333V3H8V1ZM3.33333 1C2.03779 1 1 2.05097 1 3.33333H3C3 3.14903 3.14887 3 3.33333 3V1ZM1 3.33333V12.6667H3V3.33333H1ZM1 12.6667C1 13.2855 1.24583 13.879 1.68342 14.3166L3.09763 12.9024C3.03512 12.8399 3 12.7551 3 12.6667H1ZM1.68342 14.3166C2.121 14.7542 2.71449 15 3.33333 15V13C3.24493 13 3.16014 12.9649 3.09763 12.9024L1.68342 14.3166ZM3.33333 15H12.6667V13H3.33333V15ZM12.6667 15C13.2855 15 13.879 14.7542 14.3166 14.3166L12.9024 12.9024C12.8399 12.9649 12.7551 13 12.6667 13V15ZM14.3166 14.3166C14.7542 13.879 15 13.2855 15 12.6667H13C13 12.7551 12.9649 12.8399 12.9024 12.9024L14.3166 14.3166ZM15 12.6667V8H13V12.6667H15ZM14 7H12.6667V9H14V7ZM11.6667 8V12.6667H13.6667V8H11.6667Z"
      :fill="fill"
      mask="url(#path-1-inside-1_5544_32326)" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
