import { LAYER_KEY__BUILDINGS } from '@/configs/layers/buildings';

export const LAYER_KEY__DRAFT_POP = 'draftpop';
export const LAYER_KEY__DRAFT_NVT = 'draftnvt';
export const LAYER_KEY__DRAFT_NETWORK = 'draftnetwork';

const sidebar = (name, subtitles) => ({
  name,
  subtitles,
  chips: [
    {
      title: 'Netz',
      active: true,
      items: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              key: 'no_house_connections',
              unit: 'Stk.',
              value: '0',
              decimal: 0,
            },
            {
              key: 'no_residential_units',
              unit: 'Stk.',
              value: '0',
              decimal: 0,
            },
            {
              key: 'no_commercial_units',
              unit: 'Stk.',
              value: '0',
              decimal: 0,
            },
          ],
        },
        {
          title: 'Fasern & Kabel',
          active: true,
          items: [
            {
              key: 'no_fiber_active',
              unit: 'Stk.',
              value: '0',
              decimal: 0,
            },
            {
              key: 'no_micro',
              unit: 'Stk.',
              value: '0',
              decimal: 0,
            },
            {
              key: 'no_mini',
              unit: 'Stk.',
              value: '0',
              decimal: 0,
            },
            {
              key: 'underground_construction_portion',
              unit: 'm',
              value: '0',
              decimal: 0,
            },
            {
              key: 'length_micro',
              unit: 'm',
              value: '0',
              decimal: 0,
            },
            {
              key: 'length_mini',
              unit: 'm',
              value: '0',
              decimal: 0,
            },
          ],
        },
        {
          title: 'Leerrohr',
          active: false,
          items: [
            {
              key: 'length_pipes_house_connection',
              unit: 'm',
              value: '0',
              decimal: 0,
            },
            {
              key: 'length_pipes_connection_nvt',
              unit: 'm',
              value: '0',
              decimal: 0,
            },
            {
              key: 'length_pipes_nvt_pop',
              unit: 'm',
              value: '0',
              decimal: 0,
            },
          ],
        },
        {
          title: 'Auswertung',
          active: false,
          items: [
            {
              key: 'capex_cable',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'capex_network_distributor',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'capex_point_of_presence',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'capex_underground_construction',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'capex_pipe',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'revenues_residential_units',
              unit: ' €/a',
              value: '0',
              decimal: 0,
            },
            {
              key: 'revenues_commercial_units',
              unit: ' €/a',
              value: '0',
              decimal: 0,
            },
            {
              key: 'invest_yield',
              unit: ' %',
              value: '0',
              decimal: 1,
              multiply: 100,
            },
          ],
        },
        {
          title: 'Finanzierung',
          active: false,
          items: [
            {
              key: 'capex',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'invest_house_connection_euro_per_piece',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'own_capital',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'subsidies',
              unit: 'Tsd. €',
              value: '0',
              decimal: 0,
              multiply: 0.001,
            },
            {
              key: 'subsidies_quota',
              unit: ' %',
              value: '0',
              decimal: 2,
              multiply: 100,
            },
          ],
        },
      ],
    },
  ],
});

export default {
  [LAYER_KEY__DRAFT_POP]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'infrastructure',
      router_name: 'PointOfPresence',
      key_name: 'pk',
      type: 'symbol',
      id: LAYER_KEY__DRAFT_POP,
      sourceLayer: LAYER_KEY__DRAFT_POP,
      source: LAYER_KEY__DRAFT_POP,
      visibility: 'visible',
      visible: true,
      hoverPointer: true,
      icons: [
        {
          name: 'symbolpopActive',
          path: '/static/images/mapIcons/pop_active.png',
        },
        {
          name: 'symbolPop',
          path: '/static/images/mapIcons/pop.png',
        },
      ],
    },
    onClick(click, feature, data) {
      const routeIDs = data.$store.state.map.mainResult.route_ids;
      const routeFilter = ['in', 'pk', ...routeIDs];
      const routeLayers = [LAYER_KEY__DRAFT_NETWORK];
      click.setForeignFeatureState(routeLayers, routeFilter);
      const buildingIDs = data.$store.state.map.mainResult.building_ids;
      const buildingFilter = ['in', 'pk', ...buildingIDs];
      const buildingLayers = [LAYER_KEY__BUILDINGS];
      click.setForeignFeatureState(buildingLayers, buildingFilter);
      click.map.setLayoutProperty(LAYER_KEY__DRAFT_POP, 'icon-image', [
        'match',
        ['get', 'pk'],
        feature.properties.pk,
        'symbolpopActive',
        'symbolPop',
      ]);
      click.map.setLayoutProperty(LAYER_KEY__DRAFT_POP, 'icon-size', [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        ['match', ['get', 'pk'], feature.properties.pk, 0.3, 0.2],
        15,
        ['match', ['get', 'pk'], feature.properties.pk, 0.5, 0.4],
      ]);
      click.setFeatureState([feature], true);
    },
    onDeactivate(map) {
      map.setLayoutProperty(LAYER_KEY__DRAFT_POP, 'icon-image', 'symbolPop');
      map.setLayoutProperty(LAYER_KEY__DRAFT_POP, 'icon-size', [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        0.2,
        15,
        0.4,
      ]);
    },
    sidebar: sidebar('Point of Presence', [{ key: 'pop_id' }]),
    style: {
      default: {
        layout: {
          'icon-image': 'symbolPop',
          visibility: 'none',
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.2, 15, 0.4],
          'icon-allow-overlap': true,
        },
      },
    },
    legend: {
      default: {
        name: 'Grobplanung',
        unit: '',
        items: [
          {
            color: '#0c1b62',
            name: 'Point of Presence (POP)',
            image: '/static/images/mapIcons/pop.png',
          },
        ],
      },
    },
  },
  [LAYER_KEY__DRAFT_NVT]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'infrastructure',
      router_name: 'NetworkDistributor',
      key_name: 'pk',
      type: 'symbol',
      id: LAYER_KEY__DRAFT_NVT,
      sourceLayer: LAYER_KEY__DRAFT_NVT,
      source: LAYER_KEY__DRAFT_NVT,
      visibility: 'visible',
      visible: true,
      hoverPointer: true,
      icons: [
        {
          name: 'nvtDefault',
          path: '/static/images/mapIcons/netzv-level-v1-1.png',
        },
        {
          name: 'symbolSelectedVerteil',
          path: '/static/images/mapIcons/netzverteiler_active.png',
        },
        {
          name: 'nvtNeg2',
          path: '/static/images/mapIcons/nvt_neg_2.png',
        },
        {
          name: 'nvtNeg1',
          path: '/static/images/mapIcons/nvt_neg_1.png',
        },
        {
          name: 'nvtPos1',
          path: '/static/images/mapIcons/nvt_pos_1.png',
        },
        {
          name: 'nvtPos2',
          path: '/static/images/mapIcons/nvt_pos_2.png',
        },
        {
          name: 'nvtPos3',
          path: '/static/images/mapIcons/nvt_pos_3.png',
        },
      ],
    },
    onClick(click, feature, data) {
      const routeIDs = data.$store.state.map.mainResult.route_ids;
      const routeFilter = ['in', 'pk', ...routeIDs];
      const routeLayers = [LAYER_KEY__DRAFT_NETWORK];
      click.setForeignFeatureState(routeLayers, routeFilter);
      const buildingIDs = data.$store.state.map.mainResult.building_ids;
      const buildingFilter = ['in', 'pk', ...buildingIDs];
      const buildingLayers = [LAYER_KEY__BUILDINGS];
      click.setForeignFeatureState(buildingLayers, buildingFilter);
      click.map.setLayoutProperty(LAYER_KEY__DRAFT_NVT, 'icon-image', [
        'match',
        ['get', 'pk'],
        feature.properties.pk,
        'symbolSelectedVerteil',
        [
          'case',
          ['<=', ['get', 'invest_yield'], 0],
          'nvtNeg2',
          [
            'all',
            ['>', ['get', 'invest_yield'], 0],
            ['<=', ['get', 'invest_yield'], 0.04],
          ],
          'nvtNeg1',
          [
            'all',
            ['>', ['get', 'invest_yield'], 0.04],
            ['<=', ['get', 'invest_yield'], 0.06],
          ],
          'nvtPos1',
          ['>', ['get', 'invest_yield'], 0.06],
          'nvtPos3',
          '',
        ],
      ]);
      click.map.setLayoutProperty(LAYER_KEY__DRAFT_NVT, 'icon-size', [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        ['match', ['get', 'pk'], feature.properties.pk, 0.2, 0.3],
        15,
        ['match', ['get', 'pk'], feature.properties.pk, 0.5, 0.6],
      ]);
      click.setFeatureState([feature], true);
    },
    onDeactivate(map) {
      map.setLayoutProperty(LAYER_KEY__DRAFT_NVT, 'icon-image', [
        'case',
        ['<=', ['get', 'invest_yield'], 0],
        'nvtNeg2',
        [
          'all',
          ['>', ['get', 'invest_yield'], 0],
          ['<=', ['get', 'invest_yield'], 0.04],
        ],
        'nvtNeg1',
        [
          'all',
          ['>', ['get', 'invest_yield'], 0.04],
          ['<=', ['get', 'invest_yield'], 0.06],
        ],
        'nvtPos1',
        ['>', ['get', 'invest_yield'], 0.06],
        'nvtPos3',
        '',
      ]);
      map.setLayoutProperty(LAYER_KEY__DRAFT_NVT, 'icon-size', [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        0.3,
        15,
        0.6,
      ]);
    },
    sidebar: sidebar('Netzverteiler', [{ key: 'nvt_id' }]),
    style: {
      default: {
        layout: {
          'icon-image': [
            'case',
            ['<=', ['get', 'invest_yield'], 0],
            'nvtNeg2',
            [
              'all',
              ['>', ['get', 'invest_yield'], 0],
              ['<=', ['get', 'invest_yield'], 0.04],
            ],
            'nvtNeg1',
            [
              'all',
              ['>', ['get', 'invest_yield'], 0.04],
              ['<=', ['get', 'invest_yield'], 0.06],
            ],
            'nvtPos1',
            ['>', ['get', 'invest_yield'], 0.06],
            'nvtPos3',
            '',
          ],
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 15, 0.6],
          visibility: 'none',
        },
      },
    },
    legend: {
      default: {
        name: 'NVT-Standorte',
        unit: 'Rendite in %',
        items: [
          {
            name: 'negativ',
            image: '/static/images/mapIcons/nvt_neg_2.png',
          },
          {
            name: '0 bis 4',
            image: '/static/images/mapIcons/nvt_neg_1.png',
          },
          {
            name: '4 bis 6',
            image: '/static/images/mapIcons/nvt_pos_1.png',
          },
          {
            name: 'über 6',
            image: '/static/images/mapIcons/nvt_pos_3.png',
          },
        ],
      },
    },
  },
  [LAYER_KEY__DRAFT_NETWORK]: {
    layoutState: 'default',
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__DRAFT_NETWORK,
      sourceLayer: LAYER_KEY__DRAFT_NETWORK,
      source: LAYER_KEY__DRAFT_NETWORK,
      visibility: 'none',
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#5c0000',
          ['==', ['get', 'phase'], 3],
          '#50e350',
          ['==', ['get', 'phase'], 4],
          '#013220',
          '#2962FF',
        ],
        lineWidth: 1,
      },
    },
    legend: {
      default: {
        name: 'Grobplanung',
        unit: '',
        items: [
          {
            color: '#50e350',
            name: 'Netzleitung HGF',
            icon: 'mdi-minus',
            size: '14px',
          },
          {
            color: '#013220',
            name: 'Netzleitung DGF',
            icon: 'mdi-minus',
            size: '14px',
          },
        ],
      },
    },
  },
};
