<template>
  <div class="flex justify-center">
    <v-card class="text-center rounded-0 p-4 w-112">
      <v-card-text class="login-form text-left">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <div class="flex justify-start text-xl font-bold mb-8">
            <span class="">Registrierung abschließen</span>
          </div>
          <v-text-field
            v-model="firstName"
            label="Vorname"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="surName"
            label="Nachname"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Neues Passwort"
            :append-inner-icon="
              showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'
            "
            :rules="[
              rules.required,
              rules.length,
              rules.uppercase,
              rules.number,
            ]"
            :type="showPassword ? 'text' : 'password'"
            @click:append-inner="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            label="Neues Passwort bestätigen"
            :append-inner-icon="
              showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'
            "
            :rules="[rules.required, rules.match]"
            :type="showPassword ? 'text' : 'password'"
            @click:append-inner="showPassword = !showPassword"
          ></v-text-field>
          <div v-if="registrationError" class="mb-2">
            <span class="text-red">
              Die Registrierung konnte nicht abgeschlossen werden. Bitte wenden
              Sie sich an unseren Kundenservice.
            </span>
          </div>
          <div class="flex justify-end">
            <v-btn
              color="#0692BC"
              dark
              depressed
              large
              class="mt-4 mb-4 normal-case text-white"
              @click="registerUser()"
              >Registrieren</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { axios } from '@/utils/axiosHelper';

export default {
  name: 'UserRegistration',
  data() {
    return {
      successToast: 'Registrierung erfolgreich! Sie können sich nun anmelden.',
      registrationError: false,
      isFormValid: true,
      password: '',
      passwordConfirm: '',
      firstName: '',
      surName: '',
      showPassword: false,
      rules: {
        required: (value) => (value && Boolean(value)) || 'Pflichtfeld',
        match: () => this.PasswordsMatch || 'Passwörter stimmen nicht überein',
        length: (v) =>
          (v && v.length >= 8) || 'Passwort sollte aus min. 8 Zeichen bestehen',
        uppercase: (v) =>
          /(?=.*[A-Z])/.test(v) || 'Passwort sollte Großbuchstaben enthalten',
        number: (v) => /(?=.*\d)/.test(v) || 'Passwort sollte Zahl enthalten',
      },
    };
  },
  computed: {
    PasswordsMatch() {
      return this.passwordConfirm === this.password;
    },
  },
  methods: {
    registerUser() {
      const formData = {
        firstName: this.firstName,
        surName: this.surName,
        password: this.password,
        token: this.$route.params.token,
      };
      axios({
        url: '/api/create-account/',
        data: formData,
        method: 'POST',
      })
        .then(() => {
          this.$router.push('/login');
          this.$store.commit('layout/SHOWTOAST', {
            color: 'success',
            message: this.successToast,
          });
        })
        .catch(() => {
          this.registrationError = true;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field {
  margin-top: 12px;
}
</style>
