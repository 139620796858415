<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5 8.5C14.5 8.77614 14.2761 9 14 9H3.20711L6.85355 12.6464C7.04882 12.8417 7.04882 13.1583 6.85355 13.3536C6.65829 13.5488 6.34171 13.5488 6.14645 13.3536L1.64645 8.85355C1.45118 8.65829 1.45118 8.34171 1.64645 8.14645L6.14645 3.64645C6.34171 3.45118 6.65829 3.45118 6.85355 3.64645C7.04882 3.84171 7.04882 4.15829 6.85355 4.35355L3.20711 8H14C14.2761 8 14.5 8.22386 14.5 8.5Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
