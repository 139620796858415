import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import ability from '../../../plugins/ability';

export default {
  SET_LOGOUT: (state) => {
    state.user = '';
  },
  SET_PROFILE_DATA: (state, resp) => {
    state.user = resp.user;

    const { can, rules } = new AbilityBuilder(createMongoAbility);

    for (const perm of state.user.permissions) {
      const permCode = perm.split('_');
      can(permCode[0], permCode[1]);
    }
    ability.update(rules);
  },
  SET_PERMITTED_GEMEINDEN: (state, resp) => {
    state.gemeinden = resp;
  },
  SET_PERMITTED_LANDKREISE: (state, payload) => {
    state.permittedLandkreisIds = payload;
  },
};
