<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.07353 11.0001C7.80833 11 7.55402 10.8946 7.36653 10.7071L3.36653 6.70708C3.18437 6.51848 3.08357 6.26588 3.08585 6.00368C3.08813 5.74148 3.1933 5.49067 3.37871 5.30526C3.56412 5.11985 3.81493 5.01469 4.07713 5.01241C4.33932 5.01013 4.59192 5.11092 4.78053 5.29308L8.07353 8.58608L11.3665 5.29308C11.4588 5.19757 11.5691 5.12139 11.6911 5.06898C11.8131 5.01657 11.9443 4.98898 12.0771 4.98783C12.2099 4.98668 12.3416 5.01198 12.4645 5.06226C12.5874 5.11254 12.699 5.18679 12.7929 5.28069C12.8868 5.37458 12.9611 5.48623 13.0113 5.60913C13.0616 5.73202 13.0869 5.8637 13.0858 5.99648C13.0846 6.12926 13.057 6.26048 13.0046 6.38249C12.9522 6.50449 12.876 6.61483 12.7805 6.70708L8.78053 10.7071C8.59303 10.8946 8.33872 11 8.07353 11.0001Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
