<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79425 10.5C7.40935 11.1667 6.4471 11.1667 6.0622 10.5L0.866042 1.5C0.481142 0.833333 0.962267 0 1.73207 0H12.1244C12.8942 0 13.3753 0.833334 12.9904 1.5L7.79425 10.5Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
