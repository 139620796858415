<template>
  <PerfectScrollbar
    class="absolute top-[85px] standard-elevation-2 rounded-[8px]"
    :class="{
      'left-[700px]':
        showLayerPanel || (showSearch && getResults) || getShowDataPanel,
      'left-[360px]': !showLayerPanel && !showSearch && !getShowDataPanel,
      'left-[1060px]':
        (showLayerPanel && getShowDataPanel) ||
        (getResults && getShowDataPanel),
    }"
    :style="{
      maxHeight: `${getContainerHeight + 61}px`,
    }">
    <div
      class="bg-white w-[320px] h-fit py-3 flex flex-col gap-[10px] relative">
      <!-- body-->
      <div class="px-3 w-full flex flex-col gap-3">
        <div class="flex items-center justify-between">
          <h4 class="text-infra-highlight-500">Ticket</h4>
          <!-- abort process modal-->
          <abort-process-modal type="ticket" />
        </div>
        <h6 class="text-infra-highlight-800">
          {{ `Validierung ${getGemarkungActive.name}` }}
        </h6>
        <DropDown
          v-model="category"
          :items-data="categoryValidation"
          items-data-key="display_name"
          :initial-selection="true"
          :small="true"
          :rules="{ required: true }"
          class="pointer-events-none"
          label="Kategorie" />
        <DropDown
          v-model="reason"
          :items-data="reasonsValidation"
          items-data-key="display_name"
          :initial-selection="true"
          :small="true"
          :rules="{ required: true }"
          :no-min-height="true"
          label="Begründung Ergänzung" />
        <tool-tip
          class="ml-auto"
          tooltip-text="ToolTip Text"
          :tooltip-icon-fill="'#00A1D1'" />
        <div class="flex flex-col">
          <the-label label="Weitere Bemerkungen" />
          <textarea
            v-model="content"
            class="w-full h-[100px] rounded-[6px] border border-blue-grey-100 p-[10px] focus-visible:outline-blue-grey-200 resize-none"
            placeholder="Notiz hinzufügen" />
        </div>
        <div
          class="bg-blue-grey-50 p-[10px] rounded-[8px] w-full flex flex-col">
          <button-el
            v-if="!areaEdit"
            text="Gebiet einzeichnen"
            :primary="false"
            icon="shape-polygon"
            :less-padding="true"
            @click="
              drawPolygon();
              areaEdit = true;
            " />
          <button-el
            v-else
            text="Gebiet entfernen"
            icon="remove"
            :critical="true"
            :primary="false"
            :less-padding="true"
            @click="
              deleteDrawGeometry();
              areaEdit = false;
            " />
        </div>
        <div class="flex flex-col gap-[4px]">
          <h6 class="text-infra-highlight-800">So geht es weiter</h6>
          <div class="body-2">
            In einem nächsten Schritt werden wir Ihre Angaben prüfen und Sie bei
            Bedarf zur Klärung des Sachverhalts direkt kontaktieren.
          </div>
        </div>
        <button-el text="Ticket Speichern" @click="submitTicket" />
      </div>
    </div>
  </PerfectScrollbar>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { LAYER_KEY__ADDRESS_POINTS } from '@/configs/layers/buildings';
import { deleteFeatures } from '@/features/draw/draw';
import { endValidationTicket } from '../ticketHelper';
import { addMainGeometry } from '@/features/draw/draw';

export default {
  props: {
    showLayerPanel: {
      type: Boolean,
    },
  },
  data() {
    return {
      areaEdit: false,
      content: '',
      reason: null,
      category: null,
      defaultGeometryMode: ['NB1', 'NB2'],
    };
  },
  computed: {
    ...mapGetters('ticket', [
      'getActiveProcess',
      'getTicketStep',
      'getGemarkungActive',
      'getAddressValidationOption',
      'getValidationStep',
      'getDrawAsMultiFeatures',
      'getGemeindeActiveAgs',
    ]),
    ...mapState('draw', ['selectedFeatures']),
    ...mapGetters('search', ['showSearch', 'getResults']),
    ...mapGetters('map', ['getShowDataPanel', 'getContainerHeight']),
    categoryValidation() {
      return [
        this.getAddressValidationOption.category.choices[
          this.getValidationStep - 1
        ],
      ];
    },
    reasonsValidation() {
      let filter;
      if (this.getValidationStep === 1) {
        filter = 'NB';
      } else if (this.getValidationStep === 2) {
        filter = 'AK';
      }
      return this.getAddressValidationOption.reason.choices.filter((reason) =>
        reason.value.includes(filter)
      );
    },
  },
  watch: {
    reason() {
      deleteFeatures(this);
      this.$store.commit('ticket/SET_DRAW_SELECTED_IDS', []);
      this.$store.commit('draw/RESET_GEOMETRY_SELECTION');
      this.areaEdit = false;
    },
  },
  methods: {
    drawPolygon() {
      if (!this.defaultGeometryMode.includes(this.reason)) {
        this.$store.commit('draw/SET_GEOMETRY_SELECTION', {
          layer: LAYER_KEY__ADDRESS_POINTS,
          active: true,
        });
      }
      addMainGeometry('polygon');
    },
    deleteDrawGeometry() {
      deleteFeatures(this);
    },
    submitTicket() {
      const multipolygon = this.getDrawAsMultiFeatures.multipolygon;
      const ticketData = {
        content: this.content,
        category: this.category,
        reason: this.reason,
        gemeinde: this.getGemeindeActiveAgs,
        address_points: this.selectedFeatures,
      };
      if (multipolygon) {
        ticketData.multipolygon = multipolygon;
      }
      this.$store
        .dispatch('ticket/POST_ADDRESS_VALIDATION', ticketData)
        .then(() => {
          this.$store.commit('layout/SHOWTOAST', {
            color: '#0692BC',
            message: 'Ticket wurde erfolgreich gespeichert!',
          });
          setTimeout(() => {
            endValidationTicket(this);
          }, 1000);
        })
        .catch(() => {
          this.$store.commit('layout/SHOWTOAST', {
            color: '#D50000',
            message: 'Ticket konnte nicht gespeichert werden!',
          });
        });
    },
    setNextStep(step) {
      this.$store.commit('ticket/SET_TICKET_STEP', step);
    },
  },
};
</script>
