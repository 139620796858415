import { getMap } from '@/mapbox/main';
import { getMarked } from '@/mapbox/onClick/constructorClick';
import booleanContains from '@turf/boolean-contains';
import { SM_ADDRESS, SM_LINE, SM_POLY } from "./index";

function resetGeometrySelection(g) {
  Object.keys(g).forEach((v) => (g[v] = false));
}

export default {
  SET_GEOMETRY_SELECTION: (state, payload) => {
    resetGeometrySelection(state.geometrySelection);
    if (!Object.keys(state.geometrySelection).includes(payload.layer)) {
      console.warn('Selectionmode for Layer not implemented');
    }
    state.geometrySelection[payload.layer] = payload.active;
  },
  RESET_GEOMETRY_SELECTION: (state) => {
    resetGeometrySelection(state.geometrySelection);
    getMarked().resetFeatureState();
    state.selectedFeatures = [];
  },
  SELECT_FEATURES_IN_GEOMETRY: (state, payload) => {
    const map = getMap();
    const layer = payload.layer;
    const geometry = payload.geometry;
    const features = map.queryRenderedFeatures({ layers: [layer] });
    features.forEach((e) => {
      if (booleanContains(geometry, e.geometry)) {
        const pk = e.properties.pk;
        getMarked().setFeatureState(
          [{ ...e, source: layer, sourceLayer: layer }],
          true
        );
        if (state.selectedFeatures.findIndex((f) => f.pk === pk) < 0) {
          state.selectedFeatures.push({ id: pk });
        }
      }
    });
  },
  SELECT_FEATURE: (state, pk) => {
    const index = state.selectedFeatures.findIndex((e) => e.pk === pk);
    if (index > -1) state.selectedFeatures.splice(index, 1);
    if (index === -1 && !state.selectedFeatures.includes(pk)) {
      state.selectedFeatures.push({ id: pk });
    }
  },
  SET_SELECTION_MODE: (state, payload) => {
    if (payload !== SM_POLY && payload !== SM_LINE && payload !== SM_ADDRESS && payload !== null) {
      throw Error("Invalid selection mode")
    }
    state.selectionMode = payload
  }
};
