<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3463_19042)">
      <path
        d="M9.87552 7.8287L5.92739 0.549947C5.83002 0.369362 5.68153 0.221523 5.50052 0.124947C5.2546 -0.00665839 4.96666 -0.0358401 4.69933 0.0437494C4.432 0.123339 4.20691 0.305262 4.07302 0.549947L0.125519 7.8287C0.0392017 7.98887 -0.00395649 8.16875 0.000285044 8.35066C0.00452658 8.53256 0.0560219 8.71024 0.149711 8.86622C0.2434 9.0222 0.376059 9.15112 0.534652 9.24031C0.693246 9.32951 0.872317 9.37591 1.05427 9.37495H8.94677C9.12117 9.37495 9.29289 9.33202 9.44677 9.24995C9.56868 9.18524 9.67658 9.09703 9.76424 8.99041C9.85189 8.8838 9.91757 8.76088 9.95749 8.62876C9.99741 8.49663 10.0108 8.35791 9.99681 8.22059C9.98285 8.08328 9.94121 7.95009 9.87552 7.8287ZM5.00052 8.12495C4.87691 8.12495 4.75607 8.08829 4.65329 8.01962C4.55051 7.95094 4.4704 7.85333 4.42309 7.73912C4.37579 7.62492 4.36341 7.49925 4.38753 7.37802C4.41164 7.25678 4.47117 7.14541 4.55858 7.05801C4.64599 6.9706 4.75735 6.91107 4.87859 6.88696C4.99983 6.86284 5.12549 6.87522 5.2397 6.92252C5.3539 6.96983 5.45151 7.04993 5.52019 7.15272C5.58886 7.2555 5.62552 7.37633 5.62552 7.49995C5.62552 7.66571 5.55967 7.82468 5.44246 7.94189C5.32525 8.0591 5.16628 8.12495 5.00052 8.12495ZM5.62552 5.93745C5.62552 6.02033 5.59259 6.09981 5.53399 6.15842C5.47538 6.21702 5.3959 6.24995 5.31302 6.24995H4.68802C4.60514 6.24995 4.52565 6.21702 4.46705 6.15842C4.40844 6.09981 4.37552 6.02033 4.37552 5.93745V3.43745C4.37552 3.35457 4.40844 3.27508 4.46705 3.21648C4.52565 3.15787 4.60514 3.12495 4.68802 3.12495H5.31302C5.3959 3.12495 5.47538 3.15787 5.53399 3.21648C5.59259 3.27508 5.62552 3.35457 5.62552 3.43745V5.93745Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_3463_19042">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
