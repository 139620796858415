import {
  LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT,
  LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE,
  LAYER_KEY__BUILTT_NVT,
  LAYER_KEY__BUILTT_POP,
  LAYER_KEY__BUILTT_ROUTE,
} from '@/configs/layers/builtt';

export const SWITCH_ID__BUILTT_ROUTE = 'LC_BUILTT_ROUTES';
export const SWITCH_ID__BUILTT_NVT = 'LC_BUILTT_NVT';
export const SWITCH_ID__BUILTT_POP = 'LC_BUILTT_POP';
export const SWITCH_ID__BUILTT_HOUSE_CONNECTION = 'LC_BUILTT_HOUSE_CONNECTION';

export default {
  title: 'Baufortschritt',
  modelNameForCASL: 'builttroute',
  items: [
    {
      active: false,
      title: 'Trassen',
      onLayers: [LAYER_KEY__BUILTT_ROUTE],
      switchId: SWITCH_ID__BUILTT_ROUTE,
      itemClass: 'switch',
      modelNameForCASL: 'builttroute', // for CASL permissions
    },
    {
      active: false,
      title: 'Netzverteiler (NVT)',
      onLayers: [LAYER_KEY__BUILTT_NVT],
      switchId: SWITCH_ID__BUILTT_NVT,
      itemClass: 'switch',
      modelNameForCASL: 'builttroute',
    },
    {
      active: false,
      title: 'Point of Presence (POP)',
      onLayers: [LAYER_KEY__BUILTT_POP],
      switchId: SWITCH_ID__BUILTT_POP,
      itemClass: 'switch',
      modelNameForCASL: 'builttroute',
    },
    {
      active: false,
      title: 'Hausanschlüsse',
      onLayers: [
        LAYER_KEY__BUILTT_HOME_CONNECTION_ADDRESS_POINT,
        LAYER_KEY__BUILTT_HOME_CONNECTION_ROUTE,
      ],
      switchId: SWITCH_ID__BUILTT_HOUSE_CONNECTION,
      itemClass: 'switch',
      modelNameForCASL: 'builttroute',
    },
  ],
};
