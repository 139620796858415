<template>
  <IconWrapper
    v-if="props.downloadFileName"
    v-bind="$attrs"
    id="icon-wrapper"
    icon="menu"
    size="17"
    class="cursor-pointer bg-subtle"
    @click="handleOpenDropdown" />
  <div
    v-if="showDropdown"
    :class="{ dropdown: true, active: showDropdown }"
    class="bg-subtle rounded-md py-1 px-3 border shadow-md z-10 flex flex-col gap-1">
    <div
      v-for="(buttonLabel, index) in items"
      :key="index"
      @click="handleDownload(buttonLabel)">
      {{ buttonLabel }}
    </div>
  </div>
</template>

<script setup>
import { useListeners } from '../../composables/useListeners';
import IconWrapper from '../IconWrapper/IconWrapper.vue';
import { ref, computed } from 'vue';

const showDropdown = ref(false);

useListeners({
  click: handleClickOutside,
});

const props = defineProps({
  items: {
    type: [Array, Object],
    required: true,
  },
  downloadFileName: String,
});

const emit = defineEmits(['download-png', 'download-csv']);

function handleDownload(buttonLabel) {
  if (buttonLabel.includes('png')) {
    emit('download-png');
  } else if (buttonLabel.includes('csv')) {
    emit('download-csv');
  }
}

function handleClickOutside() {
  showDropdown.value = false;
}

function handleOpenDropdown() {
  showDropdown.value = !showDropdown.value;
  // Stop event propagation to prevent closing dropdown prematurely
  event.stopPropagation();
}
</script>

<style>
#icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px !important;
  height: 33px !important;
  padding-top: 8px;
  padding-left: 8px;
  border-radius: 14%;
}

.dropdown {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: none; /* Initially hide the dropdown */
}

.dropdown.active {
  cursor: pointer;
  display: block; /* Show dropdown when active */
}
</style>
