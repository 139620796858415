<template>
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666748 14.6492V1.1302C0.666748 0.210682 1.80326 -0.22142 2.41416 0.465836L8.10638 6.86958C8.43007 7.23374 8.4442 7.77818 8.13984 8.15864L2.44762 15.2739C1.85711 16.0121 0.666748 15.5945 0.666748 14.6492Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
