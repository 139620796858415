<template>
  <div
    class="min-h-screen bg-cover flex flex-col justify-center"
    style="
      background: linear-gradient(257.82deg, #001257 -12.99%, #0692bc 117.03%);
    ">
    <slot></slot>
    <footer-section />
  </div>
</template>
