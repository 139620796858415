<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.375 0H0.625C0.279822 0 0 0.279822 0 0.625V4.375C0 4.72018 0.279822 5 0.625 5H4.375C4.72018 5 5 4.72018 5 4.375V0.625C5 0.279822 4.72018 0 4.375 0Z"
      :fill="fill" />
    <path
      d="M11.875 0H8.125C7.77982 0 7.5 0.279822 7.5 0.625V4.375C7.5 4.72018 7.77982 5 8.125 5H11.875C12.2202 5 12.5 4.72018 12.5 4.375V0.625C12.5 0.279822 12.2202 0 11.875 0Z"
      :fill="fill" />
    <path
      d="M19.375 0H15.625C15.2798 0 15 0.279822 15 0.625V4.375C15 4.72018 15.2798 5 15.625 5H19.375C19.7202 5 20 4.72018 20 4.375V0.625C20 0.279822 19.7202 0 19.375 0Z"
      :fill="fill" />
    <path
      d="M4.375 7.5H0.625C0.279822 7.5 0 7.77982 0 8.125V11.875C0 12.2202 0.279822 12.5 0.625 12.5H4.375C4.72018 12.5 5 12.2202 5 11.875V8.125C5 7.77982 4.72018 7.5 4.375 7.5Z"
      :fill="fill" />
    <path
      d="M11.875 7.5H8.125C7.77982 7.5 7.5 7.77982 7.5 8.125V11.875C7.5 12.2202 7.77982 12.5 8.125 12.5H11.875C12.2202 12.5 12.5 12.2202 12.5 11.875V8.125C12.5 7.77982 12.2202 7.5 11.875 7.5Z"
      :fill="fill" />
    <path
      d="M19.375 7.5H15.625C15.2798 7.5 15 7.77982 15 8.125V11.875C15 12.2202 15.2798 12.5 15.625 12.5H19.375C19.7202 12.5 20 12.2202 20 11.875V8.125C20 7.77982 19.7202 7.5 19.375 7.5Z"
      :fill="fill" />
    <path
      d="M4.375 15H0.625C0.279822 15 0 15.2798 0 15.625V19.375C0 19.7202 0.279822 20 0.625 20H4.375C4.72018 20 5 19.7202 5 19.375V15.625C5 15.2798 4.72018 15 4.375 15Z"
      :fill="fill" />
    <path
      d="M11.875 15H8.125C7.77982 15 7.5 15.2798 7.5 15.625V19.375C7.5 19.7202 7.77982 20 8.125 20H11.875C12.2202 20 12.5 19.7202 12.5 19.375V15.625C12.5 15.2798 12.2202 15 11.875 15Z"
      :fill="fill" />
    <path
      d="M19.375 15H15.625C15.2798 15 15 15.2798 15 15.625V19.375C15 19.7202 15.2798 20 15.625 20H19.375C19.7202 20 20 19.7202 20 19.375V15.625C20 15.2798 19.7202 15 19.375 15Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
