<template>
  <div class="custom-scrollbar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ScrollBar",
};
</script>

<style lang="sass" scoped>

.custom-scrollbar
    overflow: overlay
    max-height: 95vh
.custom-scrollbar::-webkit-scrollbar
    width: 15px

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb
    background-color: #00000000
    border: 5px solid transparent
    box-shadow: 4px 0px 0px 4px rgba(0, 0, 0, .3) inset
.custom-scrollbar:hover::-webkit-scrollbar-thumb
    box-shadow: 4px 0px 0px 4px rgba(0, 0, 0, .5) inset

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover
    box-shadow: 4px 0px 0px 4px rgba(0, 0, 0, .5) inset
</style>
