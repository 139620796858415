<template>
  <div
    v-if="Object.keys(getActiveProcess).length"
    class="absolute top-5 left-[360px]">
    <div class="flex map-actions-custom rounded-[8px] overflow-hidden">
      <tool-button
        v-if="getActiveProcess.id !== 'validation'"
        :icon="getActiveProcess.icon"
        class="rounded-l-none pointer-events-none"
        :background="'bg-infra-highlight-600'"
        icon-fill="white" />
      <draw-control-actions
        :draw-control-options="getActiveProcess.drawComponentName"
        :init-with-draw-mode="getActiveProcess.initWithDrawMode" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ticket', ['getActiveProcess']),
  },
};
</script>

<style scoped lang="scss">
.map-actions-custom {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
</style>
