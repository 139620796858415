<template>
  <v-btn
    class="infra-button-custom h-[45px] flex items-center normal-case shadow-none font-medium text-[16px] tracking-wider rounded-[6px] focus:outline-none transition duration-200"
    :class="{
      'bg-infra-highlight text-white': primary && !defaultVuetify,
      'hover:bg-infra-blue': primary && !defaultVuetify,
      'bg-red-400': !primary,
      'hover:bg-red-500': !primary,
    }"
    :disabled="disabled"
    :loading="loading"
    :style="{
      'background-color': white ? '#fff !important' : '',
      color: white ? '#001257 !important' : '',
    }"
    @click="onClick"
  >
    <slot>Button</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'InfraButton',
  props: {
    onClick: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    primary: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultVuetify: {
      type: Boolean,
      required: false,
      default: false,
    },
    white: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.disabled-button {
  background-color: #acb0b9 !important;
}

.infra-button-custom {
  padding: 13px 24px;
}
</style>
