<template>
  <div class="ml-auto">
    <div v-if="!isSelected" class="flex text-right ml-auto subtitle-2">
      <div v-if="isLoading">
        <skeleton-loader-custom />
      </div>
      <div v-else>{{ findChoice ? findChoice.display_name : '-' }}</div>
    </div>

    <!-- item type dropdown-->
    <div v-else>
      <perfect-scrollbar style="max-height: 100px; height: 100%">
        <div class="flex flex-col">
          <!-- single list item loop-->
          <div
            v-for="(choice, index) in item.choices"
            :key="index"
            class="justify-end overline-2 flex font-light border-b mb-1 cursor-pointer last:border-none last:mb-0"
            :class="choice.value === item.value ? 'order-first' : ''">
            <div
              :class="
                (currentInput && currentInput.value === choice.value
                  ? 'font-medium'
                  : '') ||
                (choice.value === item.value && currentInput === null
                  ? 'font-medium'
                  : 'font-light')
              "
              class="w-full text-right pb-0.5 max-w-[109px]"
              @click="selectChoice(choice)">
              {{ choice.display_name }}
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    isSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    clickIndex: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ['updateInput'],
  data() {
    return {
      currentInput: null,
      localIndex: null,
    };
  },
  computed: {
    findChoice() {
      return this.item.choices.find((c) => c.value === this.item.value);
    },
  },
  methods: {
    selectChoice(choice) {
      this.currentInput = choice;
      this.localIndex = this.clickIndex;
      this.$emit('updateInput', choice.value);
    },
  },
};
</script>

<style lang="scss"></style>
