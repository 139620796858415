<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8764 8.48349L12.2203 2.08034C12.1581 2.02838 12.0803 2 12 2C11.9197 2 11.8419 2.02838 11.7797 2.08034L7.82393 5.38864V3.7786C7.82393 3.68425 7.78727 3.59377 7.722 3.52706C7.65674 3.46035 7.56822 3.42287 7.47593 3.42287H6.43191C6.33961 3.42287 6.2511 3.46035 6.18583 3.52706C6.12057 3.59377 6.0839 3.68425 6.0839 3.7786V6.84393L4.12359 8.48349C4.05375 8.54373 4.00989 8.62962 4.00148 8.72262C3.99307 8.81563 4.02077 8.90828 4.07862 8.98058C4.13646 9.05288 4.21981 9.09903 4.31065 9.10905C4.4015 9.11908 4.49256 9.09218 4.56416 9.03417L12 2.81528L19.4358 9.03417C19.5074 9.09218 19.5985 9.11908 19.6893 9.10905C19.7802 9.09903 19.8635 9.05288 19.9214 8.98058C19.9792 8.90828 20.0069 8.81563 19.9985 8.72262C19.9901 8.62962 19.9462 8.54373 19.8764 8.48349ZM12.0002 3.77777L5.77799 8.79566V16.2408C6.26818 16.4338 6.70216 16.7343 7.05385 17.1111H10.2718V13.3623C10.2718 13.2719 10.3082 13.1853 10.3731 13.1214C10.4379 13.0574 10.5258 13.0215 10.6175 13.0215H13.3829C13.4746 13.0215 13.5625 13.0574 13.6274 13.1214C13.6922 13.1853 13.7286 13.2719 13.7286 13.3623V17.1111H16.9462C17.2979 16.7342 17.7321 16.4336 18.2224 16.2407V8.79566L12.0002 3.77777ZM13.0373 10.636H10.9632C10.8715 10.636 10.7836 10.6001 10.7187 10.5361C10.6539 10.4722 10.6175 10.3855 10.6175 10.2952V8.25038C10.6175 8.16 10.6539 8.07332 10.7187 8.0094C10.7836 7.94549 10.8715 7.90959 10.9632 7.90959H13.0373C13.1289 7.90959 13.2169 7.94549 13.2817 8.0094C13.3465 8.07332 13.3829 8.16 13.3829 8.25038V10.2952C13.3829 10.3855 13.3465 10.4722 13.2817 10.5361C13.2169 10.6001 13.1289 10.636 13.0373 10.636Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 17C18.47 17 17.6 17.62 17.21 18.5H6.79C6.4 17.62 5.53 17 4.5 17C3.83696 17 3.20107 17.2634 2.73223 17.7322C2.26339 18.2011 2 18.837 2 19.5C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22C5.53 22 6.4 21.38 6.79 20.5H17.21C17.6 21.38 18.47 22 19.5 22C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5C22 18.837 21.7366 18.2011 21.2678 17.7322C20.7989 17.2634 20.163 17 19.5 17ZM5.5 19.5C5.5 20.0523 5.05228 20.5 4.5 20.5C3.94772 20.5 3.5 20.0523 3.5 19.5C3.5 18.9477 3.94772 18.5 4.5 18.5C5.05228 18.5 5.5 18.9477 5.5 19.5ZM19.5 20.5C20.0523 20.5 20.5 20.0523 20.5 19.5C20.5 18.9477 20.0523 18.5 19.5 18.5C18.9477 18.5 18.5 18.9477 18.5 19.5C18.5 20.0523 18.9477 20.5 19.5 20.5Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
