<script>
import dayjs from 'dayjs';

export default {
  methods: {
    formatDateString(date) {
      return dayjs(date).format('DD.MM.YYYY');
    },
  },
};
</script>
