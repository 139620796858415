<template>
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 1.83333H17.6667V42.1667H8.5V1.83333ZM10.3333 3.66666V40.3333H15.8333V3.66666H10.3333Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 40.3333H23.1667V42.1667H3V40.3333Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5833 10.9346L16.6823 9.16416L16.8177 7.33583L42.4167 9.23205V15.5833H16.75V13.75H40.5833V10.9346Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.91667 10.7748L9.66849 9.13139L9.16484 7.3686L2.08333 9.39189V15.5833H9.41667V13.75H3.91667V10.7748Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.9167 18.3333V28.4167H35.0833V18.3333H36.9167Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.5 26.5833H41.5V33H30.5V26.5833ZM32.3333 28.4167V31.1667H39.6667V28.4167H32.3333Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0203 5.7268L17.3536 12.1435L16.1464 13.5232L8.81304 7.10652L10.0203 5.7268Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0203 18.5601L17.3536 24.9768L16.1464 26.3565L8.81304 19.9399L10.0203 18.5601Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0203 31.3935L17.3536 37.8101L16.1464 39.1899L8.81304 32.7732L10.0203 31.3935Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.81304 18.5601L16.1464 12.1435L17.3536 13.5232L10.0203 19.9399L8.81304 18.5601Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.81304 31.3935L16.1464 24.9768L17.3536 26.3565L10.0203 32.7732L8.81304 31.3935Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
