<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2019_156252)">
      <path
        d="M14.5 15.5H1.5C0.948 15.5 0.5 15.052 0.5 14.5V1.5C0.5 0.948 0.948 0.5 1.5 0.5H14.5C15.052 0.5 15.5 0.948 15.5 1.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5Z"
        stroke="#F8FDFF"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.5 9.5H10.5V12.5H5.5V9.5H0.5"
        :stroke="fill"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M5.5 5.5L7.5 7.5L11.5 3.5"
        :stroke="fill"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2019_156252">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
