import polygonBorderLayer from './polygonBorderLayer';
import dayjs from 'dayjs';
import { axios } from '@/utils/axiosHelper';

export const LAYER_KEY__TENDER_ROUTE = 'tenderroute';
export const LAYER_KEY__TENDER_EXPANSION = 'expansionsection';
export const LAYER_KEY__TENDER_EXPANSION_BORDER = 'expansionsectionBorder';

export const YEARLY_DATE_STRINGS = [...Array(6)].map(
  (_, i) => `YEARLY_DATE_STRING${i}`
);
const tenderExpansionColors = [
  'rgba(50, 205, 50, 0.6)',
  'rgba(255, 234, 0, 0.6)',
  'rgba(255, 172, 28, 0.6)',
  'rgba(238, 75, 43, 0.6)',
  'rgba(100, 149, 237, 0.6)',
  'rgba(100, 25, 117, 0.6)',
];

export default {
  [LAYER_KEY__TENDER_EXPANSION]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'tenders',
      router_name: 'ExpansionSection',
      key_name: 'pk',
      type: 'polygon',
      id: LAYER_KEY__TENDER_EXPANSION,
      source: LAYER_KEY__TENDER_EXPANSION,
      sourceLayer: LAYER_KEY__TENDER_EXPANSION,
      visibility: 'none',
      hoverPointer: true,
    },
    style: {
      replace: {
        toReplace: 'YEARLY_DATE_STRING',
        multiple: true,
        storageGetterForReplacement: 'map/getAvailableTenderYears',
      },
      default: {
        fillColor: [
          'case',
          ...Array.from(Array((YEARLY_DATE_STRINGS.length - 1) * 2)).map(
            (_, i) => {
              let index = Math.floor(i / 2);
              if (i % 2 === 0) {
                return [
                  'in',
                  YEARLY_DATE_STRINGS[index],
                  ['get', 'date_start'],
                ];
              } else {
                return tenderExpansionColors[index];
              }
            }
          ),
          tenderExpansionColors[tenderExpansionColors.length - 1],
        ],
        fillOpacity: 1.0,
      },
    },
    legend: {
      replace: {
        toReplace: 'YEARLY_DATE_STRING',
        multiple: true,
        storageGetterForReplacement: 'map/getAvailableTenderYears',
      },
      default: {
        name: 'Bauabschnitte Voraussichtlicher Baubeginn',
        unit: '',
        items: YEARLY_DATE_STRINGS.map((str, i) => ({
          color: tenderExpansionColors[i],
          name: i === YEARLY_DATE_STRINGS.length - 1 ? `ab ${str}` : str,
          icon: 'mdi-square',
          size: '14px',
        })),
      },
    },
    sidebar: {
      name: 'Ausschreibungen',
      subtitles: [
        { key: 'cluster' },
        { key: 'lot_number' },
        { key: 'section_construction' },
        { key: 'area_name' },
      ],
      chips: [
        {
          title: 'Info',
          active: true,
          items: [
            {
              title: 'Versorgung (heute)',
              active: true,
              items: [
                {
                  key: 'estimate_value',
                  unit: ' €',
                  value: '0',
                  decimal: 0,
                },
                {
                  key: 'route_length_m',
                  unit: ' m',
                  value: '0',
                  decimal: 0,
                },
                {
                  key: 'date_start',
                  unit: '',
                  value: '0',
                  decimal: 0,
                },
                {
                  key: 'date_end',
                  unit: '',
                  value: '0',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__TENDER_EXPANSION_BORDER]: polygonBorderLayer(
    LAYER_KEY__TENDER_EXPANSION_BORDER,
    LAYER_KEY__TENDER_EXPANSION
  ),
  [LAYER_KEY__TENDER_ROUTE]: {
    layoutState: 'default',
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__TENDER_ROUTE,
      sourceLayer: LAYER_KEY__TENDER_ROUTE,
      source: LAYER_KEY__TENDER_ROUTE,
      visibility: 'none',
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['==', ['get', 'phase'], 3],
          'rgba(122, 227, 48, 0.6)',
          ['==', ['get', 'phase'], 4],
          'rgba(59, 120, 14, 0.6)',
          'rgba(0, 0, 0, 0.6)',
        ],
        lineWidth: 3,
      },
    },
    legend: {
      default: {
        name: 'Trassenführung',
        unit: '',
        items: [
          {
            color: '#7AE330',
            name: 'Hellgrau',
            icon: 'mdi-minus',
            size: '14px',
          },
          {
            color: '#3B780E',
            name: 'Dunkelgrau',
            icon: 'mdi-minus',
            size: '14px',
          },
        ],
      },
    },
  },
};
