<template>
  <template
    v-for="(subItem, formattedDate) in groupedMilestones"
    :key="subItem">
    <div
      v-if="formattedDate"
      :style="calculateStylesForMilestone(subItem, formattedDate)"
      :data-duration="formattedDate + '-' + formattedDate"
      class="absolute flex justify-center h-full chart-bar-item min-w-[27px]">
      <div
        class="milestone-wrapper flex justify-center items-center"
        @mouseleave="hideMilestone">
        <div
          :ref="'toolTipActivator' + formattedDate"
          :style="{ backgroundColor: calculateBackgroundColor(subItem) }"
          class="w-3 h-3 rounded-full"
          @mouseenter="setMilestone(formattedDate)"></div>
        <ToolTipAdvanced
          v-if="formattedDate === dateItemIndex"
          v-model="showPopover"
          fixed="all"
          :offset-y="5"
          :offset-x="-3"
          :activator-bounds="buttonBounds">
          <template #content>
            <!-- Case where subItem.length === 1 -->
            <template v-if="subItem.length === 1">
              <div
                :style="{
                  backgroundColor: subItem[0].color,
                }"
                class="caption-2 text-white standard-elevation-1 p-1 rounded whitespace-pre body-2 w-max">
                <p>
                  {{ subItem[0].name }}
                </p>
                <p class="caption-3">
                  {{ formatDate(subItem[0].rawDate) }}
                </p>
              </div>
            </template>

            <!-- Case where subItem.length !== 1 -->
            <template v-else>
              <div
                class="flex flex-col gap-1 bg-white standard-elevation-1 z-10 p-1 rounded whitespace-pre body-2">
                <div
                  v-for="(item, index) in subItem"
                  :key="index"
                  class="flex justify-start items-center">
                  <div class="caption-3 flex justify-between gap-2 w-full">
                    <div class="flex">
                      <div
                        class="w-2.5 h-2.5 rounded-full"
                        :style="{
                          backgroundColor: item.color,
                        }" />
                      {{ item.name }}
                    </div>
                    <div>{{ formatDate(item.rawDate) }}</div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </ToolTipAdvanced>
      </div>
    </div>
  </template>
</template>

<script>
import ToolTipAdvanced from '../../toolTipAdvanced/ToolTipAdvanced.vue';

export default {
  name: 'MilestoneItemPis',
  components: { ToolTipAdvanced },
  props: {
    milestoneData: {
      type: Object,
      default: () => {},
    },
    dataItem: {
      type: Object,
      default: () => {},
    },
    mapConfig: {
      type: Object,
      default: () => {},
    },
    bgColor: {
      type: String,
      default: 'bg-black',
    },
    interval: {
      type: String,
      default: 'day',
    },
    intervalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showMilestonePopover: false,
      dateItemIndex: null,
      showPopover: true,
      buttonBounds: null,
    };
  },
  computed: {
    groupedMilestones() {
      let groupedMilestones = {};
      for (let milestone of this.milestoneData) {
        for (let dateObj of milestone) {
          for (let date of dateObj.dates) {
            let formattedDate = this.convertShortDate(date.date);
            if (formattedDate in groupedMilestones) {
              groupedMilestones[formattedDate].push({
                name: date.name, //former key
                rawDate: date.date, //former value
                // formattedDate not needed, is in key now
                color: dateObj.color,
                mixed: false,
              });
            } else {
              groupedMilestones[formattedDate] = [
                {
                  name: date.name, //former key
                  rawDate: date.date, //former value
                  // formattedDate not needed, is in key now
                  color: dateObj.color,
                  mixed: false,
                },
              ];
            }
          }
        }
      }
      for (let group of Object.values(groupedMilestones)) {
        group.sort((a, b) => new Date(a.rawDate) - new Date(b.rawDate));

        let groupColor = group[0].color;
        for (let item of group) {
          if (item.color !== groupColor) {
            group.map((e) => (e.mixed = true));
            break;
          }
        }
      }

      return groupedMilestones;
    },
  },
  methods: {
    setMilestone(formattedDate) {
      this.showMilestonePopover = true;
      this.dateItemIndex = formattedDate;

      if (this.showMilestonePopover && this.dateItemIndex === formattedDate) {
        this.buttonBounds =
          this.$refs[
            'toolTipActivator' + formattedDate
          ][0].getBoundingClientRect();
        this.showPopover = true;
      }
    },
    hideMilestone() {
      this.showMilestonePopover = false;
      this.showPopover = false;
      this.dateItemIndex = null;
    },

    calculateBackgroundColor(subItem) {
      if (subItem.length === 1) {
        return subItem[0].color;
      }

      if (subItem[0].mixed) {
        return '#6D4C41';
      }

      return subItem[0].color;
    },

    calculateStylesForMilestone(subItem, formattedDate) {
      const days = document.querySelectorAll('.chart-values .date-item');
      const daysArray = [...days];

      let left = 0;

      const filteredStartDay = daysArray.find(
        (day) => day.dataset.intervaldata === formattedDate,
      );
      left = filteredStartDay?.offsetLeft || 0;

      return {
        left: `${left}px`,
      };
    },
    convertShortDate(date) {
      if (this.interval === 'months') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.label;
            }
          });
        });
        return shortCut;
      } else if (this.interval === 'weeks') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.value + '.' + item.year;
            }
          });
        });
        return shortCut;
      } else {
        return date.split('-').reverse().join('.');
      }
    },
    formatDate(date) {
      return date.split('-').reverse().join('.');
    },
  },
};
</script>
