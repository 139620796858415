<template>
  <v-overlay :value="overlay" class="loading-overlay" style="z-index: 20">
    <v-progress-circular indeterminate size="64" class="loading-spinner"></v-progress-circular>

    <p class="loading-text">{{ sentence[sentenceSelector] }}</p>
  </v-overlay>
</template>

<script>
export default {
  props: ["overlay"],
  data() {
    return {
      sentence: [
        "Gebäude werden gebaut...",
        "Seen werden gegraben...",
        "Stromnetze werden verlegt...",
        "Verteiler werden gesetzt...",
        "Berge werden aufgeschüttet...",
        "Wälder werden gepflanzt...",
        "Leitungen werden gelegt...",
      ],
      sentenceSelector: null,
    };
  },
  mounted() {
    // Selects random sentence
    this.sentenceSelector = Math.floor(Math.random() * this.sentence.length);
  },
};
</script>

<style scoped>
.loading-overlay :deep(.v-overlay__content) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-text {
  margin-top: 25px;
  margin-bottom: 0;

  text-align: center;
  font-size: 0.825rem;
}
</style>
