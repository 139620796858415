<template>
  <div class="flex flex-col standard-elevation-0-dark">
    <div class="p-2 flex justify-between gap-2 items-center relative">
      <interval-switch-pis @interval="selectedInterval = $event" />
      <year-nodge-pis v-if="selectedInterval !== 'months'" :year="yearData" />
    </div>
    <div class="w-full bg-white gantt-chart-custom flex overflow-hidden">
      <construction-data-table-pis
        :data="data"
        :data-map="dataMap"
        :richtlinien="richtlinien"
        :scroll-target="sideBarScrollPosition"
        @child-scroll="updateParentScroll"
        @show-milestones="showMilestones = $event"
        @active-index="openMilestonesIndices = $event" />
      <!-- gantt chart START-->
      <div
        class="chart-wrapper overflow-x-auto overflow-y-auto max-h-[539px] custom-scrollbar-width"
        @wheel="handleWheelEvent">
        <!-- header with dates-->
        <table class="sticky top-0 z-10 bg-white">
          <tbody class="chart-values relative flex">
            <tr v-if="selectedInterval === 'days'" class="flex border-y">
              <td
                v-for="date in combinedIntervalDates.allDates"
                :key="date"
                :data-intervaldata="date"
                :class="{
                  'bg-infra-highlight-25': isWeekend(date),
                  'bg-white': !isWeekend(date),
                }"
                style="
                  border-right: 1px solid #eceff1 !important;
                  color: #607d8b !important;
                  height: 22px !important;
                "
                class="date-item w-[27px]">
                {{ convertHeaderDate(date) }}
              </td>
            </tr>
            <tr v-else-if="selectedInterval === 'weeks'" class="flex border-y">
              <td
                v-for="date in calendarWeeks"
                :key="date"
                :data-intervaldata="date.value + '.' + date.year"
                style="
                  border-right: 1px solid #eceff1 !important;
                  color: #607d8b !important;
                  height: 22px !important;
                "
                class="date-item w-[27px]">
                {{ 'KW ' + date.value }}
              </td>
            </tr>
            <tr
              v-else-if="selectedInterval === 'months'"
              class="flex border-y border-blue-grey-50">
              <td
                v-for="date in calendarMonths"
                :key="date"
                :data-intervaldata="date.label"
                style="
                  border-right: 1px solid #eceff1 !important;
                  color: #607d8b !important;
                  height: 22px !important;
                "
                class="date-item w-[27px] whitespace-break-spaces text-center leading-[9px]">
                {{
                  date.label.split('\n')[0] +
                  '\n' +
                  date.label.split('\n')[1].slice(-2)
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- table data-->
        <table class="left-0 w-full">
          <template v-for="(dataItem, index) in data" :key="dataItem">
            <tbody
              v-if="
                dataItem &&
                (selectedInterval === 'days' ||
                  selectedInterval === 'weeks' ||
                  selectedInterval === 'months') &&
                hasAnyDate(dataItem)
              "
              class="tbody-table-custom">
              <tr
                class="chart-row header-row relative border-b flex w-fit h-[24px] bg-blue-grey-25">
                <td
                  v-for="date in selectedInterval === 'days'
                    ? combinedIntervalDates.allDates
                    : selectedInterval === 'weeks'
                      ? Object.keys(calendarWeeks)
                      : Object.keys(calendarMonths)"
                  :key="date"
                  style="
                    border-right: 1px solid transparent !important;
                    height: 23px;
                  "
                  class="flex min-w-[27px]"></td>
                <milestone-item-pis
                  :milestone-data="getAllDates(dataItem)"
                  :interval="selectedInterval"
                  :interval-data="intervalValues" />
              </tr>
              <div
                v-if="
                  (selectedInterval === 'days' ||
                    selectedInterval === 'weeks' ||
                    selectedInterval === 'months') &&
                  openMilestonesIndices.includes(index) &&
                  showMilestones
                "
                class="expanded">
                <template v-for="mapConfig in dataMap" :key="mapConfig.key">
                  <tr
                    v-if="getDates(mapConfig, dataItem)"
                    :key="mapConfig.key"
                    style="border-color: #eceff1 !important"
                    class="chart-row border-b flex w-fit relative">
                    <td
                      v-for="date in selectedInterval === 'days'
                        ? combinedIntervalDates.allDates
                        : selectedInterval === 'weeks'
                          ? Object.keys(calendarWeeks)
                          : Object.keys(calendarMonths)"
                      :key="date"
                      :class="{
                        'bg-infra-highlight-25':
                          isWeekend(date) && selectedInterval === 'days',
                      }"
                      style="
                        border-right: 1px solid #eceff1 !important;
                        height: 23px !important;
                      "
                      class="flex min-w-[27px]"></td>
                    <phase-item-pis
                      :small="getDates(mapConfig, dataItem).length === 2"
                      :duration-data="getDates(mapConfig, dataItem)"
                      :interval="selectedInterval"
                      :interval-data="intervalValues" />
                  </tr>
                </template>
              </div>
            </tbody>
          </template>
        </table>
      </div>
      <!-- gantt chart END-->
    </div>
  </div>
</template>

<script>
import PhaseItemPis from './components/phaseItemPis.vue';
import MilestoneItemPis from './components/milestoneItemPis.vue';
import ConstructionDataTablePis from './components/constructionDataTablePis.vue';
import IntervalSwitchPis from './components/intervalSwitchPis.vue';
import YearNodgePis from './components/yearNodgePis.vue';

export default {
  components: {
    PhaseItemPis,
    YearNodgePis,
    MilestoneItemPis,
    IntervalSwitchPis,
    ConstructionDataTablePis,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    dataMap: {
      type: Object,
      default: () => {},
    },
    richtlinien: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      selectedInterval: null,
      yearData: [],
      openMilestonesIndices: [],
      showMilestones: null,
      sideBarScrollPosition: 0,
    };
  },
  computed: {
    intervalValues() {
      return this.selectedInterval === 'weeks'
        ? this.calendarWeeks
        : this.selectedInterval === 'months'
          ? this.calendarMonths
          : null;
    },
    durationData() {
      return this.data.map((obj) => {
        return {
          genehmigungsplanung: {
            start: obj.intervals.genehmigungsplanung_start,
            end: obj.intervals.genehmigungsplanung_end,
            name: 'Genehmigungsplanung',
          },
          ausfuehrungsplanung: {
            start: obj.intervals.ausfuehrungsplanung_start,
            end: obj.intervals.ausfuehrungsplanung_end,
            name: 'Ausführungsplanung',
          },
          bau: {
            start: obj.intervals.bau_start,
            end: obj.intervals.bau_end,
            name: 'Bau',
          },
          bauabschluss: {
            start: obj.intervals.bauabschluss_start,
            end: obj.intervals.bauabschluss_end,
            name: 'Bauabschluss',
          },
        };
      });
    },
    calendarWeeks() {
      const weeks = {};

      this.combinedIntervalDates.allDates.forEach((dateStr) => {
        const [day, month, year] = dateStr.split('.');
        const date = new Date(year, month - 1, day);
        const weekNumber = this.getISOWeekNumber(date);

        // Determine the correct year for the week
        let adjustedYear = date.getFullYear();
        if (date.getMonth() === 11 && weekNumber === 1) {
          adjustedYear++;
        }
        if (date.getMonth() === 0 && weekNumber >= 52) {
          adjustedYear--;
        }

        const weekKey = `${weekNumber}-${adjustedYear}`;

        if (weekKey in weeks) {
          weeks[weekKey].push(dateStr);
        } else {
          weeks[weekKey] = [dateStr];
        }
      });

      // Remove the 53rd week for years that do not have it
      Object.keys(weeks).forEach((weekKey) => {
        const [weekNumber, year] = weekKey.split('-').map(Number);
        if (weekNumber === 53 && !this.is53WeekYear(year)) {
          delete weeks[weekKey];
        }
      });
      // test

      // Sort the week keys in ascending order
      const sortedWeekKeys = Object.keys(weeks).sort((a, b) => {
        const [weekA, yearA] = a.split('-');
        const [weekB, yearB] = b.split('-');
        const yearOrder = parseInt(yearA) - parseInt(yearB);
        return yearOrder !== 0 ? yearOrder : parseInt(weekA) - parseInt(weekB);
      });

      // Create an array of objects with sorted weeks
      return sortedWeekKeys.map((weekKey, index) => {
        const [weekNumber, year] = weekKey.split('-');
        const weekValue = weekNumber;
        const weekData = weeks[weekKey];

        return {
          value: parseInt(weekValue),
          year: parseInt(year),
          data: weekData,
        };
      });
    },

    calendarMonths() {
      const months = {};
      const monthLabels = [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ];

      this.combinedIntervalDates.allDates.forEach((dateStr) => {
        const [day, month, year] = dateStr.split('.');
        const monthKey = `${monthLabels[parseInt(month) - 1]}\n${year}`;

        if (monthKey in months) {
          months[monthKey].push(dateStr);
        } else {
          months[monthKey] = [dateStr];
        }
      });

      // Sort the month keys in ascending order
      const sortedMonthKeys = Object.keys(months).sort((a, b) => {
        const [monthA, yearA] = a.split('\n');
        const [monthB, yearB] = b.split('\n');

        if (yearA !== yearB) {
          return parseInt(yearA) - parseInt(yearB);
        } else {
          return monthLabels.indexOf(monthA) - monthLabels.indexOf(monthB);
        }
      });

      // Create an array of objects with sorted months
      return sortedMonthKeys.map((monthKey) => {
        const [month, year] = monthKey.split('\n');
        const formattedMonth = `${year}-${
          monthLabels.indexOf(month) + 1
        }`.padStart(2, '0');
        return {
          value: `${formattedMonth}-${year}`,
          label: monthKey,
          data: months[monthKey],
        };
      });
    },

    combinedIntervalDates() {
      const dates = [];

      const gatherDates = (obj, parentKeys = []) => {
        for (const key in obj) {
          const value = obj[key];

          let currentKeys = [...parentKeys];

          // Check if current key is a number
          if (!isNaN(key)) {
            currentKeys.push(Number(key));
          }

          // If any of the parent keys or current key is a number and not in richtlinien, skip its dates
          const invalidKeyFound = currentKeys.some(
            (k) => !isNaN(k) && !this.richtlinien.includes(k),
          );

          if (invalidKeyFound) {
            continue;
          }

          if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
            dates.push(value);
          } else if (typeof value === 'object' && value !== null) {
            gatherDates(value, currentKeys);
          }
        }
      };

      this.data.forEach((record) => gatherDates(record));

      const earliestDate =
        dates.length === 0
          ? null
          : new Date(Math.min(...dates.map((date) => new Date(date))));
      const latestDate =
        dates.length === 0
          ? null
          : new Date(Math.max(...dates.map((date) => new Date(date))));

      const getAllDatesBetween = (startDate, endDate) => {
        const day = 60 * 60 * 24 * 1000;
        const dateArray = [];
        if (!startDate || !endDate) return [];
        let currentDate = new Date(startDate);
        while (
          new Date(currentDate.toDateString()) <=
          new Date(endDate.toDateString())
        ) {
          dateArray.push(currentDate.toISOString().split('T')[0]);
          currentDate = new Date(currentDate.getTime() + day);
        }
        return dateArray;
      };

      const convertDateFormat = (date) => {
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${year}`;
      };

      const allDates = getAllDatesBetween(earliestDate, latestDate).map(
        (date) => convertDateFormat(date),
      );

      return {
        allDates: allDates,
      };
    },
  },
  watch: {
    richtlinien(val) {
      if (val !== null) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
    data: {
      handler() {
        this.$nextTick(() => {
          this.createChart();
        });
      },
      deep: true,
    },
    selectedInterval: {
      immediate: true,
      handler(val) {
        this.yearData = [];
        if (val !== null) {
          this.$nextTick(() => {
            this.createChart();
          });
        }
      },
    },
    openMilestonesIndices: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.createChart();
        });
      },
    },
  },
  mounted() {
    const scrollElement = document.querySelector('.chart-wrapper');
    scrollElement.addEventListener('scroll', this.handleScroll);

    window.addEventListener('resize', this.createChart);
  },
  beforeUnmount() {
    const scrollElement = document.querySelector('.chart-wrapper');
    scrollElement.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    hasAnyDate(dataObj) {
      for (let mapConfig of this.dataMap) {
        for (let dateConfig of mapConfig.dates) {
          let dateCount = 0;
          for (let dateValue of dateConfig.values) {
            if (
              this.getValueFromKeyTree(
                mapConfig.key,
                dateValue.keyTree,
                dataObj,
              )
            ) {
              dateCount++;
              if (dateCount === 2) return true;
            }
          }
        }
      }
      return false;
    },
    getValueFromKeyTree(rootKey, keyTree, dataObj) {
      let obj = dataObj[rootKey];
      for (let key of keyTree) {
        if (!isNaN(key) && !this.richtlinien.includes(parseInt(key)))
          return null;
        if (typeof obj === 'object' && key in obj) {
          obj = obj[key];
        } else {
          throw Error(`key ${key} does not exist in object ${obj}`);
        }
      }
      return obj;
    },
    getAllDates(dataObj) {
      let data = [];
      for (let mapConfig of this.dataMap) {
        let dates = this.getDates(mapConfig, dataObj);
        if (dates) data.push(dates);
      }
      return data;
    },
    getDates(mapConfig, dataObj) {
      let allDates = [];
      for (let dateConfig of mapConfig.dates) {
        let localDates = [];
        for (let dateValue of dateConfig.values) {
          let date = this.getValueFromKeyTree(
            mapConfig.key,
            dateValue.keyTree,
            dataObj,
          );
          if (date) {
            localDates.push({ date, name: dateValue.name });
          }
        }
        if (localDates.length >= 2) {
          localDates.sort((a, b) => new Date(a.date) - new Date(b.date));
          allDates.push({
            start: localDates[0].date,
            end: localDates[localDates.length - 1].date,
            dates: localDates,
            color: dateConfig.color,
          });
        }
      }

      return allDates.length === 0 ? false : allDates;
    },

    is53WeekYear(year) {
      const firstDayOfYear = new Date(year, 0, 1).getDay();
      const isLeapYear = new Date(year, 1, 29).getMonth() === 1;
      return firstDayOfYear === 4 || (isLeapYear && firstDayOfYear === 3); // 4 = Thursday, 3 = Wednesday
    },

    getISOWeekNumber(date) {
      const dateCopy = new Date(date);
      dateCopy.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      dateCopy.setDate(dateCopy.getDate() + 3 - ((dateCopy.getDay() + 6) % 7));
      // January 4 is always in week 1.
      const week1 = new Date(dateCopy.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return (
        1 +
        Math.round(
          ((dateCopy.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7,
        )
      );
    },

    handleScroll(event) {
      this.onScroll(event);
      this.visibleHeaderItems();
    },
    onScroll(event) {
      this.sideBarScrollPosition = event.target.scrollTop;
      this.$nextTick(() => {
        this.createChart();
      });
    },
    updateParentScroll(scrollTop) {
      const container = document.querySelector('.chart-wrapper');
      container.scrollTop = scrollTop;
    },
    handleWheelEvent(event) {
      const container = document.querySelector('.chart-wrapper');
      const containerHeight = container.offsetHeight;
      if (containerHeight >= 500) {
        event.stopPropagation();
      }
    },
    visibleHeaderItems() {
      const constructionDataTableInfo = document.querySelector(
        '.construction-data-table-wrapper',
      );
      const chartWrapper = document.querySelector('.chart-wrapper');
      const headerItems = chartWrapper.querySelectorAll(
        '.chart-values .date-item',
      );

      // Filter the header items that are visible within the chart wrapper
      const visibleItems = Array.from(headerItems).filter((item) => {
        const rect = item.getBoundingClientRect();
        return (
          rect.left >= 0 + constructionDataTableInfo.offsetWidth &&
          rect.right <=
            chartWrapper.offsetWidth +
              constructionDataTableInfo.offsetWidth +
              22
        );
      });

      // Extract the unique years from the visible header items
      const uniqueYears = new Set();
      if (this.selectedInterval === 'weeks') {
        visibleItems.forEach((item) => {
          const intervalData = item.dataset.intervaldata;
          const year = intervalData.split('.')[1];
          uniqueYears.add(year);
        });
      } else if (this.selectedInterval === 'days') {
        visibleItems.forEach((item) => {
          const intervalData = item.dataset.intervaldata;
          const year = intervalData.split('.')[2];
          uniqueYears.add(year);
        });
      }

      // Convert the set of unique years to an array
      this.yearData = Array.from(uniqueYears);
    },

    isWeekend(date) {
      date = date.split('.').reverse().join('-');
      const day = new Date(date).getDay();
      return day === 0 || day === 6; // Sunday is 0, Saturday is 6
    },
    convertHeaderDate(date) {
      const [day, month, year] = date.split('.');
      return `${day}.${month}`;
    },

    createChart() {
      this.visibleHeaderItems();
      const days = document.querySelectorAll('.chart-values .date-item');
      const tasks = document.querySelectorAll('.chart-bar-item');
      const daysArray = [...days];

      tasks.forEach((el) => {
        const duration = el.dataset.duration.split('-');
        const startDay = duration[0];
        const endDay = duration[1];

        let left = 0;
        let width = 0;

        const filteredStartDay = daysArray.find(
          (day) => day.dataset.intervaldata === startDay,
        );
        left = filteredStartDay?.offsetLeft || 0;

        const filteredEndDay = daysArray.find(
          (day) => day.dataset.intervaldata === endDay,
        );
        width = filteredEndDay
          ? filteredEndDay.offsetLeft + filteredEndDay.offsetWidth - left
          : 0;
        // apply css
        el.style.left = `${left}px`;
        el.style.width = `${width}px`;
      });
    },
  },
};
</script>

<style lang="scss">
.chart-bar-item {
  position: absolute;
}

.tbody-table-custom {
  .chart-row {
    border-color: white !important;
  }

  &:last-child {
    .chart-row {
      border-color: #eceff1 !important;

      &:last-child {
        border-bottom-width: 0 !important;
        height: 23px !important;
      }
    }
  }
}

.gantt-chart-custom {
  border-color: #eceff1 !important;

  .date-item {
    font-weight: 600;
    font-size: 8px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.04em;
    color: #607d8b;
  }

  td {
    border: 1px !important;
  }
}
</style>
