<template>
  <div class="panel-content-row-wrapper-container">
    <div
      v-for="panelItem in localData"
      :key="panelItem"
      class="panel-content-row-wrapper">
      <div class="first-col-wrapper">{{ panelItem.col1 }}</div>
      <div class="other-cols-wrapper">
        <div
          v-for="col2item in panelItem.col2"
          :key="col2item"
          class="panel-content-item">
          {{ col2item }}
        </div>
      </div>
      <div class="other-cols-wrapper">
        <InputEl
          v-for="col3item in panelItem.col3"
          :key="col3item"
          v-model="col3item.input"
          input-type="float"
          :small="true"
          :placeholder="col3item.input"
          custom-background="bg-infra-highlight-25"
          :suffix="col3item.suffix" />
      </div>
    </div>
  </div>
</template>
<script>
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';

export default {
  components: { InputEl },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  emits: ['data-changed'],
  data() {
    return {
      localData: [],
    };
  },
  watch: {
    localData: {
      handler() {
        this.$emit('data-changed', this.localData);
      },
      deep: true,
    },
  },
  created() {
    // this.localData = stringiy(this.data);
    this.localData = JSON.parse(JSON.stringify(this.data));
  },
};
</script>
