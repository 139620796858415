export const LAYER_KEY__NOTES_POLYGON = 'notesPolygon';
export const LAYER_KEY__NOTES_LINE = 'notesLine';
export const LAYER_KEY__NOTES_POINT = 'notesPoint';
export const LAYER_SOURCE__NOTES = 'notes';

export const LAYER_KEY__DEVELOPMENT_AREA_POLYGON = 'developmentAreaPolygon';
export const LAYER_KEY__DEVELOPMENT_AREA_LINE = 'developmentAreaLine';
export const LAYER_SOURCE__DEVELOPMENT_AREA = 'developmentArea';

export const LAYER_KEY__COLAYING_LINE = 'coLayingLine';
export const LAYER_KEY__COLAYING_POINT = 'coLayingPoint';
export const LAYER_SOURCE__COLAYING = 'coLaying';

export const LAYER_KEY__ADDRESS_VALIDATION_POINT = 'addressValidationPoint';
export const LAYER_KEY__ADDRESS_VALIDATION_POLYGON = 'addressValidationPolygon';
export const LAYER_SOURCE__ADDRESS_VALIDATION = 'addressValidation';

export const LAYER_KEY__MEV_VALIDATION_POLYGON = 'mevValidationPolygon';
export const LAYER_SOURCE__MEV_VALIDATION = 'mevValidation';

function setFeatureStateForAllGeometries(click, feature, layers) {
  const id = feature.properties.id;
  const filter = ['==', id, ['get', 'id']];
  click.setForeignFeatureState(layers, filter);
  click.setFeatureState([feature], true);
}

const legendFactory = (name, color) => ({
  default: {
    name: 'Datenerfassung',
    unit: '',
    items: [
      {
        color,
        name,
        icon: 'mdi-square',
        size: '14px',
      },
    ],
  },
});

const ticketPolygonConfig = (
  layerKey,
  sourceKey,
  routerName,
  getter,
  color,
  name
) => ({
  layerConfig: {
    app_name: 'ticket',
    router_name: routerName,
    key_name: 'id',
    dataSourceFromStore: `ticket/${getter}`,
    geoJSONSource: true,
    type: 'polygon',
    id: layerKey,
    source: sourceKey,
    visibility: 'none',
    filter: ['==', ['geometry-type'], 'Polygon'],
    hoverPointer: true,
  },
  style: {
    default: {
      fillColor: color,
      fillOpacity: 1,
      fillOutlineColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        'rgb(96,189,255)',
        '#494949',
      ],
    },
  },
  legend: legendFactory(name, color),
});

const ticketPointConfig = (
  layerKey,
  sourceKey,
  routerName,
  getter,
  color,
  name
) => ({
  layerConfig: {
    app_name: 'ticket',
    router_name: routerName,
    key_name: 'id',
    dataSourceFromStore: `ticket/${getter}`,
    geoJSONSource: true,
    type: 'point',
    id: layerKey,
    source: sourceKey,
    visibility: 'none',
    filter: ['==', ['geometry-type'], 'Point'],
    hoverPointer: true,
  },
  style: {
    default: {
      circleColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        'rgb(96,189,255)',
        color,
      ],
      circleStrokeWidth: 0.1,
      circleRadius: {
        stops: [
          [1, 0],
          [9, 2],
          [12, 4],
        ],
      },
      circleStrokeColor: '#000000',
    },
  },
  legend: legendFactory(name, color),
});

const ticketLineConfig = (
  layerKey,
  sourceKey,
  routerName,
  getter,
  color,
  name
) => ({
  layerConfig: {
    app_name: 'ticket',
    router_name: routerName,
    key_name: 'id',
    dataSourceFromStore: `ticket/${getter}`,
    geoJSONSource: true,
    type: 'line',
    id: layerKey,
    source: sourceKey,
    visibility: 'none',
    filter: ['==', ['geometry-type'], 'LineString'],
    hoverPointer: true,
  },
  style: {
    default: {
      lineColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        'rgb(96,189,255)',
        color,
      ],
      lineWidth: 2,
    },
  },
  legend: legendFactory(name, color),
});

const noteSidebar = {
  name: 'Notiz',
  metaData: [{ key: 'username' }, { key: 'created_at' }],
  introText: { key: 'content' },
  buttons: [
    {
      label: 'Löschen',
      critical: true,
      actionOnClick: 'ticket/DELETE_NOTE',
      entityId: { key: 'id' },
      successMessage: 'Notiz erfolgreich gelöscht',
      failureMessage: 'Notiz konnte nicht gelöscht werden',
      onSuccessCommit: 'map/SET_SHOW_DATA_PANEL',
      onSuccessCommitPayload: false,
    },
  ],
  chips: [],
};

const developmentAreaSidebar = {
  name: 'Neubaugebiet',
  subtitles: [{ key: 'ticket_name' }],
  metaData: [{ key: 'username' }, { key: 'created_at' }],
  comments: {
    comments: { key: 'comments' },
    actionOnPost: 'ticket/ADD_DEVELOPMENT_AREA_COMMENT',
    entityId: { key: 'id' },
    successMessage: 'Kommentar erfolgreich hinzugefügt',
    failureMessage: 'Kommentar konnte nicht hinzugefügt werden',
    followUpCommit: 'map/ADD_COMMENT_TO_ACTIVE_MEASUREMENT',
  },
  chips: [
    {
      title: 'Info',
      active: true,
      items: [
        {
          title: '',
          active: true,
          items: [
            {
              key: 'construction_date_start',
              unit: '',
              value: '-',
            },
            {
              key: 'construction_date_end',
              unit: '',
              value: '-',
            },
            {
              key: 'amount_units',
              unit: '',
              value: '-',
            },
            {
              key: 'amount_plots',
              unit: '',
              value: '-',
            },
            {
              key: 'amount_commercial_units',
              unit: '',
              value: '-',
            },
            {
              key: 'size_sq_m',
              unit: 'm²',
              value: '-',
            },
          ],
        },
      ],
    },
  ],
};

const coLayingSidebar = {
  name: 'Mitverlegung',
  subtitles: [{ key: 'ticket_name' }],
  metaData: [{ key: 'username' }, { key: 'created_at' }],
  comments: {
    comments: { key: 'comments' },
    actionOnPost: 'ticket/ADD_CO_LAYING_COMMENT',
    entityId: { key: 'id' },
    successMessage: 'Kommentar erfolgreich hinzugefügt',
    failureMessage: 'Kommentar konnte nicht hinzugefügt werden',
    followUpCommit: 'map/ADD_COMMENT_TO_ACTIVE_MEASUREMENT',
  },
  chips: [
    {
      title: 'Info',
      active: true,
      items: [
        {
          title: '',
          active: true,
          items: [
            {
              key: 'construction_date_start',
              unit: '',
              value: '-',
            },
            {
              key: 'construction_date_end',
              unit: '',
              value: '-',
            },
            {
              key: 'responsible_construction_company',
              unit: '',
              value: '-',
            },
            {
              key: 'length_m',
              unit: 'm',
              value: '-',
            },
          ],
        },
      ],
    },
  ],
};

const addressValidationSidebar = {
  name: 'Adress-Validierung',
  buttons: [
    {
      label: 'Löschen',
      critical: true,
      actionOnClick: 'ticket/DELETE_ADDRESS_VALIDATION',
      entityId: { key: 'id' },
      successMessage: 'Adress-Validierung erfolgreich gelöscht',
      failureMessage: 'Adress-Validierung konnte nicht gelöscht werden',
      onSuccessCommit: 'map/SET_SHOW_DATA_PANEL',
      onSuccessCommitPayload: false,
    },
  ],
  metaData: [{ key: 'username' }, { key: 'created_at' }],
  longSubtitles: [{ key: 'content' }, { key: 'category' }, { key: 'reason' }],
  chips: [],
};

const mevValidationSidebar = {
  name: 'MEV-Validierung',
  metaData: [{ key: 'username' }, { key: 'created_at' }],
  buttons: [
    {
      label: 'Ticket abschließen',
      show: {
        key: 'status',
        ifValue: ['Offen', 'In Bearbeitung'],
      },
      critical: false,
      actionOnClick: 'ticket/CLOSE_MEV_VALIDATION',
      entityId: { key: 'id' },
      successMessage: 'MEV-Validierung erfolgreich abgeschlossen',
      failureMessage: 'MEV-Validierung konnte nicht abgeschlossen werden',
      onSuccessCommit: 'map/SET_SHOW_DATA_PANEL',
      onSuccessCommitPayload: false,
    },
    {
      label: 'Löschen',
      critical: true,
      actionOnClick: 'ticket/DELETE_MEV_VALIDATION',
      entityId: { key: 'id' },
      successMessage: 'MEV-Validierung erfolgreich gelöscht',
      failureMessage: 'MEV-Validierung konnte nicht gelöscht werden',
      onSuccessCommit: 'map/SET_SHOW_DATA_PANEL',
      onSuccessCommitPayload: false,
    },
  ],
  longSubtitles: [{ key: 'category' }, { key: 'content' }],
  chips: [],
  statusHeader: {
    items: [
      {
        key: 'status',
        ifValue: 'Offen',
        iconComponent: 'mev-validation-in-process-icon',
        text: 'Ticket offen',
      },
      {
        key: 'status',
        ifValue: 'In Bearbeitung',
        iconComponent: 'mev-validation-in-process-icon',
        text: 'Ticket in Bearbeitung',
      },
      {
        key: 'status',
        ifValue: 'Abgeschlossen',
        iconComponent: 'mev-validation-in-process-icon',
        text: 'Ticket abgeschlossen',
      },
      {
        key: 'status',
        ifValue: 'Unbekannt',
        iconComponent: 'mev-validation-in-process-icon',
        text: 'Ticketstatus unbekannt',
      },
    ],
  },
};

export default {
  [LAYER_KEY__NOTES_POLYGON]: {
    layoutState: 'default',
    ...ticketPolygonConfig(
      LAYER_KEY__NOTES_POLYGON,
      LAYER_SOURCE__NOTES,
      'Note',
      'getNotes',
      'rgba(255,152,0,0.7)',
      'Notiz'
    ),
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__NOTES_LINE,
        LAYER_KEY__NOTES_POINT,
      ]);
    },
    sidebar: noteSidebar,
  },
  [LAYER_KEY__NOTES_LINE]: {
    layoutState: 'default',
    ...ticketLineConfig(
      LAYER_KEY__NOTES_LINE,
      LAYER_SOURCE__NOTES,
      'Note',
      'getNotes',
      '#FF9800',
      'Notiz'
    ),
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__NOTES_POLYGON,
        LAYER_KEY__NOTES_POINT,
      ]);
    },
    sidebar: noteSidebar,
  },
  [LAYER_KEY__NOTES_POINT]: {
    layoutState: 'default',
    ...ticketPointConfig(
      LAYER_KEY__NOTES_POINT,
      LAYER_SOURCE__NOTES,
      'Note',
      'getNotes',
      '#FF9800',
      'Notiz'
    ),
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__NOTES_LINE,
        LAYER_KEY__NOTES_POLYGON,
      ]);
    },
    sidebar: noteSidebar,
  },
  [LAYER_KEY__DEVELOPMENT_AREA_POLYGON]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'ticket',
      router_name: 'DevelopmentArea',
      key_name: 'id',
      dataSourceFromStore: `ticket/getDevelopmentAreas`,
      geoJSONSource: true,
      type: 'polygon',
      id: LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
      source: LAYER_SOURCE__DEVELOPMENT_AREA,
      visibility: 'none',
      filter: ['==', ['geometry-type'], 'Polygon'],
      hoverPointer: true,
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['==', ['get', 'status'], 'Offen'],
          'rgba(255,0,0,0.7)',
          ['==', ['get', 'status'], 'In Bearbeitung'],
          'rgba(251, 192, 147,0.7)',
          ['==', ['get', 'status'], 'Abgeschlossen'],
          'rgba(0,128,0,0.7)',
          ['==', ['get', 'status'], 'Unbekannt'],
          'rgba(108,122,137,0.7)',
          'rgba(63,81,181,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          'rgb(96,189,255)',
          '#494949',
        ],
      },
    },
    legend: {
      default: {
        name: 'Datenerfassung',
        unit: '',
        items: [
          {
            color: 'rgba(255,0,0,0.7)',
            name: 'Offen',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(251, 192, 147,0.7)',
            name: 'In Bearbeitung',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(0,128,0,0.7)',
            name: 'Abgeschlossen',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(108,122,137,0.7)',
            name: 'Unbekannt',
            icon: 'mdi-square',
            size: '14px',
          },
        ],
      },
    },
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__DEVELOPMENT_AREA_LINE,
      ]);
    },
    sidebar: developmentAreaSidebar,
  },
  [LAYER_KEY__DEVELOPMENT_AREA_LINE]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'ticket',
      router_name: 'DevelopmentArea',
      key_name: 'id',
      dataSourceFromStore: `ticket/getDevelopmentAreas`,
      geoJSONSource: true,
      type: 'line',
      id: LAYER_KEY__DEVELOPMENT_AREA_LINE,
      source: LAYER_SOURCE__DEVELOPMENT_AREA,
      visibility: 'none',
      filter: ['==', ['geometry-type'], 'LineString'],
      hoverPointer: true,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['==', ['get', 'status'], 'Offen'],
          'rgba(255,0,0,0.7)',
          ['==', ['get', 'status'], 'In Bearbeitung'],
          'rgba(251, 192, 147,0.7)',
          ['==', ['get', 'status'], 'Abgeschlossen'],
          'rgba(0,128,0,0.7)',
          ['==', ['get', 'status'], 'Unbekannt'],
          'rgba(108,122,137,0.7)',
          'rgba(63,81,181,0.7)',
        ],
        lineWidth: 2,
      },
    },
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
      ]);
    },
    sidebar: developmentAreaSidebar,
  },
  [LAYER_KEY__COLAYING_LINE]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'ticket',
      router_name: 'CoLaying',
      key_name: 'id',
      dataSourceFromStore: `ticket/getCoLayings`,
      geoJSONSource: true,
      type: 'line',
      id: LAYER_KEY__COLAYING_LINE,
      source: LAYER_SOURCE__COLAYING,
      visibility: 'none',
      filter: ['==', ['geometry-type'], 'LineString'],
      hoverPointer: true,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['==', ['get', 'status'], 'Offen'],
          'rgba(255,0,0,0.7)',
          ['==', ['get', 'status'], 'In Bearbeitung'],
          'rgba(251, 192, 147,0.7)',
          ['==', ['get', 'status'], 'Abgeschlossen'],
          'rgba(0,128,0,0.7)',
          ['==', ['get', 'status'], 'Unbekannt'],
          'rgba(108,122,137,0.7)',
          'rgba(63,81,181,0.7)',
        ],
        lineWidth: 2,
      },
    },
    legend: {
      default: {
        name: 'Datenerfassung',
        unit: '',
        items: [
          {
            color: 'rgba(255,0,0,0.7)',
            name: 'Offen',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(251, 192, 147,0.7)',
            name: 'In Bearbeitung',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(0,128,0,0.7)',
            name: 'Abgeschlossen',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(108,122,137,0.7)',
            name: 'Unbekannt',
            icon: 'mdi-square',
            size: '14px',
          },
        ],
      },
    },
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__COLAYING_POINT,
      ]);
    },
    sidebar: coLayingSidebar,
  },
  [LAYER_KEY__COLAYING_POINT]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'ticket',
      router_name: 'CoLaying',
      key_name: 'id',
      dataSourceFromStore: `ticket/getCoLayings`,
      geoJSONSource: true,
      type: 'point',
      id: LAYER_KEY__COLAYING_POINT,
      source: LAYER_SOURCE__COLAYING,
      visibility: 'none',
      filter: ['==', ['geometry-type'], 'Point'],
      hoverPointer: true,
    },
    style: {
      default: {
        circleColor: [
          'case',
          ['==', ['get', 'status'], 'Offen'],
          'rgba(255,0,0,0.7)',
          ['==', ['get', 'status'], 'In Bearbeitung'],
          'rgba(251, 192, 147,0.7)',
          ['==', ['get', 'status'], 'Abgeschlossen'],
          'rgba(0,128,0,0.7)',
          ['==', ['get', 'status'], 'Unbekannt'],
          'rgba(108,122,137,0.7)',
          'rgba(63,81,181,0.7)',
        ],
        circleStrokeWidth: 0.1,
        circleRadius: {
          stops: [
            [1, 0],
            [9, 2],
            [12, 4],
          ],
        },
        circleStrokeColor: '#000000',
      },
    },
    onClick(click, feature) {
      setFeatureStateForAllGeometries(click, feature, [
        LAYER_KEY__COLAYING_LINE,
      ]);
    },
    sidebar: coLayingSidebar,
  },
  [LAYER_KEY__ADDRESS_VALIDATION_POINT]: {
    layoutState: 'default',
    ...ticketPointConfig(
      LAYER_KEY__ADDRESS_VALIDATION_POINT,
      LAYER_SOURCE__ADDRESS_VALIDATION,
      'AddressValidation',
      'getAddressValidation',
      'rgb(0,188,212)',
      'Adress-Validierung'
    ),
    sidebar: addressValidationSidebar,
  },
  [LAYER_KEY__ADDRESS_VALIDATION_POLYGON]: {
    layoutState: 'default',
    ...ticketPolygonConfig(
      LAYER_KEY__ADDRESS_VALIDATION_POLYGON,
      LAYER_SOURCE__ADDRESS_VALIDATION,
      'AddressValidation',
      'getAddressValidation',
      'rgba(0,188,212,0.6)',
      'Adress-Validierung'
    ),
    sidebar: addressValidationSidebar,
  },
  [LAYER_KEY__MEV_VALIDATION_POLYGON]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'ticket',
      router_name: 'MEVValidation',
      key_name: 'id',
      dataSourceFromStore: `ticket/getMevValidation`,
      geoJSONSource: true,
      type: 'polygon',
      id: LAYER_KEY__MEV_VALIDATION_POLYGON,
      source: LAYER_SOURCE__MEV_VALIDATION,
      visibility: 'none',
      filter: ['==', ['geometry-type'], 'Polygon'],
      hoverPointer: true,
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['==', ['get', 'status'], 'Offen'],
          'rgba(255,0,0,0.7)',
          ['==', ['get', 'status'], 'In Bearbeitung'],
          'rgba(251, 192, 147,0.7)',
          ['==', ['get', 'status'], 'Abgeschlossen'],
          'rgba(0,128,0,0.7)',
          ['==', ['get', 'status'], 'Unbekannt'],
          'rgba(108,122,137,0.7)',
          'rgba(63,81,181,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          'rgb(96,189,255)',
          '#494949',
        ],
      },
    },
    legend: {
      default: {
        name: 'Datenerfassung',
        unit: '',
        items: [
          {
            color: 'rgba(255,0,0,0.7)',
            name: 'Offen',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(251, 192, 147,0.7)',
            name: 'In Bearbeitung',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(0,128,0,0.7)',
            name: 'Abgeschlossen',
            icon: 'mdi-square',
            size: '14px',
          },
          {
            color: 'rgba(108,122,137,0.7)',
            name: 'Unbekannt',
            icon: 'mdi-square',
            size: '14px',
          },
        ],
      },
    },
    sidebar: mevValidationSidebar,
  },
};
