<template>
  <DashboardContentLayout>
    <div class="flex flex-col gap-8 p-5">
      <!-- title-->
      <div
        id="grants-title-table"
        class="flex gap-3 items-center justify-start">
        <img src="@/assets/svg/foerderantraege.svg" alt="Logo" />
        <h2>Förderanträge</h2>
      </div>

      <!-- table-->
      <VuetifyDataTable
        class="grant-table-custom"
        :headers="headers"
        :item-data="getProcessedGrantApplications"
        :reset-expansion="resetExpansion"
        :show-pagination="true"
        :show-total="true"
        :expand-on-row-click="true"
        @update:options="currentPage = $event.page"
        @expanded="expanded = $event">
        <template #name="{ item }">
          <InputEl
            v-if="enableEditMode && item.id === expanded[0]"
            class="-ml-[11px]"
            :placeholder="item.name"
            :rules="{ required: true }"
            custom-background="bg-infra-highlight-25" />
          <div v-else>{{ item.name }}</div>
        </template>

        <template #regime="{ item }">
          <div class="flex justify-start">
            {{ item.regime }}
          </div>
        </template>

        <template #investment_amount="{ item }">
          <div class="flex justify-end">
            {{ item.investment_amount_formatted }}
          </div>
        </template>

        <template #checkmark_bund="{ item }">
          <div class="ml-3">
            <v-tooltip :text="item.checkmark_bund_tooltip" location="top">
              <template #activator="{ props }">
                <img
                  v-bind="props"
                  :src="checkmarkMap[item.checkmark_bund]"
                  alt="progress-icon" />
              </template>
            </v-tooltip>
          </div>
        </template>

        <template #checkmark_land="{ item }">
          <div class="ml-3">
            <v-tooltip :text="item.checkmark_land_tooltip" location="top">
              <template #activator="{ props }">
                <img
                  v-bind="props"
                  :src="checkmarkMap[item.checkmark_land]"
                  alt="progress-icon" />
              </template>
            </v-tooltip>
          </div>
        </template>

        <template #functions="{ item }">
          <div class="flex justify-center">
            <div
              :class="
                item.download_available ? 'cursor-pointer' : 'opacity-30'
              ">
              <arrow-down-line
                fill="#001257"
                @click="
                  (e) => downloadCostPlanning(item.reference_number, e)
                " />
            </div>
          </div>
        </template>

        <template #expanded-row="{ item }">
          <div class="flex flex-col gap-[10px] p-[10px]">
            <grant-edit-steps v-if="enableEditMode" />
            <grant-cards :data="item" />
            <grant-scenario-selection v-if="enableEditMode" />
            <div v-if="!enableEditMode" class="grid grid-cols-3 gap-[10px]">
              <!--              <button-el :success="true" text="Datenübertragung an CRM" />-->
              <a :href="item.link" target="_blank">
                <button-el :primary="false" text="Nebenbestimmungen" />
              </a>
              <!--              <button-el-->
              <!--                text="Antrag bearbeiten"-->
              <!--                @click="enableEditMode = true" />-->
            </div>
            <div v-else class="grid grid-cols-3 gap-[10px]">
              <div class="flex gap-[10px]">
                <button-el
                  :primary="false"
                  text="Abbrechen"
                  :full-width="true"
                  @click="enableEditMode = false" />
                <button-el
                  :primary="false"
                  :full-width="true"
                  text="Nebenbestimmungen" />
              </div>
              <button-el
                text="Antrag speichern"
                :icon-right="true"
                icon="archive-check-small-icon"
                @click="enableEditMode = false" />
              <button-el
                :success="true"
                text="Datenübertragung an CRM"
                :icon-right="true"
                icon="open-in-new" />
            </div>
          </div>
        </template>
      </VuetifyDataTable>
      <span style="font-size: 10px"
        >* Die Antragstellung erfolgte nicht durch die OEW Breitband GmbH. Die
        Wirtschaftlichkeitsberechnung mit Investitionssumme etc. entstammt der
        algorithmischen Grobplanung und unterscheidet sich ggf. von den
        beantragten Mengen, Massen, Kosten und Erlösen.</span
      >
    </div>
  </DashboardContentLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { formatNumber } from '@/utils/formatUtils';
import { saveAs } from 'file-saver';
import { axios } from '@/utils/axiosHelper';

export default {
  data() {
    return {
      loaded: false,
      expanded: [],
      lastExpanded: null,
      step: 1,
      step1IsValid: false,
      expandedContentLoaded: false,
      showGrantApplication: false,
      enableEditMode: false,
      resetExpansion: false,
      showScenarioSelection: false,
      grantEditStep: 1,
      currentPage: 0,
      checkmarkMap: [
        new URL('@/assets/svg/check-grey-empty.svg', import.meta.url),
        new URL('@/assets/svg/check-grey.svg', import.meta.url),
        new URL('@/assets/svg/progress-icon.svg', import.meta.url),
      ],
      headers: [
        {
          title: 'Name',
          key: 'name',
        },
        {
          title: 'Förder-regime',
          key: 'funding_regime',
          align: 'end',
          width: '120px',
        },
        {
          title: 'Investitions-summe',
          key: 'investment_amount',
          align: 'end',
          width: '145px',
        },
        {
          title: 'Bund',
          key: 'checkmark_bund',
          align: 'center',
          width: '95px',
        },
        {
          title: 'Land',
          key: 'checkmark_land',
          align: 'center',
          width: '95px',
        },
        {
          title: 'Download Grobkosten',
          key: 'functions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('grantApplication', ['getGrantApplications']),
    getProcessedGrantApplications() {
      let processedGrantApplications = this.getGrantApplications;

      for (let application of processedGrantApplications) {
        // gemeinde format
        if (!application.gemeinde_amount) {
          application.gemeinde_amount = '-';
        }

        // gemarkung format
        if (!application.gemarkungen_amount) {
          application.gemarkungen_amount = '-';
        }

        // pop format
        if (!application.pop_amount) {
          application.pop_amount = '-';
        }

        // invest format
        if (application.investment_amount) {
          application.investment_amount_formatted = this.toMEuro(
            application.investment_amount
          );
          if (application.application_made_by !== 'OEW Breitband') {
            application.investment_amount_formatted += '*';
          }
        } else {
          application.investment_amount_formatted = '-';
        }

        // route meter format
        if (application.route_meter) {
          application.route_meter =
            formatNumber(application.route_meter, 0) + ' m';
        } else {
          application.route_meter = '-';
        }

        // client amount format
        if (application.clients_amount) {
          application.clients_amount = formatNumber(
            application.clients_amount,
            0
          );
        } else {
          application.clients_amount = '-';
        }

        // home connections amount format
        if (application.home_connections_amount) {
          application.home_connections_amount = formatNumber(
            application.home_connections_amount,
            0
          );
        } else {
          application.home_connections_amount = '-';
        }

        // own capital format
        if (application.own_capital) {
          application.own_capital = this.toMEuro(application.own_capital);
        } else {
          application.own_capital = '-';
        }

        // subsidies format
        if (application.subsidies) {
          application.subsidies = this.toMEuro(application.subsidies);
        } else {
          application.subsidies = '-';
        }

        // revenues format
        if (application.revenues) {
          application.revenues = {
            value: this.toMEuro(application.revenues),
            tooltip: 'Jährliche Erlöse nach Zweckbindungsfrist',
          };
        } else {
          application.revenues = '-';
        }

        // funding rate format
        if (application.funding_rate) {
          application.funding_rate =
            formatNumber(application.funding_rate * 100, 2) + ' %';
        } else {
          application.funding_rate = '-';
        }

        // investment_amount_per_home_connection format
        if (application.investment_amount_per_home_connection) {
          application.investment_amount_per_home_connection = formatNumber(
            application.investment_amount_per_home_connection,
            2
          );
        } else {
          application.investment_amount_per_home_connection = '-';
        }

        // bund checkmark
        if (application.bund_notice_from_date) {
          application.checkmark_bund = 2;
          application.checkmark_bund_tooltip = `Antrag vrl. bewilligt am ${dayjs(
            application.bund_notice_from_date
          ).format('DD.MM.YYYY')}`;
        } else {
          application.checkmark_bund = 1;
          application.checkmark_bund_tooltip = `Antrag gestellt am ${dayjs(
            application.bund_application_from_date
          ).format('DD.MM.YYYY')}`;
        }
        // land checkmark
        if (application.land_notice_from_date) {
          application.checkmark_land = 2;
          application.checkmark_land_tooltip = `Antrag vrl. bewilligt am ${dayjs(
            application.land_notice_from_date
          ).format('DD.MM.YYYY')}`;
        } else if (application.land_application_from_date) {
          application.checkmark_land = 1;
          application.checkmark_land_tooltip = `Antrag gestellt am ${dayjs(
            application.land_application_from_date
          ).format('DD.MM.YYYY')}`;
        } else {
          application.checkmark_land = 0;
          application.checkmark_land_tooltip = 'Antrag noch nicht gestellt';
        }
      }
      return processedGrantApplications;
    },
  },

  watch: {
    currentPage() {
      this.$nextTick(() => {
        document.querySelector('#grants-title-table').scrollIntoView();
      });
    },
    enableEditMode(val) {
      this.lastExpanded = val ? this.expanded[0] : this.lastExpanded;
    },
    expanded(val) {
      this.enableEditMode = this.lastExpanded === val[0];
    },
    showGrantApplication(val) {
      if (val) {
        this.enableEditMode = false;
        this.resetExpansion = true;
      } else {
        this.resetExpansion = false;
      }
    },
  },
  created() {
    this.$store.dispatch('grantApplication/GET_GRANT_APPLICATIONS');
  },
  methods: {
    toMEuro(value) {
      if (value === 0 || value === null || value === undefined) {
        return '--';
      }
      return (
        (value / 1000000).toLocaleString('de-DE', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + 'M €'
      );
    },
    downloadCostPlanning(referenceNumber, e) {
      e.stopPropagation();
      axios({
        url: `/api/funding/download_cost_planning/?reference_number=${referenceNumber}`,
        method: 'GET',
        responseType: 'blob',
      })
        .then((resp) => {
          saveAs(
            new Blob([resp.data]),
            `${referenceNumber.replace('/', '_')}.xlsx`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss"></style>
