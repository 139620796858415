<template>
  <single-page>
    <v-dialog v-model="downloadDialogVisible" width="auto">
      <div class="flex flex-col gap-5 bg-white p-5 rounded-md">
        <div>
          <div>
            <p>
              Daten werden pro Gemeinde bereitgestellt und beinhalten folgendes:
            </p>
            <v-list lines="one">
              <v-list-item
                key="1"
                title="&bull; Finale Analyse und Grundlage für die Balkendiagramme" />
              <v-list-item
                key="2"
                title="&bull; GIS-Daten der finalen Analyse" />
              <v-list-item key="3" title="&bull; Rohdaten als Upload" />
            </v-list>
            <br />
            <p>
              Bitte wählen Sie eine Gemeinde aus und starten Sie den Download
              mittels Klick auf den Button
            </p>
          </div>
        </div>
        <div class="flex flex-row gap-5">
          <div class="flex-grow-1">
            <DropDown
              v-model="selectedGemeinde"
              :items-data="availableGemeindeForDownload"
              items-data-key="name"
              value="ags" />
          </div>
          <div class="flex-grow-0">
            <button-el
              :primary="true"
              text="Herunterladen"
              :disabled="!selectedGemeinde"
              @click="startDownload" />
          </div>
        </div>
        <div class="flex flex-row gap-5">
          <the-label
            label="Hinweis: In der Liste befinden sich lediglich Gemeinden, für die MEV-Daten (Gigabit 2.0) verfügbar sind, und auf denen Sie Zugriff haben." />
        </div>
      </div>
    </v-dialog>
    <div class="flex flex-col gap-5 p-5">
      <!-- title-->
      <div class="flex gap-3 items-center justify-start">
        <img src="@/assets/svg/dashboard.svg" alt="Logo" />
        <h2>Dashboard</h2>
      </div>
      <div class="flex justify-between items-center">
        <div class="flex gap-2">
          <div class="w-[300px]">
            <drop-down-multiple
              v-model="selectedLandkreise"
              class="w-[100%]"
              item-type-all="Landkreise"
              :max-height-dropdown="250"
              label="Landkreise Filter"
              custom-background="bg-infra-highlight-25"
              :items="getLandkreise" />
          </div>
        </div>
        <div class="mt-auto">
          <button-el
            :primary="false"
            text="Daten herunterladen"
            @click="showDownloadDialog" />
        </div>
      </div>
      <!-- tabs-->
      <div class="analytics-tabs">
        <v-tabs v-model="tab" class="border-custom-after">
          <v-tab
            :disabled="true"
            :class="tabStyle"
            value="general"
            :ripple="false"
            ><h4>Allgemein</h4>
          </v-tab>
          <v-tab :class="tabStyle" value="mev" :ripple="false"
            ><h4>MEV</h4>
          </v-tab>
          <v-tooltip text="Verfügbar ab Baubeginn">
            <template #activator="{ props }">
              <div v-bind="props">
                <v-tab
                  :disabled="true"
                  :class="tabStyle"
                  value="progress"
                  :ripple="false"
                  ><h4>Baufortschritt</h4>
                </v-tab>
              </div>
            </template>
          </v-tooltip>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item value="general"> One</v-window-item>

          <v-window-item value="mev">
            <mev-window-item :landkreise="selectedLandkreise" />
          </v-window-item>

          <v-window-item value="progress">
            <progress-window-item />
          </v-window-item>
        </v-window>
      </div>
    </div>
  </single-page>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      tab: 'mev',
      selectedLandkreise: [],
      expandedContentLoaded: false,
      downloadDialogVisible: false,
      selectedGemeinde: null,
      availableGemeindeForDownload: [],
    };
  },
  computed: {
    ...mapGetters('ticket', ['getMevDashboardData']),
    getLandkreise() {
      const landkreisNames =
        this.getMevDashboardData?.data?.values?.map(
          (data) => data.name.landkreis_name || 'Unbekannt'
        ) || [];
      let landkreise = [...new Set(landkreisNames)];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedLandkreise = landkreise;
      return landkreise;
    },
    getGemeinden() {
      const gemeindeNames =
        this.getMevDashboardData?.data?.values?.map((data) => {
          return {
            name: data.name.gemeinde_name || 'Unbekannt',
            ags: data.gemeinde_id,
          };
        }) || [];
      gemeindeNames.sort((a, b) => a.name.localeCompare(b.name));
      return gemeindeNames;
    },
    tabStyle() {
      return 'ml-0 px-2 normal-case hover:text-infra-highlight-500 tracking-normal';
    },
  },
  methods: {
    showDownloadDialog() {
      this.selectedGemeinde = null;
      this.downloadDialogVisible = true;
      this.$store.dispatch('map/GET_DOWNLOADABLE_GEMEINDEN').then((resp) => {
        this.availableGemeindeForDownload = resp.gemeinden;
      });
    },
    hideDownloadDialog() {
      this.downloadDialogVisible = false;
    },
    startDownload() {
      this.$store
        .dispatch('map/DOWNLOAD_MEV_FILE', this.selectedGemeinde)
        .then((resp) => {
          this.hideDownloadDialog();
        });
    },
  },
};
</script>

<style lang="scss">
//import main.scss from storybook
@import '@/components/storybook/src/assets/styles/index.scss';

.analytics-tabs {
  .v-slide-group__container {
    z-index: 1;
  }

  .border-custom-after {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #e0e0e0;
    }
  }

  button.v-tab {
    @apply rounded-none;
    &.v-slide-group-item--active {
      h4 {
        @apply text-infra-highlight-700;
      }

      .v-tab__slider {
        @apply bg-infra-highlight-700;
      }
    }
  }

  .v-window,
  .v-card {
    overflow: visible;
  }

  .v-tab {
    min-width: unset !important;

    h4 {
      @apply text-blue-grey-500;
    }
  }
}
</style>
