<template>
  <single-page>
    <div class="flex flex-col gap-8 p-5">
      <!-- title-->
      <div class="flex gap-3 items-center justify-start">
        <img src="@/assets/svg/foerderantraege.svg" alt="Logo" />
        <h2>Förderanträge</h2>
      </div>

      <!-- table-->
      <VuetifyDataTable
        :headers="headers"
        :item-data="getGrantApplications"
        :reset-expansion="resetExpansion"
        :show-pagination="false"
        @expanded="expanded = $event">
        <template #name="item">
          <InputEl
            v-if="enableEditMode && item.item.id === expanded[0]"
            class="-ml-[11px]"
            :placeholder="item.item.name"
            :rules="{ required: true }"
            custom-background="bg-infra-highlight-25" />
          <div v-else>{{ item.item.name }}</div>
        </template>

        <template #sum_invest="item">
          <div class="flex justify-end">
            {{ toMEuro(item.item.sum_invest) }}
          </div>
        </template>

        <template #checkmark_bund="item">
          <div class="flex items-center justify-center">
            <img
              :src="getIconPath(item.item.checkmark_bund)"
              alt="progress-icon" />
          </div>
        </template>

        <template #checkmark_land="item">
          <div class="flex items-center justify-center">
            <img
              :src="getIconPath(item.item.checkmark_land)"
              alt="progress-icon" />
          </div>
        </template>

        <template #functions>
          <div class="flex justify-center">
            <div class="opacity-60 cursor-pointer">
              <arrow-down-line fill="#001257" />
            </div>
          </div>
        </template>

        <template #expanded-row>
          <div class="flex flex-col gap-[10px] p-[10px]">
            <grant-edit-steps v-if="enableEditMode" />
            <grant-cards />
            <grant-scenario-selection v-if="enableEditMode" />
            <div v-if="!enableEditMode" class="grid grid-cols-3 gap-[10px]">
              <button-el :success="true" text="Datenübertragung an CRM" />
              <button-el :primary="false" text="Nebenbestimmungen" />
              <button-el
                text="Antrag bearbeiten"
                @click="enableEditMode = true" />
            </div>
            <div v-else class="grid grid-cols-3 gap-[10px]">
              <div class="flex gap-[10px]">
                <button-el
                  :primary="false"
                  text="Abbrechen"
                  :full-width="true"
                  @click="enableEditMode = false" />
                <button-el
                  :primary="false"
                  :full-width="true"
                  text="Nebenbestimmungen" />
              </div>
              <button-el
                text="Antrag speichern"
                :icon-right="true"
                icon="archive-check-small-icon"
                @click="enableEditMode = false" />
              <button-el
                :success="true"
                text="Datenübertragung an CRM"
                :icon-right="true"
                icon="open-in-new" />
            </div>
          </div>
        </template>
      </VuetifyDataTable>

      <button-el
        v-if="!showGrantApplication"
        :icon-button="true"
        icon="e-add-big"
        text="Neuen Förderantrag erstellen"
        @click="showGrantApplication = true" />

      <div v-if="showGrantApplication" class="flex flex-col gap-5">
        <h3>Förderantrag neu erstellen</h3>

        <!-- grant name input-->
        <div class="flex w-[350px]">
          <InputEl
            label="Name des Förderprojekts"
            :rules="{ required: true }"
            custom-background="bg-infra-highlight-25" />
        </div>

        <div v-if="step === 2" class="flex flex-col gap-3">
          <!-- grant steps-->
          <grant-edit-steps @current-id="grantEditStep = $event" />

          <!-- grant cards-->
          <grant-cards />

          <grant-scenario-selection v-if="grantEditStep === 3" />
        </div>

        <!-- buttons-->
        <div class="flex justify-between">
          <button-el
            :primary="false"
            text="Abbrechen"
            @click="
              showGrantApplication = false;
              step = 1;
            " />
          <button-el v-if="step === 1" text="Weiter" @click="step++" />
          <button-el
            v-if="step === 2"
            text="Antrag speichern"
            :icon-right="true"
            icon="archive-check-small-icon"
            @click="
              showGrantApplication = false;
              step = 1;
            " />
        </div>
      </div>
    </div>
  </single-page>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loaded: false,
      expanded: [],
      lastExpanded: null,
      step: 1,
      expandedContentLoaded: false,
      showGrantApplication: false,
      enableEditMode: false,
      resetExpansion: false,
      showScenarioSelection: false,
      grantEditStep: 1,
      headers: [
        {
          title: 'Name',
          key: 'name',
        },
        {
          title: 'Investitionssumme',
          key: 'sum_invest',
          align: 'end',
        },
        {
          title: 'Bund',
          key: 'checkmark_bund',
          align: 'center',
          sortable: false,
          width: '100px',
        },
        {
          title: 'Land',
          key: 'checkmark_land',
          align: 'center',
          sortable: false,
          width: '100px',
        },
        {
          title: 'Funktionen',
          key: 'functions',
          sortable: false,
          align: 'center',
          width: '150px',
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('grantApplication/GET_GRANT_APPLICATIONS');
  },
  computed: {
    ...mapGetters('grantApplication', ['getGrantApplications']),
  },
  watch: {
    enableEditMode(val) {
      this.lastExpanded = val ? this.expanded[0] : this.lastExpanded;
    },
    expanded(val) {
      this.enableEditMode = this.lastExpanded === val[0];
    },
    showGrantApplication(val) {
      if (val) {
        this.enableEditMode = false;
        this.resetExpansion = true;
      } else {
        this.resetExpansion = false;
      }
    },
  },
  methods: {
    getIconPath(checkmark) {
      return checkmark
        ? new URL('@/assets/svg/progress-icon.svg', import.meta.url)
        : new URL('@/assets/svg/check-grey.svg', import.meta.url);
    },
    toMEuro(value) {
      if (value === 0 || value === null || value === undefined) {
        return '--';
      }
      return (
        (value / 1000000).toLocaleString('de-DE', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + 'M €'
      );
    },
  },
};
</script>

<style lang="scss"></style>
