<template>
  <div
    class="bg-infra-highlight-25 min-w-fit max-w-[320px] sticky left-0 z-20 pl-2 construction-data-table-wrapper">
    <!-- table header-->
    <div
      class="items-center sticky top-0 bg-infra-highlight-25 border-y border-t-0 pl-1 gap-3 h-[50px] text-blue-grey-700"
      style="
        display: grid;
        grid-template-columns: 75px 240px;
        border-right: 2px solid #eceff1;
      ">
      <div class="subtitle-4">Bauabschnitte</div>
      <div class="grid grid-cols-5 items-center justify-center">
        <div class="subtitle-4 values flex justify-center">
          <building-sold-shape />
        </div>
        <div class="subtitle-4 flex justify-center">Ist/Soll</div>
        <div class="subtitle-4 flex justify-center">Termin</div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!-- table body -->
    <div
      v-for="(item, index) in data"
      :key="item"
      style="border-right: 2px solid #eceff1">
      <!-- meilensteine-->
      <div
        v-if="item.milestones"
        class="h-[24px] border-b items-center flex pl-1">
        <div class="caption-3">Meilensteine - {{ item.section_name }}</div>
      </div>
      <div
        class="items-center border-b gap-3 pl-1 h-[24px] bg-infra-highlight-25"
        style="display: grid; grid-template-columns: 75px 240px">
        <!-- bauabschnitt name-->
        <div class="caption-3 overflow-hidden whitespace-normal">
          {{ item.bauabschnitt }}
        </div>

        <div class="grid grid-cols-5 items-center">
          <!-- anzahl hausanschlüsse-->
          <div
            class="subtitle-4 flex justify-end rounded py-0.5 px-1 h-full items-center relative mr-3"
            @mouseenter="
              item.total_house_connections ? openToolTip(index, 0) : null
            "
            @mouseleave="closeToolTip">
            {{
              item.total_house_connections
                ? item.total_house_connections.toLocaleString('de-DE')
                : '-'
            }}
            <ToolTipAdvanced
              v-if="isToolTipVisible(index, 0)"
              v-model="openPopOver"
              fixed="all"
              :offset-y="12">
              <template #content>
                <div
                  class="construction-popup-content standard-elevation-1 body-2">
                  {{ taskPopoverText(item) }}
                </div>
              </template>
            </ToolTipAdvanced>
          </div>

          <!-- ist/soll progress-->
          <div
            class="flex items-center justify-center"
            @mouseleave="closeToolTip">
            <v-progress-circular
              class="relative"
              size="20"
              width="2"
              bg-color="white"
              :color="
                progressColor(item['soll'] ? item['ist'] / item['soll'] : 0)
              "
              :model-value="
                item['soll'] ? (item['ist'] / item['soll']) * 100 : 0
              "
              @mouseenter="openToolTip(index, 1)">
              <div
                class="bg-blue-grey-50 w-[16px] h-[16px] flex items-center justify-center rounded-full">
                <Component
                  :is="
                    item['soll']
                      ? progressIcon(item['ist'] / item['soll'])
                      : 'check-grey-empty-icon'
                  "
                  :class="item['soll'] ? 'pb-[1px]' : ''" />
              </div>
              <ToolTipAdvanced
                v-if="isToolTipVisible(index, 1)"
                v-model="openPopOver"
                fixed="all"
                :offset-y="12"
                :offset-x="-3">
                <template #content>
                  <div
                    class="construction-popup-content standard-elevation-1 body-2">
                    {{ progressPopoverText(item) }}
                  </div>
                </template>
              </ToolTipAdvanced>
            </v-progress-circular>
          </div>

          <!-- termin status-->
          <div
            class="flex items-center justify-center"
            @mouseleave="closeToolTip">
            <div
              :class="getTerminData(item.termin[0])"
              class="w-[20px] h-[20px] rounded-full relative border-2 border-blue-grey-50"
              @mouseenter="openToolTip(index, 2)">
              <ToolTipAdvanced
                v-if="isToolTipVisible(index, 2)"
                v-model="openPopOver"
                fixed="all"
                :offset-y="5"
                :offset-x="-6">
                <template #content>
                  <div
                    class="construction-popup-content standard-elevation-1 body-2">
                    {{ item.termin[1] }}
                  </div>
                </template>
              </ToolTipAdvanced>
            </div>
          </div>

          <!-- open modal-->
          <!--          <div-->
          <!--            v-if="item.aufgaben > 0"-->
          <!--            class="flex items-center justify-center">-->
          <!--            <a target="_blank" href="https://oew.builtt.com/">-->
          <!--              <alarm-icon fill="#03A9F4" v-bind="props" />-->
          <!--            </a>-->
          <!--          </div>-->
          <!-- <news-data-modal />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuildingSoldShape from '../../../assets/icons/building-sold-shape.vue';
import NewsDataModal from '../components/newsDataModal.vue';
import ToolTipAdvanced from '../../toolTipAdvanced/ToolTipAdvanced.vue';
import AlarmIcon from '../../../assets/icons/alarm-icon.vue';
import CheckGreyEmptyIcon from '../../../assets/icons/check-grey-empty.vue';

export default {
  name: 'ConstructionDataTable',
  components: {
    NewsDataModal,
    BuildingSoldShape,
    ToolTipAdvanced,
    AlarmIcon,
    CheckGreyEmptyIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      openPopOver: false,
      popOverIndex: null,
      popOverSubIndex: null,
    };
  },
  methods: {
    isToolTipVisible(index, subIndex) {
      return this.popOverIndex === index && this.popOverSubIndex === subIndex;
    },
    openToolTip(index, subIndex) {
      this.openPopOver = true;
      this.popOverIndex = index;
      this.popOverSubIndex = subIndex;
    },
    closeToolTip() {
      this.openPopOver = false;
      this.popOverIndex = null;
      this.popOverSubIndex = null;
    },
    taskPopoverText(item) {
      return (
        item.total_house_connections.toLocaleString('de-DE') +
        ' Geplante Hausanschlüsse\n'
      );
    },
    progressPopoverText(item) {
      return `${(item['soll'] ? (item['ist'] / item['soll']) * 100 : 0).toFixed(
        0,
      )}% von ${item['soll'].toLocaleString('de-DE', {
        maximumFractionDigits: 0,
      })}€ verbaut`;
    },
    progressColor(data) {
      if (data < 0.45) {
        return '#BC0606';
      } else if (data < 0.75) {
        return '#FF9800';
      } else {
        return '#4CAF50';
      }
    },
    progressIcon(data) {
      if (data < 0.45) {
        return 'RedAlertIconGanttChart';
      } else if (data < 0.75) {
        return 'YellowAlertIconGanttChart';
      } else {
        return 'GreenCheckIconGanttChart';
      }
    },
    getTerminData(data) {
      switch (data) {
        case 1:
          return 'bg-[#00BCD4]';
        case 2:
          return 'bg-[#00B979]';
        case 3:
          return 'bg-[#FF9800]';
        default:
          return 'bg-[#BC0606]';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.construction-popup-content {
  @apply bg-white z-10 p-1 rounded-[2px] whitespace-pre overflow-hidden w-max;
}
</style>
