<template>
  <div class="ml-3 pr-1 mr-2 ticket-body-wrapper">
    <ticket-body-step1 v-if="getTicketStep === 1" />
    <ticket-body-step2 v-if="getTicketStep === 2" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ticket', ['getTicketStep']),
  },
};
</script>

<style lang="scss">
.ticket-body-wrapper {
  //  style scrollbar
  &::-webkit-scrollbar {
    width: 6px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-400;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
  }
}
</style>
