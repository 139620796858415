<script>
import { axiosInstance } from '@/utils/axiosHelper';

export default {
  methods: {
    downloadDocument(fileName) {
      window.open(
        `${axiosInstance.defaults.baseURL}/api/documents/?fileName=${fileName}`,
        '_blank'
      );
    },
  },
};
</script>
