import {
  LAYER_KEY__MEV_RESULT,
  LAYER_KEY__MEV_HFC,
  LAYER_KEY__MEV_EXPANSION,
  LAYER_KEY__MEV_SEF,
  LAYER_KEY__MEV_VERSORGUNGSGRAD,
  LAYER_KEY__MEV_TECHNOLOGY,
  LAYER_KEY__MEV_OBJECTION,
  LAYER_CONFIG__MEV_RESULT,
  LAYER_CONFIG__MEV_HFC,
  LAYER_CONFIG__MEV_EXPANSION,
  LAYER_CONFIG__MEV_SEF,
  LAYER_CONFIG__MEV_VERSORGUNGSGRAD,
  LAYER_CONFIG__MEV_TECHNOLOGY,
  LAYER_SOURCE__MEV_2022,
  LAYER_SOURCE__MEV_2023,
  LAYER_SOURCE__MEV_2024,
  LAYER_KEY__MEV_HFC_HOMES_PASSED,
  LAYER_CONFIG__MEV_HFC_HOMES_PASSED,
} from '../layers/mev';

export const SWITCH_ID__MEV_RESULTS = 'LC_MEV_RESULTS';
export const SWITCH_ID__MEV_HFC = 'LC_MEV_HFC';
export const SWITCH_ID__MEV_EXPANSION = 'LC_MEV_EXPANSION';
export const SWITCH_ID__MEV_SEF = 'LC_MEV_SEF';
export const SWITCH_ID__MEV_VERSORGUNGSGRAD = 'LC_MEV_VERSORGUNGSGRAD';
export const SWITCH_ID__MEV_TECHNOLOGY = 'LC_MEV_TECHNOLOGY';
export const SWITCH_ID__MEV_OBJECTION = 'LC_MEV_OBJECTION';
export const SWITCH_ID__MEV_HFC_HOMES_PASSED = 'LC_MEV_HFC_HOMES_PASSED';
export const RL_2022 = { name: 'Gigabit-RL 1.0 vom 26.04.2021', value: 2022 };
export const RL_2023 = { name: 'Gigabit-RL 2.0 vom 31.03.2023', value: 2023 };
export const RL_2024 = { name: 'Gigabit-RL 2.1 vom 30.04.2024', value: 2024 };

const SWITCH_IDS = [
  SWITCH_ID__MEV_RESULTS,
  SWITCH_ID__MEV_HFC,
  SWITCH_ID__MEV_EXPANSION,
  SWITCH_ID__MEV_SEF,
  SWITCH_ID__MEV_VERSORGUNGSGRAD,
  SWITCH_ID__MEV_TECHNOLOGY,
  SWITCH_ID__MEV_OBJECTION,
];

const switchOff = (owmKey) => SWITCH_IDS.filter((sID) => sID !== owmKey);

function createRadios(config) {
  const defaultFilter = config.defaultFilter ? config.defaultFilter : 'reset';
  const radioGroup = {
    visible: false,
    activated: 0,
    radios: [
      {
        title: 'Filter ausblenden',
        layerKeys: [config.layerKey],
        filter: defaultFilter,
      },
    ],
  };
  config.choices.forEach((c) => {
    radioGroup.radios.push({
      title: c[2],
      layerKeys: [config.layerKey],
      filter: Array.isArray(c[0])
        ? ['in', ['get', config.property], ['literal', c[0]]]
        : ['==', ['get', config.property], c[0]],
    });
  });
  return [radioGroup];
}

export default {
  title: 'Markterkundungsverfahren',
  modelNameForCASL: 'sensibletilesets',
  items: [
    {
      label: 'Alle Layer entsprechen',
      itemClass: 'selectSource',
      modelNameForCASL: 'sensibletilesets',
      layers: [
        LAYER_KEY__MEV_RESULT,
        LAYER_KEY__MEV_HFC,
        LAYER_KEY__MEV_EXPANSION,
        LAYER_KEY__MEV_SEF,
        LAYER_KEY__MEV_VERSORGUNGSGRAD,
        LAYER_KEY__MEV_TECHNOLOGY,
        LAYER_KEY__MEV_OBJECTION,
      ],
      items: [
        {
          title: RL_2022,
          value: { source: LAYER_SOURCE__MEV_2022, richtlinie: 2022 },
          selectLayersOnClick: [SWITCH_ID__MEV_RESULTS],
        },
        {
          title: RL_2023,
          value: { source: LAYER_SOURCE__MEV_2023, richtlinie: 2023 },
          selectLayersOnClick: [SWITCH_ID__MEV_RESULTS],
        },
        {
          title: RL_2024,
          value: { source: LAYER_SOURCE__MEV_2024, richtlinie: 2024 },
          selectLayersOnClick: [SWITCH_ID__MEV_RESULTS],
        },
      ],
      get default() {
        return this.items[0];
      },
    },
    {
      active: false,
      title: 'Ergebnis',
      onLayers: [LAYER_KEY__MEV_RESULT],
      switchId: SWITCH_ID__MEV_RESULTS,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_RESULTS),
      itemClass: 'switch',
      radioGroups: createRadios(LAYER_CONFIG__MEV_RESULT),
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'Homes passed (TKU-Rückmeldung)',
      onLayers: [LAYER_KEY__MEV_HFC],
      switchId: SWITCH_ID__MEV_HFC,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_HFC),
      itemClass: 'switch',
      radioGroups: createRadios(LAYER_CONFIG__MEV_HFC),
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'Eigenausbau (TKU)',
      onLayers: [LAYER_KEY__MEV_EXPANSION],
      switchId: SWITCH_ID__MEV_EXPANSION,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_EXPANSION),
      itemClass: 'switch',
      radioGroups: createRadios(LAYER_CONFIG__MEV_EXPANSION),
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'Standorte',
      onLayers: [LAYER_KEY__MEV_SEF],
      switchId: SWITCH_ID__MEV_SEF,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_SEF),
      itemClass: 'switch',
      radioGroups: createRadios(LAYER_CONFIG__MEV_SEF),
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'Versorgungsgrad',
      onLayers: [LAYER_KEY__MEV_VERSORGUNGSGRAD],
      switchId: SWITCH_ID__MEV_VERSORGUNGSGRAD,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_VERSORGUNGSGRAD),
      itemClass: 'switch',
      radioGroups: createRadios(LAYER_CONFIG__MEV_VERSORGUNGSGRAD),
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'Technologie',
      onLayers: [LAYER_KEY__MEV_TECHNOLOGY],
      switchId: SWITCH_ID__MEV_TECHNOLOGY,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_TECHNOLOGY),
      itemClass: 'switch',
      radioGroups: createRadios(LAYER_CONFIG__MEV_TECHNOLOGY),
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'Widerspruch TKU',
      onLayers: [LAYER_KEY__MEV_OBJECTION],
      switchId: SWITCH_ID__MEV_OBJECTION,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_OBJECTION),
      itemClass: 'switch',
      modelNameForCASL: 'sensibletilesets',
    },
    {
      active: false,
      title: 'HFC Homes Passed (Analytik)',
      onLayers: [LAYER_KEY__MEV_HFC_HOMES_PASSED],
      switchId: SWITCH_ID__MEV_HFC_HOMES_PASSED,
      turnOffSwitches: switchOff(SWITCH_ID__MEV_HFC_HOMES_PASSED),
      radioGroups: createRadios(LAYER_CONFIG__MEV_HFC_HOMES_PASSED),
      itemClass: 'switch',
      modelNameForCASL: 'sensibletilesets',
    },
  ],
};
