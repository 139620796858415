<template>
  <div class="interval-switch">
    <div
      v-for="(item, index) in intervals"
      :key="index"
      :class="['interval-switch-item', { active: interval === item.value }]"
      @click="toggleActiveSwitch(item.value)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['interval'],
  data() {
    return {
      interval: 'weeks',
      intervals: [
        { label: 'Tage', value: 'days' },
        {
          label: 'Wochen',
          value: 'weeks',
        },
        { label: 'Monate', value: 'months' },
      ],
    };
  },
  created() {
    this.$emit('interval', this.interval);
  },
  methods: {
    toggleActiveSwitch(interval) {
      this.interval = interval;
      this.$emit('interval', interval);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.interval-switch {
  @apply flex gap-2 p-0.5 bg-infra-highlight-25 rounded-[3px] overflow-hidden relative;
}

.interval-switch-item {
  @extend .caption-2;
  @apply p-[6px] text-infra-highlight-500 cursor-pointer;
  &.active {
    @apply text-white bg-infra-highlight-500 rounded-[2px] p-[6px];
    transition: all 0.2s ease-in-out;
  }
}
</style>
