<template>
  <div class="ma-auto w-96 py-8">
    <v-card class="text-center rounded-[8px] p-5">
      <v-img :src="logoURL" contain max-height="110" class="my-8"></v-img>
      <h4 class="justify-center">Passwort festlegen</h4>
      <v-card-text>
        <div class="flex flex-col justify-center gap-6">
          <div class="flex flex-col align-start">
            <the-label label="Neues Passwort" />
            <v-text-field
              v-model="newPassword"
              :append-inner-icon="
                showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'
              "
              :rules="[
                rules.required,
                rules.length,
                rules.uppercase,
                rules.number,
                rules.specialChar,
              ]"
              :type="showPassword ? 'text' : 'password'"
              :error="errorNewPassword"
              :error-messages="errorNewPasswordMessage"
              density="compact"
              bg-color="white"
              variant="outlined"
              name="password"
              class="text-black w-full"
              @change="resetErrors"
              @keyup.enter="confirmPasswordReset"
              @click:append-inner="showPassword = !showPassword"></v-text-field>
          </div>
          <div class="mx-auto">
            <button-el
              text="Neues Passwort festlegen"
              @click="confirmPasswordReset" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Reset Page Component
|---------------------------------------------------------------------
|
| Page Form to insert new password and proceed to sign in
|
*/
import { axios } from '@/utils/axiosHelper';

export default {
  data() {
    return {
      isLoading: false,
      showNewPassword: true,
      newPassword: '',
      errorNewPassword: false,
      errorNewPasswordMessage: '',
      showPassword: false,
      rules: {
        required: (value) => (value && Boolean(value)) || 'Pflichtfeld',
        length: (v) =>
          (v && v.length >= 8) || 'Passwort sollte aus min. 8 Zeichen bestehen',
        uppercase: (v) =>
          /(?=.*[A-Z])/.test(v) || 'Passwort sollte Großbuchstaben enthalten',
        number: (v) => /(?=.*\d)/.test(v) || 'Passwort sollte Zahl enthalten',
        specialChar: (v) =>
          /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(v) ||
          'Passwort sollte Sonderzeichen enthalten',
      },
    };
  },
  computed: {
    logoURL() {
      return new URL('@/assets/img/logo_two_lines.svg', import.meta.url).href;
    },
  },
  methods: {
    confirmPasswordReset() {
      this.isLoading = true;
      this.resetPassword(this.newPassword);
      this.isLoading = false;
    },
    resetPassword(newPassword) {
      const { token, uidb64 } = this.$route.query;
      axios({
        url: '/api/reset_password/',
        data: {
          new_password: newPassword,
          token,
          uidb64,
        },
        method: 'POST',
      }).then(() => {
        this.$router.push('/login');
      });
    },
    resetErrors() {
      this.errorNewPassword = false;
      this.errorNewPasswordMessage = '';
    },
  },
};
</script>
