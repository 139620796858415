<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4993 24.6667C18.9143 24.6681 17.3594 24.2335 16.0049 23.4104C14.6503 22.5873 13.5483 21.4075 12.8193 20H20.4993L21.8327 17.3333H11.9393C11.8727 16.8933 11.8327 16.4533 11.8327 16C11.8327 15.5467 11.8727 15.1067 11.9393 14.6667H20.4993L21.8327 12H12.8193C13.5496 10.5936 14.6519 9.41464 16.0062 8.59173C17.3604 7.76882 18.9147 7.33354 20.4993 7.33334C22.646 7.33334 24.6193 8.12001 26.1393 9.42667L28.4993 7.06667C26.3039 5.08996 23.4536 3.99734 20.4993 4C15.2727 4 10.846 7.34667 9.19268 12H4.49935L3.16602 14.6667H8.57935C8.47136 15.5523 8.47136 16.4477 8.57935 17.3333H4.49935L3.16602 20H9.19268C10.846 24.6533 15.2727 28 20.4993 28C23.5793 28 26.3793 26.84 28.4993 24.9333L26.126 22.5733C24.6193 23.88 22.6593 24.6667 20.4993 24.6667Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
