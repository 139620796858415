import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { LAYER_KEY__ADDRESS_POINTS } from '@/configs/layers/buildings';

export const SM_POLY = "polygon"
export const SM_LINE = "linestring"
export const SM_ADDRESS = "address"

const state = {
  selectedFeatures: [],
  selectionMode: null,
  geometrySelection: {
    [LAYER_KEY__ADDRESS_POINTS]: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
