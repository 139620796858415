<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.83333 2H3C2.44772 2 2 2.44772 2 3V6.83333C2 7.38562 2.44772 7.83333 3 7.83333H6.83333C7.38562 7.83333 7.83333 7.38562 7.83333 6.83333V3C7.83333 2.44772 7.38562 2 6.83333 2Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M6.83333 12.8333H3C2.44772 12.8333 2 13.281 2 13.8333V17.6666C2 18.2189 2.44772 18.6666 3 18.6666H6.83333C7.38562 18.6666 7.83333 18.2189 7.83333 17.6666V13.8333C7.83333 13.281 7.38562 12.8333 6.83333 12.8333Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M12 2.83325H18.6667"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M12 7H18.6667"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M12 13.6667H18.6667"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M12 17.8333H18.6667"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
