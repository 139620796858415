export const LAYER_KEY__EXPANSIONPLANNING = 'expansionplanning';

export default {
  [LAYER_KEY__EXPANSIONPLANNING]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'expansionplanning',
      router_name: 'expansionplanning',
      key_name: 'id',
      applyGemeindeFilter: true,
      type: 'polygon',
      id: LAYER_KEY__EXPANSIONPLANNING,
      sourceLayer: LAYER_KEY__EXPANSIONPLANNING,
      source: LAYER_KEY__EXPANSIONPLANNING,
      visibility: 'none',
    },
    style: {
      default: {
        fillColor: 'rgba(173, 216, 230, 0.7)',
        fillOpacity: 1,
      },
    },
    legend: {
      default: {
        name: 'Ausbauplanung',
        unit: '',
        items: [
          {
            color: 'rgba(173, 216, 230, 1)',
            name: 'Weiße Flecken',
            icon: 'mdi-square',
          },
        ],
      },
    },
  },
};
