<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_49_5118)">
      <path
        d="M16.3334 2.96132L8.66675 0.661316V4.5H9.66675V2.00535L15.3334 3.70535V7.87971C15.7065 8.13679 16.043 8.44323 16.3334 8.78961V2.96132Z"
        :fill="fill" />
      <path
        d="M16.3334 15.2104C16.1444 15.4358 15.9359 15.6443 15.7105 15.8333H16.3334V15.2104Z"
        :fill="fill" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.28965 15.8333C8.94327 15.5429 8.63683 15.2065 8.37976 14.8333H4.33341V6.5H10.0001V7.6689C10.3135 7.4876 10.6484 7.33934 11.0001 7.22889V5.5H3.33341V8.16666H0.666748V15.8333H9.28965ZM3.33341 9.16667V14.8333H1.66675V9.16667H3.33341Z"
        :fill="fill" />
      <path
        d="M13.6667 7.13685C13.3444 7.0598 13.01 7.01397 12.6667 7.00273V5.5H13.6667V7.13685Z"
        :fill="fill" />
      <path
        d="M7.84686 10.1667H6.00008V11.1667H7.56919C7.62736 10.8199 7.72122 10.4853 7.84686 10.1667Z"
        :fill="fill" />
      <path
        d="M7.50279 12.1667H6.00008V13.1667H7.6369C7.55986 12.8443 7.51403 12.5099 7.50279 12.1667Z"
        :fill="fill" />
      <path
        d="M8.33342 8.16666H6.00008V9.16667H8.33342V8.16666Z"
        :fill="fill" />
      <path
        d="M12.5001 8C10.3001 8 8.50006 9.8 8.50006 12C8.50006 14.2 10.3001 16 12.5001 16C14.7001 16 16.5001 14.2 16.5001 12C16.5001 9.8 14.7001 8 12.5001 8ZM14.3536 11.3535L12.3536 13.3535C12.2561 13.451 12.1281 13.5 12.0001 13.5C11.8721 13.5 11.7441 13.451 11.6466 13.3535L10.6466 12.3535C10.4511 12.158 10.4511 11.842 10.6466 11.6465C10.8421 11.451 11.1581 11.451 11.3536 11.6465L12.0001 12.293L13.6466 10.6465C13.8421 10.451 14.1581 10.451 14.3536 10.6465C14.5491 10.842 14.5491 11.158 14.3536 11.3535Z"
        :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
