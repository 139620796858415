<template>
  <div class="flex w-full subtitle-2 gap-1">
    <div
      v-if="!isSelected"
      class="flex justify-end w-fit ml-auto whitespace-nowrap">
      <!--      if item.value has more than 15 characters, cut it-->
      <div v-if="isLoading">
        <skeleton-loader-custom :custom-width="50" />
      </div>
      <div v-else>
        <div v-if="item.value.length < 15">
          {{ item.value }}
        </div>
        <div v-else>
          <v-tooltip :text="item.value">
            <template #activator="{ props }">
              <div v-bind="props">
                {{ sliceString() }}
              </div>
            </template>
          </v-tooltip>
        </div>
      </div>
    </div>

    <!-- text input -->
    <div v-else class="flex">
      <input
        ref="inputField"
        v-model="currentInput"
        type="text"
        :placeholder="sliceString()"
        class="w-full text-end focus:outline-none placeholder:overline-2"
        @input="updateInput()" />
    </div>
    <span v-if="item.unit" class="whitespace-nowrap">{{ item.unit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    isSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    clickIndex: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ['updateInput'],
  data() {
    return {
      currentInput: null,
    };
  },
  watch: {
    isSelected() {
      if (this.isSelected) {
        this.$nextTick(() => {
          this.$refs.inputField.focus();
        });
      }
    },
    isLoading() {
      this.currentInput = null;
    },
  },
  methods: {
    updateInput() {
      this.validateInput(this.item);
      this.$emit('updateInput', this.currentInput);
    },
    sliceString() {
      const itemValue = this.item.value;
      if (itemValue.length > 15) return `${itemValue.slice(0, 14)}...`;
      else return itemValue;
    },

    validateInput(item) {
      if (item.type === 'float') {
        const input = this.currentInput;
        const regex = /^-?[0-9]*([,][0-9]*)?$/;
        if (!regex.test(input)) {
          // Remove any non-matching characters from the input
          this.currentInput = input.replace(/[^0-9,-]/g, '');
        }
      } else if (item.type === 'integer') {
        const input = this.currentInput;
        const regex = /^[0-9]+$/;
        if (!regex.test(input)) {
          // Remove any non-matching characters from the input
          this.currentInput = input.replace(/[^\d]/g, '');
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
