<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.1665 14.6666L5.49984 6.33325"
      :stroke="fill"
      stroke-miterlimit="10" />
    <path d="M16.0002 16.3333H4.3335" :stroke="fill" stroke-miterlimit="10" />
    <path
      d="M14.9165 6.33325L17.9165 14.5833"
      :stroke="fill"
      stroke-miterlimit="10" />
    <path d="M7.6665 4.66675H12.6665" :stroke="fill" stroke-miterlimit="10" />
    <path
      d="M6.66683 3H5.3335C4.78121 3 4.3335 3.44771 4.3335 4V5.33333C4.3335 5.88562 4.78121 6.33333 5.3335 6.33333H6.66683C7.21911 6.33333 7.66683 5.88562 7.66683 5.33333V4C7.66683 3.44772 7.21911 3 6.66683 3Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M14.9998 3H13.6665C13.1142 3 12.6665 3.44771 12.6665 4V5.33333C12.6665 5.88562 13.1142 6.33333 13.6665 6.33333H14.9998C15.5521 6.33333 15.9998 5.88562 15.9998 5.33333V4C15.9998 3.44772 15.5521 3 14.9998 3Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M18.3333 14.6667H17C16.4477 14.6667 16 15.1145 16 15.6667V17.0001C16 17.5524 16.4477 18.0001 17 18.0001H18.3333C18.8856 18.0001 19.3333 17.5524 19.3333 17.0001V15.6667C19.3333 15.1145 18.8856 14.6667 18.3333 14.6667Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M3.33333 14.6667H2C1.44772 14.6667 1 15.1145 1 15.6667V17.0001C1 17.5524 1.44771 18.0001 2 18.0001H3.33333C3.88562 18.0001 4.33333 17.5524 4.33333 17.0001V15.6667C4.33333 15.1145 3.88562 14.6667 3.33333 14.6667Z"
      :stroke="fill"
      stroke-miterlimit="10"
      stroke-linecap="square" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
