<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    :width="width"
    :height="height">
    <g :fill="_secondaryfill">
      <path
        d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8,8-3.6,8-8S12.4,0,8,0Zm0,14c-3.3,0-6-2.7-6-6S4.7,2,8,2s6,2.7,6,6-2.7,6-6,6Z"
        :fill="fill"></path>
      <path
        d="M9.414,8l1.768-1.768c.195-.195,.195-.512,0-.707l-.707-.707c-.195-.195-.512-.195-.707,0l-1.768,1.768-1.768-1.768c-.195-.195-.512-.195-.707,0l-.707,.707c-.195,.195-.195,.512,0,.707l1.768,1.768-1.768,1.768c-.195,.195-.195,.512,0,.707l.707,.707c.195,.195,.512,.195,.707,0l1.768-1.768,1.768,1.768c.195,.195,.512,.195,.707,0l.707-.707c.195-.195,.195-.512,0-.707l-1.768-1.768Z"></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '16px',
    },
    height: {
      type: [Number, String],
      default: '16px',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
