<template>
  <div class="flex flex-col">
    <slot name="top" />
    <Component
      :is="totalItems !== null ? 'VDataTableServer' : 'VDataTable'"
      ref="vuetifyDataTable"
      v-model:items-per-page="itemsPerPage"
      v-model:expanded="expanded"
      :headers="headers"
      class="vuetify-data-table-custom"
      :class="dataTableClasses"
      :items-length="totalItems"
      :items="itemData"
      :show-expand="showExpand"
      :item-value="itemValue"
      :page="page"
      :loading="loading"
      :search="search"
      :show-select="showSelect"
      :expand-on-click="expandOnRowClick"
      :items-per-page="itemsPerPage"
      @click:row="clickRow"
      @update:options="setTableOptions">
      <!-- dynamic template slots-->
      <template v-for="header in headers" #[`item.${header.key}`]="{ item }">
        <slot :name="header.key" :item="item" class="align-middle">
          {{ item[header.key] }}
        </slot>
      </template>

      <!-- expanded row template slot-->
      <template #expanded-row="{ columns, item }">
        <tr
          :class="`expanded-content ${expandedBackground} ${disableInnerShadow ? '' : 'inner-shadow-1'}`">
          <td :colspan="columns.length">
            <slot name="expanded-row" :item="item" />
          </td>
        </tr>
      </template>

      <!-- select/unselect all-->
      <!-- eslint-disable-next-line -->
      <template #header.data-table-select>
        <VCheckbox
          v-model="selectAll"
          :indeterminate="checkedItems.length ? selectAll === false : null"
          indeterminate-icon="mdi-minus"
          :ripple="false"
          density="compact"
          :class="{
            'intermediate-checkbox': checkedItems.length && !selectAll,
          }"
          :hide-details="true" />
      </template>

      <!-- select row -->
      <!-- eslint-disable-next-line -->
      <template #item.data-table-select="{ item }">
        <v-checkbox
          v-model="checkedItems"
          :ripple="false"
          density="compact"
          :hide-details="true"
          content-class="elevation-0"
          :value="item[itemValue]" />
      </template>

      <!--  icons open/close expand-row  -->
      <!--    eslint-disable-next-line -->
      <template #item.data-table-expand="{ item }">
        <div
          class="cursor-pointer flex justify-center"
          data-test="open-expandable"
          @click="expandOnRowClick ? null : expandRow(item)">
          <IconWrapper
            v-if="item[itemValue] === expanded[0]"
            icon="remove"
            fill="text-title-color1"
            hover="hover:text-color1" />
          <IconWrapper
            v-else
            icon="add"
            fill="text-title-color1"
            hover="hover:text-color1" />
        </div>
      </template>

      <!-- pagination-->
      <template #bottom>
        <div
          :class="{ 'pointer-events-none': !itemData.length }"
          class="flex justify-end items-center gap-[10px] p-[10px] vuetify-data-table-footer">
          <div class="flex gap-2 items-center w-fit">
            <div class="body-2 text-neutral">Zeige:</div>
            <DropDown
              v-model="itemsPerPage"
              :items-data="perPageOptions"
              :clearable="false"
              :open-to-top="openPageOptionsToTop"
              :class="{
                'pointer-events-none':
                  perPageOptions.length === 1 || perPageOptions.length === 0,
              }" />
          </div>

          <div class="body-2 text-neutral">
            {{ startRecord }} - {{ endRecord }}
            <span v-if="showTotal"> von {{ totalRecords }}</span>
          </div>

          <!-- pagination arrows-->
          <div class="flex gap-2">
            <ButtonEl
              icon="arrow_left_alt"
              :disabled="page === 1"
              variant="secondary"
              @click="page--" />
            <ButtonEl
              :disabled="
                page * itemsPerPage >=
                (totalItems !== null ? totalItems : itemData.length)
              "
              icon="arrow_right_alt"
              variant="secondary"
              @click="page++" />
          </div>
        </div>
      </template>
    </Component>
  </div>
</template>

<script setup>
import DropDown from '../DropDown/DropDown.vue';
import ButtonEl from '../button/ButtonEl.vue';
import { computed, onBeforeMount, ref, watch } from 'vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  headers: {
    type: Array,
    default: () => [],
  },
  itemData: {
    type: Array,
    default: () => [],
  },
  showPagination: {
    type: Boolean,
    default: true,
  },
  showTotal: {
    type: Boolean,
    default: false,
  },
  itemValue: {
    type: String,
    default: 'id',
  },
  expandOnRowClick: {
    type: Boolean,
    default: false,
  },
  showExpand: {
    type: Boolean,
    default: true,
  },
  cursorPointerOnRow: {
    type: Boolean,
    default: false,
  },
  perPageOptions: {
    type: Array,
    default: () => [10, 20, 50],
  },
  openPageOptionsToTop: {
    type: Boolean,
    default: false,
  },
  fixedHeaderFooter: {
    type: Boolean,
    default: false,
  },
  resetExpansion: {
    type: Boolean,
    default: false,
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  search: {
    type: String,
    default: null,
  },
  totalItems: {
    type: Number,
    default: null,
  },
  setExpanded: {
    type: Array,
    default: () => [],
  },
  expandedBackground: {
    type: String,
    default: 'bg-active-area',
  },
  disableInnerShadow: {
    type: Boolean,
    default: false,
  },
  selectAllCheckboxes: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'expanded',
  'clickedRowData',
  'selectedRows',
  'update:options',
]);

const vuetifyDataTable = ref(null);
const page = ref(1);
const expanded = ref([]);
const itemsPerPage = ref(10);
const loading = ref(false);
const checkedItems = ref([]);
const sortBy = ref([]);

const dataTableClasses = computed(() => {
  return {
    'v-data-table-hide-pagination':
      !props.showPagination ||
      (props.itemData.length <= 10 && !props.totalItems),
    'v-data-table-fixed-header-footer': props.fixedHeaderFooter,
    'no-cursor-pointer': !props.expandOnRowClick,
  };
});
const selectAll = computed({
  get() {
    return checkedItems.value.length === props.itemData.length;
  },
  set(val) {
    checkedItems.value = val
      ? props.itemData.map((item) => item[props.itemValue])
      : [];
  },
});

const startRecord = computed(() => {
  return (page.value - 1) * itemsPerPage.value + 1;
});

const endRecord = computed(() => {
  const calculatedEnd = page.value * itemsPerPage.value;
  return calculatedEnd > totalRecords.value
    ? totalRecords.value
    : calculatedEnd;
});

const totalRecords = computed(() => {
  return props.totalItems !== null ? props.totalItems : props.itemData.length;
});

function clickRow(item, row) {
  emit('clickedRowData', row);
}

function expandRow(item) {
  if (expanded.value.includes(item[props.itemValue])) {
    expanded.value = [];
  } else {
    expanded.value = [item[props.itemValue]];
  }
}

function setTableOptions(data) {
  ({
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  } = data);
}

function updateCheckedItemsOnItemDataChange(bigger) {
  if (bigger) {
    // Check all Items
    checkedItems.value = props.itemData.map((item) => item[props.itemValue]);
  } else {
    // For performance reasons create Object (needed for big data)
    const itemDataObj = props.itemData.reduce((result, item) => {
      return { ...result, [item[props.itemValue]]: true };
    }, {});
    // Keep unchecked Items unchecked
    checkedItems.value = checkedItems.value.filter((item) => itemDataObj[item]);
  }
}

onBeforeMount(() => {
  if (props.selectAllCheckboxes) {
    checkedItems.value = props.itemData.map((item) => item[props.itemValue]);
  }
});

watch(
  [page, itemsPerPage, sortBy],
  () => {
    const data = {
      page: page.value,
      itemsPerPage: itemsPerPage.value,
      sortBy: sortBy.value,
    };
    emit('update:options', data);

    if (props.totalItems) {
      loading.value = true;
    }
  },
  { deep: true, immediate: true },
);

watch(
  () => checkedItems.value,
  (val) => {
    emit('selectedRows', val);
  },
);

watch(
  () => expanded.value,
  (val) => {
    if (val.length > 1) {
      val.shift();
    }
    emit('expanded', val);
  },
);

watch(
  () => page.value,
  () => {
    expanded.value = [];
    props.totalItems && (checkedItems.value = []);
  },
);

watch(
  () => props.itemData,
  (newVal, oldVal) => {
    if (!props.showPagination) {
      itemsPerPage.value = newVal.length;
    }
    if (props.totalItems) {
      loading.value = false;
    }
    updateCheckedItemsOnItemDataChange(newVal > oldVal);
  },
  { deep: true, immediate: true },
);

watch(
  () => itemsPerPage.value,
  () => {
    page.value = 1;
    checkedItems.value = [];
  },
);

watch(
  () => props.resetExpansion,
  () => {
    expanded.value = [];
  },
);

watch(
  () => props.setExpanded,
  (val) => {
    expanded.value = val;
  },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

:deep(.vuetify-data-table-custom) {
  position: relative;
  height: 100%;

  .v-data-table-progress {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .v-table__wrapper {
    border-radius: 0 !important;
  }

  thead th {
    @apply bg-default;
    height: 48px !important;

    .v-data-table-header__content {
      @apply text-title-neutral;
      @extend h5;
      display: flex;
      align-items: center;
    }
  }

  th,
  tr:not(.expanded-content) td {
    border: none !important;
    overflow: hidden;
  }

  tbody {
    td {
      height: 48px !important;
    }

    tr:nth-of-type(odd) {
      @apply bg-subtle;
    }

    tr:nth-of-type(even) {
      @apply bg-default;
    }
  }

  &.no-cursor-pointer tr {
    cursor: default;
  }

  &.v-data-table-hide-pagination {
    .vuetify-data-table-footer {
      display: none !important;
    }
  }

  &.v-data-table-fixed-header-footer {
    height: calc(100% - 69px);

    .v-table__wrapper {
      overflow-y: auto;
      max-height: calc(100% - 65px);

      > table > thead {
        position: sticky;
        top: 0;
      }
    }

    .vuetify-data-table-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      @apply bg-default;
      @apply border-t border-infra-lines;
    }
  }
}

// Styling for table checkboxes
:deep(tr .mdi-checkbox-marked) {
  @apply text-color1;
}

:deep(th .mdi-checkbox-marked) {
  @apply text-color2;
}
</style>
