<template>
  <div class="absolute bottom-5 right-5">
    <div class="flex flex-col bg-white toggleBarBottomRight">
      <!-- zoom in-->
      <v-icon
        class="map-control-icon rounded-t-[8px]"
        icon="$plus"
        @click="zoom" />
      <!-- zoom out-->
      <v-icon class="map-control-icon" icon="$minus" @click="unzoom" />

      <!-- show only when click on control option icon-->
      <div class="extended-controls" :class="{ visible: showExtendedControls }">
        <div class="map-control-icon font-medium" @click="pitch()">
          {{ `${pitchText}` }}
        </div>
        <!-- compass to north-->
        <v-icon class="map-control-icon" icon="$north" @click="compass" />
        <v-icon
          class="map-control-icon"
          icon="mdi-satellite-variant"
          :class="
            satelliteIsActiv
              ? 'bg-infra-gray-highlight'
              : 'hover:bg-infra-background'
          "
          @click="toggleSatellite" />
      </div>
      <control-option-icon
        class="shadow-none rounded-t-none"
        :class="
          showExtendedControls
            ? 'bg-infra-highlight-600'
            : 'hover:bg-infra-background'
        "
        :icon="showExtendedControls ? 'map-white' : 'map'"
        @click="showExtendedControls = !showExtendedControls" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { compassMap, setPitch, unzoomMap, zoomMap } from '@/mapbox/main';

export default {
  name: 'MapControls',
  data() {
    return {
      showModalMapSettings: false,
      pitchText: '3D',
      showExtendedControls: false,
    };
  },
  computed: {
    ...mapState('map', ['satelliteIsActiv']),
  },
  methods: {
    pitch() {
      this.pitchText = setPitch(this.pitchText);
    },
    zoom() {
      zoomMap();
    },
    unzoom() {
      unzoomMap();
    },
    compass() {
      compassMap();
    },
    toggleLegend() {
      this.$emit('toggleLegendState');
    },
    toggleSatellite() {
      this.$store.commit('map/TOGGLE_SATELLITE');
    },
  },
};
</script>

<style lang="scss" scoped>
.map-control-icon {
  width: 45px;
  height: 45px;
  @apply hover:bg-infra-background active:bg-infra-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggleBarBottomRight {
  width: 45px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.extended-controls {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-out;
}

.visible {
  max-height: 200px;
}
</style>
