export default function polygonBorderLayer(id, source, visibility = 'none') {
  return {
    layoutState: 'default',
    visible: visibility === 'visible',
    layerConfig: {
      type: 'line',
      id,
      source,
      sourceLayer: source,
      visibility,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#000000',
          '#5d5d5d',
        ],
        lineWidth: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          2,
          0.5,
        ],
      },
    },
  };
}
