<template>
  <div class="control-option-icon">
    <img :src="iconPath" />
  </div>
</template>

<script>
export default {
  name: 'ControlOptionIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconPath() {
      return new URL(`/src/assets/svg/${this.icon}.svg`, import.meta.url);
    },
  },
};
</script>

<style lang="scss">
.control-option-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
</style>
