import polygonBorderLayer from './polygonBorderLayer';
export const LAYER_KEY__CONSTRUCTION_PLAN = 'constructionplan';
export const LAYER_KEY__CONSTRUCTION_PLAN_BORDER = 'constructionplanBorder';

export default {
  [LAYER_KEY__CONSTRUCTION_PLAN]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'construction',
      router_name: 'constructionPlans',
      key_name: 'pk',
      type: 'polygon',
      id: LAYER_KEY__CONSTRUCTION_PLAN,
      source: LAYER_KEY__CONSTRUCTION_PLAN,
      sourceLayer: LAYER_KEY__CONSTRUCTION_PLAN,
      visibility: 'none',
      hoverPointer: true,
    },
    style: {
      default: {
        fillColor: 'rgba(47,246,163,0.6)',
        fillOpacity: 1,
      },
    },
    legend: {
      default: {
        name: 'Bebauungspläne',
        unit: '',
        items: [
          {
            color: 'rgba(47,246,163,0.6)',
            name: 'Gewerbegebiete',
            icon: 'mdi-square',
            size: '14px',
          },
        ],
      },
    },
    sidebar: {
      name: 'Bebauungs- und Flächennutzungspläne',
      subtitles: [{ key: 'name' }, { key: 'location' }],
      chips: [
        {
          title: 'Info',
          active: true,
          items: [],
        },
      ],
    },
  },
  [LAYER_KEY__CONSTRUCTION_PLAN_BORDER]: polygonBorderLayer(
    LAYER_KEY__CONSTRUCTION_PLAN_BORDER,
    LAYER_KEY__CONSTRUCTION_PLAN
  ),
};
