<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 6V5.5H13H6.33337H5.83337V6V7.33333V7.83333H6.33337H13H13.5V7.33333V6ZM8.50004 10V10.5H9.00004H10.3334H10.8334V10V8.66667V8.16667H10.3334H9.00004H8.50004V8.66667V10ZM10.8334 11.3333V10.8333H10.3334H9.00004H8.50004V11.3333V12.6667V13.1667H9.00004H10.3334H10.8334V12.6667V11.3333ZM7.66671 10.5H8.16671V10V8.66667V8.16667H7.66671H6.33337H5.83337V8.66667V10V10.5H6.33337H7.66671ZM11.6667 8.16667H11.1667V8.66667V10V10.5H11.6667H13H13.5V10V8.66667V8.16667H13H11.6667ZM6.33337 10.8333H5.83337V11.3333V12.6667V13.1667H6.33337H7.66671H8.16671V12.6667V11.3333V10.8333H7.66671H6.33337ZM11.1667 12.6667V13.1667H11.6667H13H13.5V12.6667V11.3333V10.8333H13H11.6667H11.1667V11.3333V12.6667ZM11.1667 3.33333V3.5H10.8334V3.33333V2.83333H10.3334H3.66671H3.16671V3.33333V10V10.5H3.66671H3.83337V10.8333H3.66671C3.20952 10.8333 2.83337 10.4572 2.83337 10V3.33333C2.83337 2.87614 3.20952 2.5 3.66671 2.5H10.3334C10.7906 2.5 11.1667 2.87614 11.1667 3.33333ZM6.33337 5.16667H13C13.4572 5.16667 13.8334 5.54281 13.8334 6V12.6667C13.8334 13.1239 13.4572 13.5 13 13.5H6.33337C5.87618 13.5 5.50004 13.1239 5.50004 12.6667V6C5.50004 5.54281 5.87618 5.16667 6.33337 5.16667Z"
      fill="black"
      :stroke="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
