<template>
  <div class="bg-infra-highlight-25 p-3 gap-0.5 flex flex-col w-100">
    <div
      v-for="(item, index) in metaDataProps"
      :key="index"
      class="flex justify-between">
      <div class="caption-2">{{ item.label }}</div>
      <div class="caption-1">
        {{ item.value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MetaDataEntries',
  props: {
    metaDataProps: {
      type: Array,
      required: true,
    },
  },
};
</script>
