<template>
  <div class="h-full pt-5 px-1">
    <div
      class="standard-elevation-1 rounded grid grid-cols-5 overflow-hidden mb-[30px]">
      <div
        v-for="item in progressItems"
        :key="item"
        :class="{
          'bg-white text-infra-highlight-800': item.selected,
          'bg-infra-highlight-25 text-infra-highlight-900': !item.selected,
        }"
        class="flex flex-col items-center p-6">
        <v-icon color="">{{ item.icon }}</v-icon>
        <h5 class="mt-2">{{ item.title }}</h5>
        <h3 class="mt-6">
          {{ item.selected ? item.value : item.value + ' %' }}
        </h3>
      </div>
    </div>

    <h4 class="text-infra-highlight-700 mb-[20px]">Filterbare Detailansicht</h4>

    <div
      class="flex flex-col gap-[10px] standard-elevation-1 overflow-hidden rounded">
      <div class="flex justify-between p-[10px] pb-0">
        <div class="flex gap-4 w-[400px]">
          <DropDown
            v-model="filteredCluster"
            placeholder="Bitte wählen"
            label="Cluster-Los"
            custom-background="bg-infra-highlight-25"
            class="min-w-[50%]"
            items-data-key="lot_name"
            :items-data="filteredItemData" />
          <DropDown
            v-model="filteredBauabschnitt"
            placeholder="Bitte wählen"
            label="Bauabschnitt"
            custom-background="bg-infra-highlight-25"
            class="min-w-[50%]"
            items-data-key="cluster_name"
            :items-data="filteredItemData" />
        </div>
        <InputEl
          v-model="filteredGemeinde"
          placeholder="Bitte Gemeindenamen eingeben"
          label="Gemeindefilter"
          custom-background="bg-infra-highlight-25"
          class="w-[350px]" />
      </div>

      <vuetify-data-table
        :headers="headers"
        :item-data="filteredItemData"
        :open-page-options-to-top="true"
        item-value="lot_id"
        :expand-on-row-click="true"
        :show-total="true">
        <template #checkmark="item">
          <div class="flex items-center justify-center">
            <img
              class="opacity-80"
              :src="getIconPath(item.item.checkmark)"
              alt="progress-icon" />
          </div>
        </template>
        <template #functions>
          <div class="flex gap-4 justify-center">
            <img
              class="opacity-40 hover:opacity-70"
              :src="'@/assets/svg/map-add.svg'"
              alt="map-add" />
          </div>
        </template>
        <template #expanded-row>
          <div
            class="bg-infra-highlight-25 flex p-[16px] items-center justify-between">
            <!-- bauphase-->
            <div class="progress-date-item">
              <div class="body-3 whitespace-nowrap">Bauphase (vsl.)</div>
              <div class="subtitle-2 whitespace-nowrap">
                01.05.23 - 07.11.23
              </div>
            </div>

            <!-- progress items-->
            <div class="flex items-center gap-[10px] w-1/2 justify-between">
              <div
                v-for="item in progressItems.filter((item) => !item.selected)"
                :key="item"
                class="flex flex-col gap-[15px]">
                <div class="body-3 text-center">{{ item.title }}</div>
                <div
                  class="h-[11px] bg-white rounded-[2px] border border-blue-grey-50">
                  <div
                    :style="{ width: item.value + '%' }"
                    class="h-full bg-infra-highlight-500 rounded-[2px]"></div>
                </div>
              </div>
            </div>

            <!-- übergabe netzbetreiber-->
            <div class="progress-date-item">
              <div class="body-3 whitespace-nowrap">Übergabe Netzbetreiber</div>
              <div class="subtitle-2">23.12.2023</div>
            </div>
          </div>
        </template>
      </vuetify-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressWindowItem',
  data() {
    return {
      filteredCluster: null,
      filteredBauabschnitt: null,
      filteredGemeinde: null,
      progressItems: [
        {
          icon: 'mdi-account',
          title: 'Bauabschnitte',
          value: 10,
          selected: true,
        },
        {
          icon: 'mdi-account',
          title: 'Trassenbau',
          value: 50,
          selected: false,
        },
        {
          icon: 'mdi-account',
          title: 'Technik Standorte',
          value: 41,
          selected: false,
        },
        {
          icon: 'mdi-account',
          title: 'Hausanschlüsse',
          value: 33,
          selected: false,
        },
        {
          icon: 'mdi-account',
          title: 'LWL-Arbeiten',
          value: 20,
          selected: false,
        },
      ],
      headers: [
        {
          title: 'Cluster-Los',
          key: 'lot_name',
          width: '175px',
        },
        {
          title: 'Bauabschnitt',
          key: 'cluster_name',
        },
        {
          title: 'Gemeinde',
          key: 'name_company',
        },
        {
          title: 'Gemarkung',
          key: 'sum_invest',
        },
        {
          title: 'Status',
          key: 'checkmark',
          align: 'center',
          sortable: false,
          width: '75px',
        },
        {
          title: '',
          key: 'functions',
          sortable: false,
          align: 'center',
          width: '75px',
        },
      ],
      itemData: [
        {
          url: 'http://localhost/api/builtt/BuilttLot/137/',
          lot_id: 137,
          cluster_name: 'KZUMiOFuESLMpWrxzOwJaRYBIbZWGdXxQYfhKPrCHoKpcCXvJB',
          sum_invest: 2289401.61386588,
          checkmark: true,
          lot_name: 'LGmIRsebSnIDLWkesltniEsMPCvuaGbrRcDThFvbqLLMjHYDfp',
          name_company: 'oAzWKeczYIFrSMlWqUeaZZPpDHNqftPQnsWWKKpXrfqiYyFyqY',
          cluster: 'http://localhost/api/builtt/BuilttCluster/694/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/157/',
          lot_id: 157,
          cluster_name: 'KbpKgTGzsJeMdOhjbLDVyzVPmOAIfYpttkIxzNeECjeyqiuuxT',
          sum_invest: 0,
          checkmark: false,
          lot_name: 'nDyvqsMzkUitZyrXBkcBgRnAgSAkVuHZBshzldQRVOEtVklanI',
          name_company: 'CfNknvnWYmHlvyYbLSzmgRENyBRMCNYqmyouqyoyJoCnIHlzWo',
          cluster: 'http://localhost/api/builtt/BuilttCluster/113/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/529/',
          lot_id: 529,
          cluster_name: 'BIZtFYUQHYRstsqfHWkDoEVAQztvIsPDHxIVRptcKpwXRBrlwO',
          sum_invest: 0,
          checkmark: false,
          lot_name: 'QoZKDLiGGrlYHqNXkYiVMtQEzxBYRJpuaEJSRpENiDvReSomlO',
          name_company: 'bMhXQukNRkQqqGqgecaSqndmImIaRJoiOcVRSEBQOJurYWMzNt',
          cluster: 'http://localhost/api/builtt/BuilttCluster/165/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/62/',
          lot_id: 62,
          cluster_name: 'jNVrjYOQCFJEouUOgLVPUPQISlrfsnIkqlLkPREqqPKQzUGXJJ',
          sum_invest: 0,
          checkmark: false,
          lot_name: 'VetiGGrWQvXTrNPNKREUtqxIDtrRZtdVZdwnoTyjFpxCPbPeCW',
          name_company: 'yrKBTdvGrOzTsTTMAJhIMNBbUfUEALmKKKQSNfhKHIkQMobEtl',
          cluster: 'http://localhost/api/builtt/BuilttCluster/233/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 738,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 739,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 73688,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 736883456,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 73677,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 736567,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 736456456,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
        {
          url: 'http://localhost/api/builtt/BuilttLot/736/',
          lot_id: 736456456546546,
          cluster_name: 'jmOGDgUAjVJXdGGWaByUqBEGdFFCZKAOApqYYUbkzEsxhHubAB',
          sum_invest: 764398.388389291,
          checkmark: false,
          lot_name: 'ZsDRyCezWdfbfOEyJoMTIrFQARwzgulIwDjpslnJIDtjUhMaCM',
          name_company: 'AWLYvHWfqASPKsmlihgTyIUJtmkxYhmgRYexsPnhxHXNJIQbvU',
          cluster: 'http://localhost/api/builtt/BuilttCluster/145/',
        },
      ],
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filteredItemData() {
      return this.itemData.filter((item) => {
        // Filter by selected Cluster-Los
        if (this.filteredCluster && item.lot_name !== this.filteredCluster) {
          return false;
        }

        // Filter by selected Bauabschnitt
        if (
          this.filteredBauabschnitt &&
          item.cluster_name !== this.filteredBauabschnitt
        ) {
          return false;
        }

        // Filter by Gemeindefilter
        return !(
          this.filteredGemeinde &&
          !item.name_company
            .toLowerCase()
            .includes(this.filteredGemeinde.toLowerCase())
        );
      });
    },
  },
  methods: {
    getIconPath(checkmark) {
      return checkmark
        ? new URL('@/assets/svg/check-green.svg', import.meta.url)
        : new URL('@/assets/svg/progress-icon.svg', import.meta.url);
    },
  },
};
</script>

<style lang="scss">
@import '@/components/storybook/src/assets/styles/index.scss';

.progress-date-item {
  @extend .standard-elevation-0;
  @apply bg-white rounded-[8px] overflow-hidden p-3 flex flex-col gap-3 w-fit h-fit text-blue-grey-700 items-center;
}
</style>
