<template>
  <div class="ma-auto w-96 py-8 forgot-password-page">
    <v-card class="text-center rounded-[8px] p-5 overflow-visible">
      <v-img :src="logoURL" contain max-height="110" class="my-8"></v-img>
      <h4 class="mb-2 justify-center">
        {{ $t('forgot.title') }}
      </h4>
      <div class="body-2 text-blue-grey-300 whitespace-normal">
        {{ $t('forgot.subtitle') }}
      </div>
      <!-- reset form -->
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          v-model="isFormValid"
          lazy-validation
          @submit.prevent>
          <div class="flex flex-col justify-center gap-6">
            <div class="flex flex-col align-start">
              <the-label label="E-Mail-Adresse" />
              <v-text-field
                v-model="username"
                :rules="[rules.required]"
                :validate-on-blur="false"
                :error="error"
                :error-messages="errorMessages"
                name="username"
                density="compact"
                bg-color="white"
                variant="outlined"
                hide-details
                class="text-black w-full"
                style=""
                @change="resetErrors"></v-text-field>
            </div>
            <div class="mx-auto">
              <button-el :text="$t('forgot.button')" @click="submit" />
            </div>
          </div>
        </v-form>
      </v-card-text>
      <div class="absolute bottom-0 -mb-8 text-sm left-0">
        <router-link to="/login" class="text-white" style="color: #ffffff">
          &laquo; {{ $t('forgot.backtosign') }}
        </router-link>
      </div>
    </v-card>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Forgot Page Component
|---------------------------------------------------------------------
|
| Template to send username to remember/replace password
|
*/
import { axios, axiosInstance } from '@/utils/axiosHelper';

export default {
  data() {
    return {
      // reset button
      isLoading: false,

      // form
      isFormValid: true,
      username: '',

      // form error
      error: false,
      errorMessages: '',

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Pflichtfeld',
      },
    };
  },
  computed: {
    logoURL() {
      return new URL('@/assets/img/logo_two_lines.svg', import.meta.url).href;
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.resetEmail(this.username);
        this.isLoading = false;
      }
    },
    resetEmail(username) {
      const url = axiosInstance.defaults.baseURL;
      axios({
        url: '/api/forgot_password/',
        data: { username, url },
        method: 'POST',
      });
    },
    resetErrors() {
      this.error = false;
      this.errorMessages = '';
    },
  },
};
</script>
