<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 15C20 15.1326 20.0527 15.2598 20.1464 15.3536C20.2402 15.4473 20.3674 15.5 20.5 15.5H21C21.6048 15.5002 22.1991 15.6573 22.725 15.956C23.2508 16.2546 23.6901 16.6847 24 17.204V4C24 3.53991 23.9093 3.08434 23.7331 2.65934C23.5568 2.23434 23.2985 1.84826 22.9729 1.52318C22.6473 1.19811 22.2609 0.940413 21.8356 0.764846C21.4103 0.589278 20.9546 0.499281 20.4945 0.500004C20.3628 0.501453 20.2371 0.55477 20.1445 0.648381C20.0519 0.741993 20 0.868344 20 1V15Z"
      :fill="fill" />
    <path
      d="M21 16.5H20.3885C20.0202 16.5 19.6671 16.3537 19.4067 16.0933C19.1463 15.8329 19 15.4798 19 15.1115V3.5H1.5C1.36739 3.5 1.24021 3.55268 1.14645 3.64645C1.05268 3.74021 1 3.86739 1 4V21C1 21.1326 1.05268 21.2598 1.14645 21.3536C1.24021 21.4473 1.36739 21.5 1.5 21.5H21C21.663 21.5 22.2989 21.2366 22.7678 20.7678C23.2366 20.2989 23.5 19.663 23.5 19C23.5 18.337 23.2366 17.7011 22.7678 17.2322C22.2989 16.7634 21.663 16.5 21 16.5ZM16.5 18C16.5 18.1326 16.4473 18.2598 16.3536 18.3536C16.2598 18.4473 16.1326 18.5 16 18.5H8C7.86739 18.5 7.74021 18.4473 7.64645 18.3536C7.55268 18.2598 7.5 18.1326 7.5 18V16C7.5 15.8674 7.55268 15.7402 7.64645 15.6464C7.74021 15.5527 7.86739 15.5 8 15.5C8.13261 15.5 8.25979 15.5527 8.35355 15.6464C8.44732 15.7402 8.5 15.8674 8.5 16V17.5H15.5V7.5H11V12C11 12.1326 10.9473 12.2598 10.8536 12.3536C10.7598 12.4473 10.6326 12.5 10.5 12.5H8C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12C7.5 11.8674 7.55268 11.7402 7.64645 11.6464C7.74021 11.5527 7.86739 11.5 8 11.5H10V7.5H4.5V18C4.5 18.1326 4.44732 18.2598 4.35355 18.3536C4.25979 18.4473 4.13261 18.5 4 18.5C3.86739 18.5 3.74021 18.4473 3.64645 18.3536C3.55268 18.2598 3.5 18.1326 3.5 18V7C3.5 6.86739 3.55268 6.74021 3.64645 6.64645C3.74021 6.55268 3.86739 6.5 4 6.5H16C16.1326 6.5 16.2598 6.55268 16.3536 6.64645C16.4473 6.74021 16.5 6.86739 16.5 7V18Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
