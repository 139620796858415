<template>
  <div ref="chart" style="width: 245px; height: 200px"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  computed: {
    maxStackedValue() {
      let maxVal = 0;
      let multiplier = 100;
      let largestItemValue = 0; // Variable to keep track of the largest item.value

      this.data.forEach((dataItem) => {
        let total = 0;

        dataItem.items.forEach((item) => {
          total += item.value;
          // Update largestItemValue if the current item's value is larger
          if (item.value > largestItemValue) {
            largestItemValue = item.value;
          }
        });

        maxVal = Math.max(maxVal, total);
      });

      // Apply the if-else logic to the largest item.value
      if (largestItemValue > 1 && largestItemValue <= 10) {
        multiplier = 10;
      } else if (largestItemValue <= 1) {
        multiplier = 1;
      } else {
        multiplier = 100;
      }

      return Math.max(maxVal, multiplier);
    },

    series() {
      const seriesData = [];

      this.data[0].items.forEach((_, index) => {
        // Create data and colors arrays for the current stack
        const data = [];
        const colors = [];

        this.data.forEach((dataItem) => {
          data.push(dataItem.items[index].value);
          colors.push(dataItem.items[index].color);
        });

        seriesData.push({
          name: this.data[0].items[index].label,
          type: 'bar',
          stack: 'total',
          data,
          itemStyle: {
            color: (params) => colors[params.dataIndex], // Use a function to retrieve the color for each bar in the stack
          },
          barWidth: '25px',
        });
      });

      // Add the background bar
      seriesData.unshift({
        type: 'bar',
        barWidth: '25px',
        itemStyle: {
          color: 'transparent',
          borderColor: '#ECEFF1', // Adjusted border color
          borderWidth: 1, // Thinner border
          borderType: 'solid',
        },
        // silent: true,
        data: this.data
          ? Array(this.data.length).fill(this.maxStackedValue)
          : [],
        barGap: '-100%',
      });

      return seriesData;
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.chartInstance.clear();
        this.initChart();
      },
    },
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chart);
    this.initChart();
  },
  beforeUnmount() {
    this.chartInstance.dispose();
  },
  methods: {
    initChart() {
      const option = {
        tooltip: {
          trigger: 'item',
          position: 'top',
          borderColor: '#ECEFF1',
          borderWidth: 1,
          borderRadius: 0,
          shadowColor: 'transparent',
          padding: [4, 8],
          textStyle: {
            fontSize: 12,
            fontFamily: 'Inter',
          },
          axisPointer: {
            type: 'none',
          },
          formatter: (params) => {
            const dataItem = this.data[params.dataIndex];
            const nonZeroItems = dataItem.items.filter(
              (item) => item.value > 0,
            );
            if (nonZeroItems.length > 1) {
              let tooltipContent = '';
              nonZeroItems.forEach((item) => {
                tooltipContent +=
                  item.label +
                  ': ' +
                  Number(item.value).toLocaleString('de-DE', {
                    maximumFractionDigits: 1,
                  }) +
                  ' MW<br/>';
              });
              return tooltipContent;
            }
            return null;
          },
        },

        legend: {
          show: false,
        },
        grid: {
          top: '5%',
          left: '5%',
          right: '3%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.data.map((item) => [
            item.title,
            item.items.reduce((acc, item) => acc + item.value, 0),
            Object.keys(item.items).map((key) => [
              item.items[key].label,
              item.items[key].value,
            ]),
          ]),
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            interval: 0,
            formatter: (value) => {
              const parts = value.split(',');
              return (
                '{a|' +
                parts[0] +
                '}\n' +
                '{b|' +
                Number(parts[1]).toLocaleString('de-DE', {
                  maximumFractionDigits: 1,
                }) +
                ' MW}'
              );
            },
            rich: {
              a: {
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: 12,
                lineHeight: 16,
                verticalAlign: 'middle',
              },
              b: {
                fontFamily: 'Borna',
                fontWeight: 800,
                fontSize: 13,
                lineHeight: 16,
                verticalAlign: 'middle',
              },
            },
          },
        },

        yAxis: {
          type: 'value',
          max: this.maxStackedValue,
          axisLabel: { show: false },
          axisLine: { show: false }, // Hide axis line
          axisTick: { show: false }, // Hide axis tick marks
          splitLine: { show: false }, // Hide grid lines in the background
        },
        series: this.series,
      };
      this.chartInstance.setOption(option);
    },
  },
};
</script>

<style scoped>
/* Add any styles if needed */
</style>
