<template>
  <div>
    <!-- menu level 1 -->
    <template v-if="!hideAll">
      <template v-for="(level1Item, level1Index) in menu">
        <nav-menu-item
          v-if="!hideSub(level1Item)"
          :key="level1Index"
          :menu-item="level1Item"
          class="nav-menu-item"
          :open="open"
          @emit-activator="setOpenedActivator($event)">
          <template v-if="level1Item.items">
            <!-- menu level 2 -->
            <nav-menu-item
              v-for="(level2Item, level2Index) in level1Item.items"
              :key="level2Index"
              :menu-item="level2Item"
              subgroup
              small>
              <template v-if="level2Item.items">
                <!-- menu level 3 -->
                <nav-menu-item
                  v-for="(level3Item, level3Index) in level2Item.items"
                  :key="level3Index"
                  :menu-item="level3Item"
                  small />
              </template>
            </nav-menu-item>
          </template>
        </nav-menu-item>
      </template>
    </template>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Navigation Menu Component
|---------------------------------------------------------------------
|
| Multi-layer navigation menu
|
| menu: [{ text: 'Menu Levels',
|    items: [
|      { text: 'Menu Levels 2.1' },
|      { text: 'Menu Levels 2.2',
|        items: [
|          { text: 'Menu Levels 3.1' },
|          { text: 'Menu Levels 3.2' }
|        ]
|      }
|    ]
|  }]
|
*/
export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    open: null,
  }),
  computed: {
    hideAll() {
      for (let subMenu of this.menu)
        if (subMenu.modelNameForCASL) {
          if (this.$can('view', subMenu.modelNameForCASL)) {
            return false;
          }
        } else {
          return false;
        }
      return true;
    },
  },
  methods: {
    setOpenedActivator(title) {
      if (this.open === title) this.open = null;
      else this.open = title;
    },
    hideSub(subMenu) {
      if (subMenu.modelNameForCASL) {
        if (this.$can('view', subMenu.modelNameForCASL)) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    },
  },
};
</script>
