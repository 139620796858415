<template>
  <div class="flex items-center relative w-full" :class="classes">
    <input
      v-model="location"
      type="text"
      :placeholder="placeholder"
      class="p-[8px] focus:outline-none w-full"
      :class="{
        'body-2 placeholder:text-[14px]': small,
        'body-1 placeholder:text-[16px]': !small,
      }"
      @input="
        searchLocation();
        searchState(true);
      " />
    <div
      v-if="!location.length"
      class="search-bar-icon absolute right-0 pointer-events-none h-[16px]">
      <img src="@/assets/svg/search.svg" alt="search" />
    </div>
    <div
      v-else
      class="h-[32px] w-[32px] cursor-pointer absolute right-0 flex items-center justify-center"
      @click="closeSearchbar(false)">
      <v-icon>mdi-close</v-icon>
    </div>

    <!-- search results-->
    <search-results />
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters } from 'vuex';
import { removeMarker } from '@/mapbox/main';

export default {
  name: 'SearchBar',
  props: {
    placeholder: {
      type: String,
      default: 'Suchbegriff',
    },
    backgroundWhite: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showSearchResults: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      location: '',
    };
  },
  methods: {
    searchLocation: debounce(function () {
      this.$store.dispatch('search/FETCH_PLACES', this.location);
    }, 500),
    searchState(payload) {
      this.$store.dispatch('search/DISPLAY_SEARCHBAR', payload);
    },
    closeSearchbar(payload) {
      this.$store.dispatch('search/DISPLAY_SEARCHBAR', payload);
      this.$store.commit('search/RESET_PLACES', null);
      // removeMarker();
      this.location = '';
    },
  },
  computed: {
    ...mapGetters({
      showSearch: 'search/showSearch',
    }),
    classes() {
      //   return some props
      return {
        'bg-white': this.backgroundWhite,
        'rounded-[6px]': this.rounded,
        'h-[32px]': this.small,
      };
    },
  },
  watch: {
    showSearch(val) {
      if (!val) {
        this.location = '';
      }
    },
  },
};
</script>

<style scoped></style>
