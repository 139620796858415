<template>
  <div class="flex flex-col gap-4 overflow-hidden">
    <Transition name="slide-up">
      <div v-if="!hubCardActive" class="flex flex-col gap-4">
        <h3>Gesamtstatus Projekte auf Gebietsebene</h3>
        <DashboardFilter />
      </div>
    </Transition>
    <DashboardCards />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onBeforeMount, ref } from 'vue';

const store = useStore();
const router = useRouter();

onBeforeMount(() => {
  // Fetch the latest cards after initialization.
  const allCards = store.getters['dashboard/getAggregatedCardsData'];

  // Determine the id based on the current route.
  const currentCard = allCards.find(
    (card) => card.route === router.currentRoute.value.path
  );

  if (currentCard) {
    store.dispatch('dashboard/UPDATE_CARD_STATUS', currentCard.id);
  }
});

const hubCardActive = computed(
  () => store.getters['dashboard/getHubCardActive']
);

router.afterEach((to, from) => {
  const allCards = store.getters['dashboard/getAggregatedCardsData'];

  // Determine the id based on the current route.
  const currentCard = allCards.find((card) => card.route === to.path);

  if (currentCard) {
    store.dispatch('dashboard/UPDATE_CARD_STATUS', currentCard.id);
  }
});
</script>

<style lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: max-height 0.3s ease-in-out, opacity 0s;
}

.slide-up-leave-active,
.slide-up-enter-to {
  max-height: 100px;
  opacity: 0;
}

.slide-up-leave-to,
.slide-up-enter-from {
  max-height: 0;
  opacity: 0;
}
</style>
