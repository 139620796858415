<template>
  <component
    :is="isDisabled ? 'span' : 'router-link'"
    :to="data.route"
    class="overflow-hidden">
    <div
      class="card standard-elevation-0-dark"
      :class="{
        'hub-card-active': hubCardActive,
      }"
      :style="{
        'background-color': data.colors.bg,
      }">
      <h4
        :class="{
          'active-card-top': currentCard.id === data.id,
          'whitespace-pre-wrap': currentCard.id !== data.id,
        }"
        class="dashboard-card-label">
        {{ formatDataLabel(data) }}
      </h4>
      <dashboard-card-content v-if="!hubCardActive" :data="data" />
      <dashboard-card-bottom v-if="!isActive" :data="data" />
    </div>
  </component>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  isDisabled: {
    type: Boolean,
    default: () => false,
  },
  isActive: {
    type: Boolean,
    default: () => false,
  },
});

const hubCardActive = computed(
  () => store.getters['dashboard/getHubCardActive']
);

const cards = computed(() => store.getters['dashboard/getDashboardCards']);

const currentCard = computed(() =>
  cards.value.find((card) => card.id === router.currentRoute.value.meta.id)
);

const formatDataLabel = (data) => {
  if (currentCard.value.id !== data.id) {
    return data.label;
  } else {
    return data.label.replace(/-/g, '');
  }
};
</script>

<style lang="scss">
@import '@/components/storybook/src/assets/styles/index.scss';

.card {
  @apply w-full p-3 rounded-[8px] h-full flex flex-col gap-3 text-white;

  .dashboard-card-label {
    min-height: 52px;

    &.active-card-top {
      min-height: fit-content !important;
    }
  }

  &.hub-card-active {
    @apply p-2.5 gap-0;

    .dashboard-card-label {
      @extend h5;
      height: unset;
    }
  }
}
</style>
