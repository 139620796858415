import { multiLineString, multiPolygon } from '@turf/helpers';
import { getTicketsAsFC } from './utils';

export default {
  getActiveProcess: (state) =>
    state.ticketProcesses.find((e) => e.selected === true) || {},
  getNotes: (state) => getTicketsAsFC(state.notes),
  getDevelopmentAreas: (state) => getTicketsAsFC(state.developmentAreas),
  getCoLayings: (state) => getTicketsAsFC(state.coLayings),
  getAddressValidation: (state) => getTicketsAsFC(state.addressValidation),
  getMevValidation: (state) => getTicketsAsFC(state.mevValidation),
  getDrawFeaturesCount: (state) => state.drawFeatures.length,
  getTicketStep: (state) => state.ticketStep,
  getValidationStep: (state) => state.validationStep,
  getDrawAsMultiFeatures: (state) => {
    const lines = [];
    const polygons = [];
    state.drawFeatures.forEach((f) => {
      const type = f.geometry.type.toLowerCase();
      const coords = f.geometry.coordinates;
      if (type === 'polygon') polygons.push(coords);
      if (type === 'linestring') lines.push(coords);
    });
    return {
      multipolygon:
        polygons.length > 0 ? multiPolygon(polygons).geometry : null,
      multilinestring:
        lines.length > 0 ? multiLineString(lines).geometry : null,
    };
  },
  getGemeindeActive: (state) => state.gemeindeActive,
  getGemarkungActive: (state) => state.gemarkungActive,
  getGemeindeActiveAgs: (state) =>
    state.gemeindeActive ? state.gemeindeActive.ags : null,
  getShowValidationTicket: (state) => state.showValidationTicket,
  getAddressValidationOption: (state) =>
    state.addressValidationData.data.actions.POST,
  getMevValidationOption: (state) =>
    state.mevValidationData.data.actions.POST.category.choices,
  drawControlDeleteMode: (state) => !!state.drawSelectedIds.length,
  getDrawSelectedIds: (state) => state.drawSelectedIds,
  getBuilttData: (state) => state.builttData.data,
  getBuilttLotData: (state) => state.builttLotData,
  getMevDashboardData: (state) => state.mevDashboardData,
  getMevGemarkungenData: (state) => state.mevGemarkungenData,
  getRichtlinien: (state) => state.richtlinien,
  getRichtlinieSelected: (state) => state.richtlinieSelected,
  getLandkreise: (state) => state.landkreise,
  getLandkreiseActive: (state) => state.landkreiseActive,
  getGemeinden: (state) => state.gemeinden,
  getGemeindenFiltered: (state) => state.gemeindenFiltered,
  getGemeindenActive: (state) => state.gemeindenActive,
  getTku: (state) => state.tku,
  getTkuActive: (state) => state.tkuActive,
  getExpansionTypes: (state) =>
    state.mevDashboardData?.data?.fields?.expansion || {},
  getNotesLoading: (state) => state.notesLoading,
  getDevelopmentAreasLoading: (state) => state.developmentAreasLoading,
  getCoLayingsLoading: (state) => state.coLayingsLoading,
  getAddressValidationLoading: (state) => state.addressValidationLoading,
  getMevValidationLoading: (state) => state.mevValidationLoading,
};
