<template>
  <single-page>
    <div class="flex flex-col gap-5 p-5">
      <!-- title-->
      <div class="flex gap-3 items-center justify-start">
        <img src="@/assets/svg/bausteuerung.svg" alt="Logo" />
        <h2>Digitale Bausteuerung</h2>
      </div>

      <!-- subtitle-->
      <h3>Übersicht GÜ-Rahmenverträge</h3>
      <DashboardFilter v-if="loaded" />
      <!-- table-->
      <div
        class="scenario-list-custom rounded overflow-hidden">
        <div v-if="!loaded" class="flex flex-col">
          <div class="h-[48px] bg-[#f2f3f4] w-full"></div>
          <skeleton-loader-list />
        </div>
        <vuetify-data-table
          v-else
          style="padding-bottom: 100px"
          :headers="headers"
          :item-data="filteredBuilttData"
          item-value="lot_id"
          disable-inner-shadow
          expanded-background=""
          :expand-on-row-click="true"
          :show-pagination="true"
          :show-total="true">
          <template #sum_invest_tender="item">
            <template v-if="item.item.sum_invest_tender">
              <div class="flex justify-end items-center">
                {{ formatNumber(item.item.sum_invest_tender) }} Mio. Euro
              </div>
            </template>
            <template v-else> - </template>
          </template>
          <template #sum_invest_planned="item">
            <div class="flex justify-end items-center">
              <template v-if="item.item.sum_invest_planned">
                {{ formatNumber(item.item.sum_invest_planned) }} Mio. Euro
              </template>
              <template v-else> - </template>
            </div>
          </template>
          <template #checkmark="item">
            <div class="flex items-center justify-center">
              <img
                class="opacity-80"
                :src="getIconPath(item.item.checkmark)"
                alt="progress-icon" />
            </div>
          </template>
          <!--          <template #functions>-->
          <!--            <div class="flex gap-4 text-blue-grey-700 font-bold">-->
          <!--              <img-->
          <!--                class="opacity-40 hover:opacity-70"-->
          <!--                :src="'@/assets/svg/map-add.svg'"-->
          <!--                alt="map-add" />-->
          <!--            </div>-->
          <!--          </template>-->
          <template #expanded-row="{ item }">
            <gantt-chart :data="item" />
          </template>
        </vuetify-data-table>
      </div>
    </div>
  </single-page>
</template>
<script>
import { mapGetters } from 'vuex';
import DashboardFilter from '@/views/dashboard-views/layout-components/DashboardFilter.vue';

export default {
  name: 'ConstructionControl',
  components: { DashboardFilter },
  data() {
    return {
      loaded: false,
      currentExpanded: null,
      headers: [
        {
          title: 'Cluster',
          key: 'cluster_name',
        },
        {
          title: 'Los',
          key: 'lot_name',
        },
        {
          title: 'GÜ',
          key: 'name_company',
        },
        {
          title: 'Ausgeschriebene Investitionen',
          key: 'sum_invest_tender',
          align: 'end',
        },
        {
          title: 'Geplante Investitionen',
          key: 'sum_invest_planned',
          align: 'end',
        },
        {
          title: 'Status',
          key: 'checkmark',
          align: 'center',
        },
        // {
        //   title: '',
        //   key: 'functions',
        //   sortable: false,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('ticket', [
      'getBuilttData',
      'getBuilttLotData',
      'getGemeindenActive',
      'getLandkreiseActive',
      'getGemeindenFiltered',
    ]),
    ...mapGetters('dashboard', ['getSelectedSections']),
    filteredBuilttData() {
      const sectionSet = new Set(this.getSelectedSections.map((s) => s.value));
      const gemeindeSet = new Set(this.getGemeindenActive.map((g) => g.ags));
      let filteredData = JSON.parse(JSON.stringify(this.getBuilttData));
      for (let entry of filteredData) {
        entry.sections = entry.sections.filter(
          (section) =>
            sectionSet.has(section.section_id) &&
            gemeindeSet.has(section.gemeinde_id)
        );
      }
      filteredData = filteredData.filter((entry) => entry.sections.length > 0);

      return filteredData;
    },
  },
  mounted() {
    this.$store.dispatch('ticket/GET_BUILTT_DATA').then((data) => {
      this.$store.commit('ticket/SET_LANDKREISE', data.data.gemeinden);
      const landkreiseSelected = this.getLandkreiseActive;
      this.$store.commit('ticket/SET_GEMEINDEN_FILTERED', landkreiseSelected);
      this.$store.commit(
        'ticket/SET_GEMEINDEN_ACTIVE',
        this.getGemeindenFiltered
      );
      this.loaded = true;
    });
  },
  methods: {
    getIconPath(checkmark) {
      return checkmark
        ? new URL('/src/assets/svg/check-green.svg', import.meta.url).href
        : new URL('/src/assets/svg/progress-icon.svg', import.meta.url).href;
    },
    hasLotName(array, value) {
      return array.some((obj) => obj.lot_id === value);
    },
    formatNumber(number) {
      const rounded = (number / 1000000).toFixed(1);
      return Number(rounded).toLocaleString('de-DE', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },
  },
};
</script>

<style lang="scss">
//  skeleton-loader
.skeleton-loader-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skeleton-row {
  width: 100%;
  height: 52px;
  background-color: silver;
  animation: skeleton-pulse 1s ease-in-out infinite;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
