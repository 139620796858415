<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    :width="width"
    :height="height">
    <g stroke-width="1" :fill="_secondaryfill" :stroke="_secondaryfill">
      <circle
        cx="8"
        cy="8"
        r="7.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke="fill"></circle>
      <line
        x1="11"
        y1="5"
        x2="5"
        y2="11"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"></line>
      <line
        x1="5"
        y1="5"
        x2="11"
        y2="11"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"></line>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
