<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.112"
    height="7.584"
    viewBox="0 0 10.112 7.584"
  >
    <g id="triangle-down" transform="translate(-130 -1)">
      <path
        id="Pfad_71"
        data-name="Pfad 71"
        d="M9.691,2H.421a.421.421,0,0,0-.347.66L4.709,9.4a.421.421,0,0,0,.694,0L10.038,2.66A.421.421,0,0,0,9.691,2Z"
        transform="translate(130 -1)"
        fill="#001257"
      />
    </g>
  </svg>
</template>
