<template>
  <DashboardContentLayout>
    <DashboardFilter />

    <div
      class="flex justify-between items-center w-full border-b-2 border-[#e0e0e0] mb-[20px]">
      <h4 class="text-infra-highlight-700 pb-1">
        Ergebnis
        {{
          gemarkungenIsLoaded && splitGemarkung ? 'Gemarkungen' : 'Gemeinden'
        }}
      </h4>

      <div
        v-if="getGemeindenActive && getGemeindenActive.length === 1"
        class="w-fit">
        <vuetify-switch
          label="Split nach Gemarkung"
          @selected="splitGemarkung = $event" />
      </div>
    </div>
    <div
      v-if="!getGemeindenActive || getGemeindenActive.length === 0"
      class="body-1">
      Keine Gemeinde ausgewählt
    </div>
    <div v-else-if="!getTkuActive || getTkuActive.length === 0" class="body-1">
      Kein TKU ausgewählt
    </div>
    <div v-else class="flex flex-col gap-6">
      <div class="w-full">
        <stackedbar-chart
          :custom-min-height="38"
          :bar-width="15"
          :category-order="categoryOrderData"
          :chart-data="
            splitGemarkung &&
            gemarkungenIsLoaded &&
            getGemeindenActive.length === 1
              ? mevResultsGemarkungenFormatted
              : mevData
          " />
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div class="w-full flex justify-center">
          <doughtnut-chart
            class="max-w-[60%] min-w-[400px]"
            :custom-radius="['42%', '55%']"
            :legend-data="getTku"
            :custom-tooltip="expansionCompanyData"
            :show-default-tooltip="false"
            title="Ausbauankündigungen"
            title-inside="Gebäude"
            custom-margin-bottom="-mb-10"
            :chart-data="expansionData" />
        </div>
        <div class="w-full flex justify-center">
          <doughtnut-chart
            class="max-w-[60%] min-w-[400px]"
            :custom-radius="['42%', '55%']"
            :legend-data="legendDataVersorgungsgrad"
            :show-default-tooltip="false"
            title="Versorgungsgrad"
            title-inside="Gebäude"
            custom-margin-bottom="-mb-10"
            :chart-data="versorgungsGradData" />
        </div>
      </div>
    </div>
  </DashboardContentLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      splitGemarkung: false,
      gemarkungenIsLoaded: false,
    };
  },
  computed: {
    ...mapGetters('ticket', [
      'getMevDashboardData',
      'getMevGemarkungenData',
      'getRichtlinieSelected',
      'getLandkreise',
      'getLandkreiseActive',
      'getGemeindenFiltered',
      'getGemeindenActive',
      'getTku',
      'getTkuActive',
      'getExpansionTypes',
    ]),

    expansionData() {
      return this.aggregateData(
        this.dataFilteredByGemeinden,
        'expansion',
        this.getExpansionTypes
      );
    },

    expansionCompanyData() {
      const aggregatedData = {};
      this.dataFilteredByGemeinden.forEach((item) => {
        item['expansion'].forEach((subItem) => {
          const key = subItem['expansion'];
          const companyKey = subItem['company'];
          if (aggregatedData[key] !== undefined) {
            if (aggregatedData[key][companyKey] !== undefined) {
              aggregatedData[key][companyKey] += subItem.count;
            } else {
              aggregatedData[key][companyKey] = subItem.count;
            }
          } else {
            aggregatedData[key] = { [companyKey]: subItem.count };
          }
        });
      });
      return Object.entries(aggregatedData).map(([key, value]) => ({
        name: this.getExpansionTypes[Number(key)],
        value: value,
      }));
    },

    categoryOrderData() {
      return this.getMevDashboardData?.data
        ? this.getMevDashboardData?.data?.fields.mev_class
        : null;
    },

    dataFilteredByGemeinden() {
      if (!this.getGemeindenActive) {
        return [];
      }
      return (
        this.getMevDashboardData?.data?.values?.filter((item) =>
          this.getGemeindenActive.map((g) => g.ags).includes(item.gemeinde_id)
        ) || []
      );
    },

    mevResultsGemarkungenFormatted() {
      return (
        this.getMevGemarkungenData?.data?.values.map((item) => ({
          mev: [...item.mev],
          gemeinde_name: item.name,
          gemeinde_id: item.gemarkung_id,
        })) || []
      );
    },

    mevData() {
      return (
        this.dataFilteredByGemeinden?.map((item) => ({
          mev: [...item.mev],
          gemeinde_name: item.name.gemeinde_name,
          gemeinde_id: item.gemeinde_id,
        })) || []
      );
    },

    legendDataVersorgungsgrad() {
      return this.getMevDashboardData?.data?.fields?.versorgungsgrad;
    },

    versorgungsGradData() {
      return this.aggregateData(
        this.dataFilteredByGemeinden,
        'versorgungsgrad',
        this.legendDataVersorgungsgrad
      );
    },
  },
  watch: {
    getRichtlinieSelected() {
      if (this.splitGemarkung) {
        this.$store
          .dispatch('ticket/GET_MEV_GEMARKUNGEN_DATA', {
            gemeinde: this.getGemeindenActive.map((g) => g.ags),
            richtlinie: this.getRichtlinieSelected,
          })
          .then(() => {
            this.gemarkungenIsLoaded = true;
          });
      } else {
        this.loadData();
      }
    },
    getGemeindenActive() {
      this.gemarkungenIsLoaded = false;
      this.splitGemarkung = false;
      this.$store.commit('ticket/SET_MEV_GEMARKUNGEN_DATA', []);
    },
    getTkuActive: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (
          (!oldVal && newVal) ||
          (oldVal.length && JSON.stringify(newVal) !== JSON.stringify(oldVal))
        ) {
          this.loadData(false);
        }
      },
    },
    splitGemarkung(val) {
      if (this.getMevGemarkungenData.length === 0 && val) {
        this.$store
          .dispatch('ticket/GET_MEV_GEMARKUNGEN_DATA', {
            gemeinde: this.getGemeindenActive.map((g) => g.ags),
            richtlinie: this.getRichtlinieSelected,
          })
          .then(() => {
            this.gemarkungenIsLoaded = true;
          });
      }
    },
  },
  methods: {
    aggregateData(inputData, field, legendDataMapping) {
      const aggregatedData = {};

      inputData.forEach((item) => {
        item[field].forEach((subItem) => {
          const key = subItem[field];
          if (aggregatedData[key] !== undefined) {
            aggregatedData[key] += subItem.count;
          } else {
            aggregatedData[key] = subItem.count;
          }
        });
      });

      return Object.entries(aggregatedData).map(([key, value]) => ({
        name: legendDataMapping[Number(key)],
        value: value,
      }));
    },
    loadData() {
      this.$store.dispatch('ticket/GET_MEV_DASHBOARD_DATA');
    },
  },
};
</script>
