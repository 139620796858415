import {
  LAYER_KEY__NOTES_POLYGON,
  LAYER_KEY__NOTES_LINE,
  LAYER_KEY__NOTES_POINT,
  LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
  LAYER_KEY__DEVELOPMENT_AREA_LINE,
  LAYER_KEY__COLAYING_POINT,
  LAYER_KEY__COLAYING_LINE,
  LAYER_KEY__ADDRESS_VALIDATION_POINT,
  LAYER_KEY__ADDRESS_VALIDATION_POLYGON,
  LAYER_KEY__MEV_VALIDATION_POLYGON,
} from '../layers/tickets';
import { LAYER_KEY__TENDER_ROUTE } from '@/configs/layers/tenders';

export const SWITCH_ID__NOTES = 'LC_NOTES';
export const SWITCH_ID__DEVELOPMENT_AREA = 'LC_DEVELOPMENT_AREA';
export const SWITCH_ID__COLAYING = 'LC_COLAYING';
export const SWITCH_ID__ADDRESS_VALIDATION = 'LC_ADDRESS_VALIDATION';

export const SWITCH_ID__MEV_VALIDATION = 'LC_MEV_VALIDATION';

export default {
  title: 'Datenerfassung',
  items: [
    {
      active: false,
      title: 'Notizen',
      onLayers: [
        LAYER_KEY__NOTES_POLYGON,
        LAYER_KEY__NOTES_LINE,
        LAYER_KEY__NOTES_POINT,
      ],
      switchId: SWITCH_ID__NOTES,
      itemClass: 'switch',
      modelNameForCASL: 'note', // for CASL permissions
      loadingRefFromStorage: 'ticket/getNotesLoading',
    },
    {
      active: false,
      title: 'Neubaugebiet',
      onLayers: [
        LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
        LAYER_KEY__DEVELOPMENT_AREA_LINE,
      ],
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [
                LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
                LAYER_KEY__DEVELOPMENT_AREA_LINE,
              ],
              filter: 'reset',
            },
            {
              title: 'Offen',
              layerKeys: [
                LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
                LAYER_KEY__DEVELOPMENT_AREA_LINE,
              ],
              filter: ['==', ['get', 'status'], 'Offen'],
            },
            {
              title: 'In Bearbeitung',
              layerKeys: [
                LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
                LAYER_KEY__DEVELOPMENT_AREA_LINE,
              ],
              filter: ['==', ['get', 'status'], 'In Bearbeitung'],
            },
            {
              title: 'Abgeschlossen',
              layerKeys: [
                LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
                LAYER_KEY__DEVELOPMENT_AREA_LINE,
              ],
              filter: ['==', ['get', 'status'], 'Abgeschlossen'],
            },
            {
              title: 'Unbekannt',
              layerKeys: [
                LAYER_KEY__DEVELOPMENT_AREA_POLYGON,
                LAYER_KEY__DEVELOPMENT_AREA_LINE,
              ],
              filter: ['==', ['get', 'status'], 'Unbekannt'],
            },
          ],
        },
      ],
      switchId: SWITCH_ID__DEVELOPMENT_AREA,
      itemClass: 'switch',
      modelNameForCASL: 'developmentarea',
      loadingRefFromStorage: 'ticket/getDevelopmentAreasLoading',
    },
    {
      active: false,
      title: 'Mitverlegung',
      onLayers: [LAYER_KEY__COLAYING_POINT, LAYER_KEY__COLAYING_LINE],
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [LAYER_KEY__COLAYING_POINT, LAYER_KEY__COLAYING_LINE],
              filter: 'reset',
            },
            {
              title: 'Offen',
              layerKeys: [LAYER_KEY__COLAYING_POINT, LAYER_KEY__COLAYING_LINE],
              filter: ['==', ['get', 'status'], 'Offen'],
            },
            {
              title: 'In Bearbeitung',
              layerKeys: [LAYER_KEY__COLAYING_POINT, LAYER_KEY__COLAYING_LINE],
              filter: ['==', ['get', 'status'], 'In Bearbeitung'],
            },
            {
              title: 'Abgeschlossen',
              layerKeys: [LAYER_KEY__COLAYING_POINT, LAYER_KEY__COLAYING_LINE],
              filter: ['==', ['get', 'status'], 'Abgeschlossen'],
            },
            {
              title: 'Unbekannt',
              layerKeys: [LAYER_KEY__COLAYING_POINT, LAYER_KEY__COLAYING_LINE],
              filter: ['==', ['get', 'status'], 'Unbekannt'],
            },
          ],
        },
      ],
      switchId: SWITCH_ID__COLAYING,
      itemClass: 'switch',
      modelNameForCASL: 'colaying',
      loadingRefFromStorage: 'ticket/getCoLayingsLoading',
    },
    {
      active: false,
      title: 'Adress-Validierung',
      onLayers: [
        LAYER_KEY__ADDRESS_VALIDATION_POINT,
        LAYER_KEY__ADDRESS_VALIDATION_POLYGON,
      ],
      switchId: SWITCH_ID__ADDRESS_VALIDATION,
      itemClass: 'switch',
      modelNameForCASL: 'addressvalidation',
      loadingRefFromStorage: 'ticket/getAddressValidationLoading',
    },
    {
      active: false,
      title: 'MEV-Validierung',
      onLayers: [LAYER_KEY__MEV_VALIDATION_POLYGON],
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [LAYER_KEY__MEV_VALIDATION_POLYGON],
              filter: 'reset',
            },
            {
              title: 'Offen',
              layerKeys: [LAYER_KEY__MEV_VALIDATION_POLYGON],
              filter: ['==', ['get', 'status'], 'Offen'],
            },
            {
              title: 'In Bearbeitung',
              layerKeys: [LAYER_KEY__MEV_VALIDATION_POLYGON],
              filter: ['==', ['get', 'status'], 'In Bearbeitung'],
            },
            {
              title: 'Abgeschlossen',
              layerKeys: [LAYER_KEY__MEV_VALIDATION_POLYGON],
              filter: ['==', ['get', 'status'], 'Abgeschlossen'],
            },
            {
              title: 'Unbekannt',
              layerKeys: [LAYER_KEY__MEV_VALIDATION_POLYGON],
              filter: ['==', ['get', 'status'], 'Unbekannt'],
            },
          ],
        },
      ],
      switchId: SWITCH_ID__MEV_VALIDATION,
      itemClass: 'switch',
      modelNameForCASL: 'mevvalidation',
      loadingRefFromStorage: 'ticket/getMevValidationLoading',
    },
  ],
};
