<template>
  <DropDown
    v-model="selectedFilter"
    class="py-2 px-4 bg-infra-highlight-25"
    :items-data="menuItem.items"
    items-data-key="title"
    :initial-selection="true"
    :label="menuItem.label"
    :rules="{ required: true }"
    size="m"
    value="filter"
    @update:model-value="setFilter($event)" />
</template>

<script>
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedFilter: null,
    };
  },
  mounted() {
    this.setFilter(this.menuItem.default.filter);
  },
  methods: {
    setFilter(filter) {
      this.$store.commit('map/SET_FILTER', {
        layerKeys: this.menuItem.layers,
        filter,
      });
      if (typeof this.menuItem.customFunction !== 'undefined') {
        this.menuItem.customFunction(this, filter);
      }
    },
  },
};
</script>
