import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import {
  LAYER_SOURCE__COLAYING,
  LAYER_SOURCE__DEVELOPMENT_AREA,
  LAYER_SOURCE__NOTES,
  LAYER_SOURCE__ADDRESS_VALIDATION,
  LAYER_SOURCE__MEV_VALIDATION,
} from '@/configs/layers/tickets';

import getters from './getters';

export default {
  GET_BUILTT_DATA: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/builtt/get_construction_overview/',
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_BUILTT_DATA', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_BUILTT_LOT_DATA: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/builtt/byLotId/${payload}`,
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_BUILTT_LOT_DATA', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_MEV_DASHBOARD_DATA: ({ commit, getters }) =>
    new Promise((resolve, reject) => {
      let richtlinie = getters['getRichtlinieSelected'];
      let richtlinieFinal;
      if (Array.isArray(richtlinie)) {
        richtlinieFinal =
          'value' in richtlinie[0] ? richtlinie[0].value : richtlinie[0];
      } else {
        richtlinieFinal = 'value' in richtlinie ? richtlinie.value : richtlinie;
      }
      const tku = getters['getTkuActive']
        ? getters['getTkuActive'].reduce(
            (prev, cur) => prev + '&tku[]=' + cur.value,
            ''
          )
        : '';
      axios({
        url: `/api/mev/MEVResult/dashboard/?richtlinie=${richtlinieFinal}${tku}`,
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_MEV_DASHBOARD_DATA', resp);
          commit('SET_TKU', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_MEV_GEMARKUNGEN_DATA: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/mev/MEVResult/dashboard/?gemeinde[]=${payload.gemeinde}&richtlinie=${payload.richtlinie}`,
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_MEV_GEMARKUNGEN_DATA', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_NOTES: ({ commit }) => {
    axios({
      url: '/api/ticket/Note/',
      method: 'GET',
    })
      .then((resp) => {
        resp.keyInStore = 'notes';
        resp.layerSource = LAYER_SOURCE__NOTES;
        commit('SET_TICKETS', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GET_DEVELOPMENT_AREA: ({ commit }) => {
    axios({
      url: '/api/ticket/DevelopmentArea/',
      method: 'GET',
    })
      .then((resp) => {
        resp.keyInStore = 'developmentAreas';
        resp.layerSource = LAYER_SOURCE__DEVELOPMENT_AREA;
        commit('SET_TICKETS', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GET_CO_LAYING: ({ commit }) => {
    axios({
      url: '/api/ticket/CoLaying/',
      method: 'GET',
    })
      .then((resp) => {
        resp.keyInStore = 'coLayings';
        resp.layerSource = LAYER_SOURCE__COLAYING;
        commit('SET_TICKETS', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GET_ADDRESS_VALIDATION_OPTION: ({ commit }) => {
    axios({
      url: `/api/ticket/AddressValidation/`,
      method: 'OPTIONS',
    })
      .then((resp) => {
        commit('SET_ADDRESS_VALIDATION_OPTION', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GET_ADDRESS_VALIDATION: ({ commit }) => {
    axios({
      url: `/api/ticket/AddressValidation/`,
      method: 'GET',
    })
      .then((resp) => {
        resp.keyInStore = 'addressValidation';
        resp.layerSource = LAYER_SOURCE__ADDRESS_VALIDATION;
        commit('SET_TICKETS', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GET_MEV_VALIDATION_OPTION: ({ commit }) => {
    axios({
      url: `/api/ticket/MEVValidation/`,
      method: 'OPTIONS',
    })
      .then((resp) => {
        commit('SET_MEV_VALIDATION_OPTION', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GET_MEV_VALIDATION: ({ commit }) => {
    axios({
      url: `/api/ticket/MEVValidation/`,
      method: 'GET',
    })
      .then((resp) => {
        resp.keyInStore = 'mevValidation';
        resp.layerSource = LAYER_SOURCE__MEV_VALIDATION;
        commit('SET_TICKETS', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  POST_DEVELOPMENT_AREA: ({ commit }, ticket) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/ticket/DevelopmentArea/',
        method: 'POST',
        headers: {
          'X-CSRFToken': cookie.get('csrftoken'),
          'content-type': 'multipart/form-data',
        },
        data: ticket,
      })
        .then((resp) => {
          resp.keyInStore = 'developmentAreas';
          resp.layerSource = LAYER_SOURCE__DEVELOPMENT_AREA;
          commit('ADD_TICKET', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  POST_COLAYING: ({ commit }, ticket) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/ticket/CoLaying/',
        method: 'POST',
        headers: {
          'X-CSRFToken': cookie.get('csrftoken'),
          'content-type': 'multipart/form-data',
        },
        data: ticket,
      })
        .then((resp) => {
          resp.keyInStore = 'coLayings';
          resp.layerSource = LAYER_SOURCE__COLAYING;
          commit('ADD_TICKET', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  POST_NOTE: ({ commit }, ticket) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/ticket/Note/',
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
        data: ticket,
      })
        .then((resp) => {
          resp.keyInStore = 'notes';
          resp.layerSource = LAYER_SOURCE__NOTES;
          commit('ADD_TICKET', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DELETE_NOTE: ({ commit }, { id, successMessage, failureMessage }) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/ticket/Note/${id}`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          resp.id = id;
          resp.keyInStore = 'notes';
          resp.layerSource = LAYER_SOURCE__NOTES;
          commit(
            'layout/SHOWTOAST',
            {
              color: '#0692BC',
              message: successMessage,
            },
            { root: true }
          );
          commit('REMOVE_TICKET', resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#D50000',
              message: failureMessage,
            },
            { root: true }
          );
          reject(err);
        });
    }),
  POST_ADDRESS_VALIDATION: ({ commit }, ticket) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/ticket/AddressValidation/',
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
        data: ticket,
      })
        .then((resp) => {
          resp.keyInStore = 'addressValidation';
          resp.layerSource = LAYER_SOURCE__ADDRESS_VALIDATION;
          commit('ADD_TICKET', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DELETE_ADDRESS_VALIDATION: (
    { commit },
    { id, successMessage, failureMessage }
  ) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/ticket/AddressValidation/${id}`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          resp.id = id;
          resp.keyInStore = 'addressValidation';
          resp.layerSource = LAYER_SOURCE__ADDRESS_VALIDATION;
          commit('REMOVE_TICKET', resp);
          commit(
            'layout/SHOWTOAST',
            {
              color: '#0692BC',
              message: successMessage,
            },
            { root: true }
          );
          resolve(resp);
        })
        .catch((err) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#D50000',
              message: failureMessage,
            },
            { root: true }
          );
          reject(err);
        });
    }),
  POST_MEV_VALIDATION: ({ commit }, ticket) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/ticket/MEVValidation/',
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
        data: ticket,
      })
        .then((resp) => {
          resp.keyInStore = 'mevValidation';
          resp.layerSource = LAYER_SOURCE__MEV_VALIDATION;
          commit('ADD_TICKET', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DELETE_MEV_VALIDATION: ({ commit }, { id, successMessage, failureMessage }) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/ticket/MEVValidation/${id}`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          resp.id = id;
          resp.keyInStore = 'mevValidation';
          resp.layerSource = LAYER_SOURCE__MEV_VALIDATION;
          commit('REMOVE_TICKET', resp);
          commit(
            'layout/SHOWTOAST',
            {
              color: '#0692BC',
              message: successMessage,
            },
            { root: true }
          );
          resolve(resp);
        })
        .catch((err) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#D50000',
              message: failureMessage,
            },
            { root: true }
          );
          reject(err);
        });
    }),
  CLOSE_MEV_VALIDATION: ({ commit }, { id, successMessage, failureMessage }) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/ticket/MEVValidation/${id}/close/`,
        method: 'PUT',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          resp.id = id;
          resp.keyInStore = 'mevValidation';
          resp.layerSource = LAYER_SOURCE__MEV_VALIDATION;
          commit('CLOSE_TICKET', resp);
          commit(
            'layout/SHOWTOAST',
            {
              color: '#0692BC',
              message: successMessage,
            },
            { root: true }
          );
          resolve(resp);
        })
        .catch((err) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#D50000',
              message: failureMessage,
            },
            { root: true }
          );
          reject(err);
        });
    }),
  ADD_DEVELOPMENT_AREA_COMMENT: (
    { commit },
    { comment, id, followUpCommit, successMessage, failureMessage }
  ) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/ticket/DevelopmentArea/${id}/comment/`,
        data: { comment },
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#0692BC',
              message: successMessage,
            },
            { root: true }
          );
          commit(followUpCommit, comment, {
            root: true,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#D50000',
              message: failureMessage,
            },
            { root: true }
          );
          reject(err);
        });
    }),
  ADD_CO_LAYING_COMMENT: (
    { commit },
    { comment, id, followUpCommit, successMessage, failureMessage }
  ) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/ticket/CoLaying/${id}/comment/`,
        data: { comment },
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#0692BC',
              message: successMessage,
            },
            { root: true }
          );
          commit(followUpCommit, comment, {
            root: true,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit(
            'layout/SHOWTOAST',
            {
              color: '#D50000',
              message: failureMessage,
            },
            { root: true }
          );
          reject(err);
        });
    }),
};
