export const LAYER_SOURCE__MEV_2022 = 'mevresults_2022';
export const LAYER_SOURCE__MEV_2023 = 'mevresults_2023';
export const LAYER_SOURCE__MEV_2024 = 'mevresults_2024';

export const LAYER_KEY__MEV_RESULT = 'mevResult';
export const LAYER_KEY__MEV_HFC = 'homesPassedTechnology';
export const LAYER_KEY__MEV_EXPANSION = 'mevExpansion';
export const LAYER_KEY__MEV_SEF = 'mevSocioEconomicFocus';
export const LAYER_KEY__MEV_VERSORGUNGSGRAD = 'mevVersorgungsgrad';
export const LAYER_KEY__MEV_TECHNOLOGY = 'mevTechnology';
export const LAYER_KEY__MEV_OBJECTION = 'mevObjection';
export const LAYER_KEY__MEV_HFC_HOMES_PASSED = 'mevHfcHomesPassed';

const DEFAULT_FILTER__MEV_RESULT = [
  'any',
  ['==', ['get', 'mev_class'], 1],
  ['>', ['get', 'mev_class'], 2],
];
export const LAYER_CONFIG__MEV_RESULT = {
  layerKey: LAYER_KEY__MEV_RESULT,
  defaultFilter: DEFAULT_FILTER__MEV_RESULT,
  choices: [
    [1, '#46b1e3', 'Weiße Flecken (Antrag)'],
    [3, '#7AE330', 'Hellgraue Flecken'],
    [4, '#3b780e', 'Dunkelgraue Flecken'],
    [5, '#a77293', 'Schwarze Flecken (FTTH)'],
    [6, '#8F0296', 'Schwarze Flecken (Kabel)'],
    [7, '#f58a06', 'Sonderförderfähige Adressen'],
    [9, '#808080', 'Förderung im GFP'],
  ],
  property: 'mev_class',
};

const DEFAULT_FILTER__MEV_HFC = ['>', ['get', 'homes_passed_technology'], 0];
export const LAYER_CONFIG__MEV_HFC = {
  layerKey: LAYER_KEY__MEV_HFC,
  defaultFilter: DEFAULT_FILTER__MEV_HFC,
  choices: [
    [1, '#a77293', 'FTTH'],
    [2, '#8F0296', 'Kabel (HFC)'],
  ],
  property: 'homes_passed_technology',
};

const DEFAULT_FILTER__MEV_EXPANSION = [
  'any',
  ['all', ['>', ['get', 'expansion'], 0], ['<', ['get', 'expansion'], 2]],
  ['>', ['get', 'expansion'], 7],
];
export const LAYER_CONFIG__MEV_EXPANSION = {
  layerKey: LAYER_KEY__MEV_EXPANSION,
  defaultFilter: DEFAULT_FILTER__MEV_EXPANSION,
  choices: [
    [1, '#462DE3', 'FTTH/B'],
    [8, '#E33B24', 'Sonstige'],
  ],
  property: 'expansion',
};

const DEFAULT_FILTER__MEV_SEF = ['>', ['get', 'socio_economic_focus'], 0];
export const LAYER_CONFIG__MEV_SEF = {
  layerKey: LAYER_KEY__MEV_SEF,
  defaultFilter: DEFAULT_FILTER__MEV_SEF,
  choices: [
    [1, '#462DE3', 'Krankenhäuser'],
    [2, '#E36C19', 'Schulen'],
    [3, '#3b780e', 'Unternehmen'],
    [4, '#964C18', 'Sozioökonomisch'],
    [5, '#E33B24', 'Schwer erschl. Einzellagen'],
  ],
  property: 'socio_economic_focus',
};
export const LAYER_CONFIG__MEV_VERSORGUNGSGRAD = {
  layerKey: LAYER_KEY__MEV_VERSORGUNGSGRAD,
  choices: [
    [1, '#898989', 'Keine Rückmeldung'],
    [2, '#465CE3', 'Weiße Flecken Antrag'],
    [3, '#000000', 'unter 30 Mbits'],
    [4, '#e32424', '30 bis 99 Mbits'],
    [5, '#e38324', '100 bis 999 Mbits'],
    [6, '#3b780e', 'über 1000 Mbits'],
  ],
  property: 'versorgungsgrad',
};

const DEFAULT_FILTER__MEV_TECHNOLOGY = [
  'in',
  ['get', 'technology'],
  ['literal', [1, 2, 3, 4, 5, 6, 8, 100]],
];
export const LAYER_CONFIG__MEV_TECHNOLOGY = {
  layerKey: LAYER_KEY__MEV_TECHNOLOGY,
  defaultFilter: DEFAULT_FILTER__MEV_TECHNOLOGY,
  choices: [
    [1, '#601571', 'FTTH/B connected'],
    [2, '#e32424', 'HFC connected'],
    [3, '#e38324', 'DSL/Vectoring'],
    [4, '#3b780e', 'Drahtlose Zugangsnetze'],
    [5, '#465CE3', 'Kabel-TV (Coax)'],
    [6, '#46b1e3', 'Supervectoring'],
    [[8, 100], '#808080', 'Sonstige'],
  ],
  property: 'technology',
};

const DEFAULT_FILTER__MEV_OBJECTION = ['>', ['get', 'objections'], 0];
export const LAYER_CONFIG__MEV_OBJECTION = {
  layerKey: LAYER_KEY__MEV_OBJECTION,
  defaultFilter: DEFAULT_FILTER__MEV_OBJECTION,
  choices: [[1, '#46b1e3', 'Hausanschluss']],
  property: 'objections',
};

const DEFAULT_FILTER__MEV_HFC_HOMES_PASSED = [
  '==',
  ['get', 'homes_passed_algorithm_result'],
  true,
];
export const LAYER_CONFIG__MEV_HFC_HOMES_PASSED = {
  layerKey: LAYER_KEY__MEV_HFC_HOMES_PASSED,
  defaultFilter: DEFAULT_FILTER__MEV_HFC_HOMES_PASSED,
  choices: [
    [1, '#46b1e3', 'Weiße Flecken (Antrag)'],
    [3, '#7AE330', 'Hellgraue Flecken'],
    [4, '#3b780e', 'Dunkelgraue Flecken'],
    [5, '#a77293', 'Schwarze Flecken (FTTH)'],
    [6, '#8F0296', 'Schwarze Flecken (Kabel)'],
    [7, '#f58a06', 'Sonderförderfähige Adressen'],
    [9, '#808080', 'Förderung im GFP'],
  ],
  property: 'mev_class',
};

function createStyle(choices, property, outline) {
  const config = {
    style: {
      default: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#5c0000',
        ],
        circleStrokeWidth: outline ? 1.5 : 0.1,
        circleRadius: {
          stops: [
            [1, 0],
            [9, 2],
            [12, 4],
          ],
        },
        circleStrokeColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'MEV Ergebnisse',
        unit: '',
        items: [],
      },
    },
  };
  choices.forEach((c) => {
    if (Array.isArray(c[0])) {
      config.style.default.circleColor.push([
        'in',
        ['get', property],
        ['literal', c[0]],
      ]);
    } else {
      config.style.default.circleColor.push(['==', ['get', property], c[0]]);
    }
    config.style.default.circleColor.push(c[1]);
    config.legend.default.items.push({
      color: c[1],
      name: c[2],
      icon: 'mdi-checkbox-blank-circle',
    });
  });
  config.style.default.circleColor.push('#888888');
  if (outline) {
    config.legend.default.items.push({
      color: '#000000',
      name: 'HFC Homes Passed (Analytik)',
      icon: 'mdi-circle-outline',
    });
  }
  return config;
}

const mevConfig = (
  config,
  defaultFilter = null,
  filter = true,
  outline = false
) => ({
  layoutState: 'default',
  layerConfig: {
    app_name: 'mev',
    router_name: 'MEVResult',
    key_name: 'pk',
    type: 'point',
    id: config.layerKey,
    source: LAYER_SOURCE__MEV_2022,
    sourceLayer: LAYER_SOURCE__MEV_2022,
    visibility: 'none',
    filter: filter
      ? defaultFilter
        ? defaultFilter
        : ['!=', ['get', config.property], 0]
      : null,
    hoverPointer: true,
  },
  ...createStyle(config.choices, config.property, outline),
  sidebar: {
    name: 'Markterkundungsverfahren',
    subtitles: [
      { key: 'mev_class' },
      { key: 'socio_economic_focus' },
      { key: 'expansion' },
      { key: 'homes_passed_technology' },
      { key: 'rejections' },
    ],
    longSubtitles: [{ key: 'address' }],
    chips: [
      {
        title: 'MEV',
        active: true,
        items: [
          {
            title: 'Gebäude',
            active: true,
            items: [
              {
                key: 'mev_class',
                unit: '',
                value: '-',
              },
              {
                key: 'socio_economic_focus',
                unit: '',
                value: '-',
              },
              {
                key: 'expansion',
                unit: '',
                value: '-',
              },
              {
                key: 'homes_passed_technology',
                unit: '',
                value: '-',
              },
              {
                key: 'rejections',
                unit: '',
                value: '0',
              },
            ],
          },
          {
            title: 'Versorgung (heute)',
            active: true,
            items: [
              {
                key: 'status_quo_company',
                unit: '',
                value: '-',
              },
              {
                key: 'status_quo_technology',
                unit: '',
                value: '-',
              },
              {
                key: 'status_quo_download',
                unit: ' MBits/s',
                value: '0',
                decimal: 0,
              },
              {
                key: 'status_quo_upload',
                unit: ' MBits/s',
                value: '0',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Versorgung (geplant)',
            active: false,
            items: [
              {
                key: 'plan_company',
                unit: '',
                value: '-',
              },
              {
                key: 'plan_technology',
                unit: '',
                value: '-',
              },
              {
                key: 'plan_download',
                unit: ' MBits/s',
                value: '0',
                decimal: 0,
              },
              {
                key: 'plan_upload',
                unit: ' MBits/s',
                value: '0',
                decimal: 0,
              },
            ],
          },
        ],
      },
    ],
  },
});

export default {
  [LAYER_KEY__MEV_RESULT]: mevConfig(LAYER_CONFIG__MEV_RESULT),
  [LAYER_KEY__MEV_HFC]: mevConfig(
    LAYER_CONFIG__MEV_HFC,
    DEFAULT_FILTER__MEV_HFC
  ),
  [LAYER_KEY__MEV_EXPANSION]: mevConfig(
    LAYER_CONFIG__MEV_EXPANSION,
    DEFAULT_FILTER__MEV_EXPANSION
  ),
  [LAYER_KEY__MEV_SEF]: mevConfig(
    LAYER_CONFIG__MEV_SEF,
    DEFAULT_FILTER__MEV_SEF
  ),
  [LAYER_KEY__MEV_VERSORGUNGSGRAD]: mevConfig(
    LAYER_CONFIG__MEV_VERSORGUNGSGRAD
  ),
  [LAYER_KEY__MEV_TECHNOLOGY]: mevConfig(
    LAYER_CONFIG__MEV_TECHNOLOGY,
    DEFAULT_FILTER__MEV_TECHNOLOGY
  ),
  [LAYER_KEY__MEV_OBJECTION]: mevConfig(
    LAYER_CONFIG__MEV_OBJECTION,
    DEFAULT_FILTER__MEV_OBJECTION
  ),
  [LAYER_KEY__MEV_HFC_HOMES_PASSED]: mevConfig(
    LAYER_CONFIG__MEV_HFC_HOMES_PASSED,
    DEFAULT_FILTER__MEV_HFC_HOMES_PASSED,
    true,
    true
  ),
};
