<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4165 1.66675H4.08317C3.1627 1.66675 2.4165 2.41294 2.4165 3.33342V16.6667C2.4165 17.5872 3.1627 18.3334 4.08317 18.3334H17.4165C18.337 18.3334 19.0832 17.5872 19.0832 16.6667V3.33341C19.0832 2.41294 18.337 1.66675 17.4165 1.66675ZM3.24984 3.33342C3.24984 2.87318 3.62293 2.50008 4.08317 2.50008H17.4165C17.8767 2.50008 18.2498 2.87318 18.2498 3.33341V16.6667C18.2498 17.127 17.8767 17.5001 17.4165 17.5001H4.08317C3.62293 17.5001 3.24984 17.127 3.24984 16.6667V3.33342ZM13.081 15.8334H12.4977L13.081 11.2964H11.0393C10.701 11.2964 10.7068 11.089 10.8176 10.8686C10.9285 10.6482 10.8468 10.8167 10.8585 10.7908C11.3034 9.91703 11.8809 8.78943 12.5898 7.40535C13.055 6.49721 13.5766 5.47866 14.1545 4.34893C14.2116 4.2374 14.3265 4.16675 14.4518 4.16675C14.6529 4.16675 14.8083 4.34341 14.7827 4.54291L14.2477 8.70379H16.2894C16.5753 8.70379 16.6161 8.91767 16.5636 9.03434L16.5228 9.13156C14.2244 13.5973 13.081 15.8334 13.081 15.8334ZM7.41081 15.8334C6.1151 15.8277 6.69847 13.8643 7.11926 12.4481C7.2797 11.9081 7.41651 11.4477 7.41651 11.2056C7.4165 10.8334 7.73236 10.8022 7.86337 11.0622C8.69372 12.7101 8.6877 15.8252 7.42156 15.8334C9.41735 15.8311 9.9165 14.4568 9.9165 13.0826C9.9165 10.0666 8.51396 6.71116 7.44901 4.44089C7.42729 4.39459 7.39946 4.35296 7.36598 4.31612C7.07621 3.99728 6.5714 4.2228 6.54135 4.6725C6.40934 6.64796 5.98851 8.08331 5.60823 9.38038L5.60823 9.38039C5.24374 10.6236 4.9165 11.7397 4.9165 13.0826C4.9165 14.4567 5.41555 15.8308 7.41081 15.8334Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
