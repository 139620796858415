<template>
  <v-dialog
    v-model="dialogAbortProcess"
    :no-click-animation="true"
    :persistent="true"
    width="auto">
    <template #activator="{ props }">
      <v-icon v-bind="props" class="cursor-pointer text-[18px]">
        mdi-close
      </v-icon>
    </template>

    <v-card class="max-w-lg">
      <v-card-text class="px-[20px] pt-[20px] pb-0">
        <h3 class="mb-3">{{ titleText }}</h3>
        <div class="body-1 whitespace-pre-line">
          {{ bodyText }}
        </div>
      </v-card-text>
      <v-card-actions class="flex justify-between p-[20px]">
        <button-el
          text="Fortsetzen"
          :primary="false"
          @click="dialogAbortProcess = false" />
        <button-el text="Beenden" :critical="true" @click="abortProcess" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { endTicketProcess, endValidationTicket } from '../ticketHelper';

export default {
  props: {
    type: {
      type: String,
      default: 'process',
    },
  },
  data() {
    return {
      dialogAbortProcess: false,
    };
  },
  computed: {
    titleText() {
      if (this.type === 'process') {
        return 'Möchten Sie den Prozess unterbrechen?';
      }
      return 'Möchten Sie das Ticket beenden?';
    },

    bodyText() {
      return (
        'Der Vorgang wurde zwischengespeichert.' +
        '\nSie können diesen zu einem späteren Zeitpunkt fortsetzen.'
      );
    },
  },
  methods: {
    abortProcess() {
      if (this.type === 'process') {
        endTicketProcess(this);
      } else if (this.type === 'ticket') {
        endValidationTicket(this);
      }
    },
  },
};
</script>
