<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5 7L9.33333 7V9.33333L24.5 9.33333V7ZM24.5 12.8333L12.8333 12.8333L12.8333 15.1667L24.5 15.1667V12.8333ZM24.5 21V18.6667H9.33333V21L24.5 21ZM3.5 9.81167L7.67667 14L3.5 18.1883L5.145 19.8333L10.9783 14L5.145 8.16667L3.5 9.81167Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
