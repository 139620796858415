<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_608_31915)">
      <path
        d="M39.875 42.625H4.125C2.607 42.625 1.375 41.393 1.375 39.875V4.125C1.375 2.607 2.607 1.375 4.125 1.375H39.875C41.393 1.375 42.625 2.607 42.625 4.125V39.875C42.625 41.393 41.393 42.625 39.875 42.625Z"
        :stroke="fill"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M42.625 26.125H28.875V34.375H15.125V26.125H1.375"
        :stroke="fill"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.125 15.125L20.625 20.625L31.625 9.625"
        :stroke="fill"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_608_31915">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
