<template>
  <h3 v-if="hubCardActive">
    {{ cards.find((card) => card.id === currentCard).label.replace(/-/g, '') }}
  </h3>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const cards = computed(() => store.getters['dashboard/getAggregatedCardsData']);
const hubCardActive = computed(
  () => store.getters['dashboard/getHubCardActive']
);

const currentCard = router.currentRoute.value.meta.id;
</script>
