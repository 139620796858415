import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import {
  SWITCH_ID__ADDRESS_VALIDATION,
  SWITCH_ID__COLAYING,
  SWITCH_ID__DEVELOPMENT_AREA,
  SWITCH_ID__MEV_VALIDATION,
  SWITCH_ID__NOTES,
} from '@/configs/sidebar/tickets';
import { RL_2022, RL_2023, RL_2024 } from '@/configs/sidebar/mev';

const state = {
  ticketProcesses: [
    {
      title: 'Neubaugebiet einzeichnen',
      id: 'developmentArea',
      icon: 'crane-icon',
      drawComponentName: ['polygon'],
      initWithDrawMode: true,
      selected: false,
      showControls: true,
      modelNameForCASL: 'developmentarea',
      activateSwitchOnStart: SWITCH_ID__DEVELOPMENT_AREA,
    },
    {
      title: 'Mitverlegung einzeichnen',
      id: 'coLaying',
      icon: 'blueprint-icon',
      drawComponentName: ['linestring'],
      initWithDrawMode: true,
      selected: false,
      showControls: true,
      modelNameForCASL: 'colaying',
      activateSwitchOnStart: SWITCH_ID__COLAYING,
    },
    {
      title: 'Notiz hinterlegen',
      id: 'ticket',
      icon: 'comment-icon',
      drawComponentName: ['polygon', 'linestring', 'address'],
      initWithDrawMode: false,
      selected: false,
      showControls: true,
      modelNameForCASL: 'note',
      activateSwitchOnStart: SWITCH_ID__NOTES,
    },
    {
      title: 'Adress-Validierung',
      id: 'validation',
      icon: 'pincheck-icon',
      drawComponentName: ['polygon', 'address'],
      initWithDrawMode: false,
      selected: false,
      showControls: false,
      modelNameForCASL: 'addressvalidation',
      disabled: true,
      activateSwitchOnStart: SWITCH_ID__ADDRESS_VALIDATION,
    },
    {
      title: 'MEV-Validierung',
      id: 'mev',
      icon: 'pincheck-icon',
      drawComponentName: ['polygon'],
      initWithDrawMode: true,
      selected: false,
      showControls: true,
      modelNameForCASL: 'mevvalidation',
      disabled: true,
      activateSwitchOnStart: SWITCH_ID__MEV_VALIDATION,
    },
  ],
  gemeindeActive: null,
  gemarkungActive: null,
  notes: { type: 'FeatureCollection', features: [] },
  notesLoading: true,
  developmentAreas: { type: 'FeatureCollection', features: [] },
  developmentAreasLoading: true,
  coLayings: { type: 'FeatureCollection', features: [] },
  coLayingsLoading: true,
  addressValidation: { type: 'FeatureCollection', features: [] },
  addressValidationLoading: true,
  mevValidation: { type: 'FeatureCollection', features: [] },
  mevValidationLoading: true,
  drawFeatures: [],
  drawSelectedIds: [],
  ticketStep: 1,
  validationStep: 1,
  showValidationTicket: false,
  addressValidationData: null,
  mevValidationData: null,
  builttData: [],
  builttLotData: [],
  mevDashboardData: [],
  mevGemarkungenData: [],
  richtlinien: [RL_2022, RL_2023, RL_2024],
  richtlinieSelected: [RL_2022],
  landkreise: [],
  landkreiseActive: ['Alb-Donau-Kreis'],
  gemeinden: [],
  gemeindenFiltered: [],
  tku: [],
  tkuActive: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
