<template>
  <div
    ref="phaseItem"
    :data-duration="
      convertShortDate(durationData.start) +
      '-' +
      convertShortDate(durationData.end)
    "
    class="chart-bar-item relative phase-item-wrapper"
    :style="inactive ? 'opacity: 0.3;' : ''">
    <div
      ref="toolTipActivator"
      class="phase-item"
      :class="bgColor"
      @mouseenter="showPopover"
      @mouseleave="hidePopover"></div>
    <ToolTipAdvanced
      v-if="showPhaseItemPopover"
      v-model="showPhaseItemPopover"
      fixed="y"
      :offset-y="5"
      :activator-bounds="phaseItemActivatorBounds">
      <template #content>
        <div
          ref="toolTipContent"
          class="standard-elevation-1 p-1.5 rounded flex flex-col gap-2"
          :class="bgColor">
          <div class="text-white flex flex-col gap-0.5">
            <div class="caption-1 text-[12px]">
              {{ durationData.name }}
            </div>
          </div>
        </div>
      </template>
    </ToolTipAdvanced>
  </div>
</template>

<script>
import ToolTipAdvanced from '../../toolTipAdvanced/ToolTipAdvanced.vue';

export default {
  name: 'PhaseItem',
  components: { ToolTipAdvanced },
  props: {
    durationData: {
      type: Object,
      default: () => {},
    },
    bgColor: {
      type: String,
      default: 'bg-black',
    },
    interval: {
      type: String,
      default: 'day',
    },
    intervalData: {
      type: Object,
      default: () => {},
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPhaseItemPopover: false,
      phaseItemActivatorBounds: null,
    };
  },
  methods: {
    convertShortDate(date) {
      if (this.interval === 'months') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.label;
            }
          });
        });
        return shortCut;
      } else if (this.interval === 'weeks') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.value + '.' + item.year;
            }
          });
        });
        return shortCut;
      } else {
        return date.split('-').reverse().join('.');
      }
    },
    showPopover() {
      this.phaseItemActivatorBounds =
        this.$refs.toolTipActivator.getBoundingClientRect();
      this.showPhaseItemPopover = true;
    },
    hidePopover() {
      this.showPhaseItemPopover = false;
    },
  },
};
</script>

<style lang="scss">
.phase-item-wrapper {
  @apply flex flex-col justify-center px-0.5;
  height: 100%;
  .phase-item {
    @apply rounded-[2px] opacity-60 h-full;
  }
}
</style>
