<template>
  <div
    class="bg-infra-background flex overflow-hidden"
    style="flex-wrap: unset !important"
  >
    <!-- CONTENT -->
    <v-main class="h-screen">
      <v-container class="pa-0 max-w-none w-full" :fluid="true">
        <v-layout class="flex-col">
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
    <!-- CONTENT END -->
  </div>
</template>

<script>
export default {};
</script>
