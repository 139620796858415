<template>
  <v-card
    class="panel3-custom sidebar map_sidebar overflow-visible standard-elevation-2"
    :class="{ 'expanded-sidebar': drawer }">
    <div
      class="relative"
      style="
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 320px;
      ">
      <div class="subtitles" style="width: 320px">
        <div class="title p-3 flex items-center justify-between top-0 w-full">
          <v-toolbar-title>
            <h4 class="text-infra-highlight-500">{{ getProperties.name }}</h4>
          </v-toolbar-title>
          <v-icon class="cursor-pointer text-[18px]" @click="closeSecSidebar()">
            mdi-close
          </v-icon>
        </div>
        <status-header
          v-if="getProperties.statusHeader"
          :property-data="getProperties.statusHeader" />
        <!-- intro text (also used for displaying note content)-->
        <div
          v-if="getProperties.introText"
          class="w-full flex flex-col gap-2 p-3 pt-0">
          <div class="body-2">{{ getProperties.introText.value }}</div>
        </div>
        <div
          v-if="!showMapSelection && getProperties.subtitles"
          class="p-3 pt-0">
          <info-entries
            v-for="subtitle in getProperties.subtitles"
            :key="subtitle.index"
            :subtitle="subtitle">
          </info-entries>
        </div>
        <div
          v-if="!showMapSelection && getProperties.longSubtitles"
          class="p-3 pt-0">
          <info-entries
            v-for="subtitle in getProperties.longSubtitles"
            :key="subtitle.index"
            :subtitle="subtitle"
            :long="true">
          </info-entries>
        </div>
        <chip-bar
          v-if="!showMapSelection"
          v-show="cleanPanelData.chips.length > 1"
          :property-data="getProperties" />
      </div>
      <!-- object panel-->
      <panel-content v-if="!showMapSelection" :property-data="cleanPanelData" />
      <!-- meta data-->
      <meta-data-entries
        v-if="getProperties.metaData"
        :meta-data-props="getProperties.metaData" />
      <!-- comment section-->
      <comment-section
        v-if="getProperties.comments"
        :property-data="getProperties.comments" />
      <!-- buttons-->
      <div v-if="getProperties.buttons" class="w-full pt-3">
        <panel-button
          v-for="(button, index) in getProperties.buttons"
          :key="index"
          :property-data="button" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  emits: ['clicked'],
  data() {
    return {
      showModalInfo: false,
    };
  },
  computed: {
    ...mapState('layout', ['drawer']),
    ...mapGetters('map', ['getProperties', 'getContainerHeight', 'layerId']),
    // If key does not exsist in main result, no value is added to fields.
    // This can be due to limited read permissions.
    // Filter all chips and expandables without values set:
    cleanPanelData() {
      const cleadedData = { ...this.getProperties };
      cleadedData.chips = [];
      this.getProperties.chips.forEach((chip) => {
        const expandables = [];
        chip.items.forEach((expandable) => {
          expandable.items = expandable.items.filter(
            (i) => i.value !== undefined
          );
          if (expandable.items.length > 0) expandables.push(expandable);
        });
        chip.items = expandables;
        if (expandables.length > 0) cleadedData.chips.push(chip);
      });
      if (cleadedData.chips.length === 1) cleadedData.chips[0].active = true;
      return cleadedData;
    },
    showMapSelection() {
      const { validationContent } = this.getProperties;
      return validationContent;
    },
  },
  methods: {
    closeSecSidebar() {
      this.$emit('clicked');
    },
    closeModal() {
      this.showModalInfo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel3-custom {
  width: 320px;
  border-radius: 8px;
  height: fit-content !important;

  .subtitles {
    border-radius: 8px 8px 0 0;

    .title {
      border-radius: 8px 8px 0 0;
    }
  }
}
</style>
