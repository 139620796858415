<template>
  <div
    class="h-[44px] w-[44px] hover:bg-infra-gray cursor-pointer flex justify-center items-center"
    :class="background">
    <component :is="icon" :fill="iconFill" class="w-[22px] h-[22px]" />
  </div>
</template>

<script>
export default {
  name: 'ToolButton',
  props: {
    icon: {
      required: true,
    },
    background: {
      type: String,
      default: 'bg-white',
    },
    iconFill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style></style>
