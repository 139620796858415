import mapboxgl from 'mapbox-gl';
import U from 'mapbox-gl-utils';
import config from '@/configs';
import { addDraw } from '@/features/draw/draw';
import onLeftClick from './onClick/main';
import { createLayer, setLayerHierarchy } from './createLayer';
import { initMarked, initClicked } from './onClick/constructorClick';

mapboxgl.accessToken = window.MAPBOX_TOKEN;

let map;
let preventClick = false;

export function flyTo(configFlyTo) {
  map.flyTo({
    zoom: configFlyTo.zoom,
    center: [configFlyTo.lng, configFlyTo.lat],
  });
}

export function getMap() {
  return map;
}

export function getZoom() {
  return map.getZoom();
}

function create3dLayers(map_) {
  map_.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 14,
  });
  map_.addLayer({
    id: 'sky',
    type: 'sky',
    paint: {
      // set up the sky layer to use a color gradient
      'sky-type': 'gradient',
      // the sky will be lightest in the center and get darker moving radially outward
      // this simulates the look of the sun just below the horizon
      'sky-gradient': [
        'interpolate',
        ['linear'],
        ['sky-radial-progress'],
        0.8,
        'rgba(135, 206, 235, 1.0)',
        1,
        'rgba(0,0,0,0.1)',
      ],
      'sky-gradient-center': [0, 0],
      'sky-gradient-radius': 90,
      'sky-opacity': [
        'interpolate',
        ['exponential', 0.1],
        ['zoom'],
        5,
        0,
        22,
        1,
      ],
    },
  });
}

export function setPitch(pitch) {
  if (pitch === '3D') {
    map.easeTo({ pitch: 60 });
    const terrainOptions = { source: 'mapbox-dem', exaggeration: 1.5 };
    map.setTerrain(terrainOptions);
    return '2D';
  }
  map.setTerrain();
  map.easeTo({ pitch: 0 });
  return '3D';
}

export function zoomMap() {
  // map.setZoom(map.getZoom() + 0.5);
  map.easeTo({
    zoom: map.getZoom() + 0.5,
    duration: 750,
  });
}

export function unzoomMap() {
  // map.setZoom(map.getZoom() - 0.5);
  map.easeTo({
    zoom: map.getZoom() - 0.5,
    duration: 750,
  });
}

export function compassMap() {
  const options = { bearing: 0 };
  map.easeTo(options);
}

export function removeMarker() {
  map.U.setData('markerSource', {
    type: 'FeatureCollection',
    features: [],
  });
}

export function setMarker(coords) {
  removeMarker();
  map.U.setData('markerSource', {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coords,
        },
      },
    ],
  });
  map.moveLayer('marker');
}

/**
 * set visibility (usually changed through switch in panel 3).
 * @param  {array} layers get all layers and corresponding layer states and visibility
 */
export function setVisibility(layers) {
  for (let i = 0, len = layers.length; i < len; i += 1) {
    if (layers[i].visible) map.U.show(layers[i].layer);
    else map.U.hide(layers[i].layer);
  }
}

export function setGeoJsonData(
  sourceLayer,
  featureCollection,
  layer = null,
  move = false
) {
  map.U.setData(sourceLayer, featureCollection);
  if (move) map.moveLayer(layer);
}

/**
 * set layout depending on layer states (usually changed through radio in panel 3). The state
 * is being watched and whenever any state changes, the layout is set for all visible layers.
 * @param  {array} layers get all layers and corresponding layer states
 * @param  {object} layerConfig man layer config
 */
export function setStyle(layers, layerConfig, data) {
  for (let i = 0, len = layers.length; i < len; i += 1) {
    if (layers[i].visible) {
      let style = layerConfig[layers[i].layer].style;
      if (style.replace) {
        if (style.replace.multiple) {
          style[layers[i].state] = JSON.parse(
            data.$store.getters[
              style.replace.storageGetterForReplacement
            ].reduce(
              (prev, cur, curI) =>
                prev.replaceAll(style.replace.toReplace + curI, cur),
              JSON.stringify(style[layers[i].state])
            )
          );
        } else {
          style[layers[i].state] = JSON.parse(
            JSON.stringify(style[layers[i].state]),
            data.$store.getters[style.replace.storageGetterForReplacement]
          );
        }
      }
      if (layers[i].layer === 'district_heating_network_cluster') {
        map.setLayoutProperty(
          layers[i].layer,
          'icon-image',
          style[layers[i].state].layout['icon-image']
        );
      } else {
        map.U.setProperty(layers[i].layer, style[layers[i].state]);
      }
    }
  }
}

/**
 * set filter (usually changed through radio in panel 3). The state
 * is being watched and whenever any state changes, the filters are set.
 * @param  {String} layerKey
 * @param  {Array} filter
 */
export function setFilter(layerKey, filter) {
  map.setFilter(layerKey, filter);
}

/**
 * Change source layer and rerender all layers bound to the source layer
 * @param  {Array}  layerKeys layers to change source of
 * @param  {string} Source new source layer
 */
export function changeSourceLayer(layerKeys, Source) {
  map.U.addVector(
    Source,
    `${window.TILESERVER_URL}/data/${Source}/{z}/{x}/{y}.pbf`
  );
  for (let i = 0, len = layerKeys.length; i < len; i += 1) {
    const layer = map.getStyle().layers.find((e) => e['id'] === layerKeys[i]);
    map.removeLayer(layer.id);
    layer['source-layer'] = Source;
    layer['source'] = Source;
    map.addLayer(layer);
  }
  setLayerHierarchy(map);
}

/**
 * initialize map on URL load.
 * @param  {object} data get all layers and corresponding layer states
 * @param  {string} layer optional layer name to activate on load
 * @param  {number} lat optional latitude to jump to on load
 * @param  {number} lng optional longitude to jump to on load
 */
export function createMap(data, layer, lat, lng) {
  map = new mapboxgl.Map(config.constraints.mapView.mapConstraints);
  map.addControl(new mapboxgl.AttributionControl(), 'bottom-left');

  U.init(map, mapboxgl);
  const marked = initMarked(map);
  const clicked = initClicked(map);
  const getLayersToClick = data.$store.getters['map/getLayersToClick'];
  const hoverPointLayers = data.$store.getters['map/getHoverPointer'];
  addDraw(map);

  map.on('load', () => {
    create3dLayers(map);
    const toCoords =
      layer && lat && lng
        ? [lng, lat]
        : data.$store.getters['auth/gemeindenCenter'].geometry.coordinates;
    map.flyTo({
      zoom: layer && lat && lng ? 16 : 11,
      center: toCoords,
    });
    createLayer(map, data, setGeoJsonData);
    data.$store.commit('map/RESET_LAYER_STATES');
    if (layer) {
      data.$store.commit('map/CHANGE_SWITCH', {
        switches: [layer],
        active: true,
      });
    }
    if (data.$can('view', 'note')) {
      data.$store.dispatch('ticket/GET_NOTES');
    }
    if (data.$can('view', 'developmentarea')) {
      data.$store.dispatch('ticket/GET_DEVELOPMENT_AREA');
    }
    if (data.$can('view', 'colaying')) {
      data.$store.dispatch('ticket/GET_CO_LAYING');
    }
    if (data.$can('view', 'addressvalidation')) {
      data.$store.dispatch('ticket/GET_ADDRESS_VALIDATION');
      data.$store.dispatch('ticket/GET_ADDRESS_VALIDATION_OPTION');
    }
    if (data.$can('view', 'mevvalidation')) {
      data.$store.dispatch('ticket/GET_MEV_VALIDATION');
      data.$store.dispatch('ticket/GET_MEV_VALIDATION_OPTION');
    }
  });

  map.on('draw.modechange', (e) => {
    // sad fix for preventing the last click
    // that changes the draw mode to trigger a click event
    if (e.mode === 'simple_select') {
      setTimeout(() => {
        preventClick = false;
      }, 500);
    } else {
      preventClick = true;
    }
  });

  map.on('click', (e) => {
    // removeMarker();
    data.$store.commit('map/RESET_FEATURE_SELECTIONS');
    if (!preventClick) onLeftClick(map, e, data, clicked, getLayersToClick);
  });

  map.on('contextmenu', (e) => {
    // to be discussed
    clicked.resetFeatureState();
    clicked.iconLayers.forEach((el) => {
      map.U.removeSource(el);
    });
    data.showSidebar(false);
    marked.getFeatureOnClick(e.point, data);
  });

  map.U.hoverPointer(hoverPointLayers);

  return map;
}

export function toggleSatellite(isActiv) {
  const satLayerId = 'satellite';
  if (!isActiv) {
    map.removeLayer(satLayerId);
    map.removeSource(satLayerId);
  } else {
    map.addLayer({
      id: satLayerId,
      source: {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256,
      },
      type: 'raster',
      visibility: false,
      style: null,
    });
    setLayerHierarchy(map);
  }
}

export function destroyMap() {
  map.remove();
}
