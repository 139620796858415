<template>
  <div
    class="relative flex items-center justify-center overflow-y-auto max-h-screen pt-[100px]"
    style="
      background: linear-gradient(257.82deg, #001257 -12.99%, #0692bc 117.03%);
    ">
    <!-- card grid top wrapper -->
    <div class="fixed top-0 bg-white w-full h-[100px] z-[1000]">
      <div
        class="relative flex justify-between items-center py-[12px] px-[20px] h-full">
        <!-- logo-->
        <img
          class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
          src="@/assets/svg/infra-icon.svg"
          alt="logo" />
        <!-- userdata + logout-->
        <div class="flex gap-5 ml-auto">
          <div
            class="flex flex-col items-end userdata-custom justify-space-between">
            <div class="body-3" data-cy="profilename">
              {{ profil.username }}
            </div>
            <!-- logout-->
            <v-menu>
              <template #activator="{ props }">
                <v-icon
                  data-cy="landingpage-settings"
                  color="blue"
                  icon="mdi-dots-horizontal"
                  v-bind="props" />
              </template>
              <v-list>
                <v-list-item key="logout" data-cy="logout" @click="logout">
                  <v-list-item-title> Logout </v-list-item-title>
                </v-list-item>
                <v-list-item
                  key="settings"
                  data-cy="to-admin"
                  @click="goToSettings">
                  <v-list-item-title> User-Einstellungen </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <div class="w-20 h-20 rounded-lg overflow-hidden">
            <RouterLink to="/profile">
              <personal-avatar />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>

    <!-- navigation cards-->
    <div
      class="flex flex-col relative justify-center pb-15"
      :style="`min-height: calc(100vh - 100px); width: ${
        upperRowCards.length * 250
      }px;`">
      <div class="flex gap-5">
        <!-- card wrapper item cards-->
        <card-item
          v-for="item in upperRowCards"
          :key="item"
          :disabled="item.disabled"
          :card-data="item"
          :data-cy="item.testId" />
      </div>
      <div class="flex gap-5 mt-5">
        <card-item
          v-for="item in lowerRowCards"
          :key="item"
          :small="upperRowCards.length !== lowerRowCards.length"
          :width-to-height-ratio="upperRowCards.length / lowerRowCards.length"
          :disabled="item.disabled"
          :card-data="item"
          :data-cy="item.testId" />
      </div>

      <!-- footer-->
      <footer-section
        class="absolute bottom-0.5"
        custom-text-color="text-infra-gray-highlight" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      communityData: {},
      maxAmountPerRow: 3,
      cardData: [
        {
          title: 'Kartenansicht',
          description:
            'Visualisierung von Adresskulisse, Infrastruktur, ' +
            'Auswertungen und Datenerfassung',
          icon: 'map-compass.svg',
          link: '/mapview',
          method: 'goToUrl',
          testId: 'map-view-card',
          show: true,
        },
        {
          title: 'Dashboard',
          description:
            'Userspezifische Übersicht mit Kennzahlen zu Auswertungen',
          icon: 'dashboard.svg',
          link: '/dashboard',
          method: 'goToUrl',
          disabled: !this.$can('view', 'tendercluster'),
          testId: 'dashboard-card',
          show: true,
        },
        {
          title: 'Digitale Bausteuerung',
          description:
            'Übersicht Lose und Bauabschnitte eines GÜ-Vertrags ' +
            'mit Kosten-/Terminkontrolle',
          icon: 'bausteuerung.svg',
          link: '/construction-control',
          method: 'goToUrl',
          testId: 'construction-control-card',
          show: this.$can('view', 'builttroute'),
        },
        {
          title: 'Förderanträge',
          description:
            'Statusverfolgung der Antragsstellung und Darstellung ' +
            'der Grobkostenschätzung mit Datendownload',
          icon: 'grantApplication.svg',
          link: '/dashboard/foerderantrag',
          method: 'goToUrl',
          disabled: false,
          testId: 'grant-applications-card',
          show: this.$can('view', 'invitation'),
        },
        {
          title: 'Dokumente',
          description: 'User Anleitungen zum Download',
          icon: 'foerderantraege.svg',
          link: '/documents',
          method: 'goToUrl',
          testId: 'documents-card',
          show: true,
        },
        // {
        //   title: 'Kooperations-\nentwicklung',
        //   description:
        //     'Geografische Verortung der Analysen in der Kartenansicht.',
        //   icon: 'cooperation.svg',
        //   link: '/mapview',
        //   method: 'goToUrl',
        //   disabled: true,
        // },
        // {
        //   title: 'Markt-\nerkundungsverfahren',
        //   description: 'Übersicht Markterkundungsverfahren und Dokumentation',
        //   icon: 'market.svg',
        //   link: '/mapview',
        //   method: 'goToUrl',
        //   disabled: true,
        // },
        // {
        //   title: 'Ausschreibungen',
        //   description:
        //     'Geografische Verortung der Analysen in der Kartenansicht.',
        //   icon: 'foerderantraege.svg',
        //   link: '/mapview',
        //   method: 'goToUrl',
        //   disabled: true,
        // },
        // {
        //   title: 'Assetmanagement',
        //   description: '...',
        //   icon: 'assetmanagement.svg',
        //   link: '/mapview',
        //   method: 'goToUrl',
        //   disabled: true,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['profil']),
    upperRowCards() {
      const filteredCards = this.cardData.filter((c) => c.show);
      const amountCards = filteredCards.length;

      if (amountCards > this.maxAmountPerRow) {
        return filteredCards.slice(
          0,
          Math.floor(amountCards / 2) + (amountCards % 2)
        );
      } else {
        return filteredCards;
      }
    },
    lowerRowCards() {
      const filteredCards = this.cardData.filter((c) => c.show);
      const amountCards = filteredCards.length;
      if (amountCards > this.maxAmountPerRow) {
        return filteredCards.slice(
          Math.floor(amountCards / 2) + (amountCards % 2),
          amountCards
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/LOGOUT').then(() => {
        this.$router.push('/login');
      });
    },
    goToSettings() {
      this.$router.push('/profile');
    },
  },
};
</script>

<style lang="scss">
.location-meta-data,
.userdata-custom {
  div,
  h6 {
    @apply opacity-70;
  }
}

.navigation-cards-custom {
  @apply grid-cols-3;
  @media (max-width: 1084px) {
    @apply grid-cols-3;
  }
}
</style>
