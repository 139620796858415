<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 17C18.47 17 17.6 17.62 17.21 18.5H6.79C6.4 17.62 5.53 17 4.5 17C3.83696 17 3.20107 17.2634 2.73223 17.7322C2.26339 18.2011 2 18.837 2 19.5C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22C5.53 22 6.4 21.38 6.79 20.5H17.21C17.6 21.38 18.47 22 19.5 22C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5C22 18.837 21.7366 18.2011 21.2678 17.7322C20.7989 17.2634 20.163 17 19.5 17ZM5.5 19.5C5.5 20.0523 5.05228 20.5 4.5 20.5C3.94772 20.5 3.5 20.0523 3.5 19.5C3.5 18.9477 3.94772 18.5 4.5 18.5C5.05228 18.5 5.5 18.9477 5.5 19.5ZM19.5 20.5C20.0523 20.5 20.5 20.0523 20.5 19.5C20.5 18.9477 20.0523 18.5 19.5 18.5C18.9477 18.5 18.5 18.9477 18.5 19.5C18.5 20.0523 18.9477 20.5 19.5 20.5Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 4C6.44772 4 6 4.44772 6 5V15C6 15.5523 6.44772 16 7 16H17C17.5523 16 18 15.5523 18 15V5C18 4.44772 17.5523 4 17 4H7ZM12.9639 9.78918C12.1909 8.11939 12.1994 7.38878 12.5294 6.22782C12.5761 6.06324 12.3872 5.93149 12.254 6.03899C12.1768 6.10135 12.1009 6.16421 12.0267 6.22782C10.9194 7.17643 10.1835 8.29407 11.0414 10.4708C11.8583 12.5435 11.5405 13.1613 11.2104 13.803L11.1672 13.8873C11.1164 13.987 11.2094 14.0849 11.3106 14.0372C11.3939 13.9979 11.4926 13.9482 11.6008 13.8873C12.496 13.3841 14.0448 12.1242 12.9639 9.78918ZM15.7231 9.82324C15.1433 8.57089 15.1496 8.02294 15.3971 7.15222C15.4322 7.02878 15.2905 6.92997 15.1907 7.0106C15.1327 7.05737 15.0758 7.10451 15.0201 7.15222C14.1897 7.86367 13.6377 8.7019 14.2812 10.3344C14.8938 11.8889 14.6555 12.3523 14.4079 12.8336L14.3755 12.8968C14.3375 12.9716 14.4072 13.045 14.483 13.0093C14.5455 12.9798 14.6196 12.9425 14.7007 12.8968C15.3721 12.5194 16.5338 11.5745 15.7231 9.82324ZM9.39714 7.15222C9.14964 8.02294 9.1433 8.57089 9.72305 9.82324C10.5338 11.5745 9.37212 12.5194 8.70074 12.8968C8.61955 12.9425 8.54554 12.9798 8.48304 13.0093C8.40716 13.045 8.33746 12.9716 8.3755 12.8968L8.4079 12.8336C8.65547 12.3523 8.89383 11.8889 8.28115 10.3344C7.63771 8.7019 8.1897 7.86367 9.02012 7.15222C9.07581 7.10451 9.13274 7.05737 9.19066 7.0106C9.29049 6.92997 9.43223 7.02878 9.39714 7.15222Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
