import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  searchResults: null,
  showSearch: false,
  searchbarWidth: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
