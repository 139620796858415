import {
  LAYER_KEY__TENDER_ROUTE,
  LAYER_KEY__TENDER_EXPANSION,
  LAYER_KEY__TENDER_EXPANSION_BORDER,
  YEARLY_DATE_STRINGS,
} from '../layers/tenders';

export const SWITCH_ID__TENDER_ROUTE = 'LC_TENDER_ROUTE';
export const SWITCH_ID__TENDER_EXPANSION = 'LC_TENDER_EXPANSION';

export default {
  title: 'Ausschreibungen',
  modelNameForCASL: 'sensibletilesets',
  replace: {
    toReplace: 'YEARLY_DATE_STRING',
    multiple: true,
    storageGetterForReplacement: 'map/getAvailableTenderYears',
  },
  items: [
    {
      title: 'Bauabschnitte',
      onLayers: [
        LAYER_KEY__TENDER_EXPANSION,
        LAYER_KEY__TENDER_EXPANSION_BORDER,
      ],
      switchId: SWITCH_ID__TENDER_EXPANSION,
      itemClass: 'switch',
      modelNameForCASL: 'sensibletilesets',
      additionalFilterComponent: 'tenderSelect',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [
                LAYER_KEY__TENDER_EXPANSION,
                LAYER_KEY__TENDER_EXPANSION_BORDER,
              ],
              filter: 'reset',
            },
            ...YEARLY_DATE_STRINGS.map((str, i) => ({
              title: `Baubeg. ${
                i === YEARLY_DATE_STRINGS.length - 1 ? 'ab ' : ''
              }${str}`,
              layerKeys: [
                LAYER_KEY__TENDER_EXPANSION,
                LAYER_KEY__TENDER_EXPANSION_BORDER,
              ],
              filter: ['in', str, ['get', 'date_start']],
            })),
          ],
        },
      ],
    },
    {
      title: 'Trassen',
      onLayers: [LAYER_KEY__TENDER_ROUTE],
      switchId: SWITCH_ID__TENDER_ROUTE,
      itemClass: 'switch',
      modelNameForCASL: 'sensibletilesets',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [LAYER_KEY__TENDER_ROUTE],
              filter: 'reset',
            },
            {
              title: 'Hellgrau',
              layerKeys: [LAYER_KEY__TENDER_ROUTE],
              filter: ['==', ['get', 'phase'], 3],
            },
            {
              title: 'Dunkelgrau',
              layerKeys: [LAYER_KEY__TENDER_ROUTE],
              filter: ['==', ['get', 'phase'], 4],
            },
          ],
        },
      ],
    },
  ],
};
