<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1967 8.24264L15.9697 3.46967C16.2625 3.17678 16.7374 3.17678 17.0303 3.46967L21.8033 8.24264C22.0962 8.53553 22.0962 9.01041 21.8033 9.3033C21.5104 9.59619 21.0355 9.59619 20.7426 9.3033L17.25 5.81066L17.25 28C17.25 28.4142 16.9142 28.75 16.5 28.75C16.0858 28.75 15.75 28.4142 15.75 28L15.75 5.81066L12.2573 9.3033C11.9644 9.59619 11.4896 9.59619 11.1967 9.3033C10.9038 9.01041 10.9038 8.53553 11.1967 8.24264Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.14645 16.3536C5.95118 16.1583 5.95118 15.8417 6.14645 15.6465L9.32842 12.4645C9.52368 12.2692 9.84026 12.2692 10.0355 12.4645C10.2308 12.6597 10.2308 12.9763 10.0355 13.1716L7.70711 15.5L12.5 15.5C14.9853 15.5 17 17.5147 17 20V24C17 24.2761 16.7761 24.5 16.5 24.5C16.2238 24.5 16 24.2761 16 24V20C16 18.067 14.433 16.5 12.5 16.5L7.70711 16.5L10.0355 18.8284C10.2308 19.0237 10.2308 19.3403 10.0355 19.5356C9.84028 19.7308 9.5237 19.7308 9.32844 19.5356L6.14645 16.3536Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.6716 12.4645L26.8535 15.6464C27.0488 15.8417 27.0488 16.1583 26.8535 16.3536L23.6716 19.5355C23.4763 19.7308 23.1597 19.7308 22.9644 19.5355C22.7692 19.3403 22.7692 19.0237 22.9644 18.8284L25.2929 16.5H20.5C18.567 16.5 17 18.067 17 20V24C17 24.2761 16.7761 24.5 16.5 24.5C16.2238 24.5 16 24.2761 16 24V20C16 17.5147 18.0147 15.5 20.5 15.5H25.2929L22.9644 13.1716C22.7692 12.9763 22.7692 12.6597 22.9644 12.4645C23.1597 12.2692 23.4763 12.2692 23.6716 12.4645Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
