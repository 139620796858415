<template>
  <div class="flex flex-col gap-3">
    <div class="flex flex-col gap-[10px]">
      <!-- title-->
      <div class="flex justify-between items-center">
        <h4 class="text-infra-highlight-500">
          {{ currentGemarkung }}
        </h4>
        <abort-process-modal />
      </div>
      <h6 class="text-infra-highlight-800">
        {{ subTitleStep2 }}
      </h6>
      <div class="flex justify-between items-start"></div>
      <div class="flex flex-col w-full gap-3">
        <div
          v-for="textItem in bodyTextStep2"
          :key="textItem"
          class="body-2 grid"
          :class="!textItem.icon ? 'grid-cols-1' : 'grid-cols-[20%,80%]'">
          <div v-if="textItem.icon !== null" v-html="textItem.icon"></div>
          <div v-if="textItem.text !== null" v-html="textItem.text"></div>
        </div>
      </div>
    </div>
    <div
      v-if="
        getValidationStep === 1 ||
        getValidationStep === 2 ||
        getValidationStep === 4
      "
      class="w-full flex flex-col gap-3">
      <div class="bg-green-50 p-3 rounded-[4px] flex flex-col gap-3">
        <div class="flex flex-col gap-3">
          <div
            v-for="textItem in bodyTextStep3"
            :key="textItem"
            class="body-2 text-green-900"
            v-html="textItem"></div>
        </div>
        <div v-if="getValidationStep === 4">
          <h6 class="text-green-900">Daraus geht hervor</h6>
          <ul class="list-disc body-2 list-outside mt-1">
            <li
              v-for="item in validationStep4Text"
              :key="item"
              class="marker:text-green-900 ml-[20px] text-green-900">
              {{ item }}
            </li>
          </ul>
        </div>
        <button-el
          :text="textActionButton"
          :success="true"
          :disabled="getShowValidationTicket"
          @click="methodActionButton" />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-[10px] mt-auto">
      <button-el
        icon="arrow_back"
        text="zurück"
        :disabled="getShowValidationTicket"
        :primary="false"
        :less-padding="true"
        @click="setNextStepValidation(-1)" />
      <button-el
        :disabled="getShowValidationTicket"
        :text="getValidationStep === 4 ? 'Abschließen' : 'weiter'"
        :icon="getValidationStep === 4 ? null : 'arrow_forward'"
        :icon-right="true"
        @click="setNextStepValidation(1)" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { flyTo } from '@/mapbox/main';

export default {
  name: 'Step2Validation',
  data() {
    return {
      countGemarkungActive: 0,
    };
  },
  computed: {
    ...mapGetters('ticket', ['getActiveProcess', 'getDrawFeaturesCount']),
    ...mapGetters('ticket', ['getValidationStep', 'getShowValidationTicket']),
    ...mapGetters('ticket', ['getGemeindeActive', 'getGemarkungActive']),
    ...mapState('draw', ['selectedFeatures']),

    gemarkungenGemeindeActive() {
      return this.getGemeindeActive.gemarkung_set;
    },

    textActionButton() {
      switch (this.getValidationStep) {
        case 1:
          return 'Ticket erstellen';
        case 2:
          return 'Ticket erstellen';
        case 3:
          return '';
        case 4:
          return 'CSV Download';
      }
      return '-';
    },

    currentGemarkung() {
      return this.getValidationStep === 4
        ? 'Validierungsprozess'
        : this.gemarkungenGemeindeActive[this.countGemarkungActive].name;
    },
    subTitleStep2() {
      switch (this.getValidationStep) {
        case 1:
          return 'Neubaugebiete und Baulücken.';
        case 2:
          return 'Adresskorrekturen';
        case 3:
          return 'Ergebnis Prüfung Adresskulisse';
        case 4:
          return 'Ergebnis Prüfung Adresskulisse';
      }
      return '-';
    },
    bodyTextStep2() {
      switch (this.getValidationStep) {
        case 1:
          return [
            {
              icon:
                '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M34.1458 2.52051H36.4374C38.1392 2.52051 39.7713 3.19655 40.9747 4.39991C42.178 5.60326 42.8541 7.23537 42.8541 8.93717V33.4555C42.8745 34.0379 42.8157 34.6221 42.6778 35.1918C42.4531 36.1202 42.0242 36.9868 41.4223 37.7284C40.8204 38.4701 40.0606 39.0682 39.1983 39.4791C38.3361 39.89 37.393 40.1035 36.4378 40.1038H1.14575V8.02051H31.6249V9.85384H2.97909V38.2705H36.437C36.4369 38.2705 36.4372 38.2705 36.437 38.2705C37.1195 38.2702 37.7936 38.1177 38.4096 37.8241C39.0258 37.5305 39.5687 37.1031 39.9988 36.5731C40.4289 36.0432 40.7354 35.4239 40.8959 34.7606C40.9813 34.408 41.0242 34.0477 41.0247 33.6872H41.0208V33.4904C41.0104 33.2414 40.9798 32.9929 40.9288 32.7475C40.6838 31.6979 40.0867 30.7637 39.2366 30.1006C38.3842 29.4356 37.3291 29.0837 36.2481 29.1037L36.2397 29.1038H34.1458V2.52051ZM41.0208 29.2423V8.93717C41.0208 7.7216 40.5379 6.55581 39.6783 5.69627C38.8188 4.83673 37.653 4.35384 36.4374 4.35384H35.9791V27.2705H36.223C37.7211 27.2448 39.1828 27.7334 40.3643 28.6551C40.5971 28.8367 40.8163 29.033 41.0208 29.2423ZM5.95825 12.833H31.1666V35.2913H12.8333V29.333H14.6666V33.458H29.3333V14.6663H20.1666V24.2913H12.8333V22.458H18.3333V14.6663H7.79159V35.2913H5.95825V12.833Z" fill="#212121"/>\n' +
                '</svg>\n',
              text: 'Im Abgleich mit vorliegenden Um- bzw. Masterplanungen wurden Neubaugebiet und Baulücken identifiziert.',
            },
            {
              icon:
                '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 2.75033C11.6601 2.75033 4.58333 7.81254 4.58333 16.5003C4.58333 18.8182 5.43243 21.4778 6.78074 24.2165C8.12246 26.9419 9.92112 29.6714 11.7354 32.1093C13.5475 34.5444 15.3618 36.6707 16.724 38.1889C17.4046 38.9476 17.9712 39.5531 18.3666 39.968C18.5644 40.1754 18.7192 40.3351 18.8241 40.4424C18.8765 40.496 18.9165 40.5365 18.943 40.5633L18.9726 40.5932L18.9797 40.6003L19.6297 41.2503L18.3333 42.5467L17.6852 41.8985L18.3333 41.2503C17.6852 41.8985 17.6852 41.8985 17.6852 41.8985L17.6816 41.895L17.6728 41.8861L17.6395 41.8525C17.6105 41.8232 17.5681 41.7802 17.5131 41.7239C17.4032 41.6115 17.2429 41.4462 17.0396 41.2329C16.633 40.8063 16.0537 40.1872 15.3594 39.4133C13.9715 37.8664 12.1192 35.6959 10.2646 33.2038C8.41221 30.7147 6.5442 27.8869 5.13593 25.0263C3.73423 22.1791 2.75 19.2241 2.75 16.5003C2.75 6.62561 10.8312 0.916992 18.3333 0.916992C25.8354 0.916992 33.9167 6.62561 33.9167 16.5003C33.9167 17.4628 33.7942 18.4507 33.5754 19.447L33.3788 20.3423L31.5881 19.949L31.7848 19.0537C31.9803 18.1635 32.0833 17.3089 32.0833 16.5003C32.0833 7.81254 25.0066 2.75033 18.3333 2.75033Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 12.8337C16.3083 12.8337 14.6667 14.4753 14.6667 16.5003C14.6667 18.5254 16.3083 20.167 18.3333 20.167C20.3584 20.167 22 18.5254 22 16.5003C22 14.4753 20.3584 12.8337 18.3333 12.8337ZM12.8333 16.5003C12.8333 13.4628 15.2958 11.0003 18.3333 11.0003C21.3709 11.0003 23.8333 13.4628 23.8333 16.5003C23.8333 19.5379 21.3709 22.0003 18.3333 22.0003C15.2958 22.0003 12.8333 19.5379 12.8333 16.5003Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M36.1297 31.167L31.1667 36.13L28.037 33.0003L29.3333 31.704L31.1667 33.5373L34.8333 29.8706L36.1297 31.167Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M32.0833 25.667C28.0332 25.667 24.75 28.9502 24.75 33.0003C24.75 37.0504 28.0332 40.3337 32.0833 40.3337C36.1334 40.3337 39.4167 37.0504 39.4167 33.0003C39.4167 28.9502 36.1334 25.667 32.0833 25.667ZM22.9167 33.0003C22.9167 27.9377 27.0207 23.8337 32.0833 23.8337C37.1459 23.8337 41.25 27.9377 41.25 33.0003C41.25 38.0629 37.1459 42.167 32.0833 42.167C27.0207 42.167 22.9167 38.0629 22.9167 33.0003Z" fill="#212121"/>\n' +
                '</svg>\n',
              text: '<b>Bitte prüfen Sie</b>, ob die Adresspunkte der dargestellten Neubaugebiete und Baulücken vollständig und korrekt sind.',
            },
          ];
        case 2:
          return [
            {
              icon:
                '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M34.1458 2.52051H36.4374C38.1392 2.52051 39.7713 3.19655 40.9747 4.39991C42.178 5.60326 42.8541 7.23537 42.8541 8.93717V33.4555C42.8745 34.0379 42.8157 34.6221 42.6778 35.1918C42.4531 36.1202 42.0242 36.9868 41.4223 37.7284C40.8204 38.4701 40.0606 39.0682 39.1983 39.4791C38.3361 39.89 37.393 40.1035 36.4378 40.1038H1.14575V8.02051H31.6249V9.85384H2.97909V38.2705H36.437C36.4369 38.2705 36.4372 38.2705 36.437 38.2705C37.1195 38.2702 37.7936 38.1177 38.4096 37.8241C39.0258 37.5305 39.5687 37.1031 39.9988 36.5731C40.4289 36.0432 40.7354 35.4239 40.8959 34.7606C40.9813 34.408 41.0242 34.0477 41.0247 33.6872H41.0208V33.4904C41.0104 33.2414 40.9798 32.9929 40.9288 32.7475C40.6838 31.6979 40.0867 30.7637 39.2366 30.1006C38.3842 29.4356 37.3291 29.0837 36.2481 29.1037L36.2397 29.1038H34.1458V2.52051ZM41.0208 29.2423V8.93717C41.0208 7.7216 40.5379 6.55581 39.6783 5.69627C38.8188 4.83673 37.653 4.35384 36.4374 4.35384H35.9791V27.2705H36.223C37.7211 27.2448 39.1828 27.7334 40.3643 28.6551C40.5971 28.8367 40.8163 29.033 41.0208 29.2423ZM5.95825 12.833H31.1666V35.2913H12.8333V29.333H14.6666V33.458H29.3333V14.6663H20.1666V24.2913H12.8333V22.458H18.3333V14.6663H7.79159V35.2913H5.95825V12.833Z" fill="#212121"/>\n' +
                '</svg>\n',
              text: 'Auf Basis der uns vorliegenden Gebäudeinformationen (Typisierung in Wohngebäude, Fabrik, Garage, Schuppen, …) und der TKU-Rückmeldungen haben wir Adressen identifiziert.',
            },
            {
              icon: ' ',
              text: 'Diese werden voraussichtlich auch zukünftig keinen Glasfaser-anschluss benötigen und sind somit vom Förderantrag (Graue Flecken) ausgeschlossen.',
            },
            {
              icon:
                '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 2.75033C11.6601 2.75033 4.58333 7.81254 4.58333 16.5003C4.58333 18.8182 5.43243 21.4778 6.78074 24.2165C8.12246 26.9419 9.92112 29.6714 11.7354 32.1093C13.5475 34.5444 15.3618 36.6707 16.724 38.1889C17.4046 38.9476 17.9712 39.5531 18.3666 39.968C18.5644 40.1754 18.7192 40.3351 18.8241 40.4424C18.8765 40.496 18.9165 40.5365 18.943 40.5633L18.9726 40.5932L18.9797 40.6003L19.6297 41.2503L18.3333 42.5467L17.6852 41.8985L18.3333 41.2503C17.6852 41.8985 17.6852 41.8985 17.6852 41.8985L17.6816 41.895L17.6728 41.8861L17.6395 41.8525C17.6105 41.8232 17.5681 41.7802 17.5131 41.7239C17.4032 41.6115 17.2429 41.4462 17.0396 41.2329C16.633 40.8063 16.0537 40.1872 15.3594 39.4133C13.9715 37.8664 12.1192 35.6959 10.2646 33.2038C8.41221 30.7147 6.5442 27.8869 5.13593 25.0263C3.73423 22.1791 2.75 19.2241 2.75 16.5003C2.75 6.62561 10.8312 0.916992 18.3333 0.916992C25.8354 0.916992 33.9167 6.62561 33.9167 16.5003C33.9167 17.4628 33.7942 18.4507 33.5754 19.447L33.3788 20.3423L31.5881 19.949L31.7848 19.0537C31.9803 18.1635 32.0833 17.3089 32.0833 16.5003C32.0833 7.81254 25.0066 2.75033 18.3333 2.75033Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 12.8337C16.3083 12.8337 14.6667 14.4753 14.6667 16.5003C14.6667 18.5254 16.3083 20.167 18.3333 20.167C20.3584 20.167 22 18.5254 22 16.5003C22 14.4753 20.3584 12.8337 18.3333 12.8337ZM12.8333 16.5003C12.8333 13.4628 15.2958 11.0003 18.3333 11.0003C21.3709 11.0003 23.8333 13.4628 23.8333 16.5003C23.8333 19.5379 21.3709 22.0003 18.3333 22.0003C15.2958 22.0003 12.8333 19.5379 12.8333 16.5003Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M36.1297 31.167L31.1667 36.13L28.037 33.0003L29.3333 31.704L31.1667 33.5373L34.8333 29.8706L36.1297 31.167Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M32.0833 25.667C28.0332 25.667 24.75 28.9502 24.75 33.0003C24.75 37.0504 28.0332 40.3337 32.0833 40.3337C36.1334 40.3337 39.4167 37.0504 39.4167 33.0003C39.4167 28.9502 36.1334 25.667 32.0833 25.667ZM22.9167 33.0003C22.9167 27.9377 27.0207 23.8337 32.0833 23.8337C37.1459 23.8337 41.25 27.9377 41.25 33.0003C41.25 38.0629 37.1459 42.167 32.0833 42.167C27.0207 42.167 22.9167 38.0629 22.9167 33.0003Z" fill="#212121"/>\n' +
                '</svg>\n',
              text: '<b>Bitte prüfen Sie</b>, ob die als Ausschlussadressen gekennzeichneten Adresspunkte vollständig und korrekt sind.',
            },
          ];
        case 3:
          return [
            {
              icon:
                '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<g clip-path="url(#clip0_1065_136765)">\n' +
                '<path d="M39.875 42.625H4.125C2.607 42.625 1.375 41.393 1.375 39.875V4.125C1.375 2.607 2.607 1.375 4.125 1.375H39.875C41.393 1.375 42.625 2.607 42.625 4.125V39.875C42.625 41.393 41.393 42.625 39.875 42.625Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '<path d="M42.625 26.125H28.875V34.375H15.125V26.125H1.375" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '<path d="M15.125 15.125L20.625 20.625L31.625 9.625" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '</g>\n' +
                '<defs>\n' +
                '<clipPath id="clip0_1065_136765">\n' +
                '<rect width="44" height="44" fill="white"/>\n' +
                '</clipPath>\n' +
                '</defs>\n' +
                '</svg>\n',
              text: 'Nach Validierung der Adresskulisse in Langenenslingen ergibt sich folgendes Ergebnis.',
            },
            {
              icon: null,
              text: 'Mit Klick auf weiter gelangen Sie zur nächsten Gemarkung oder ggf. zum Abschluss des Prozesses.',
            },
          ];
        case 4:
          return [
            {
              icon:
                '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 2.75033C11.6601 2.75033 4.58333 7.81254 4.58333 16.5003C4.58333 18.8182 5.43243 21.4778 6.78074 24.2165C8.12246 26.9419 9.92112 29.6714 11.7354 32.1093C13.5475 34.5444 15.3618 36.6707 16.724 38.1889C17.4046 38.9476 17.9712 39.5531 18.3666 39.968C18.5644 40.1754 18.7192 40.3351 18.8241 40.4424C18.8765 40.496 18.9165 40.5365 18.943 40.5633L18.9726 40.5932L18.9797 40.6003L19.6297 41.2503L18.3333 42.5467L17.6852 41.8985L18.3333 41.2503C17.6852 41.8985 17.6852 41.8985 17.6852 41.8985L17.6816 41.895L17.6728 41.8861L17.6395 41.8525C17.6105 41.8232 17.5681 41.7802 17.5131 41.7239C17.4032 41.6115 17.2429 41.4462 17.0396 41.2329C16.633 40.8063 16.0537 40.1872 15.3594 39.4133C13.9715 37.8664 12.1192 35.6959 10.2646 33.2038C8.41221 30.7147 6.5442 27.8869 5.13593 25.0263C3.73423 22.1791 2.75 19.2241 2.75 16.5003C2.75 6.62561 10.8312 0.916992 18.3333 0.916992C25.8354 0.916992 33.9167 6.62561 33.9167 16.5003C33.9167 17.4628 33.7942 18.4507 33.5754 19.447L33.3788 20.3423L31.5881 19.949L31.7848 19.0537C31.9803 18.1635 32.0833 17.3089 32.0833 16.5003C32.0833 7.81254 25.0066 2.75033 18.3333 2.75033Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 12.8337C16.3083 12.8337 14.6667 14.4753 14.6667 16.5003C14.6667 18.5254 16.3083 20.167 18.3333 20.167C20.3584 20.167 22 18.5254 22 16.5003C22 14.4753 20.3584 12.8337 18.3333 12.8337ZM12.8333 16.5003C12.8333 13.4628 15.2958 11.0003 18.3333 11.0003C21.3709 11.0003 23.8333 13.4628 23.8333 16.5003C23.8333 19.5379 21.3709 22.0003 18.3333 22.0003C15.2958 22.0003 12.8333 19.5379 12.8333 16.5003Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M36.1297 31.167L31.1667 36.13L28.037 33.0003L29.3333 31.704L31.1667 33.5373L34.8333 29.8706L36.1297 31.167Z" fill="#212121"/>\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M32.0833 25.667C28.0332 25.667 24.75 28.9502 24.75 33.0003C24.75 37.0504 28.0332 40.3337 32.0833 40.3337C36.1334 40.3337 39.4167 37.0504 39.4167 33.0003C39.4167 28.9502 36.1334 25.667 32.0833 25.667ZM22.9167 33.0003C22.9167 27.9377 27.0207 23.8337 32.0833 23.8337C37.1459 23.8337 41.25 27.9377 41.25 33.0003C41.25 38.0629 37.1459 42.167 32.0833 42.167C27.0207 42.167 22.9167 38.0629 22.9167 33.0003Z" fill="#212121"/>\n' +
                '</svg>\n',
              text: 'Sie haben die Prüfung der Adresskulisse nun für alle Gemarkungen durchgeführt. Vielen Dank!',
            },
          ];
      }
      return ['-', '-'];
    },
    bodyTextStep3() {
      switch (this.getValidationStep) {
        case 1:
          return [
            '<b>Bitte erstellen Sie</b> bei Abweichungen oder fehlenden Neubaugebieten und Baulücken <b>ein Ticket.</b>',
            'Durch die <b>Auswahl</b> einer einzelnen oder mehreren <b>Adressen</b> erstellen Sie entsprechend ein oder mehrere Tickets.',
          ];
        case 2:
          return [
            'Bitte erstellen Sie bei Abweichungen ein oder mehrere Tickets, indem Sie entweder mehrere oder einzelne Adressen auswählen.',
          ];
        case 4:
          return [
            'Sie können sich nun zur Kontrolle Ihrer Änderungen die Adressliste als csv. Datei herunterladen.',
          ];
      }
      return null;
    },

    validationStep4Text() {
      return [
        'Alle Adressen die als Neubaugebiete / Baulücken ergänzend aufgenommen werden sollen',
        'Alle Adressen die begründet ausgeschlossen werden',
        'Alle förderfähigen Adressen die nun als Graue Flecken ausgebaut werden können',
      ];
    },
  },
  watch: {
    countGemarkungActive(val) {
      const gemarkungActive = this.getGemeindeActive.gemarkung_set[val];
      this.$store.commit('ticket/SET_GEMARKUNG_ACTIVE', gemarkungActive);
    },
    getGemarkungActive(val) {
      if (!val) return;
      const config = {
        zoom: 16.9,
        lng: val.centroid[0],
        lat: val.centroid[1],
      };
      flyTo(config);
    },
  },
  mounted() {
    const gemarkungActive = this.getGemeindeActive.gemarkung_set[0];
    this.$store.commit('ticket/SET_GEMARKUNG_ACTIVE', gemarkungActive);
  },
  methods: {
    methodActionButton() {
      if (this.getValidationStep === 4) {
        console.log('download csv');
      } else {
        this.$store.commit('ticket/SET_SHOW_VALIDATION_TICKET', true);
      }
    },
    deleteDrawGeometry() {
      document
        .querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash')
        .click();
    },
    setNextStepValidation(step) {
      const validationStep = this.getValidationStep + step;
      const countGemarkungActive = this.countGemarkungActive;
      const gemarkungenGemeindeActiveLength =
        this.gemarkungenGemeindeActive.length;

      if (validationStep === 0) {
        if (countGemarkungActive === 0) {
          this.$store.commit('ticket/SET_TICKET_STEP', 1);
        } else if (step === -1) {
          this.$store.commit('ticket/SET_VALIDATION_STEP', 3);
          this.countGemarkungActive -= 1;
        }
      } else if (validationStep > 3) {
        if (countGemarkungActive !== gemarkungenGemeindeActiveLength - 1) {
          this.$store.commit('ticket/SET_VALIDATION_STEP', 1);
          this.countGemarkungActive += 1;
        } else if (step === 1) {
          this.$store.commit('ticket/SET_VALIDATION_STEP', 4);
        }
      } else if (step === -1 && this.getValidationStep === 4) {
        this.$store.commit('ticket/SET_VALIDATION_STEP', 3);
        if (gemarkungenGemeindeActiveLength > 1) {
          this.countGemarkungActive -= 1;
        }
      } else if (this.getValidationStep === 4) {
        console.log('complete process');
      } else {
        this.$store.commit('ticket/SET_VALIDATION_STEP', validationStep);
      }
    },
  },
};
</script>
