<template>
  <div
    class="p-5 flex flex-col border-radius-custom custom-background h-full w-full">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    borderRadius: {
      type: Boolean,
      default: true,
      required: false,
    },
    background: {
      type: Boolean,
      default: true,
      required: false,
    },
    overflowHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.border-radius-custom {
  border-radius: 8px;
}

.custom-background {
  background: white;
}
</style>
