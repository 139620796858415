<template>
  <div
    class="text-xs legend-wrapper absolute top-0 w-52 relative overflow-hidden bg-white">
    <div class="h-full">
      <div
        class="uppercase text-xs font-bold text-black h-[45px] px-4 py-0 top-0 w-full content-center flex flex-wrap justify-between items-center">
        {{ legend }}
      </div>
      <div class="p-4 pt-0">
        <p
          v-for="info in getUniqueInfos()"
          :key="info"
          class="text-blue-grey text-[11px]">
          {{ info }}
        </p>
        <v-list class="p-0 m-0">
          <v-list-item v-for="item in getLegend" :key="item.key" class="p-0">
            <div class="py-1">
              <span class="text-xs text-black font-medium">{{
                item.name
              }}</span>
              <br />
              <span v-if="item.unit" class="text-xs font-medium">
                [{{ item.unit }}]</span
              >
              <div
                v-if="item.colorScale"
                :style="{
                  background: `linear-gradient(0.25turn,${item.colorScale.join(
                    ','
                  )})`,
                  height: '11px',
                }"
                class="w-100"></div>
              <div
                v-if="item.colorScaleTextLeft && item.colorScaleTextRight"
                class="w-100 flex flex-row justify-space-between align-content-space-between">
                <span>{{ item.colorScaleTextLeft }}</span>
                <span>{{ item.colorScaleTextRight }}</span>
              </div>
            </div>
            <v-container v-if="item.type !== 'gradient'" class="pl-0 py-2">
              <v-row
                v-for="item in item.items"
                :key="item.key"
                dense
                class="legend-entries-wrapper p-0">
                <v-col cols="1" class="flex content-center items-center mr-1">
                  <v-avatar v-if="item.image" size="14">
                    <img :src="item.image" alt="John" />
                  </v-avatar>
                  <v-icon
                    v-if="item.color"
                    :color="item.color"
                    :size="item.size ? item.size : '11px'">
                    {{ item.icon }}
                  </v-icon>
                  <img v-if="item.iconPath" :src="item.iconPath" />
                </v-col>
                <v-col class="legend-entries">
                  <span class="legend-entries-text text-black">
                    {{ item.name }}
                  </span>
                </v-col>
              </v-row>
            </v-container>

            <v-container v-if="item.type === 'gradient'" class="pl-0 py-2">
              <div class="flex items-center">
                <div class="w-fit">
                  {{ item.items.min.value.toLocaleString('de-DE') }}
                </div>
                <div
                  class="w-full text-center mx-1 text-[10px]"
                  :style="`background: linear-gradient(to right, ${item.items.min.color}, ${item.items.max.color})`">
                  {{ item.unit }}
                </div>
                <div>{{ item.items.max.value.toLocaleString('de-DE') }}</div>
              </div>
            </v-container>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneObject from '@/utils/objectUtils';

export default {
  name: 'LegendMap',
  emits: ['setLegendIconState'],
  data() {
    return {
      legend: 'Legende',
    };
  },
  methods: {
    setLegendIconState(entriesExist) {
      this.$emit('setLegendIconState', !!entriesExist);
    },
    getUniqueInfos() {
      let uniqueInfos = new Set();
      for (let item of this.getLegendEntries) {
        if (item.info) {
          uniqueInfos.add(item.info);
        }
      }
      return uniqueInfos;
    },
    setLegendData(legends) {
      const outLegends = {};

      for (let i = 0; i < legends.length; i += 1) {
        const elem = legends[i];

        if (!(elem.type === 'gradient')) {
          if (elem.name in outLegends) {
            for (let j = 0; j < elem.items.length; j += 1) {
              const item = elem.items[j];
              const namesInLegendCategory = outLegends[elem.name].items.map(
                (e) => e.name
              );
              if (!namesInLegendCategory.includes(item.name)) {
                outLegends[elem.name].items.push(item);
              }
            }
          } else {
            outLegends[elem.name] = cloneObject(elem);
          }
        } else if (elem.type === 'gradient') {
          outLegends[elem.name] = cloneObject(elem);
        }
      }
      return Object.values(outLegends);
    },
  },
  computed: {
    ...mapGetters('map', ['getLegendEntries']),

    getLegend() {
      const legendEnties = this.setLegendData(this.getLegendEntries);
      this.setLegendIconState(legendEnties.length > 0);
      return legendEnties;
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
</style>
