<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 4V12"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4 8H12"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '16',
    },
    height: {
      type: [Number, String],
      default: '16',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
