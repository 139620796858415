<template>
  <div
    class="flex justify-between w-full panelItem pt-[12px] pb-[11px]"
    :class="
      isSelected
        ? ''
        : 'border-b border-infra-highlight-200 hover:border-none singleItemcustom'
    "
    @click="editItem">
    <div
      class="flex items-center relative body-3"
      style="width: 60%"
      :class="isSelected ? 'font-medium' : ''">
      {{ item.label }}
      <v-tooltip>
        <template #activator="{ props }">
          <v-icon
            v-show="isEditable"
            class="ml-auto absolute right-0"
            :color="versionToColorMapper[item.sourceVersion] || 'grey'"
            icon="mdi-circle-medium"
            :style="isSelected ? 'padding-left: 0px' : ''"
            v-bind="props" />
        </template>
        <div>
          <div v-for="version in getBuildingVersions" :key="version.index">
            <v-icon
              class="ml-auto"
              :color="versionToColorMapper[version.value]"
              icon="mdi-circle-medium" />
            <span>{{ version.display_name }}</span>
          </div>
        </div>
      </v-tooltip>
    </div>
    <div
      style="width: 38%"
      class="flex items-center"
      :class="
        item.type !== 'boolean'
          ? 'overflow-hidden hover:overflow-visible hover:z-10 justify-start'
          : 'justify-end'
      ">
      <dropdown-field
        v-if="item.type === 'choice'"
        :item="item"
        :is-selected="isSelected"
        :is-loading="getPropertyDataIsLoading"
        :click-index="clickIndex"
        :index="index"
        v-bind="$attrs"
        @update-input="currentInput = $event" />
      <checkbox-field
        v-else-if="item.type === 'boolean'"
        :item="item"
        :is-selected="isSelected"
        :is-loading="getPropertyDataIsLoading"
        :click-index="clickIndex"
        :index="index"
        v-bind="$attrs"
        @update-input="currentInput = $event" />
      <input-field
        v-else
        :item="item"
        :is-selected="isSelected"
        :is-loading="getPropertyDataIsLoading"
        :click-index="clickIndex"
        :index="index"
        v-bind="$attrs"
        @update-input="currentInput = $event" />
    </div>
    <!--itemOptions (confirm/delete) with absolute positioning-->
    <div
      v-if="isSelected"
      class="flex absolute w-3 h-full bg-white items-center py-2 left-outside-custom"
      style="left: -10px; top: 0" />
    <div
      v-if="isSelected"
      class="flex absolute bg-white items-center w-auto h-full py-2 right-outside-custom"
      style="right: -64px; top: 0">
      <check-green
        class="cursor-pointer ml-3 mr-1 h-fit"
        icon="mdi-check"
        @click.stop="saveValue()" />
      <remove-icon
        class="cursor-pointer ml-1 mr-3"
        icon="mdi-close"
        @click.stop="dropValue()" />
    </div>
    <triangle-down
      v-if="isSelected && item.type === 'choice'"
      class="absolute"
      style="right: -5px; top: 13px" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    isSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
    clickIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['editItem', 'setIndex', 'notInRange'],
  data() {
    return {
      currentInput: null,
      versionToColorMapper: {
        0: 'green',
        1: 'yellow',
        2: 'red',
        3: 'grey',
      },
    };
  },
  computed: {
    ...mapState('map', ['mainResult']),
    ...mapGetters('map', ['getPropertyDataIsLoading']),
    ...mapGetters('map', ['getModelOptions']),
    getBuildingVersions() {
      let version = this.getModelOptions.version;
      if (typeof version === 'undefined') return [];
      version = version.choices.slice(0, 3);
      version.push({ display_name: 'Nicht definiert', value: 3 });
      return version;
    },
  },
  methods: {
    editItem() {
      this.$emit('editItem', this.index);
    },
    saveValue() {
      const key = this.item.key;
      // test if input contains a number
      const containsNumber =
        this.item.type === 'integer' || this.item.type === 'float';
      // if it is a number, check if it is between the min_value and max_value of item
      if (containsNumber) {
        const value = parseFloat(this.currentInput.replace(',', '.'));
        if (value < this.item.min_value || value > this.item.max_value) {
          // emit min_value or max_value to to snackbar
          this.$emit('notInRange', {
            snackbar: true,
            min: this.item.min_value,
            max: this.item.max_value,
          });
          return;
        }
      }
      // check if this.currentInput has a comma at the end, if so, remove it
      if (containsNumber && /[,-]$/.test(this.currentInput)) {
        this.currentInput = this.currentInput.slice(0, -1);
      }
      const value = containsNumber
        ? this.currentInput.replace(',', '.')
        : this.currentInput;
      const url = this.mainResult.aggregate.value;
      const payload = { data: { [key]: value }, url };
      // only dispatch if input is not null
      if (value !== null) {
        this.$store.commit('map/SET_PROPERTY_DATA_IS_LOADING', true);
        this.$store.dispatch('map/PATCH_BUILDING_PROPERTY', payload);
      }
      this.currentInput = null;
      this.$emit('setIndex', this.index);
    },
    dropValue() {
      this.currentInput = null;
      this.$emit('setIndex', this.index);
    },
  },
};
</script>

<style lang="scss">
.panelItem {
  .left-outside-custom {
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .right-outside-custom {
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
