<template>
  <div class="flex absolute justify-between top-5 w-[320px] left-5 z-10">
    <div class="flex w-[320px] bg-white rounded-[8px] standard-elevation-2">
      <to-home />
      <div class="border-gray navi_toggler">
        <div
          class="search-bar-icon bg-infra-highlight-25 hover:bg-infra-highlight-50"
          @click="$emit('togglePanel2')">
          <img
            class="mt-[2px]"
            src="@/assets/svg/menu-options-icon.svg"
            alt="menu_options" />
        </div>
      </div>
      <search-bar />
      <!--      <map-actions v-bind="$attrs" />-->
      <map-actions-dialog />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['togglePanel2'],
  data() {
    return {
      mapActionsDialog: false,
    };
  },
};
</script>
