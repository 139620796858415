import { createApp } from 'vue';

const globalComponents = {
  install: (app) => {
    // Eagerly load and register components
    const componentFiles = import.meta.glob('../**/*.vue', { eager: true });

    // List of filenames to exclude
    const excludeFiles = ['App.vue', 'AnotherComponent.vue'];

    // Process each component file
    for (const [path, componentModule] of Object.entries(componentFiles)) {
      // Extract the component name from the file path
      const filename = path.split('/').pop();

      // Check if the file is in the excludeFiles array and skip if it is
      if (excludeFiles.includes(filename)) {
        continue;
      }

      const componentName = filename.replace(/\.\w+$/, '');
      // Register the component globally
      app.component(componentName, componentModule.default);
    }
  },
};

export default globalComponents;
