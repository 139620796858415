<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_104_8340)">
      <path
        d="M0.865904 3.83404L0.865946 3.83401L0.860903 3.82849C0.620982 3.56625 0.5 3.30144 0.5 3C0.5 2.51577 0.785922 2.09911 1.39497 1.72806C2.00782 1.35469 2.8647 1.08496 3.79242 0.896491C5.63938 0.521284 7.59861 0.5 8 0.5C8.40139 0.5 10.3606 0.521284 12.2076 0.896491C13.1353 1.08496 13.9922 1.35469 14.605 1.72806C15.2141 2.09911 15.5 2.51577 15.5 3C15.5 3.31434 15.3694 3.59025 15.1096 3.86047L15.1027 3.86765L15.096 3.8751L9.62605 10.0381L9.5 10.1801V10.37V15C9.5 15.2765 9.27618 15.5 9 15.5H7C6.72382 15.5 6.5 15.2765 6.5 15V10.37V10.1801L6.3739 10.038L0.865904 3.83404ZM14.0916 3.42548L14.781 3L14.0916 2.57452C13.6803 2.32063 12.9395 2.05625 11.9371 1.85389C10.9208 1.64875 9.59878 1.5 8 1.5C6.40122 1.5 5.07915 1.64875 4.06294 1.85389C3.06045 2.05625 2.31974 2.32063 1.90839 2.57452L1.21903 3L1.90839 3.42548C2.31974 3.67937 3.06045 3.94375 4.06294 4.14611C5.07915 4.35125 6.40122 4.5 8 4.5C9.59878 4.5 10.9208 4.35125 11.9371 4.14611C12.9395 3.94375 13.6803 3.67937 14.0916 3.42548Z"
        fill="transparent"
        :stroke="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
