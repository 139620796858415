<template>
  <v-img
    class="overflow-hidden h-full w-full rounded-[6px]"
    :src="emblemURL"></v-img>
</template>

<script>
import { axiosInstance } from '@/utils/axiosHelper';

export default {
  props: {
    url: String,
  },
  computed: {
    emblemURL() {
      return `${axiosInstance.defaults.baseURL}/api${this.url}`;
    },
  },
};
</script>
