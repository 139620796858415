<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fill-rule="evenodd"
      stroke="#A9A9A9"
      stroke-linecap="round"
      stroke-linejoin="round"
      transform="translate(2 2)"
      stroke-width="1.5">
      <circle cx="6" cy="6" r="7" />
      <g transform="matrix(0 1 -1 0 17 0)">
        <path d="m3 14 6-6" />
        <path d="m3 8 6 6" />
      </g>
    </g>
  </svg>
</template>
