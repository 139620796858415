<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1186_149422)">
      <path
        d="M24 9V24H36.75"
        stroke="#015B76"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M3.765 31.9913C5.75128 37.0326 9.54668 41.1516 14.4089 43.5428C19.2712 45.9341 24.8506 46.4256 30.056 44.9211C35.2615 43.4167 39.7185 40.0246 42.5555 35.4082C45.3925 30.7918 46.4054 25.2832 45.3961 19.9595C44.3869 14.6359 41.428 9.88021 37.0982 6.62251C32.7684 3.3648 27.3791 1.8394 21.9843 2.34461C16.5894 2.84982 11.5771 5.34931 7.92739 9.35427C4.27774 13.3592 2.2533 18.5816 2.25 24"
        stroke="#015B76"
        stroke-width="3"
        stroke-miterlimit="10" />
      <path
        d="M4.5165 44.2808L3.75 31.9868L15.5167 36.0773"
        stroke="#015B76"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="square" />
    </g>
    <defs>
      <clipPath id="clip0_1186_149422">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MevValidationInProcessIcon',
};
</script>
