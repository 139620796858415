<template>
  <single-page>
    <div class="text-3xl text-infra-blue p-4">
      Reports- und Dokumenten-Downloads
    </div>
    <v-data-table :headers="headers" :items="items" class="tailwind px-4">
      <template #[`item.document`]="{ item }">
        <div class="flex items-center">
          <div class="mr-4 font-medium">
            {{ item.props.title.document.name }}
          </div>
          <div class="text-infra-lines">
            {{ item.props.title.document.description }}
          </div>
        </div>
      </template>
      <template #[`item.filetype`]="{ item }">
        <div class="flex items-center justify-between">
          <div class="mr-2 font-medium">
            <span class="mr-2">{{ item.props.title.filetype }}</span>
            <span>({{ documentSize(item.props.title.id) }})</span>
          </div>
          <a
            :href="item.props.title.filepath"
            :download="item.props.title.document.name"
            class="cursor-pointer">
          </a>
        </div>
      </template>
    </v-data-table>
  </single-page>
</template>

<script>
export default {
  data() {
    return {
      filesizeData: [],
      headers: [
        {
          title: 'Dokumentenname',
          key: 'document',
        },
        {
          title: 'Dateityp',
          key: 'filetype',
        },
      ],
      items: [
        {
          id: 1,
          document: {
            name: 'Energiebilanz 2020',
            description: 'Energiebilanz für das Jahr 2020',
          },
          filetype: 'PDF',
          filepath: '/public/report-example.pdf',
        },
        {
          id: 2,
          document: {
            name: 'Energiebilanz 2021',
            description: 'Energiebilanz für das Jahr 2021',
          },
          filetype: 'PDF',
          filepath: '/public/report-example.pdf',
        },
        {
          id: 3,
          document: {
            name: 'Energiebilanz 2022',
            description: 'Energiebilanz für das Jahr 2022',
          },
          filetype: 'PDF',
          filepath: '/public/report-example.pdf',
        },
        {
          id: 4,
          document: {
            name: 'Energiebilanz 2023',
            description: 'testjk',
          },
          filetype: 'JPG',
          filepath: '/public/testimgjk.jpg',
        },
        {
          id: 5,
          document: {
            name: 'Energiebilanz 2023 powerpoint',
            description: 'testjk',
          },
          filetype: 'PPTX',
          filepath: '/public/wp_fixes.pptx',
        },
      ],
    };
  },

  computed: {
    // eslint-disable-next-line vue/no-async-in-computed-properties
    async filesizes() {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      return await Promise.all(
        this.items.map(async (item) => {
          const response = await fetch(item.filepath);
          const size = response.headers.get('content-length');
          return {
            id: item.id,
            size: `${(Math.floor((size / 1000000) * 100) / 100).toLocaleString(
              navigator.language
            )} MB`,
          };
        })
      );
    },
    // eslint-disable-next-line vue/no-async-in-computed-properties,vue/return-in-computed-property
    // when url starts with http
    // async filesizes() {
    //   //create an array of promises
    //   const promises = this.items.map((item) => {
    //     if (item.filepath.startsWith('http')) {
    //       // if filePath is a URL, use fetch to get the file size
    //       return fetch(item.filepath, { method: 'HEAD' })
    //         .then((response) => response.headers.get('content-length'))
    //         .then((size) => {
    //           // convert size to MB
    //           return {
    //             id: item.id,
    //             size: (size / 1024 / 1024).toFixed(2) + ' MB',
    //           };
    //         });
    //     } else {
    //       // if filePath is a local file, use fs to get the file size
    //       return new Promise((resolve, reject) => {
    //         fs.stat(item.filepath, (err, stats) => {
    //           if (err) {
    //             reject(err);
    //           } else {
    //             const fileSizeInBytes = stats.size;
    //             // convert size to MB
    //             resolve({
    //               id: item.id,
    //               size: (fileSizeInBytes / 1024 / 1024).toFixed(2) + ' MB',
    //             });
    //           }
    //         });
    //       });
    //     }
    //   });
    //   try {
    //     // wait for all promises to resolve
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const result = await Promise.all(promises);
    //     return result;
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
  },

  mounted() {
    this.filesizes.then((data) => (this.filesizeData = data));
  },

  methods: {
    documentSize(id) {
      let item = this.filesizeData.find((item) => item.id === id);
      return item ? item.size : 'Item not found';
    },
  },
};
</script>

<style lang="scss">
.documentFiling {
  th {
    border: none !important;
  }

  .v-data-table__tr:last-child {
    td {
      border-bottom: thin solid #e0e0e0 !important;
    }
  }

  .v-data-table__tr:hover {
    td {
      @apply bg-infra-background;
    }
  }

  .v-data-table-footer {
    display: none;
  }

  .mdi-arrow-up {
    display: none !important;
  }
}
</style>
