<template>
  <div class="h-full flex flex-col">
    <breadcrumb-navigation class="single-page-wrapper breadcrumb-container" />
    <perfect-scrollbar
      ref="singlePageScrollWrapper"
      style="max-height: calc(100vh - 85px)"
      class="single-page-scroll-wrapper"
      :class="{ 'pb-5': hideFooter }"
      @scroll="handleScroll">
      <div
        class="flex flex-col single-page-wrapper content-container standard-elevation-1 mt-[2px]"
        :class="{
          'border-radius-custom': borderRadius,
          'custom-background': background,
          'overflow-hidden': overflowHidden,
        }"
        :style="{
          'min-height': !hideFooter
            ? 'calc(100vh - 143px)'
            : 'calc(100vh - 105px)',
        }">
        <slot></slot>
      </div>
      <footer-section v-if="!hideFooter" />
    </perfect-scrollbar>
  </div>
</template>
<script>
export default {
  props: {
    borderRadius: {
      type: Boolean,
      default: true,
      required: false,
    },
    background: {
      type: Boolean,
      default: true,
      required: false,
    },
    overflowHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  methods: {
    handleScroll() {
      // const lastScrollPosition =
      //   this.$refs.singlePageScrollWrapper.ps.lastScrollTop;
      // this.$store.commit(
      //   'dashboard/SET_LAST_SCROLL_POSITION',
      //   lastScrollPosition
      // );
    },
  },
};
</script>

<style scoped lang="scss">
.single-page-wrapper {
  &.content-container {
    @media (max-width: 1440px) {
      @apply mx-5;
    }
  }

  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto w-full;
    &.breadcrumb-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.border-radius-custom {
  border-radius: 8px;
}

.custom-background {
  background: white;
}
</style>
