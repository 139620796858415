import {
  LAYER_KEY__BUILDINGS,
  LAYER_KEY__ADDRESS_POINTS,
} from '../layers/buildings';

export const SWITCH_ID__BUILDINGS = 'LC_BUILDINGS';
export const SWITCH_ID__ADDRESS_POINTS = 'LC_ADDRESS_POINTS';

export default {
  title: 'Gebäude',
  items: [
    {
      title: 'Adresspunkte',
      onLayers: [LAYER_KEY__ADDRESS_POINTS],
      switchId: SWITCH_ID__ADDRESS_POINTS,
      itemClass: 'switch',
    },
    {
      title: 'LOD-1 Gebäude',
      onLayers: [LAYER_KEY__BUILDINGS],
      switchId: SWITCH_ID__BUILDINGS,
      itemClass: 'switch',
    },
  ],
};
