export default {
  SET_VALID_MODE: (state, payload) => {
    state.validMode = { ...state.validMode, state: payload };
  },
  SET_FULLSCREEN_MODAL: (state, payload) => {
    state.modalFullScreen = payload;
  },
  SET_RIGHTSCREEN_MODAL: (state, payload) => {
    state.modalRightScreen = payload;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
};
