<template>
  <div
    v-for="(data, index) in durationData"
    :key="index"
    ref="phaseItem"
    :data-duration="
      convertShortDate(data.start) + '-' + convertShortDate(data.end)
    "
    class="chart-bar-item phase-item-wrapper-pis"
    :style="`top: ${
      small && index === 0 ? '0px' : small ? '11px' : '0px'
    }; ${small ? 'height: 12px' : null}`">
    <div
      class="relative h-full"
      @mouseenter="showPopover(index)"
      @mouseleave="hidePopover">
      <div
        :ref="'toolTipActivator' + index"
        class="phase-item h-full"
        :style="{ backgroundColor: data.color }"></div>
      <ToolTipAdvanced
        v-if="phaseItemIndex === index"
        v-model="showPhaseItemPopover"
        fixed="y"
        :offset-y="5"
        :activator-bounds="phaseItemActivatorBounds">
        <template #content>
          <div
            class="standard-elevation-1 p-1.5 rounded flex flex-col gap-2"
            :style="{ backgroundColor: data.color }">
            <div
              v-for="date in data.dates"
              :key="date.date"
              class="text-white flex flex-col gap-0.5">
              <div class="caption-2 text-[15px]">
                {{
                  new Date(date.date).toLocaleDateString('de-DE', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })
                }}
              </div>
              <div class="caption-1 text-[12px]">
                {{ date.name }}
              </div>
            </div>
          </div>
        </template>
      </ToolTipAdvanced>
    </div>
  </div>
</template>

<script>
import ToolTipAdvanced from '../../toolTipAdvanced/ToolTipAdvanced.vue';

export default {
  name: 'PhaseItemPis',
  components: { ToolTipAdvanced },
  props: {
    durationData: {
      type: Array,
      default: () => [],
    },
    interval: {
      type: String,
      default: 'day',
    },
    intervalData: {
      type: Object,
      default: () => {},
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPhaseItemPopover: false,
      mouseX: 0,
      mouseY: 0,
      popoverDimensions: null,
      phaseItemIndex: null,
      phaseItemActivatorBounds: null,
    };
  },
  methods: {
    convertShortDate(date) {
      if (this.interval === 'months') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.label;
            }
          });
        });
        return shortCut;
      } else if (this.interval === 'weeks') {
        const dateFormatted = date.split('-').reverse().join('.');
        let shortCut;
        this.intervalData.forEach((item) => {
          item.data.forEach((date2) => {
            if (date2 === dateFormatted) {
              shortCut = item.value + '.' + item.year;
            }
          });
        });
        return shortCut;
      } else {
        return date.split('-').reverse().join('.');
      }
    },
    showPopover(index) {
      this.phaseItemActivatorBounds =
        this.$refs['toolTipActivator' + index][0].getBoundingClientRect();
      this.phaseItemIndex = index;
      this.showPhaseItemPopover = true;
    },
    hidePopover() {
      this.showPhaseItemPopover = false;
      this.phaseItemIndex = null;
    },
  },
};
</script>

<style lang="scss">
.phase-item-wrapper-pis {
  @apply h-full flex flex-col justify-center p-0.5 pr-[3px];
  .phase-item {
    @apply rounded-[2px] h-[8px];
  }
}
</style>
