import { axios } from '@/utils/axiosHelper';
import cardData from '../card-data';
import cookie from 'vue-cookies';
import { saveAs } from 'file-saver';

export default {
  INITIALIZE_CARDS({ commit, rootGetters }, router) {
    return new Promise((resolve, reject) => {
      const hubRoute = router.options.routes.find(
        (route) => route.path === '/dashboard'
      );

      let tempCards = hubRoute.children.map((childRoute, index) => ({
        id: index,
        label: index === 0 ? 'Gesamtstatus' : childRoute.meta.title,
        active: index !== 0,
        route:
          index === 0 ? hubRoute.path : hubRoute.path + '/' + childRoute.path,
      }));

      let mergedCards = tempCards.map((card) => {
        const additionalData = cardData.find((data) => data.id === card.id);
        return { ...card, ...additionalData };
      });

      axios({
        url: `/api/dashboard/aggregate/`,
        method: 'GET',
      })
        .then((resp) => {
          const { data } = resp.data;
          commit('ticket/SET_LANDKREISE', data, { root: true });
          const landkreiseSelected = rootGetters['ticket/getLandkreiseActive'];
          commit('ticket/SET_GEMEINDEN_FILTERED', landkreiseSelected, {
            root: true,
          });
          commit(
            'ticket/SET_GEMEINDEN_ACTIVE',
            rootGetters['ticket/getGemeindenFiltered'],
            { root: true }
          );

          commit('SET_DASHBOARD_CARDS_DATA', data);
          commit('SET_DASHBOARD_CARDS', mergedCards);

          const hubCardStatus = router.currentRoute.value.path !== '/dashboard';
          commit('SET_HUB_CARD_ACTIVE', hubCardStatus);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  UPDATE_CARD_STATUS({ commit, state }, id) {
    commit('TOGGLE_CARD_ACTIVE', id);

    const hubCardStatus = id !== 0;
    commit('SET_HUB_CARD_ACTIVE', hubCardStatus);
  },
  GET_PLANNING_CONSTRUCTION_DATA({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/builtt/get_construction_dashboard/',
        method: 'GET',
      })
        .then((resp) => {
          const { data } = resp.data;
          commit('SET_PLANNING_CONSTRUCTION_DATA', data);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  GET_USERS({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/ViewLevel/User/',
        method: 'GET',
      })
        .then((resp) => {
          const { data } = resp;
          commit('SET_USERS', data.users);
          commit('SET_GEMEINDEN', data.gemeinden);
          commit('SET_LANDKREISE', data.landkreise);
          commit('SET_GROUPS', data.groups);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  GET_USER_DETAILS({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/User/${id}/`,
        method: 'GET',
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  SAVE_USER({ commit, state }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/User/${id}/`,
        method: 'PUT',
        data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  SET_ACTIVATE_USERS({ commit, state }, { userIds, active }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/User/set_active/`,
        method: 'PUT',
        data: { user_ids: userIds, active },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  SEND_PASSWORD_RESET_EMAIL({ commit, state }, userIds) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/User/send_password_reset_mail/`,
        method: 'POST',
        data: { user_ids: userIds },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  GET_INVITATIONS({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/ViewLevel/Invitation/',
        method: 'GET',
      })
        .then((resp) => {
          const { data } = resp;
          commit('SET_INVITATIONS', data.invitations);
          commit('SET_GEMEINDEN', data.gemeinden);
          commit('SET_LANDKREISE', data.landkreise);
          commit('SET_GROUPS', data.groups);
          commit('SET_EMAIL_PREVIEW', data.email_preview);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  GET_INVITATION_DETAILS({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/Invitation/${id}/`,
        method: 'GET',
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  SAVE_INVITATION({ commit, state }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/Invitation/${id}/`,
        method: 'PUT',
        data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  CREATE_INVITATION({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/Invitation/`,
        method: 'POST',
        data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  DELETE_INVITATIONS({ commit, state }, invitationIds) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/ViewLevel/Invitation/delete_multiple/`,
        method: 'DELETE',
        data: { invitation_ids: invitationIds },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  GET_DOCUMENTS() {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/documents_list/',
        method: 'GET',
      })
        .then((resp) => {
          const { files } = resp.data;
          resolve(files);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  DOWNLOAD_FILE: (
    { commit, state },
    { displayName, fileName, fileExt, directory }
  ) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/documents_download/?file_name=${fileName}&file_extension=${fileExt}&directory=${directory}`,
        method: 'GET',
        responseType: 'blob',
      })
        .then((resp) => {
          saveAs(new Blob([resp.data]), `${displayName}.${fileExt}`);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
