<template>
  <div class="control-item" :class="classes">
    <v-tooltip v-if="tooltipText" activator="parent" location="top">
      <div class="subtitle-2 text-white">
        <slot name="text" />
        {{ tooltipText }}
      </div>
    </v-tooltip>
    <div v-if="textLeft" class="body-2 text-black">{{ textLeft }}</div>
    <component :is="icon" v-if="icon" class="w-4 h-4" />
    <h6 :class="h6classes">
      {{
        mileStonesTotal !== null
          ? mileStoneCount + ' / ' + mileStonesTotal
          : (singleText * 100).toFixed(0) + '%'
      }}
    </h6>
  </div>
</template>

<script>
import newConstructionShape from '../../../assets/icons/new-construction-shape.vue';
import journeyShape from '../../../assets/icons/journey-shape.vue';
import EuroShape from '../../../assets/icons/EuroShape.vue';
import CompassIcon from '../../../assets/icons/CompassIcon.vue';
export default {
  name: 'ChipBarItem',
  components: {
    newConstructionShape,
    journeyShape,
    EuroShape,
    CompassIcon,
  },
  props: {
    singleText: {
      type: Number,
      default: 0,
    },
    textLeft: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    mileStoneCount: {
      type: Number,
      default: null,
    },
    mileStonesTotal: {
      type: Number,
      default: null,
    },
    tooltipText: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      return {
        'bg-blue-grey-700 text-white': this.darkTheme,
        'bg-white border border-blue-grey-50': !this.darkTheme,
      };
    },
    h6classes() {
      return {
        'text-blue-grey-700': !this.darkTheme,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.control-item {
  @apply w-fit rounded-[2px] py-[8px] px-2 flex items-center justify-center gap-[6px];
  h6 {
    line-height: normal;
  }
}
</style>
