import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import PrimaryNavIcon from '../components/Icons/PrimaryNavIcon.vue';
import MapControlBarIcon from '../components/Icons/MapControlBarIcon.vue';
import i18n from './vue-i18n';
import config from '../configs';
import { h } from 'vue';

export default createVuetify({
  components: {
    ...components,
  },
  directives,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
    current: 'de',
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      adminPanel: h(PrimaryNavIcon, { name: 'adminPanel' }),
      map: h(PrimaryNavIcon, { name: 'map' }),
      archive: h(PrimaryNavIcon, { name: 'archive' }),
      support: h(PrimaryNavIcon, { name: 'support' }),
      arrowRight: h(MapControlBarIcon, { name: 'arrowRight' }),
      arrowLeft: h(MapControlBarIcon, { name: 'arrowLeft' }),
      search: h(MapControlBarIcon, { name: 'search' }),
      north: h(MapControlBarIcon, { name: 'north' }),
      plus: h(MapControlBarIcon, { name: 'plus' }),
      minus: h(MapControlBarIcon, { name: 'minus' }),
      timemachine: h(MapControlBarIcon, { name: 'timemachine' }),
      settings: h(MapControlBarIcon, { name: 'settings' }),
      legend: h(MapControlBarIcon, { name: 'legend' }),
      magnifier: h(MapControlBarIcon, { name: 'magnifier' }),
      document: h(MapControlBarIcon, { name: 'document' }),
      cluster: h(MapControlBarIcon, { name: 'cluster' }),
      cluster_add: h(MapControlBarIcon, { name: 'cluster_add' }),
      cluster_remove: h(MapControlBarIcon, { name: 'cluster_remove' }),
      more: h(MapControlBarIcon, { name: 'more' }),
    },
    sets: {
      mdi,
    },
  },
});
