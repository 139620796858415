<template>
  <div class="flex flex-col gap-1.5">
    <div
      v-for="(item, index) in data.milestoneGroups"
      :key="item"
      class="flex flex-col gap-0.5"
      @mouseenter="parentActiveIndex = index">
      <div class="caption-1">{{ item.name }}</div>
      <div class="flex flex-col gap-1">
        <div
          v-for="(milestone, index2) in item.milestones"
          :key="milestone"
          class="progress-container relative"
          :style="{
            'background-color': data.colors.progressWrapper,
          }"
          @mouseenter="
            showPopup = true;
            activeMilestone = index2;
          "
          @mouseleave="showPopup = false">
          <div
            class="progress-bar"
            :style="{
              width: `${
                milestone.max > 0 ? (milestone.value / milestone.max) * 100 : 0
              }%`,
              'background-color': data.colors.progressBar,
            }"></div>

          <!--popup-->
          <div
            v-if="
              showPopup &&
              activeMilestone === index2 &&
              parentActiveIndex === index
            "
            class="popup-card-content standard-elevation-1"
            :class="{
              richtlinie: richtlinieSelected[index2] && data.grouped,
            }"
            :style="{ 'background-color': data.colors.popper }"
            @mouseenter="showPopup = false">
            <div
              v-if="richtlinieSelected[index2] && data.grouped"
              class="caption-2">
              {{
                richtlinien
                  .find(
                    (richtlinie) =>
                      richtlinie.value === richtlinieSelected[index2]
                  )
                  ?.name.split('vom')[0]
              }}
            </div>
            <span class="caption-1"
              >{{ milestone.value }} von {{ milestone.max }} abgeschlossen</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const richtlinieSelected = computed(() => {
  return [...store.getters['ticket/getRichtlinieSelected']].sort(
    (a, b) => a - b
  );
});

const richtlinien = computed(() => store.getters['ticket/getRichtlinien']);

const showPopup = ref(false);
const activeMilestone = ref(0);
const parentActiveIndex = ref(0);
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  height: 14px;
  padding: 1px;
  border-radius: 4px;
}

.progress-bar {
  border-radius: 3px;
  height: 100%;
  animation: progressAnimation 1.1s forwards;
}

@keyframes progressAnimation {
  from {
    width: 0;
  }
  to {
    /* The width will be set by the inline style binding in the template */
  }
}

.popup-card-content {
  @apply absolute left-0 px-[4px] py-[3px] w-full rounded-[4px]
  flex flex-col gap-1;
  &:not(.richtlinie) {
    @apply top-[-21px];
  }

  &.richtlinie {
    @apply top-[-39px];
  }
}
</style>
