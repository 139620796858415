<template>
  <div class="mt-auto flex items-center gap-2 justify-between">
    <v-tooltip location="top"
      >{{ data.tooltip }}
      <template #activator="{ props }">
        <div
          v-if="!hubCardActive"
          v-bind="props"
          :class="{ 'w-full text-center': data.noLink }"
          class="rounded-[4px] px-2 py-1"
          :style="{
            'background-color': data.colors.bottom,
          }">
          <h6>{{ data.communityCount }} Gemeinden</h6>
        </div>
      </template>
    </v-tooltip>
    <div
      v-if="currentCard.id !== data.id"
      :class="[
        data.id === 0 || currentCard.id === data.id
          ? 'hub-card-bottom-custom'
          : 'ml-auto',
        {
          'card-bottom-disabled': Array.isArray(data.disabled)
            ? !$can(...data.disabled)
            : data.disabled,
        },
      ]"
      class="dashboard-bottom-arrow bg-white rounded-[4px] flex items-center justify-center h-full px-2 py-1.5">
      <arrow-right :fill="data.colors.bottom" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const cards = computed(() => store.getters['dashboard/getAggregatedCardsData']);

const hubCardActive = computed(
  () => store.getters['dashboard/getHubCardActive']
);

const currentCard = cards.value.find(
  (card) => card.id === router.currentRoute.value.meta.id
);
</script>

<style lang="scss" scoped>
.hub-card-bottom-custom {
  @apply transform rotate-180 mr-auto;
}
.card-bottom-disabled {
  opacity: 0.5;
}
</style>
