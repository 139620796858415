<template>
  <v-img
    v-if="!profil.avatar"
    class="overflow-hidden h-full w-full rounded-[6px]"
    :src="avatarURL"></v-img>
</template>

<script>
import { axiosInstance } from '@/utils/axiosHelper';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['profil']),
    avatarURL() {
      if (!this.profil.avatar)
        return new URL('@/assets/img/default_avatar.png', import.meta.url).href;
      return `${axiosInstance.defaults.baseURL}${this.profil.avatar}`;
    },
  },
};
</script>
