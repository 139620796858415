<template>
  <v-app>
    <div id="app" class="relative" style="background-color: #f0f2f8">
      <v-progress-linear
        v-if="isLoaderVisible"
        :indeterminate="true"
        class="absolute top-0"
        style="z-index: 1000"
        color="#001257" />
      <!-- Layout component -->
      <transition name="fade" mode="out-in">
        <component :is="currentLayout">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </component>
      </transition>

      <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
        :color="toast.color"
        :value="true"
        bottom
        :absolute="true"
        right
        outlined>
        <div v-if="toast.title">
          <h4 class="text-white">{{ toast.title }}</h4>
        </div>
        <div class="body-1 text-white text-center w-full ml-2">
          {{ toast.message }}
        </div>
        <template #actions>
          <v-btn
            v-if="toast.timeout === -1"
            color="white"
            @click="toast.show = false">
            <v-icon icon="mdi-close" />
          </v-btn>
        </template>
      </v-snackbar>

      <!-- alert screen size-->
      <alert-screen-size />
    </div>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      isLoaderVisible: false,
      loaderTimeout: null,
    };
  },
  computed: {
    ...mapState('layout', ['toast']),
    ...mapGetters(['getLoadingState']),
    currentLayout() {
      return `${this.$route.meta.layout || 'default'}Layout`;
    },
  },
  watch: {
    getLoadingState: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.isLoaderVisible = true;
        } else {
          if (this.loaderTimeout) {
            clearTimeout(this.loaderTimeout);
          }

          // Ensure loader stays visible for at least 500ms
          this.loaderTimeout = setTimeout(() => {
            this.isLoaderVisible = false;
          }, 500);
        }
      },
    },
  },
};
</script>

<style lang="scss">
/**
 * Transition animation between pages
 */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
  overflow-y: hidden;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
