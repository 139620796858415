// Formats Numbers to german conventions
export function formatNumber(obj, decimal) {
  if (typeof obj === 'string' || obj instanceof String) {
    return obj;
  }
  return obj.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal,
  });
}
export function formatNumberMultiply(obj, decimal, multiply) {
  if (typeof obj === 'string' || obj instanceof String) {
    return obj;
  }
  return (obj * multiply)
    .toFixed(decimal)
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
