<template>
  <div
    v-if="show_breadcrumb"
    class="flex w-full justify-start items-center p-5 gap-4 breadcrumb-nav">
    <RouterLink to="/">
      <to-home :is-rounded-right="true" />
    </RouterLink>
    <div class="body-2 flex">
      Sie sind hier:
      <RouterLink to="/">
        <div class="mx-1.5 w-fit">Hub</div>
      </RouterLink>
      <div class="flex items-center gap-2">
        <RouterLink
          v-for="item in breadcrumb_items"
          :key="item.path"
          :to="item.path"
          class="flex items-center gap-1">
          <div>&rsaquo;</div>
          <div>
            {{ item.title.replace(/-/g, '') }}
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import ToHome from '@/features/map/controlOptions/components/toHome.vue';

export default {
  name: 'BreadcrumbNavigation',
  components: { ToHome },
  data() {
    return {};
  },
  computed: {
    show_breadcrumb() {
      return this.$route.meta.hide_breadcrumb ? 0 : 1;
    },

    breadcrumb_items() {
      const items = [];
      this.$route.matched.forEach((e) => {
        if (e.meta.title) {
          items.push({
            title: e.meta.title,
            path: e.path,
          });
        }
      });
      return items;
    },
  },
};
</script>
