<template>
  <div class="-mb-[1px]">
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1C7.32608 1 8.59785 1.52678 9.53553 2.46447C10.4732 3.40215 11 4.67392 11 6C11 7.32608 10.4732 8.59785 9.53553 9.53553C8.59785 10.4732 7.32608 11 6 11"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.02067 6.41466C1.00933 6.27799 1 6.13999 1 5.99999C1 5.85999 1.00933 5.72199 1.02067 5.58533"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.38265 1.74534C3.61808 1.59905 3.86539 1.47283 4.12198 1.368"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.54065 3.74734C1.66569 3.50021 1.81131 3.26404 1.97598 3.04134"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.96666 8.94668C1.80284 8.72262 1.6579 8.48537 1.53333 8.23734"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.02067 5.58533C1.00933 5.72199 1 5.85999 1 5.99999C1 6.13999 1.00933 6.27799 1.02067 6.41466"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M4.12198 1.368C3.86539 1.47283 3.61808 1.59905 3.38265 1.74534"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.97598 3.04134C1.81131 3.26404 1.66569 3.50021 1.54065 3.74734"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.37398 10.2494C3.61004 10.3965 3.85802 10.5236 4.11531 10.6294"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.53333 8.23734C1.6579 8.48537 1.80284 8.72262 1.96666 8.94668"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.66667 6.33334L5 7.66667L8.33333 4.33334"
        stroke="#4CAF50"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </div>
</template>
