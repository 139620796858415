<template>
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6667 6.41667H38.75C41.2812 6.41667 43.3333 8.46882 43.3333 11V27.5C43.3333 30.0312 41.2812 32.0833 38.75 32.0833H18.9152L6.66667 42.2905V17.4167H8.5V38.3762L18.2515 30.25H38.75C40.2687 30.25 41.5 29.0187 41.5 27.5V11C41.5 9.48134 40.2687 8.25 38.75 8.25H17.6667V6.41667Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 0.916666V13.75H6.66667V0.916666H8.5Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.16667 6.41667H14V8.25H1.16667V6.41667Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
