<template>
  <div class="skeleton-loader-table">
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
    <div class="skeleton-row"></div>
  </div>
</template>

<style scoped lang="scss">
//  skeleton-loader
.skeleton-loader-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skeleton-row {
  width: 100%;
  height: 48px;
  background-color: white;
  animation: skeleton-pulse 1s ease-in-out infinite;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
