import {
  SWITCH_ID__COLAYING,
  SWITCH_ID__DEVELOPMENT_AREA,
  SWITCH_ID__NOTES,
} from '@/configs/sidebar/tickets';
import { SWITCH_ID__TENDER_EXPANSION } from '@/configs/sidebar/tenders';

export default [
  {
    title: 'Gehe zu Notizen',
    itemClass: 'modal',
    id: 'notes',
    modelNameForCASL: 'note',
    config: {
      filterPlaceholder: 'Ersteller eingeben...',
      fromState: { namespace: 'ticket', state: 'notes' },
      activateOnSelect: [SWITCH_ID__NOTES],
      headers: [
        {
          title: 'Inhalt',
          key: 'content',
        },
        {
          title: 'Erstellt von',
          key: 'username',
          width: '30%',
        },
        {
          title: 'Erstellt am',
          key: 'created_at',
          width: '20%',
        },
        {
          title: '',
          key: 'functions',
          sortable: false,
          align: 'end',
          width: '50px',
        },
      ],
    },
  },
  {
    title: 'Gehe zu Los',
    itemClass: 'modal',
    id: 'los',
    modelNameForCASL: 'tenderlot',
    config: {
      filterPlaceholder: 'Cluster, Gemeinde oder Bauabschnitt eingeben...',
      fromURL: '/api/tenders/ExpansionSection',
      activateOnSelect: [SWITCH_ID__TENDER_EXPANSION],
      headers: [
        {
          title: 'Los',
          key: 'lot',
        },
        {
          title: 'Gemeinde',
          key: 'gemeinde',
        },
        {
          title: 'Bauabschnitt',
          key: 'section_name',
        },
        {
          title: 'Start Datum',
          key: 'date_start',
        },
        {
          title: '',
          key: 'functions',
          sortable: false,
          align: 'end',
          width: '50px',
        },
      ],
    },
  },
  {
    title: 'Gehe zu Neubaugebiet',
    itemClass: 'modal',
    id: 'developmentAreas',
    modelNameForCASL: 'developmentarea',
    config: {
      filterPlaceholder: 'Gemeinde oder Namen eingeben...',
      fromState: { namespace: 'ticket', state: 'developmentAreas' },
      activateOnSelect: [SWITCH_ID__DEVELOPMENT_AREA],
      headers: [
        {
          title: 'ID',
          key: 'id',
          width: '10%',
        },
        {
          title: 'Name der Datenerfassung',
          key: 'name',
          width: '30%',
        },
        {
          title: 'Gemeinde',
          key: 'gemeinde_name',
        },
        {
          title: 'Erstellt von',
          key: 'username',
          width: '30%',
        },
        {
          title: 'Erstellt am',
          key: 'created_at',
        },
        {
          title: '',
          key: 'functions',
          sortable: false,
          align: 'end',
          width: '50px',
        },
      ],
    },
  },
  {
    title: 'Gehe zu Mitverlegung',
    itemClass: 'modal',
    id: 'coLaying',
    modelNameForCASL: 'colaying',
    config: {
      filterPlaceholder: 'Gemeinde, Namen oder Ausbauinstanz eingeben...',
      fromState: { namespace: 'ticket', state: 'coLayings' },
      activateOnSelect: [SWITCH_ID__COLAYING],
      headers: [
        {
          title: 'ID',
          key: 'id',
          width: '10%',
        },
        {
          title: 'Name der Datenerfassung',
          key: 'name',
          width: '30%',
        },
        {
          title: 'Gemeinde',
          key: 'gemeinde_name',
        },
        {
          title: 'Erstellt von',
          key: 'username',
          width: '30%',
        },
        {
          title: 'Erstellt am',
          key: 'created_at',
        },
        {
          title: '',
          key: 'functions',
          sortable: false,
          align: 'end',
          width: '50px',
        },
      ],
    },
  },
];
