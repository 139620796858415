<template>
  <div class="flex flex-col gap-3">
    <h6 class="text-infra-highlight-800">
      {{ subTitleStep1 }}
    </h6>
    <div class="flex justify-between items-start">
      <div class="flex flex-col gap-3">
        <div class="flex flex-col w-full gap-3">
          <div
            v-for="textItem in bodyTextStep1"
            :key="textItem"
            class="body-2 grid"
            :class="!textItem.icon ? 'grid-cols-1' : 'grid-cols-[20%,80%]'">
            <div v-if="textItem.icon !== null" v-html="textItem.icon"></div>
            <div v-if="textItem.text !== null" v-html="textItem.text"></div>
          </div>
        </div>
        <h6 v-if="getActiveProcess.id !== 'validation'">
          Weiter klicken, um die Verortung abzuschließen
        </h6>
      </div>
    </div>
    <button-el
      text="Weiter"
      icon="arrow_forward"
      :icon-right="true"
      :disabled="
        !(getDrawFeaturesCount > 0 || selectedFeatures.length > 0) &&
        getActiveProcess.id !== 'validation'
      "
      @click="setNextStep(2)" />
  </div>
  <!--  </div>-->
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { SM_ADDRESS, SM_LINE, SM_POLY } from '../../../draw/store';

export default {
  computed: {
    ...mapGetters('ticket', ['getActiveProcess', 'getDrawFeaturesCount']),
    ...mapState('draw', ['selectedFeatures']),
    ...mapGetters('draw', ['getSelectionMode']),
    subTitleStep1() {
      if (this.getActiveProcess.id === 'validation') {
        return 'Bitte verorten Sie zunächst ein Gebiet geografisch.';
      } else if (this.getActiveProcess.id === 'mev') {
        return 'Geografische Verortung des Gebiets, für das eine falsche Versorgung von den TKUs zurückgemeldet wurde.';
      }
      switch (this.getSelectionMode) {
        case SM_POLY:
          return 'Bitte verorten Sie ein Gebiet geografisch.';
        case SM_LINE:
          return 'Bitte verorten Sie eine Linie geografisch.';
        case SM_ADDRESS:
          return 'Bitte wählen Sie eine oder mehrere Adressen';
      }
      return 'Wählen Sie eine Verortungsmethode aus der oberen Leiste aus';
    },
    bodyTextStep1() {
      if (this.getActiveProcess.id === 'validation') {
        return [
          {
            icon:
              '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 2.74984C11.6601 2.74984 4.58333 7.81205 4.58333 16.4998C4.58333 18.8177 5.43243 21.4773 6.78074 24.2161C8.12246 26.9414 9.92112 29.6709 11.7354 32.1088C13.5475 34.5439 15.3618 36.6702 16.724 38.1885C17.4046 38.9471 17.9712 39.5526 18.3666 39.9675C18.5644 40.1749 18.7192 40.3346 18.8241 40.4419C18.8765 40.4955 18.9165 40.536 18.943 40.5629L18.9726 40.5928L18.9797 40.5999L19.6297 41.2498L18.3333 42.5462L17.6852 41.898L18.3333 41.2498C17.6852 41.898 17.6852 41.898 17.6852 41.898L17.6816 41.8945L17.6728 41.8856L17.6395 41.8521C17.6105 41.8228 17.5681 41.7797 17.5131 41.7235C17.4032 41.611 17.2429 41.4457 17.0396 41.2324C16.633 40.8058 16.0537 40.1867 15.3594 39.4128C13.9715 37.8659 12.1192 35.6954 10.2646 33.2033C8.41221 30.7142 6.5442 27.8864 5.13593 25.0258C3.73423 22.1786 2.75 19.2236 2.75 16.4998C2.75 6.62513 10.8312 0.916504 18.3333 0.916504C25.8354 0.916504 33.9167 6.62513 33.9167 16.4998C33.9167 17.4623 33.7942 18.4502 33.5754 19.4465L33.3788 20.3418L31.5881 19.9485L31.7848 19.0532C31.9803 18.163 32.0833 17.3084 32.0833 16.4998C32.0833 7.81205 25.0066 2.74984 18.3333 2.74984Z" fill="#212121"/>\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 12.8332C16.3083 12.8332 14.6667 14.4748 14.6667 16.4998C14.6667 18.5249 16.3083 20.1665 18.3333 20.1665C20.3584 20.1665 22 18.5249 22 16.4998C22 14.4748 20.3584 12.8332 18.3333 12.8332ZM12.8333 16.4998C12.8333 13.4623 15.2958 10.9998 18.3333 10.9998C21.3709 10.9998 23.8333 13.4623 23.8333 16.4998C23.8333 19.5374 21.3709 21.9998 18.3333 21.9998C15.2958 21.9998 12.8333 19.5374 12.8333 16.4998Z" fill="#212121"/>\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M36.1297 31.1665L31.1667 36.1295L28.037 32.9998L29.3333 31.7035L31.1667 33.5368L34.8333 29.8701L36.1297 31.1665Z" fill="#212121"/>\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M32.0833 25.6665C28.0332 25.6665 24.75 28.9497 24.75 32.9998C24.75 37.0499 28.0332 40.3332 32.0833 40.3332C36.1334 40.3332 39.4167 37.0499 39.4167 32.9998C39.4167 28.9497 36.1334 25.6665 32.0833 25.6665ZM22.9167 32.9998C22.9167 27.9372 27.0207 23.8332 32.0833 23.8332C37.1459 23.8332 41.25 27.9372 41.25 32.9998C41.25 38.0624 37.1459 42.1665 32.0833 42.1665C27.0207 42.1665 22.9167 38.0624 22.9167 32.9998Z" fill="#212121"/>\n' +
              '</svg>\n',
            text: 'Die Prüfung der Adresskulisse erfolgt <b>Schritt für Schritt</b> und Gemarkung für Gemarkung.',
          },
          {
            icon: null,
            text: '<b>Ziel</b> ist es, alle im Rahmen des Markterkundungsverfahrens als förderfähig identifizierten Adressen zu überprüfen und festzustellen, ob die <b>Adressliste vollständig</b> ist oder ergänzt bzw. bereinigt werden muss.',
          },
          {
            icon: null,
            text: '<b>Das betrifft</b> sowohl die bereits heute förderfähigen <b>hellgrauen Flecken</b> als auch die voraussichtlich ab 2023 förderfähigen <b>dunkelgrauen Flecken.</b>',
          },
        ];
      } else if (this.getActiveProcess.id === 'mev') {
        return [
          {
            icon:
              '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M6.96667 32.2669L12.1 13.9336" stroke="#00030E" stroke-miterlimit="10"/>\n' +
              '<path d="M35.2 35.9336H9.53333" stroke="#00030E" stroke-miterlimit="10"/>\n' +
              '<path d="M32.8167 13.9336L39.4166 32.0836" stroke="#00030E" stroke-miterlimit="10"/>\n' +
              '<path d="M16.8666 10.2656H27.8666" stroke="#00030E" stroke-miterlimit="10"/>\n' +
              '<path d="M15.8667 6.59961H10.5333C9.98104 6.59961 9.53333 7.04733 9.53333 7.59961V12.9329C9.53333 13.4852 9.98104 13.9329 10.5333 13.9329H15.8667C16.4189 13.9329 16.8667 13.4852 16.8667 12.9329V7.59961C16.8667 7.04732 16.4189 6.59961 15.8667 6.59961Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
              '<path d="M34.1999 6.59961H28.8666C28.3143 6.59961 27.8666 7.04733 27.8666 7.59961V12.9329C27.8666 13.4852 28.3143 13.9329 28.8666 13.9329H34.1999C34.7522 13.9329 35.1999 13.4852 35.1999 12.9329V7.59961C35.1999 7.04732 34.7522 6.59961 34.1999 6.59961Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
              '<path d="M41.5333 32.2656H36.2C35.6477 32.2656 35.2 32.7133 35.2 33.2656V38.599C35.2 39.1512 35.6477 39.599 36.2 39.599H41.5333C42.0856 39.599 42.5333 39.1512 42.5333 38.599V33.2656C42.5333 32.7133 42.0856 32.2656 41.5333 32.2656Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
              '<path d="M8.53328 32.2656H3.19995C2.64767 32.2656 2.19995 32.7133 2.19995 33.2656V38.599C2.19995 39.1512 2.64767 39.599 3.19995 39.599H8.53328C9.08557 39.599 9.53328 39.1512 9.53328 38.599V33.2656C9.53328 32.7133 9.08557 32.2656 8.53328 32.2656Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
              '</svg>\n',
            text: 'Eingezeichnet wird das Areal durch Klicken und Gedrückthalten der Maus am Rand des gewünschten Areals.',
          },
          {
            icon: null,
            text: 'Der Mauszeiger kann dann bewegt werden, um das Areal zu definieren.',
          },
          {
            icon: null,
            text: 'Das Einzeichnen wird mit dem Loslassen der Maustaste beendet oder mittels "Esc"-Taste abgebrochen.',
          },
          {
            icon: null,
            text: 'Mittels Pfeiltasten kann die Karte auch während des Zeichenprozesses bewegt werden',
          },
        ];
      } else {
        switch (this.getSelectionMode) {
          case SM_POLY:
            return [
              {
                icon:
                  '<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                  '<path d="M6.96667 32.2669L12.1 13.9336" stroke="#00030E" stroke-miterlimit="10"/>\n' +
                  '<path d="M35.2 35.9336H9.53333" stroke="#00030E" stroke-miterlimit="10"/>\n' +
                  '<path d="M32.8167 13.9336L39.4166 32.0836" stroke="#00030E" stroke-miterlimit="10"/>\n' +
                  '<path d="M16.8666 10.2656H27.8666" stroke="#00030E" stroke-miterlimit="10"/>\n' +
                  '<path d="M15.8667 6.59961H10.5333C9.98104 6.59961 9.53333 7.04733 9.53333 7.59961V12.9329C9.53333 13.4852 9.98104 13.9329 10.5333 13.9329H15.8667C16.4189 13.9329 16.8667 13.4852 16.8667 12.9329V7.59961C16.8667 7.04732 16.4189 6.59961 15.8667 6.59961Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '<path d="M34.1999 6.59961H28.8666C28.3143 6.59961 27.8666 7.04733 27.8666 7.59961V12.9329C27.8666 13.4852 28.3143 13.9329 28.8666 13.9329H34.1999C34.7522 13.9329 35.1999 13.4852 35.1999 12.9329V7.59961C35.1999 7.04732 34.7522 6.59961 34.1999 6.59961Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '<path d="M41.5333 32.2656H36.2C35.6477 32.2656 35.2 32.7133 35.2 33.2656V38.599C35.2 39.1512 35.6477 39.599 36.2 39.599H41.5333C42.0856 39.599 42.5333 39.1512 42.5333 38.599V33.2656C42.5333 32.7133 42.0856 32.2656 41.5333 32.2656Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '<path d="M8.53328 32.2656H3.19995C2.64767 32.2656 2.19995 32.7133 2.19995 33.2656V38.599C2.19995 39.1512 2.64767 39.599 3.19995 39.599H8.53328C9.08557 39.599 9.53328 39.1512 9.53328 38.599V33.2656C9.53328 32.7133 9.08557 32.2656 8.53328 32.2656Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '</svg>\n',
                text: 'Eingezeichnet wird das Areal durch Klicken und Gedrückthalten der Maus am Rand des gewünschten Areals.',
              },
              {
                icon: null,
                text: 'Der Mauszeiger kann dann bewegt werden, um das Areal zu definieren.',
              },
              {
                icon: null,
                text: 'Das Einzeichnen wird mit dem Loslassen der Maustaste beendet oder mittels "Esc"-Taste abgebrochen.',
              },
              {
                icon: null,
                text: 'Mittels Pfeiltasten kann die Karte auch während des Zeichenprozesses bewegt werden',
              },
            ];
          case SM_LINE:
            return [
              {
                icon:
                  '<svg width="44" height="44" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                  '<path d="M5.6665 14.5002L11.1665 5.3335" stroke="#00030E" stroke-miterlimit="10"/>\n' +
                  '<path d="M13.167 16.3335H6.16699" stroke="#00030E" stroke-miterlimit="10"/>\n' +
                  '<path d="M12.8333 2H11.5C10.9477 2 10.5 2.44771 10.5 3V4.33333C10.5 4.88562 10.9477 5.33333 11.5 5.33333H12.8333C13.3856 5.33333 13.8333 4.88562 13.8333 4.33333V3C13.8333 2.44772 13.3856 2 12.8333 2Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '<path d="M5.33333 14.5H4C3.44772 14.5 3 14.9477 3 15.5V16.8333C3 17.3856 3.44771 17.8333 4 17.8333H5.33333C5.88562 17.8333 6.33333 17.3856 6.33333 16.8333V15.5C6.33333 14.9477 5.88562 14.5 5.33333 14.5Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '<path d="M16.3333 14.5H15C14.4477 14.5 14 14.9477 14 15.5V16.8333C14 17.3856 14.4477 17.8333 15 17.8333H16.3333C16.8856 17.8333 17.3333 17.3856 17.3333 16.8333V15.5C17.3333 14.9477 16.8856 14.5 16.3333 14.5Z" stroke="#00030E" stroke-miterlimit="10" stroke-linecap="square"/>\n' +
                  '</svg>',
                text: 'Eingezeichnet wird die Straße zwischen den Punkten, die mittels Linksklick gesetzt werden können.',
              },
              {
                icon: null,
                text: "Das Einzeichnen wird mittels Linksklick auf den zuletzt gesetzten Punkt beendet oder mittels 'Esc'-Taste abgebrochen.",
              },
              {
                icon: null,
                text: 'Mittels Pfeiltasten kann die Karte auch während des Zeichenprozesses bewegt werden',
              },
            ];
          case SM_ADDRESS:
            return [
              {
                icon:
                  '<svg width="44" height="44" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                  '<path d="M6.66683 3H5.3335C4.78121 3 4.3335 3.44771 4.3335 4V5.33333C4.3335 5.88562 4.78121 6.33333 5.3335 6.33333H6.66683C7.21911 6.33333 7.66683 5.88562 7.66683 5.33333V4C7.66683 3.44772 7.21911 3 6.66683 3Z" stroke="#00030E" stroke-miterlimit="10" />\n' +
                  '<path d="M14.9998 3H13.6665C13.1142 3 12.6665 3.44771 12.6665 4V5.33333C12.6665 5.88562 13.1142 6.33333 13.6665 6.33333H14.9998C15.5521 6.33333 15.9998 5.88562 15.9998 5.33333V4C15.9998 3.44772 15.5521 3 14.9998 3Z" stroke="#00030E" stroke-miterlimit="10" />\n' +
                  '<path d="M18.3333 14.6667H17C16.4477 14.6667 16 15.1145 16 15.6667V17.0001C16 17.5524 16.4477 18.0001 17 18.0001H18.3333C18.8856 18.0001 19.3333 17.5524 19.3333 17.0001V15.6667C19.3333 15.1145 18.8856 14.6667 18.3333 14.6667Z" stroke="#00030E" stroke-miterlimit="10" />\n' +
                  '<path d="M3.33333 14.6667H2C1.44772 14.6667 1 15.1145 1 15.6667V17.0001C1 17.5524 1.44771 18.0001 2 18.0001H3.33333C3.88562 18.0001 4.33333 17.5524 4.33333 17.0001V15.6667C4.33333 15.1145 3.88562 14.6667 3.33333 14.6667Z" stroke="#00030E" stroke-miterlimit="10" />\n' +
                  '</svg>',
                text: 'Initial kann einmalig durch Verzeichnen eines Polygons mehrere Adresspunkte ausgewählt werden.',
              },
              {
                icon: null,
                text: 'Danach kann mittels Rechtsklick einzelne Adresspunkte an- und abgewählt werden.',
              },
              {
                icon: null,
                text: 'Mittels Pfeiltasten kann die Karte auch während des Zeichenprozesses bewegt werden',
              },
            ];
        }
      }
      return [];
    },
  },
  methods: {
    setNextStep(step) {
      this.$store.commit('ticket/SET_TICKET_STEP', step);
    },
  },
};
</script>
