<template>
  <div ref="dropdownContainer" class="relative w-full flex">
    <div
      class="flex justify-between text-infra-blue h-[45px] border-[#C5CBE0] items-center bg-white rounded-[6px] py-2 px-4 w-full cursor-pointer border"
      :class="{
        'border-red-500': inputIsEmpty,
        'rounded-b-none outline-b outline-infra-background border-infra-blue':
          showDropdown,
      }"
      @click="toggleDropdown">
      <div
        class="text-[16px] text-black overflow-hidden"
        :class="showDropdown ? 'text-opacity-100' : 'text-opacity-60'">
        {{
          noPlaceholder
            ? currentSelection
            : currentSelection === null
            ? placeholder
            : currentSelection
        }}
      </div>
      <div class="flex gap-2">
        <v-icon
          v-if="noPlaceholder ? false : currentSelection !== null"
          class="w-4 h-4"
          @click.stop="clearSelection"
          >mdi-close-circle</v-icon
        >
        <img src="@/assets/svg/enlarge.svg" class="w-4 h-4" />
      </div>
    </div>
    <div
      v-if="showDropdown"
      class="absolute z-10 left-0 top-[45px] w-full bg-white py-1 rounded-b-[6px] dropdown-animation border border-infra-blue border-t-0"
      :class="showGap ? 'pr-1' : ''">
      <div
        ref="dropDownList"
        class="items-list-custom overflow-y-auto"
        :style="{
          'max-height': `${maxHeightDropdown}px`,
        }"
        @wheel.stop>
        <div
          v-for="(item, index) in itemsData"
          :key="index"
          class="block px-4 py-2 text-sm text-infra-blue hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          :class="{
            'bg-gray-100': itemsDataKey
              ? item[itemsDataKey] === currentSelection
              : item === currentSelection,
            'mr-1': showGap,
            'pointer-events-none text-gray-400': item.disabled,
          }"
          role="menuitem"
          @click="selectItem(item)">
          {{ itemsDataKey ? item[itemsDataKey] : item }}
        </div>
      </div>
    </div>
    <span
      v-if="errorText !== null"
      class="absolute bottom-[-18px] text-xs text-red-500">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  props: {
    itemsData: {
      required: true,
    },
    itemsDataKey: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Alle',
    },
    resetSelection: {
      type: Boolean,
      default: false,
    },
    initialSelection: {
      default: null,
    },
    noPlaceholder: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['selectedItem'],
  data() {
    return {
      showDropdown: false,
      currentSelection: null,
      maxHeightDropdown: 150,
      showGap: true,
      inputIsEmpty: false,
      errorText: null,
    };
  },
  watch: {
    resetSelection() {
      if (this.resetSelection) {
        this.currentSelection = null;
      }
    },
  },
  mounted() {
    this.currentSelection = this.itemsDataKey
      ? this.initialSelection[this.itemsDataKey]
      : this.initialSelection;
    this.$emit('selectedItem', this.initialSelection);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
      this.dropdownListHeight();
    },
    closeDropdown(event) {
      if (!this.$refs.dropdownContainer.contains(event.target)) {
        this.showDropdown = false;
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    selectItem(item) {
      this.currentSelection = this.itemsDataKey
        ? item[this.itemsDataKey]
        : item;
      this.$emit('selectedItem', item);
      this.showDropdown = false;
      this.inputOps();
    },
    clearSelection() {
      this.currentSelection = null;
      this.$emit('selectedItem', null);
      this.showDropdown = false;
      this.inputOps();
    },

    dropdownListHeight() {
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.showGap = !(
            this.$refs.dropDownList.offsetHeight < this.maxHeightDropdown
          );
        });
      }
    },

    inputOps() {
      if (!this.currentSelection && this.isRequired) {
        this.inputIsEmpty = true;
        this.errorText = 'Pflichtfeld';
      } else {
        this.inputIsEmpty = false;
        this.errorText = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.items-list-custom {
  &::-webkit-scrollbar {
    background: white;
    width: 9px;
    cursor: auto;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9deec;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c4c9d8;
    cursor: pointer;
  }
}

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@keyframes dropdown-animation {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
