<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_73_1722)">
      <path
        d="M3 14.1667L5.33333 5.83337"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10" />
      <path
        d="M15.8337 15.8334H4.16699"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10" />
      <path
        d="M14.75 5.83337L17.75 14.0834"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10" />
      <path
        d="M7.5 4.16663H12.5"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10" />
      <path
        d="M7.50033 2.5H4.16699V5.83333H7.50033V2.5Z"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M15.8333 2.5H12.5V5.83333H15.8333V2.5Z"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M19.1668 14.1666H15.8335V17.5H19.1668V14.1666Z"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M4.16683 14.1666H0.833496V17.5H4.16683V14.1666Z"
        :stroke="fill"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
