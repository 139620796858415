<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1196_40544)">
      <path d="M0.5 12.5H3.5V15.5H0.5V12.5Z" :fill="fill" :stroke="fill" />
      <path d="M6.5 7.5H9.5V15.5H6.5V7.5Z" :fill="fill" :stroke="fill" />
      <path d="M12.5 11.5H15.5V15.5H12.5V11.5Z" :fill="fill" :stroke="fill" />
      <path
        d="M7.063 3.757C7.34959 3.91637 7.67208 4 8 4C8.32792 4 8.65042 3.91637 8.937 3.757L12.1 6.4C12.0359 6.59364 12.0022 6.79604 12 7C11.9945 7.44432 12.1353 7.87812 12.4007 8.2345C12.6662 8.59089 13.0414 8.85008 13.4687 8.97212C13.896 9.09416 14.3515 9.07227 14.7651 8.90983C15.1787 8.74738 15.5274 8.45341 15.7574 8.07321C15.9874 7.69302 16.086 7.24773 16.038 6.80598C15.9899 6.36422 15.7979 5.95054 15.4915 5.62871C15.1851 5.30688 14.7813 5.09476 14.3425 5.02506C13.9036 4.95535 13.454 5.03194 13.063 5.243L9.9 2.6C9.96409 2.40636 9.99782 2.20396 10 2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2C6.00218 2.20396 6.03592 2.40636 6.1 2.6L2.937 5.243C2.6496 5.08568 2.32763 5.00219 2 5C1.60444 5 1.21776 5.1173 0.88886 5.33706C0.559962 5.55682 0.303617 5.86918 0.152242 6.23463C0.000866562 6.60009 -0.0387401 7.00222 0.0384303 7.39018C0.115601 7.77814 0.306082 8.13451 0.585787 8.41421C0.865492 8.69392 1.22186 8.8844 1.60982 8.96157C1.99778 9.03874 2.39992 8.99913 2.76537 8.84776C3.13082 8.69638 3.44318 8.44004 3.66294 8.11114C3.8827 7.78224 4 7.39556 4 7C3.99782 6.79604 3.96409 6.59364 3.9 6.4L7.063 3.757Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_1196_40544">
        <rect width="16" height="16" :fill="fill" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
