<template>
  <div class="h-full flex flex-col bg-infra-background">
    <breadcrumb-navigation class="single-page-wrapper breadcrumb-container" />
    <perfect-scrollbar
      style="max-height: calc(100vh - 85px)"
      class="single-page-scroll-wrapper">
      <div
        class="flex flex-col gap-10 single-page-wrapper content-container standard-elevation-1 p-5 mt-[2px]"
        style="min-height: calc(100vh - 143px)">
        <!-- logo + heading-->
        <div class="flex gap-3 items-center justify-start">
          <img src="@/assets/svg/dashboard.svg" alt="Logo" />
          <h2>Dashboard</h2>
        </div>

        <!-- content-->
        <div
          v-if="loadingBaseRoute && loadingSubRoute"
          class="flex justify-center items-center my-auto">
          <v-progress-circular
            class="mb-[85px]"
            :size="100"
            color="#001257"
            indeterminate></v-progress-circular>
        </div>
        <div v-else class="flex flex-col gap-10">
          <div v-if="!loadingBaseRoute">
            <DashboardContent />
          </div>
          <div
            v-if="loadingSubRoute"
            class="flex justify-center items-center my-auto">
            <v-progress-circular
              class="mb-[85px]"
              :size="100"
              color="#001257"
              indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
      </div>
      <footer-section />
    </perfect-scrollbar>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();

// loading state
const loadingBaseRoute = ref(true);
const loadingSubRoute = ref(true);

const routeIsMevOrGrants = computed(
  () =>
    router.currentRoute.value.path === '/dashboard/markterkundungsverfahren' ||
    router.currentRoute.value.path === '/dashboard/foerderantrag'
);

const richtlinie = computed(() => {
  const richtlinieSelected = store.getters['ticket/getRichtlinieSelected'];
  if (routeIsMevOrGrants.value) {
    return richtlinieSelected[0];
  } else {
    return richtlinieSelected;
  }
});

const preLoad = (init) => {
  if (router.currentRoute.value.meta.actionBeforeLoad) {
    loadingBaseRoute.value = init;
    loadingSubRoute.value = true;
    Promise.all([
      ...(init ? [store.dispatch('dashboard/INITIALIZE_CARDS', router)] : []),
      store.dispatch(router.currentRoute.value.meta.actionBeforeLoad),
    ]).then(() => {
      loadingBaseRoute.value = false;
      loadingSubRoute.value = false;
    });
  } else {
    if (init) {
      loadingBaseRoute.value = true;
      store.dispatch('dashboard/INITIALIZE_CARDS', router).then(() => {
        loadingBaseRoute.value = false;
        loadingSubRoute.value = false;
      });
    }
  }
};

preLoad(true);

watch(
  () => route.name,
  () => {
    preLoad(false);
  }
);
</script>

<style lang="scss">
.single-page-wrapper {
  &.content-container {
    @apply bg-white rounded-[8px];
    @media (max-width: 1440px) {
      @apply mx-5;
    }
  }

  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto w-full;
    &.breadcrumb-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
</style>
