export default [
  {
    key: 'cooperation_agreement',
    title: 'Kooperationsvereinbarung',
    dates: [
      {
        color: '#E64A19',
        values: [
          {
            keyTree: ['dispatch_documents_till_date'],
            name: 'Kooperationsunterlagen versendet',
          },
          {
            keyTree: ['gr_meeting_date'],
            name: 'Gemeinderatsbeschluss',
          },
        ],
      },
    ],
  },
  {
    key: 'mev',
    title: 'Markterkundungsverfahren (MEV)',
    dates: [
      {
        color: '#FF8F00',
        values: [
          {
            keyTree: ['2022', 'start_date'],
            name: 'Start MEV 1.0',
          },
          {
            keyTree: ['2022', 'end_date'],
            name: 'Ende MEV 1.0',
          },
          {
            keyTree: ['2022', 'publish_date_mev'],
            name: 'Veröffentlichung MEV 1.0',
          },
        ],
      },
      {
        color: '#FF8F00',
        values: [
          {
            keyTree: ['2023', 'start_date'],
            name: 'Start MEV 2.0',
          },
          {
            keyTree: ['2023', 'end_date'],
            name: 'Ende MEV 2.0',
          },
          {
            keyTree: ['2023', 'publish_date_mev'],
            name: 'Veröffentlichung MEV 2.0',
          },
        ],
      },
      {
        color: '#FF8F00',
        values: [
          {
            keyTree: ['2024', 'start_date'],
            name: 'Start MEV 2.1',
          },
          {
            keyTree: ['2024', 'end_date'],
            name: 'Ende MEV 2.1',
          },
          {
            keyTree: ['2024', 'publish_date_mev'],
            name: 'Veröffentlichung MEV 2.1',
          },
        ],
      },
    ],
  },
  {
    key: 'grant_applications',
    title: 'Förderantrag',
    dates: [
      {
        color: '#9E9D24',
        values: [
          {
            keyTree: ['2022', 'federal_application_made_date'],
            name: 'Antrag Bund gestellt 1.0',
          },
          {
            keyTree: ['2022', 'state_application_made_date'],
            name: 'Antrag Land gestellt 1.0',
          },
          {
            keyTree: ['2022', 'federal_notice_from_date'],
            name: 'Antrag Bund vorläufig bewilligt 1.0',
          },
          {
            keyTree: ['2022', 'state_notice_from_date'],
            name: 'Antrag Land vorläufig bewilligt 1.0',
          },
        ],
      },
      {
        color: '#9E9D24',
        values: [
          {
            keyTree: ['2023', 'federal_application_made_date'],
            name: 'Antrag Bund gestellt 2.0',
          },
          {
            keyTree: ['2023', 'state_application_made_date'],
            name: 'Antrag Land gestellt 2.0',
          },
          {
            keyTree: ['2023', 'federal_notice_from_date'],
            name: 'Antrag Bund vorläufig bewilligt 2.0',
          },
          {
            keyTree: ['2023', 'state_notice_from_date'],
            name: 'Antrag Land vorläufig bewilligt 2.0',
          },
        ],
      },
    ],
  },
  {
    key: 'tenders_mains_operation',
    title: 'Ausschreibung Netzbetrieb',
    dates: [
      {
        color: '#388E3C',
        values: [
          {
            keyTree: ['notice_nb_date'],
            name: 'Bekanntmachung NB',
          },
          {
            keyTree: ['submission_eval_participation_competition_date'],
            name: 'Teilnahmewettbewerb',
          },
          {
            keyTree: ['submission_eval_initial_offer_date'],
            name: 'Abgabe Erstangebot',
          },
          {
            keyTree: ['negotiation_procedure_date'],
            name: 'Verhandlungsverfahren',
          },
          {
            keyTree: ['submission_eval_final_offer_date'],
            name: 'Abgabe finales Angebot',
          },
          {
            keyTree: ['award_of_contract_nb_date'],
            name: 'Zuschlagserteilung',
          },
        ],
      },
    ],
  },
  {
    key: 'tenders_general_contractor',
    title: 'Ausschreibung GÜ',
    dates: [
      {
        color: '#00796B',
        values: [
          {
            keyTree: ['notice_date'],
            name: 'Bekanntmachung',
          },
          {
            keyTree: ['submission_first_offer_date'],
            name: 'Abgabe Erstangebot',
          },
          {
            keyTree: ['closure_negotiation_procedure_date'],
            name: 'Verhandlungsverfahren',
          },
          {
            keyTree: ['submission_final_offer_date'],
            name: 'Abgabe finales Angebot',
          },
          {
            keyTree: ['award_of_contract_date'],
            name: 'Zuschlagserteilung',
          },
        ],
      },
    ],
  },
  {
    key: 'planning_construction_general_contractor',
    title: 'Planung und Bau (GÜ)',
    dates: [
      {
        color: '#0097A7',
        values: [
          {
            keyTree: ['opening_conversation_date'],
            name: 'Auftaktgespräch GÜ',
          },
          {
            keyTree: ['initial_consultation_municipality_date'],
            name: 'Erstgespräch Kommune',
          },
          {
            keyTree: ['retrieval_date_date'],
            name: 'Abruf',
          },
          {
            keyTree: ['start_date_planning_date'],
            name: 'Beginn Planungsphase',
          },
          {
            keyTree: ['end_date_planning_date'],
            name: 'Ende Planungsphase',
          },
        ],
      },
      {
        color: '#bee5ec',
        values: [
          {
            keyTree: ['start_date_construction_date'],
            name: 'Start Bauphase',
          },
          {
            keyTree: ['end_date_construction_date'],
            name: 'Ende Bauphase',
          },
        ],
      },
    ],
  },
];
