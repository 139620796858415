<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    :width="width"
    :height="height">
    <g stroke-width="1" :fill="_secondaryfill" :stroke="_secondaryfill">
      <line
        x1="1.5"
        y1="8"
        x2="14.5"
        y2="8"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke="fill"></line>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '16px',
    },
    height: {
      type: [Number, String],
      default: '16px',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
