<template>
  <div
    v-for="(item, index) in drawControlOptions"
    :key="item"
    :class="{ hidden: !getActiveProcess.showControls }">
    <div
      class="p-1 w-[44px] h-[44px] flex justify-center items-center cursor-pointer bg-white"
      @mouseenter="
        itemIndex = index;
        showAdd = itemIndex === index;
      "
      @mouseleave="
        showAdd = false;
        itemIndex = null;
      ">
      <div
        class="flex justify-center items-center w-[90%] h-[90%] bg-infra-highlight-100 rounded-[4px]">
        <component :is="iconComponent(item)" v-if="showIcon(item, index)" />
        <div v-else>
          <v-icon
            v-if="drawControlDeleteMode && getSelectionMode === item"
            ref="deleteIcon"
            class="text-red-700"
            @click="deleteItem(item)"
            >mdi-delete
          </v-icon>
          <v-icon v-else class="text-infra-highlight-700" @click="addItem(item)"
            >mdi-plus
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { mapGetters } from 'vuex';
import { draw, addMainGeometry } from '@/features/draw/draw';
import { getMap } from '@/mapbox/main';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { LAYER_KEY__ADDRESS_POINTS } from '@/configs/layers/buildings';
import { SWITCH_ID__ADDRESS_POINTS } from '@/configs/sidebar/buildings';

let map;

export default {
  props: {
    drawControlOptions: {
      type: Array,
      default: () => [],
    },
    initWithDrawMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAdd: false,
      itemIndex: null,
      addressPointsActiveBeforeProcess: true,
    };
  },
  computed: {
    ...mapGetters('draw', ['getGeometrySelection', 'getSelectionMode']),
    ...mapGetters('ticket', [
      'drawControlDeleteMode',
      'getDrawSelectedIds',
      'getActiveProcess',
    ]),
    ...mapGetters('map', ['getSwitchStates']),
  },
  watch: {
    getDrawSelectedIds(val) {
      this.showAdd = this.itemIndex !== null || !!val.length;
    },
    drawControlOptions() {
      if (this.initWithDrawMode) {
        this.drawShape(this.drawControlOptions[0]);
        this.$store.commit(
          'draw/SET_SELECTION_MODE',
          this.drawControlOptions[0]
        );
      }
    },
  },
  mounted() {
    map = getMap();
    map.on('draw.create', this.create);
    map.on('draw.selectionchange', this.selectionChange);
    map.on('draw.delete', this.toStore);
    if (this.initWithDrawMode) {
      this.drawShape(this.drawControlOptions[0]);
      this.$store.commit('draw/SET_SELECTION_MODE', this.drawControlOptions[0]);
    }
    if (!this.getSwitchStates[SWITCH_ID__ADDRESS_POINTS]) {
      this.addressPointsActiveBeforeProcess = false;
    }
    if (this.drawControlOptions.includes('address')) {
      this.$store.commit('map/CHANGE_SWITCH', {
        switches: [SWITCH_ID__ADDRESS_POINTS],
        active: true,
      });
    }
    document.querySelector('.mapboxgl-canvas').focus();
  },
  unmounted() {
    this.$store.commit('draw/SET_SELECTION_MODE', null);
    if (!this.addressPointsActiveBeforeProcess) {
      this.$store.commit('map/CHANGE_SWITCH', {
        switches: [SWITCH_ID__ADDRESS_POINTS],
        active: false,
      });
    }
    map.off('draw.delete', this.toStore);
    map.off('draw.create', this.create);
    document.querySelector('.mapboxgl-canvas').focus();
  },
  methods: {
    drawShape(type) {
      let drawType = type;
      if (type === 'address') {
        this.$store.commit('draw/SET_GEOMETRY_SELECTION', {
          layer: LAYER_KEY__ADDRESS_POINTS,
          active: true,
        });
        drawType = 'polygon';
      }
      addMainGeometry(drawType);
      document.querySelector('.mapboxgl-canvas').focus();
    },
    showIcon(item, index) {
      return this.drawControlDeleteMode
        ? this.getSelectionMode === item
          ? false
          : this.itemIndex !== index || !this.showAdd
        : this.itemIndex !== index || !this.showAdd;
    },
    selectionChange() {
      // update selectionMode according to draw.getSelectedIds()
      this.$store.commit(
        'draw/SET_SELECTION_MODE',
        draw.getSelectedIds().length
          ? draw
              .getAll()
              .features.find(
                (feature) => feature.id === draw.getSelectedIds()[0]
              )
              .geometry.type.toLowerCase()
          : null
      );
      this.$store.commit('ticket/SET_DRAW_SELECTED_IDS', draw.getSelectedIds());
    },
    deleteSelectedGeometry() {
      draw.delete(draw.getSelectedIds());
      this.toStore();
      this.$store.commit('ticket/SET_DRAW_SELECTED_IDS', []);
      document.querySelector('.mapboxgl-canvas').focus();
    },

    create() {
      const features = draw.getAll().features;
      const lastFeature = features[features.length - 1];
      if (
        this.getGeometrySelection &&
        lastFeature.geometry.type.toLowerCase() === 'polygon'
      ) {
        this.$store.commit('draw/SELECT_FEATURES_IN_GEOMETRY', {
          geometry: lastFeature.geometry,
          layer: this.getGeometrySelection,
        });
        draw.delete([lastFeature.id]);
      } else {
        this.toStore();
      }
    },

    toStore() {
      this.$store.commit('ticket/SET_DRAW_FEATURES', draw.getAll().features);
    },

    deleteItem(item) {
      this.$store.commit('draw/SET_SELECTION_MODE', item);
      this.deleteSelectedGeometry();
    },

    addItem(item) {
      this.$store.commit('draw/SET_SELECTION_MODE', item);
      this.drawShape(item);
    },

    iconComponent(icon) {
      switch (icon) {
        case 'polygon':
          return 'shape-polygon';
        case 'linestring':
          return 'shape-triangle';
        case 'point':
          return 'shape-paragraph';
        case 'address':
          return 'shape-dots';
        default:
          return null;
      }
    },
  },
};
</script>
