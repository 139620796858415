<template>
  <div v-if="show" class="w-full flex flex-col gap-2 p-3 pt-0">
    <button-el
      :text="propertyData.label"
      :critical="propertyData.critical"
      @click="handleClick" />
  </div>
</template>

<script>
export default {
  props: {
    propertyData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    show() {
      if ('show' in this.propertyData) {
        if (Array.isArray(this.propertyData.show.ifValue)) {
          return this.propertyData.show.ifValue.includes(
            this.propertyData.show.value
          );
        } else {
          return this.propertyData.show.value === this.propertyData.show.value;
        }
      }
      return true;
    },
  },
  methods: {
    handleClick() {
      this.$store
        .dispatch(this.propertyData.actionOnClick, {
          id: this.propertyData.entityId.value,
          successMessage: this.propertyData.successMessage,
          failureMessage: this.propertyData.failureMessage,
        })
        .then(() =>
          this.$store.commit(
            this.propertyData.onSuccessCommit,
            this.propertyData.onSuccessCommitPayload
          )
        );
    },
  },
};
</script>
