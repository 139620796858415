<template>
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.8958 2.52083H37.1875C38.8893 2.52083 40.5214 3.19687 41.7248 4.40023C42.9281 5.60358 43.6042 7.23569 43.6042 8.9375V33.4558C43.6246 34.0382 43.5658 34.6224 43.4279 35.1921C43.2032 36.1205 42.7743 36.9871 42.1724 37.7288C41.5704 38.4704 40.8107 39.0685 39.9484 39.4794C39.0861 39.8904 38.1431 40.1038 37.1879 40.1042H1.89583V8.02083H32.375V9.85416H3.72916V38.2708H37.1871C37.187 38.2708 37.1872 38.2708 37.1871 38.2708C37.8695 38.2705 38.5437 38.118 39.1597 37.8244C39.7758 37.5308 40.3187 37.1034 40.7489 36.5735C41.179 36.0435 41.4855 35.4243 41.646 34.7609C41.7313 34.4083 41.7743 34.048 41.7748 33.6875H41.7708V33.4907C41.7605 33.2417 41.7298 32.9933 41.6789 32.7479C41.4339 31.6982 40.8367 30.764 39.9867 30.1009C39.1343 29.4359 38.0792 29.084 36.9982 29.104L36.9897 29.1042H34.8958V2.52083ZM41.7708 29.2426V8.9375C41.7708 7.72192 41.2879 6.55613 40.4284 5.69659C39.5689 4.83705 38.4031 4.35416 37.1875 4.35416H36.7292V27.2708H36.9731C38.4711 27.2451 39.9329 27.7337 41.1144 28.6554C41.3472 28.837 41.5664 29.0333 41.7708 29.2426ZM6.70833 12.8333H31.9167V35.2917H13.5833V29.3333H15.4167V33.4583H30.0833V14.6667H20.9167V24.2917H13.5833V22.4583H19.0833V14.6667H8.54166V35.2917H6.70833V12.8333Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
