<template>
  <div
    class="absolute top-[85px] rounded-[8px] standard-elevation-2"
    :class="{
      'left-[360px]':
        showLayerPanel || (showSearch && getResults) || getShowDataPanel,
      'left-5': !showLayerPanel && !showSearch && !getShowDataPanel,
      'left-[700px]':
        (showLayerPanel && getShowDataPanel) ||
        (getResults && getShowDataPanel),
    }"
    :style="{
      maxHeight: `${getContainerHeight + 61}px`,
    }">
    <div
      class="bg-white w-[320px] h-fit py-3 rounded-[8px] flex flex-col gap-[10px] relative">
      <div
        v-if="!(getTicketStep > 1 && getActiveProcess.id === 'validation')"
        class="flex justify-between items-center px-3">
        <!-- title-->
        <h4 class="text-infra-highlight-500">
          {{ getActiveProcess.title }}
        </h4>
        <!-- abort process modal-->
        <div class="ml-auto flex items-center justify-center">
          <abort-process-modal />
        </div>
      </div>
      <!-- body-->
      <ticket-body-wrapper />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SWITCH_ID__ADDRESS_POINTS } from '@/configs/sidebar/buildings';

export default {
  props: {
    showLayerPanel: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.getActiveProcess.activateSwitchOnStart) {
      this.$store.commit('map/CHANGE_SWITCH', {
        switches: [this.getActiveProcess.activateSwitchOnStart],
        active: true,
      });
    }
  },
  computed: {
    ...mapGetters('ticket', [
      'getActiveProcess',
      'getTicketStep',
      'getGemarkungActive',
    ]),
    ...mapGetters('search', ['showSearch', 'getResults']),
    ...mapGetters('map', ['getShowDataPanel', 'getContainerHeight']),
  },
  methods: {
    deleteDrawGeometry() {
      document
        .querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash')
        .click();
    },
    setNextStep(step) {
      this.$store.commit('ticket/SET_TICKET_STEP', step);
    },
  },
};
</script>
