<template>
  <div class="flex flex-col w-full justify-center items-center py-7">
    <h5
      :style="titleCustomSize ? { 'font-size': titleCustomSize + 'px' } : {}"
      :class="customMarginBottom">
      {{ title }}
    </h5>
    <VChart
      class="h-full w-full"
      :class="customPadding"
      :style="{ 'min-height': customMinHeight }"
      :option="option"
      autoresize
      @legendselectchanged="handleLegendChange" />
  </div>
</template>

<script>
import { sum } from 'lodash';

export default {
  props: {
    chartData: {
      type: Array,
      default: () => [],
      deep: true,
    },
    titleInside: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    chartUnit: {
      type: String,
      default: '',
    },
    chartTop: {
      type: String,
      default: '',
    },
    titleTop: {
      type: String,
      default: 'center',
    },
    titleInsideLH: {
      type: Number,
      default: 0,
    },
    titleCustomSize: {
      type: Number,
      default: null,
    },
    multiply: {
      type: Number,
      default: null,
    },
    customMarginBottom: {
      type: String,
      default: '-mb-5',
    },
    customPadding: {
      type: String,
      default: '',
    },
    customMinHeight: {
      type: String,
      default: '250px',
    },
    customRadius: {
      type: Array,
      default: () => ['35%', '45%'],
    },
    customTooltip: {
      type: Array,
    },
    showDefaultTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    filterChartData() {
      return this.chartData.filter(
        (item) => item.value !== 0 || item.value !== 0.0,
      );
    },
    sumValues() {
      const selectedChartData =
        this.selectedItems.length === 0
          ? this.filterChartData
          : this.filterChartData.filter((item) =>
              this.selectedItems.includes(item.name),
            );
      return sum(
        selectedChartData.map((item) =>
          Math.round(this.multiply ? item.value * this.multiply : item.value),
        ),
      ).toLocaleString(navigator.language);
    },
    option() {
      return {
        title: {
          text:
            `{a|${this.sumValues}}` +
            `{b| ${this.chartUnit}` +
            '\n' +
            `${this.titleInside}}`,
          left: 'center',
          z: -1,
          top: this.titleTop,
          textStyle: {
            color: 'black',
            lineHeight: 15,
            fontFamily: 'Borna, sans-serif',
            rich: {
              a: {
                fontWeight: 'bold',
                fontSize: 12,
                lineHeight: this.titleInsideLH,
              },
              b: {
                fontSize: 12,
                color: 'black',
                padding: [2, 0, 0, 0],
                fontFamily: 'Borna, sans-serif',
                fontWeight: 500,
              },
            },
          },
        },
        tooltip: {
          show: !this.showDefaultTooltip,
          formatter: (params) => {
            if (this.customTooltip && this.customTooltip.length > 0) {
              let tooltipVal = this.customTooltip.find(
                (e) => e.name === params.data.name,
              ).value;
              let isNumericValue = true;
              for (let e of Object.keys(tooltipVal)) {
                if (typeof tooltipVal[e] !== 'number') {
                  isNumericValue = false;
                  break;
                }
              }
              let tooltips = Object.keys(tooltipVal).map(
                (e) =>
                  `\n${e}: ${
                    isNumericValue
                      ? tooltipVal[e].toLocaleString()
                      : tooltipVal[e]
                  }`,
              );
              let htmlElements = tooltips.map((e) => {
                let elem = document.createElement('p');
                elem.innerHTML = e;
                return elem;
              });
              let outerHtmlElement = document.createElement('div');
              for (let item of htmlElements) {
                outerHtmlElement.appendChild(item);
              }
              return outerHtmlElement;
            } else {
              let isNumericValue = typeof params.value === 'number';
              let value = isNumericValue
                ? params.value.toLocaleString()
                : params.value;
              return `<p>${value}</p>`;
            }
          },
        },
        legend: {
          itemWidth: 12,
          itemHeight: 12,
          left: 'center',
          orient: 'horizontal',
          bottom: 0,
          selected:
            this.selectedItems.length === 0
              ? this.filterChartData.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.name]: true,
                  }),
                  {},
                )
              : this.filterChartData.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.name]: this.selectedItems.includes(cur.name),
                  }),
                  {},
                ),
        },
        grid: {
          containLabel: true,
        },
        series: [
          {
            type: 'pie',
            radius: this.customRadius,
            top: this.chartTop,
            data: this.filterChartData,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              disabled: !this.showDefaultTooltip,
              scale: false,
              label: {
                show: true,
                formatter: (params) =>
                  `${Math.round(params.value).toLocaleString(
                    navigator.language,
                  )}`,
                position: 'inside',
                fontSize: 10,
                fontWeight: 'bold',
                backgroundColor: 'white',
                borderColor: 'gray',
                padding: [2, 2, 2, 2],
                borderRadius: 2,
                borderWidth: 1,
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    handleLegendChange(params) {
      this.selectedItems = Object.keys(params.selected).filter(
        (key) => params.selected[key],
      );
    },
  },
};
</script>
