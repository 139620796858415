import axiosBase from 'axios';

const axiosConfig = {
  withCredentials: true,
};

if (!window.location.hostname.split('.').includes('localhost')) {
  const location = window.location.hostname.replace('www.', '');
  axiosConfig.baseURL = `https://api.${location}`;
} else {
  axiosConfig.baseURL = '';
}

const axiosInstance = axiosBase.create(axiosConfig);

function axios(config) {
  return axiosInstance.request(config);
}

export { axios };
export { axiosInstance };

export default axios;
