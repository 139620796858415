<template>
  <div
    class="switch-container"
    :class="{ 'is-disabled': disabled }"
    @click="disabled ? null : toggle()">
    <div
      class="switch"
      :class="[size, { 'is-checked': isActive }]"
      :style="{ backgroundColor: isActive ? onColor : offColor }">
      <div
        class="switch-handle"
        :style="{ backgroundColor: handleColor }"></div>
    </div>

    <div v-if="label" class="body-3">
      {{ label }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Boolean, String, Number, Array],
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
    validator: (value) => ['small', 'medium', 'large'].includes(value),
  },
  onColor: {
    type: String,
    default: '#0692bc',
  },
  offColor: {
    type: String,
    default: '#ccc',
  },
  handleColor: {
    type: String,
    default: '#FFFFFF',
  },
  value: {
    type: [Boolean, String, Number],
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const isActive = computed(() => {
  if (Array.isArray(props.modelValue)) {
    // For arrays, check if the value is included in the array
    return props.modelValue.includes(props.value);
  } else {
    // For non-array values, check if modelValue equals the value prop
    // This also covers boolean toggles where value prop might not be used
    return props.modelValue === props.value || props.modelValue === true;
  }
});

function toggle() {
  if (props.disabled) {
    return;
  }

  // If modelValue is an array, add or remove the value
  if (Array.isArray(props.modelValue)) {
    let newValue;
    if (props.modelValue.includes(props.value)) {
      // Remove the value from the array
      newValue = props.modelValue.filter((item) => item !== props.value);
    } else {
      // Add the value to the array
      newValue = [...props.modelValue, props.value];
    }
    emit('update:modelValue', newValue);
  } else {
    // If there's a specific value to emit and modelValue is not an array
    if (props.value !== null) {
      const newValue = props.modelValue === props.value ? false : props.value;
      emit('update:modelValue', newValue);
    } else {
      // If no value prop is provided, just toggle the boolean state
      emit('update:modelValue', !props.modelValue);
    }
  }
}
</script>

<style scoped>
.switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.switch-container.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.switch {
  min-width: 30px;
  height: 18px;
  border-radius: 45px;
  position: relative;
  transition: background-color 0.3s;
}

.switch-handle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch.is-checked .switch-handle {
  transform: translateX(12px);
}

.switch.small {
  min-width: 24px;
  height: 14px;
}

.switch.small .switch-handle {
  width: 8px;
  height: 8px;
  top: 3px;
  left: 2px;
  transform: translateX(0);
}

.switch.small.is-checked .switch-handle {
  transform: translateX(12px);
}

.switch.large {
  min-width: 36px;
  height: 22px;
}

.switch.large .switch-handle {
  width: 16px;
  height: 16px;
  top: 3px;
  left: 2px;
  transform: translateX(0);
}

.switch.large.is-checked .switch-handle {
  transform: translateX(18px);
}
</style>
