import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state = {
  dashboardCards: [],
  dashboardCardsData: [],
  hubCardActive: false,
  planningConstructionData: [],
  selectedLots: [],
  selectedSections: [],
  users: [],
  invitations: [],
  gemeinden: [],
  landkreise: [],
  groups: [],
  emailPreview: '',
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
