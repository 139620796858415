<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8866 11.3813L1.33325 30.6667"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10" />
    <path
      d="M18.1133 11.3813L30.6666 30.6667"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10" />
    <path
      d="M16 12C18.2091 12 20 10.2091 20 8C20 5.79086 18.2091 4 16 4C13.7909 4 12 5.79086 12 8C12 10.2091 13.7909 12 16 12Z"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M16 1.33325V3.99992"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M16 17.3333V22.6666"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M5.33325 20H26.6666"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
