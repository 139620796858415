<template>
  <div>
    <v-form
      ref="form"
      v-model="isFormValid"
      data-cy="password-reset-form"
      lazy-validation>
      <div>
        <span class="text-black text-[13px]">Altes Passwort</span>
        <v-text-field
          v-model="passwordOld"
          density="compact"
          bg-color="white"
          variant="outlined"
          class="w-[350px]"
          :append-icon="showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          :error="error"
          :error-messages="errorMessages"
          @change="resetErrors"
          @click:append="showPassword = !showPassword"></v-text-field>
      </div>
      <div>
        <span class="text-black text-[13px]">Neues Passwort</span>
        <v-text-field
          v-model="passwordNew"
          density="compact"
          bg-color="white"
          variant="outlined"
          class="w-[350px]"
          :append-icon="showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"
          :rules="[rules.required, rules.length, rules.uppercase, rules.number]"
          :type="showPassword ? 'text' : 'password'"
          :error="error"
          :error-messages="errorMessages"
          @change="resetErrors"
          @click:append="showPassword = !showPassword"></v-text-field>
      </div>
      <div>
        <span class="text-black text-[13px]">Neues Passwort bestätigen</span>
        <v-text-field
          v-model="passwordConfirm"
          density="compact"
          bg-color="white"
          variant="outlined"
          class="w-[350px]"
          :append-icon="showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"
          :rules="[rules.required, rules.match]"
          :type="showPassword ? 'text' : 'password'"
          :error="error"
          :error-messages="errorMessages"
          @change="resetErrors"
          @click:append="showPassword = !showPassword"></v-text-field>
      </div>
      <infra-button :disabled="!isFormValid" @click="submit">
        Speichern
      </infra-button>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      // form data
      isFormValid: true,
      passwordOld: '',
      passwordNew: '',
      passwordConfirm: '',
      // input rules
      error: false,
      errorMessages: '',
      showPassword: false,
      rules: {
        required: (value) => (value && Boolean(value)) || 'Pflichtfeld',
        match: () =>
          this.passwordConfirm === this.passwordNew ||
          'Passwörter stimmen nicht überein',
        length: (v) =>
          (v && v.length >= 8) || 'Passwort sollte aus min. 8 Zeichen bestehen',
        uppercase: (v) =>
          /(?=.*[A-Z])/.test(v) || 'Passwort sollte Großbuchstaben enthalten',
        number: (v) => /(?=.*\d)/.test(v) || 'Passwort sollte Zahl enthalten',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['profil']),
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.update(this.passwordOld, this.passwordNew);
      }
    },
    update(passwordOld, passwordNew) {
      this.$store
        .dispatch('auth/PATCH_PASSWORD', { passwordOld, passwordNew })
        .then(() => {
          this.$router.push('/profile');
          this.passwordOld = '';
          this.passwordNew = '';
          this.passwordConfirm = '';
        });
    },
    resetErrors() {
      this.error = false;
      this.errorMessages = '';
    },
  },
};
</script>

<style lang="css" scoped></style>
