<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2777_29990)">
      <path
        d="M17.8333 16.552V0.0666504C26.044 0.74665 32.5 7.61332 32.5 16C32.5 24.836 25.336 32 16.5 32C12.5627 32 8.96133 30.5707 6.17467 28.2107L17.8333 16.552ZM15.1667 0.0666504C6.956 0.74665 0.5 7.61332 0.5 16C0.5 19.9386 1.92933 23.5387 4.28933 26.3267L6.18267 24.4333C4.3 22.1333 3.16667 19.196 3.16667 16C3.16667 9.09732 8.43733 3.40532 15.1667 2.73465V0.0666504Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_2777_29990">
        <rect width="32" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
