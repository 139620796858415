<template>
  <ToolTipAdvanced
    v-model="tooltipVisible"
    :custom-tooltip-classes="`text-inverted-color1 p-2 w-[280px] rounded mr-1 ${tooltipBoxBackground ? tooltipBoxBackground : 'bg-button-primary-default-color1'}`"
    :class="tooltipContainerClasses"
    :offset-x="-160"
    :offset-y="tooltipOffsetY"
    @mouseenter="openTooltip"
    @mouseleave="closeTooltip">
    <template #activator>
      <IconWrapper
        :class="cursorType"
        :icon="icon"
        type="round"
        :fill="iconFill"
        :size="iconSize" />
    </template>
    <template #content>{{ tooltipText }}</template>
  </ToolTipAdvanced>
</template>

<script setup>
import IconWrapper from '../../stories/IconWrapper/IconWrapper.vue';
import ToolTipAdvanced from '../../stories/toolTipAdvanced/ToolTipAdvanced.vue';
import { ref } from 'vue';

const props = defineProps({
  tooltipText: String,
  tooltipContainerClasses: String,
  iconSize: {
    type: Number,
    default: 24,
  },
  tooltipBoxBackground: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: 'info',
  },
  iconFill: {
    type: String,
    default: 'text-button-primary-default-neutral',
  },
  tooltipOffsetY: {
    type: Number,
    default: -20,
  },
  cursorType: {
    type: String,
    default: 'cursor-default',
  },
});

const tooltipVisible = ref(false);

function openTooltip() {
  tooltipVisible.value = true;
}

function closeTooltip() {
  tooltipVisible.value = false;
}
</script>
