<template>
  <div class="bg-white p-3">
    <div class="flex flex-col gap-3 p-3 bg-white standard-elevation-1">
      <div class="grid grid-cols-4 gap-3">
        <DropDown
          v-model="startYear"
          :small="true"
          label="Startjahr"
          :initial-selection="true"
          :items-data="['2023', '2024', '2025']" />
        <InputEl
          v-model="rateStartYear"
          input-type="float"
          :small="true"
          :label="`${rateName} Startjahr`"
          suffix="%" />
        <InputEl
          v-model="duration"
          input-type="integer"
          :small="true"
          label="Dauer"
          suffix="Jahre" />
        <InputEl
          v-model="rateEndYear"
          input-type="float"
          :small="true"
          :label="`${rateName} Endjahr`"
          type="float"
          suffix="%" />
      </div>
      <div v-if="duration && rateEndYear" class="grid gap-3 grid-flow-col">
        <InputEl
          v-for="i in Number(duration)"
          :key="i"
          v-model="calculatedRate[i - 1].rate"
          input-type="float"
          :small="true"
          :rules="{ required: true }"
          :label="`${startYear - 1 + i}`"
          suffix="%" />
      </div>
      <div class="flex justify-between">
        <button-el
          size="m"
          :primary="false"
          text="Abbrechen"
          @click="togglePopUp" />
        <button-el
          size="m"
          :primary="false"
          text="Speichern"
          @click="togglePopUp" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rateName: {
      type: String,
      default: 'Zinssatz',
    },
  },
  emits: ['togglePopUp'],
  data() {
    return {
      duration: null,
      startYear: null,
      rateStartYear: null,
      rateEndYear: null,
    };
  },
  computed: {
    calculatedRate() {
      const years = Array.from(
        { length: this.duration },
        (_, i) => this.startYear + i
      );
      const rateIncrement =
        (this.toNumber(this.rateEndYear) - this.toNumber(this.rateStartYear)) /
        (this.duration - 1);

      return years.map((year, index) => ({
        year: year,
        rate: this.toCommaSeparated(
          this.toNumber(this.rateStartYear) + rateIncrement * index
        ),
      }));
    },
  },
  methods: {
    togglePopUp() {
      this.$emit('togglePopUp');
    },
    toNumber(valueWithComma) {
      return parseFloat(valueWithComma.replace(',', '.'));
    },
    toCommaSeparated(value) {
      return value.toFixed(1).replace('.', ',');
    },
  },
};
</script>
