export default {
  SET_DASHBOARD_CARDS: (state, payload) => {
    state.dashboardCards = payload;
  },
  SET_DASHBOARD_CARDS_DATA: (state, payload) => {
    state.dashboardCardsData = payload;
  },
  SET_HUB_CARD_ACTIVE(state, status) {
    state.hubCardActive = status;
  },
  TOGGLE_CARD_ACTIVE(state, id) {
    if (id === 0) {
      state.dashboardCards.find((card) => card.id === 0).active = false;
      state.dashboardCards.forEach((card) => {
        if (card.id !== 0) card.active = true;
      });
    } else {
      state.dashboardCards.forEach((card) => (card.active = true));
      const clickedCard = state.dashboardCards.find((card) => card.id === id);
      if (clickedCard) clickedCard.active = false;
      state.dashboardCards.find((card) => card.id === 0).active = true;
    }
  },
  SET_PLANNING_CONSTRUCTION_DATA(state, data) {
    state.planningConstructionData = data;
  },
  SET_SELECTED_LOTS(state, lots) {
    state.selectedLots = lots;
  },
  SET_SELECTED_SECTIONS(state, sections) {
    state.selectedSections = sections;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_INVITATIONS(state, invitations) {
    state.invitations = invitations;
  },
  SET_GEMEINDEN(state, gemeinden) {
    state.gemeinden = gemeinden;
  },
  SET_LANDKREISE(state, landkreise) {
    state.landkreise = landkreise;
  },
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },
  SET_EMAIL_PREVIEW(state, emailPreview) {
    state.emailPreview = emailPreview;
  },
};
