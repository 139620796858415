export default {
  getDashboardCards: (state) => state.dashboardCards,
  getHubCardActive: (state) => state.hubCardActive,
  getAggregatedCardsData: (state, _, __, rootGetters) => {
    const richtlinien = rootGetters['ticket/getRichtlinieSelected'].sort(
      (a, b) => a.value - b.value
    );
    const gemeinden = new Set(
      rootGetters['ticket/getGemeindenActive'].map((g) => g.ags)
    );
    const cardsConfig = JSON.parse(JSON.stringify(state.dashboardCards));
    const data = state.dashboardCardsData.filter((e) => gemeinden.has(e.ags));

    const getAmountWithFlag = (...keyTreeArrays) => {
      if (keyTreeArrays.length === 0) throw Error('Empty array');
      if (!Array.isArray(keyTreeArrays[0])) {
        keyTreeArrays = [keyTreeArrays];
      }
      return data.filter((e) => {
        for (let array of keyTreeArrays) {
          let val = JSON.parse(JSON.stringify(e));
          for (let key of array) {
            if (typeof val === 'object' && key in val) {
              val = val[key];
            } else {
              throw Error(`key ${key} does not exist in object ${val}`);
            }
          }
          if (!val) return false;
        }
        return true;
      }).length;
    };

    // Abschluss Vereinbarung
    cardsConfig[1].communityCount = getAmountWithFlag([
      'cooperation_agreement',
      'community_interest_exists',
    ]);
    cardsConfig[1].milestoneGroups[0].milestones = [
      {
        value: getAmountWithFlag(
          ['cooperation_agreement', 'completed'],
          ['cooperation_agreement', 'community_interest_exists']
        ),
        max: cardsConfig[1].communityCount,
      },
    ];
    cardsConfig[1].tooltip = `${cardsConfig[1].communityCount} der ${gemeinden.size} ausgewählten Gemeinden`;

    // MEV
    let maxMev = [];
    let maxPublished = [];
    for (let rl of richtlinien.map((r) => r.value || r)) {
      const max = getAmountWithFlag(['mev', rl, 'mev_grey_spots_available']);
      maxMev.push(max);
      // Start
      cardsConfig[2].milestoneGroups[0].milestones.push({
        value: getAmountWithFlag(
          ['mev', rl, 'start_date'],
          ['mev', rl, 'oew_is_executive']
        ),
        max,
      });
      // Ende
      cardsConfig[2].milestoneGroups[1].milestones.push({
        value: getAmountWithFlag(
          ['mev', rl, 'end_date'],
          ['mev', rl, 'oew_is_executive']
        ),
        max,
      });
      // Veröffentlichung Ergebnisse
      let published = getAmountWithFlag(
        ['mev', rl, 'publish_date_mev'],
        ['mev', rl, 'oew_is_executive']
      );
      maxPublished.push(published);
      cardsConfig[2].milestoneGroups[2].milestones.push({
        value: published,
        max,
      });
    }
    cardsConfig[2].communityCount = Math.max(...maxMev);
    cardsConfig[2].tooltip = `${cardsConfig[2].communityCount} der ${gemeinden.size} ausgewählten Gemeinden`;

    // Förderantrag
    let grantApplicationsMax = [];
    for (let rl of richtlinien.map((r) => r.value || r)) {
      let max = getAmountWithFlag([
        'grant_applications',
        rl,
        'federal_application_made',
      ]);
      grantApplicationsMax.push(max);
      // Antrag Bund gestellt
      cardsConfig[3].milestoneGroups[0].milestones.push({
        value: getAmountWithFlag([
          'grant_applications',
          rl,
          'federal_application_made',
        ]),
        max,
      });
      // Antrag Land gestellt
      cardsConfig[3].milestoneGroups[1].milestones.push({
        value: getAmountWithFlag([
          'grant_applications',
          rl,
          'state_application_made',
        ]),
        max,
      });
      // Vorläufige Bewilligung Bund
      cardsConfig[3].milestoneGroups[2].milestones.push({
        value: getAmountWithFlag([
          'grant_applications',
          rl,
          'federal_temporary_approval',
        ]),
        max,
      });
      // Vorläufige Bewilligung Land
      cardsConfig[3].milestoneGroups[3].milestones.push({
        value: getAmountWithFlag([
          'grant_applications',
          rl,
          'state_temporary_approval',
        ]),
        max,
      });
      // Endgültige Bewilligung Bund
      // TODO: Progress not defined yet
      cardsConfig[3].milestoneGroups[4].milestones.push({
        value: 0,
        max,
      });
      // Endgültige Bewilligung Land
      // TODO: Progress not defined yet
      cardsConfig[3].milestoneGroups[5].milestones.push({
        value: 0,
        max,
      });
    }
    cardsConfig[3].communityCount = Math.max(...grantApplicationsMax);
    cardsConfig[3].tooltip = `${cardsConfig[3].communityCount} der ${Math.max(
      ...maxPublished
    )} Gemeinden mit Kooperationsvereinbarung und abgeschlossenem MEV`;

    // Ausschreibung Netzbetrieb
    const maxTendersMainsOperation = getAmountWithFlag([
      'tenders_mains_operation',
      'preparation_tender',
    ]);
    // Bekanntmachung
    cardsConfig[4].milestoneGroups[0].milestones = [
      {
        value: getAmountWithFlag(['tenders_mains_operation', 'notice_nb_past']),
        max: maxTendersMainsOperation,
      },
    ];
    // Teilnahmewettbewerb
    cardsConfig[4].milestoneGroups[1].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_mains_operation',
          'submission_eval_participation_competition_past',
        ]),
        max: maxTendersMainsOperation,
      },
    ];
    // Abgabe Erstangebot
    cardsConfig[4].milestoneGroups[2].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_mains_operation',
          'submission_eval_initial_offer_past',
        ]),
        max: maxTendersMainsOperation,
      },
    ];
    // Verhandlungsverfahren
    cardsConfig[4].milestoneGroups[3].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_mains_operation',
          'negotiation_procedure_past',
        ]),
        max: maxTendersMainsOperation,
      },
    ];
    // Abgabe finales Angebot
    cardsConfig[4].milestoneGroups[4].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_mains_operation',
          'submission_eval_final_offer_past',
        ]),
        max: maxTendersMainsOperation,
      },
    ];
    // Zuschlagserteilung
    cardsConfig[4].milestoneGroups[5].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_mains_operation',
          'award_of_contract_nb_past',
        ]),
        max: maxTendersMainsOperation,
      },
    ];
    cardsConfig[4].communityCount = maxTendersMainsOperation;
    cardsConfig[4].tooltip = `${cardsConfig[4].communityCount} der ${cardsConfig[3].communityCount} Gemeinden mit vorläufig bewilligtem Förderantrag`;

    // Ausschreibung GÜ
    const maxTendersGeneralContractor = getAmountWithFlag([
      'tenders_general_contractor',
      'notice',
    ]);
    cardsConfig[5].milestoneGroups[0].milestones = [
      {
        value: getAmountWithFlag(['tenders_general_contractor', 'notice_past']),
        max: maxTendersGeneralContractor,
      },
    ];
    cardsConfig[5].milestoneGroups[1].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_general_contractor',
          'submission_first_offer_past',
        ]),
        max: maxTendersGeneralContractor,
      },
    ];
    cardsConfig[5].milestoneGroups[2].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_general_contractor',
          'closure_negotiation_procedure_past',
        ]),
        max: maxTendersGeneralContractor,
      },
    ];
    cardsConfig[5].milestoneGroups[3].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_general_contractor',
          'submission_final_offer_past',
        ]),
        max: maxTendersGeneralContractor,
      },
    ];
    cardsConfig[5].milestoneGroups[4].milestones = [
      {
        value: getAmountWithFlag([
          'tenders_general_contractor',
          'award_of_contract_past',
        ]),
        max: maxTendersGeneralContractor,
      },
    ];
    cardsConfig[5].communityCount = maxTendersGeneralContractor;
    cardsConfig[5].tooltip = `${cardsConfig[5].communityCount} der ${cardsConfig[3].communityCount} Gemeinden mit vorläufig bewilligtem Förderantrag`;

    // Planung und Bau GÜ
    const maxPlanningConstructionGeneralContractor = cardsConfig[5].milestoneGroups[4].milestones[0].value
    cardsConfig[6].milestoneGroups[0].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'opening_conversation_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].milestoneGroups[1].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'initial_consultation_municipality_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].milestoneGroups[2].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'retrieval_date_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].milestoneGroups[3].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'start_date_planning_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].milestoneGroups[4].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'start_date_construction_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].milestoneGroups[5].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'end_date_planning_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].milestoneGroups[6].milestones = [
      {
        value: getAmountWithFlag([
          'planning_construction_general_contractor',
          'end_date_construction_past',
        ]),
        max: maxPlanningConstructionGeneralContractor,
      },
    ];
    cardsConfig[6].communityCount = maxPlanningConstructionGeneralContractor;
    cardsConfig[6].tooltip = `${cardsConfig[6].communityCount} der ${cardsConfig[5].communityCount} Gemeinden mit abgeschlossenem Auswahlverfahren`;

    return cardsConfig;
  },
  getDashboardCardsData: (state) => state.dashboardCardsData,
  getFilteredDashboardCardsData: (state, _, __, rootGetters) => {
    const gemeinden = new Set(
      rootGetters['ticket/getGemeindenActive'].map((g) => g.ags)
    );
    const data = state.dashboardCardsData.filter((e) => gemeinden.has(e.ags));
    return data;
  },
  getPlanningConstructionData: (state) => state.planningConstructionData,
  getSelectedLots: (state) => state.selectedLots,
  getSelectedSections: (state) => state.selectedSections,
  getUsers: (state) => state.users,
  getInvitations: (state) => state.invitations,
  getGemeinden: (state) => state.gemeinden,
  getLandkreise: (state) => state.landkreise,
  getGroups: (state) => state.groups,
  getEmailPreview: (state) => state.emailPreview,
};
