<template>
  <div class="filter_group">
    <!-- Special components -->
    <div class="flex">
      <tender-select
        v-if="menuItem.additionalFilterComponent === 'tenderSelect'" />
    </div>
    <!-- Switch-List -->
    <switchery-layer
      v-if="menuItem.itemClass === 'switch'"
      :menu-item="menuItem"
      v-bind="$attrs"
      :loading="loadingValueFromStore"></switchery-layer>
    <!-- / Switch-List -->

    <!-- Modal-List -->
    <modal-item
      v-else-if="menuItem.itemClass === 'modal'"
      class="border-b border-[#e5e7eb]"
      :menu-item="menuItem"></modal-item>
    <!-- / Modal-List -->

    <!-- select -->
    <select-layer-filter
      v-else-if="menuItem.itemClass === 'selectFilter'"
      :menu-item="menuItem" />
    <!-- / select -->

    <!-- select -->
    <select-source
      v-else-if="menuItem.itemClass === 'selectSource'"
      :menu-item="menuItem" />
    <!-- / select -->

    <v-list
      v-else
      :opened="openActivator"
      class="bg-transparent font-medium p-0 border-t border-infra-lines filter-group-list-custom overflow-visible">
      <v-list-group :value="menuItem.title">
        <template #activator="{ props }">
          <v-list-item
            class="p-3"
            v-bind="props"
            @click="$emit('emitActivator', menuItem.title)">
            <v-list-item-title class="flex items-center">
              <span class="body-2">{{ menuItem.title }}</span>
              <span v-show="layerCategories > 0" class="count">
                {{ layerCategories }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </template>
        <slot />
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
import { mapGetters } from 'vuex';

export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    open: {
      type: String,
      default: '',
    },
  },
  emits: ['emitActivator'],
  computed: {
    ...mapGetters('map', ['getSwitchStates']),
    loadingValueFromStore() {
      if (this.menuItem.loadingRefFromStorage) {
        return this.$store.getters[this.menuItem.loadingRefFromStorage];
      }
      return false;
    },
    layerCategories() {
      let switchesActive = 0;
      this.menuItem.items.forEach((e) => {
        if (this.getSwitchStates[e.switchId] === true) switchesActive += 1;
      });
      return switchesActive;
    },
    openActivator() {
      return [this.open];
    },
  },
};
</script>

<style lang="scss">
.filter_group:last-child {
  .filter-group-list-custom {
    @apply border-b;
  }

  .switchery-modal {
    border: none !important;
  }
}
</style>
