import { createStore } from 'vuex';
import auth from './modules/auth/index';
import layout from './modules/layout/index';
import map from '../features/map/store';
import ticket from '../features/ticket/store';
import grantApplication from '../features/grantApplications/store';
import draw from '../features/draw/store';
import root from './modules/index';
import search from '../features/map/controlOptions/search/store';
import dashboard from '../features/dashboard/store';

export default createStore({
  modules: {
    auth,
    layout,
    map,
    ticket,
    grantApplication,
    draw,
    root,
    search,
    dashboard,
  },
});
