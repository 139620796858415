<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16602 29.3334L11.166 2.66675"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M21.832 2.66675L29.832 29.3334"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M16.5 6.66675V9.33341"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M16.5 14.6667V17.3334"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
    <path
      d="M16.5 22.6667V25.3334"
      :stroke="fill"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
