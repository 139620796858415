<template>
  <div class="flex flex-col gap-3">
    <h6 class="text-infra-highlight-800">Angaben zum Neubaugebiet</h6>
    <div class="flex flex-wrap gap-[10px]">
      <the-label label="Maßnahmenname (automatisch generiert)" />
      <span class="text-sm font-medium w-full" style="margin-top: -4px">
        {{ measurementName }}
      </span>
      <InputEl
        ref="ticketInput1"
        v-model="ticketData.optional.ticket_name"
        :small="true"
        label="Titel aus vorhandener Maßnahmendokumentation"
        class="w-full" />
      <InputEl
        ref="ticketInput2"
        v-model="ticketData.optional.amount_plots"
        :small="true"
        label="Anzahl Grundstücke"
        class="w-full"
        input-type="integer" />
      <div class="flex gap-[10px]">
        <InputEl
          ref="ticketInput3"
          v-model="ticketData.optional.amount_units"
          :small="true"
          label="Wohneinheiten"
          input-type="integer" />
        <InputEl
          ref="ticketInput4"
          v-model="ticketData.optional.amount_commercial_units"
          :small="true"
          label="Gewerbeeinheiten"
          input-type="integer" />
      </div>
      <InputEl
        ref="ticketInput5"
        v-model="ticketData.required.measure_area"
        :small="true"
        :rules="{ required: true }"
        label="Maßnahmenbereich (Straße, Bezirk etc.)*"
        class="w-full" />
      <div class="flex gap-[10px]">
        <date-picker
          v-model="ticketData.required.construction_date_start"
          :small="true"
          label="Baubeginn*" />
        <date-picker
          v-model="ticketData.required.construction_date_end"
          :small="true"
          label="Bauende*" />
      </div>
      <div class="flex flex-col w-full">
        <the-label label="Wurden Mitverlegungsabsichten gemeldet?" />
        <div class="flex gap-[12px] items-center">
          <radio-button-group
            v-model="ticketData.optional.relocation_intentions"
            :small="true"
            items-data-key="display_name"
            :items-data="[
              { value: true, display_name: 'Ja' },
              { value: false, display_name: 'Nein' },
            ]" />
          <InputEl
            v-if="ticketData.optional.relocation_intentions"
            ref="ticketInput6"
            v-model="ticketData.optional.relocation_intentions_text"
            :small="true"
            placeholder="TKU"
            class="w-full" />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <the-label label="Upload" />
        <upload-field
          v-model="ticketData.optional.upload"
          :small="true"
          :allow-multiple="true" />
      </div>
      <div class="bg-blue-grey-50 p-[10px] rounded-[8px] w-full flex flex-col">
        <button-el
          v-if="!showStreetInput"
          text="Zuführungsstraße hinzufügen"
          :primary="false"
          icon="add"
          :less-padding="true"
          @click="addStreet()" />
        <div v-if="showStreetInput" class="flex flex-col gap-[10px]">
          <the-label
            label="Eingezeichnet wird die Straße zwischen den Punkten, die mittels Linksklick gesetzt werden können." />
          <the-label
            label="Das Einzeichnen wird mittels Linksklick auf den zuletzt gesetzten Punkt beendet oder mittels Klick auf den unteren Button abgebrochen." />
          <button-el
            text="Zuführungsstraße entfernen"
            icon="remove"
            :critical="true"
            :primary="false"
            :less-padding="true"
            @click="removeStreet()" />
          <InputEl
            v-model="ticketData.optional.street_name"
            :small="true"
            label="Zuführungstraße"
            placeholder="Bitte Namen eingeben" />
        </div>
      </div>
    </div>

    <button-el
      text="Datenerfassung speichern"
      :disabled="!formIsValid"
      @click="submitTicket()" />
  </div>
</template>
<script>
import { addMainGeometry, draw } from '@/features/draw/draw';
import { mapGetters } from 'vuex';
import { step2submitData } from '../../../ticketHelper';
import dayjs from 'dayjs';

export default {
  name: 'Step2DevelopmentArea',
  props: {
    successToast: {
      type: String,
    },
  },
  data() {
    return {
      showStreetInput: false,
      ticketData: {
        required: {
          measure_area: null,
          construction_date_start: null,
          construction_date_end: null,
        },
        optional: {},
      },
    };
  },
  computed: {
    formIsValid() {
      return Object.values(this.ticketData.required).every(
        (item) => item || item === false || item === 0
      );
    },
    ...mapGetters('ticket', [
      'getDrawAsMultiFeatures',
      'getGemeindeActiveAgs',
      'getGemeindeActive',
    ]),
    measurementName() {
      return `${this.getGemeindeActive.name}${
        this.ticketData.required.measure_area
          ? ' ' + this.ticketData.required.measure_area
          : ''
      }`;
    },
  },
  methods: {
    addStreet() {
      this.showStreetInput = true;
      addMainGeometry('linestring');
    },
    removeStreet() {
      const features = draw.getAll();
      const toTrash = [];
      features.features.forEach((f) => {
        if (f.geometry.type.toLowerCase() === 'linestring') {
          toTrash.push(f.id);
        }
      });
      draw.delete(toTrash);
      this.showStreetInput = false;
    },
    submitTicket() {
      const multilinestring = this.getDrawAsMultiFeatures.multilinestring;
      const multipolygon = this.getDrawAsMultiFeatures.multipolygon;
      let formTicketData = new FormData();
      const totalTicketData = {
        ...this.ticketData.optional,
        ...this.ticketData.required,
        construction_date_start: dayjs(
          this.ticketData.required.construction_date_start
        ).format('YYYY-MM-DD'),
        construction_date_end: dayjs(
          this.ticketData.required.construction_date_end
        ).format('YYYY-MM-DD'),
        measurement_name: this.measurementName,
        gemeinde: this.getGemeindeActiveAgs,
      };

      for (const [key, val] of Object.entries(totalTicketData)) {
        if (key === 'upload') {
          if (totalTicketData.upload) {
            for (let i = 0; i < totalTicketData.upload.length; i++) {
              formTicketData.append(`upload[${i}]`, totalTicketData.upload[i]);
            }
          }
        } else {
          formTicketData.append(key, val);
        }
      }

      if (multilinestring) {
        formTicketData.append(
          'multilinestring',
          JSON.stringify(multilinestring)
        );
      }
      if (multipolygon) {
        formTicketData.append('multipolygon', JSON.stringify(multipolygon));
      }
      step2submitData(
        'ticket/POST_DEVELOPMENT_AREA',
        formTicketData,
        this.successToast,
        this
      );
    },
  },
};
</script>
