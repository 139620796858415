import { omit } from 'lodash';
import layers from '@/configs/layers';
import {
  LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
  LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
} from '@/configs/layers/viewingLevel';
import { getMap } from '@/mapbox/main';

const viewingKeys = [
  LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
  LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
];

export default {
  SET_FEATURE: (state, payload) => {
    if (payload.options) {
      state.layerConfigs[state.layerID].sidebar.OPTIONS = payload.options;
    }
    if (viewingKeys.includes(state.layerID)) {
      const main = omit(payload.result, ['distribution', 'mev', 'subsidies']);
      state.mainResult = {
        ...main,
        ...payload.result.distribution,
        ...payload.result.mev,
        ...payload.result.subsidies,
      };
    } else {
      state.mainResult = payload.result;
    }
  },
  SET_LAYER_ID: (state, payload) => {
    state.layerID = payload;
  },
  RESET_LAYER_STATES: (state) => {
    for (const [key, value] of Object.entries(layers)) {
      state.layerConfigs[key].layoutState = value.layoutState;
    }
  },
  SET_CHIP_ACTIVE_CHANGE: (state, payload) => {
    const { chips } = state.layerConfigs[payload.layerID].sidebar;
    for (let i = 0; i < chips.length; i += 1) {
      chips[i].active = payload.index === i;
    }
  },
  CHANGE_SWITCH: (state, payload) => {
    // simplify config
    payload.switches.forEach((e) => {
      for (const category of state.navigationConfig) {
        for (const section of category.sections) {
          for (const group of section.items) {
            if (group.items) {
              for (const switchItem of group.items) {
                if (switchItem.switchId === e) {
                  switchItem.active = payload.active;
                }
              }
            }
          }
        }
      }
    });
  },
  SET_LAYER_VIS: (state, payload) => {
    payload.onLayers.forEach((i) => {
      state.layerConfigs[i].visible = payload.active;
      if (!payload.active && state.layerConfigs[i].onDeactivate) {
        const map = getMap();
        state.layerConfigs[i].onDeactivate(map);
      }
    });
  },
  RESET_FEATURE_SELECTIONS: (state) => {
    const map = getMap();
    for (let layer of Object.keys(state.layerConfigs)) {
      if (state.layerConfigs[layer].onDeactivate) {
        state.layerConfigs[layer].onDeactivate(map);
      }
    }
  },
  SET_LAYER_STATE: (state, payload) => {
    payload.layerKeys.forEach((i) => {
      state.layerConfigs[i].layoutState = payload.layerState;
    });
  },
  SET_FILTER: (state, payload) => {
    payload.layerKeys.forEach((i) => {
      const defaultFilter = state.layerConfigs[i].layerConfig.filter || null;
      if (payload.filter === 'reset') state.filters[i] = defaultFilter;
      else {
        if (defaultFilter) {
          state.filters[i] = ['all', defaultFilter, payload.filter];
        } else {
          state.filters[i] = payload.filter;
        }
      }
    });
  },
  SET_ADDITIONAL_FILTERS: (state, payload) => {
    payload.layerKeys.forEach((i) => {
      if (payload.filter) {
        state.additionalFilters[i] = payload.filter;
      } else {
        state.additionalFilters[i] = null;
      }
    });
  },
  SET_PROPERTY_DATA_IS_LOADING: (state, payload) => {
    state.propertyDataIsLoading = payload;
  },
  SET_HAS_RESIZED: (state, payload) => {
    state.hasResized = payload;
  },
  TOGGLE_SATELLITE: (state) => {
    state.satelliteIsActiv = !state.satelliteIsActiv;
  },
  SET_CONTAINER_HEIGHT: (state, payload) => {
    state.containerHeight = payload;
  },
  SET_SHOW_DATA_PANEL: (state, payload) => {
    state.showDataPanel = payload;
  },
  SET_MAP_ACTIONS_DIALOG_IS_OPEN: (state, payload) => {
    state.mapActionsDialogIsOpen = payload;
  },
  ADD_COMMENT_TO_ACTIVE_MEASUREMENT: (state, comment) => {
    state.mainResult.comments.push(comment);
  },
  SET_MEV_RICHTLINIE: (state, richtlinie) => {
    state.richtlinie = richtlinie;
  },
  SET_PHASE_FILTER: (state, phase) => {
    state.phase = phase;
  },
};
