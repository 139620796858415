<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2 9.192L10.072 8.064L8.79999 9.328V6H7.19999V9.328L5.92799 8.056L4.79999 9.192L7.99199 12.4L11.2 9.192Z"
      :fill="fill" />
    <path
      d="M14.4 3.6H8L6.4 2H1.6C0.72 2 0.00799999 2.72 0.00799999 3.6L0 13.2C0 14.08 0.72 14.8 1.6 14.8H14.4C15.28 14.8 16 14.08 16 13.2V5.2C16 4.32 15.28 3.6 14.4 3.6ZM14.4 13.2H1.6V5.2H14.4V13.2Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
