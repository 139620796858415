import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  loading: false,
  modalFullScreen: {
    visible: false,
    config: {},
  },
  modalRightScreen: {
    visible: false,
    type: '',
    title: '',
  },
  validMode: { state: 'none' },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
