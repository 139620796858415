<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 12C20 12.2761 19.7761 12.5 19.5 12.5C19.2239 12.5 19 12.2761 19 12C19 11.7239 19.2239 11.5 19.5 11.5C19.7761 11.5 20 11.7239 20 12Z"
      :fill="fill" />
    <path
      d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z"
      :fill="fill" />
    <path
      d="M5 12C5 12.2761 4.77614 12.5 4.5 12.5C4.22386 12.5 4 12.2761 4 12C4 11.7239 4.22386 11.5 4.5 11.5C4.77614 11.5 5 11.7239 5 12Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 14.5C5.70948 14.5 6.71836 13.6411 6.94999 12.5H9.55001C9.78164 13.6411 10.7905 14.5 12 14.5C13.2095 14.5 14.2184 13.6411 14.45 12.5H17.05C17.2816 13.6411 18.2905 14.5 19.5 14.5C20.8807 14.5 22 13.3807 22 12C22 10.6193 20.8807 9.5 19.5 9.5C18.2905 9.5 17.2816 10.3589 17.05 11.5H14.45C14.2184 10.3589 13.2095 9.5 12 9.5C10.7905 9.5 9.78164 10.3589 9.55001 11.5H6.94999C6.71836 10.3589 5.70948 9.5 4.5 9.5C3.11929 9.5 2 10.6193 2 12C2 13.3807 3.11929 14.5 4.5 14.5ZM4.5 13.5C5.32843 13.5 6 12.8284 6 12C6 11.1716 5.32843 10.5 4.5 10.5C3.67157 10.5 3 11.1716 3 12C3 12.8284 3.67157 13.5 4.5 13.5ZM12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5ZM21 12C21 12.8284 20.3284 13.5 19.5 13.5C18.6716 13.5 18 12.8284 18 12C18 11.1716 18.6716 10.5 19.5 10.5C20.3284 10.5 21 11.1716 21 12Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
