<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_679_54471)">
      <path d="M5 3.5L8 0.5L11 3.5" fill="#263238" />
      <path
        d="M5 3.5L8 0.5L11 3.5"
        :stroke="fill"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M8 0.5V10.5"
        :stroke="fill"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M10.5 6.5H13.5L15.5 15.5H0.5L2.5 6.5H5.5"
        :stroke="fill"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_679_54471">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
