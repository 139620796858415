<template>
  <div class="grid grid-cols-3 bg-infra-highlight-25 p-2">
    <div
      v-for="(item, index) in grantSteps"
      :key="item"
      :class="{
        'opacity-50': !item.active,
        'pointer-events-none': item.id === 2,
      }"
      class="button-2 text-infra-highlight-600 flex gap-2 items-center justify-center relative">
      <div
        class="flex items-center gap-1 cursor-pointer"
        @click="setCurrentId(item.id)">
        <component :is="item.icon" />
        {{ item.title }}
      </div>
      <vectorarrow-icon v-if="item.id !== 2" class="absolute right-0" />
      <vuetify-dialog
        v-if="index === currentId"
        v-model="dialog"
        :persistent="true"
        :full-screen="true"
        :overflow-visible="item.id === 0"
        :title="item.titleModal">
        <template #content>
          <div v-if="currentId === 0" class="flex flex-col gap-5 min-h-full">
            <drop-down-multiple
              :init-select-all="false"
              label="Gemeinden und Gemarkungen"
              placeholder="Bitte aus Liste auswählen"
              :items="step1LocationData"
              :group-data="true"
              data-key="gemeinde"
              @selected-items="selectedAreas = $event" />

            <div
              v-if="selectedAreas.length"
              class="flex gap-1 bg-infra-highlight-25 p-2.5 rounded-[6px]">
              <div
                v-for="area in selectedAreas"
                :key="area"
                class="w-fit text-infra-highlight-800 bg-infra-highlight-50 button-2 p-1 rounded-[3px]">
                {{ area }}
              </div>
            </div>
          </div>
          <div v-if="currentId === 1" class="flex flex-col gap-5 min-h-full">
            <div
              class="bg-infra-highlight-50 rounded-[4px] p-[10px] flex gap-3 items-end">
              <DropDown
                :small="true"
                class="w-[34%]"
                label="Szenario"
                :initial-selection="true"
                :rules="{ required: true }"
                :items-data="[
                  'Kostenannahme 2022',
                  'Kostenannahme 2023',
                  'Kostenannahme 2024',
                ]" />
              <div
                v-if="costsDataChanged"
                class="w-[66%] py-2 px-2.5 bg-green-500 text-white rounded flex gap-3 items-center justify-center h-8">
                <v-icon icon="mdi-information" class="text-white text-[18px]" />
                Benutzerdefiniertes Szenario muss gespeichert werden.
              </div>
            </div>
            <div class="grantStep2ExpansionPanel">
              <v-expansion-panels
                v-model="isOpen"
                variant="accordion"
                class="gap-1 scenario-frame-expansion-panel">
                <!-- kosten-->
                <v-expansion-panel>
                  <v-expansion-panel-title
                    ><h5>Kosten</h5>
                    <template #actions>
                      <expansion-panel-title-actions />
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <costs-panel-content
                      :data="costPanelContentData"
                      @data-changed="costsDataChanged = $event" />
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <!-- erlöse-->
                <v-expansion-panel>
                  <v-expansion-panel-title
                    ><h5>Erlöse</h5>
                    <template #actions>
                      <expansion-panel-title-actions />
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <revenues-panel-content />
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <!-- sonstige -->
                <v-expansion-panel>
                  <v-expansion-panel-title
                    ><h5>Sonstige</h5>
                    <template #actions>
                      <expansion-panel-title-actions />
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <others-panel-content />
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </template>
        <template #actions>
          <button-el
            :primary="false"
            text="Abbrechen"
            @click="dialog = false" />
          <button-el text="Speichern" @click="triggerNextStep(item.id + 1)" />
        </template>
      </vuetify-dialog>
    </div>
  </div>
</template>
<script>
import { markRaw } from 'vue';

export default {
  emits: ['currentId'],
  data() {
    return {
      dialog: false,
      currentId: 1,
      isOpen: 0,
      clickIndex: -1,
      selectedAreas: [],
      costsDataChanged: null,
      step1LocationData: [
        {
          id: 1,
          gemeinde: 'Allmendingen',
          gemarkungen: [
            'Gemarkung 1',
            'Gemarkung 2',
            'Gemarkung 3',
            'Gemarkung 4',
          ],
        },
        {
          id: 2,
          gemeinde: 'Altheim',
          gemarkungen: [
            'Gemarkung 4',
            'Gemarkung 6',
            'Gemarkung 7',
            'Gemarkung 8',
          ],
        },
      ],
      expandableData: [
        {
          title: 'Kosten',
          text: 'text',
        },
        {
          title: 'Erlöse',
          text: 'text',
        },
        {
          title: 'Sonstige',
          text: 'text',
        },
      ],
      grantSteps: [
        {
          id: 0,
          icon: 'map2-icon',
          title: 'Gebiet auswählen',
          active: true,
          titleModal: 'Auswahl Gebiet',
        },
        {
          id: 1,
          icon: 'stockmarket-icon',
          title: 'Parameter einstellen',
          active: false,
          titleModal: 'Parametrierung',
        },
        {
          id: 2,
          icon: 'tableview-icon',
          title: 'Grobkostenschätzung',
          active: false,
          titleModal: 'Grobkostenschätzung',
        },
      ],
    };
  },
  computed: {
    dataChanged() {
      for (let i = 0; i < this.costsDataChanged.length; i++) {
        if (this.costsDataChanged[i] !== this.costPanelContentData[i]) {
          return true;
        }
      }

      return false;
    },
    costPanelContentData() {
      return [
        {
          col1: 'Anlagen',
          col2: ['Netzverteiler', 'PoP'],
          col3: [
            { input: 100, suffix: '€ / Stück' },
            {
              input: 100,
              suffix: '€ / Stück',
            },
          ],
        },
        {
          col1: 'Leerrohre',
          col2: [
            'Hausanschluss – 1 × 10/6',
            'Verteilnetz – 12 × 10/6',
            'Hauptnetz – 4 × 20/15',
          ],
          col3: [
            { input: 100, suffix: '€ / Meter' },
            {
              input: 100,
              suffix: '€ / Meter',
            },
            {
              input: 100,
              suffix: '€ / Meter',
            },
          ],
        },
        {
          col1: 'Kabel (inkl. einblasen 2 Euro/m)',
          col2: ['Mikrokabel – 48 Fasern', 'Minikabel – 288 Fasern'],
          col3: [
            { input: 1800, suffix: '€ / Meter' },
            {
              input: 140000,
              suffix: '€ / Meter',
            },
          ],
        },
        {
          col1: 'Tiefbau',
          col2: ['Mischsatz (un-/versiegelt)'],
          col3: [{ input: 6.5, suffix: '€ / Meter' }],
        },
        {
          col1: 'Hausanschluss',
          col2: ['Hausanschluss (exkl. Tiefbau)'],
          col3: [{ input: 1800, suffix: '€ / Stück' }],
        },
        {
          col1: 'Baunebenkosten',
          col2: ['Baunebenkosten'],
          col3: [{ input: 18, suffix: '% an Gesamt-invest' }],
        },
      ];
    },
  },
  watch: {
    currentExpandable() {
      this.isOpen = 0;
      this.clickIndex = -1;
    },
    currentId(val) {
      this.$emit('currentId', val);
    },
  },
  methods: {
    triggerNextStep(id) {
      this.grantSteps.forEach((step) => {
        if (step.id === id) {
          step.active = true;
          this.setCurrentId(id);
          this.dialog = false;
        }
      });
    },
    setCurrentId(id) {
      this.currentId = id;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss">
//import main.scss from storybook
@import '@/components/storybook/src/assets/styles/index.scss';
// expansion Panel custom style
.scenario-frame-expansion-panel {
  .v-expansion-panel__shadow {
    @apply shadow-none;
  }

  .v-expansion-panel::after {
    @apply border-none;
  }

  .v-expansion-panel-title {
    width: 100% !important;
    padding: 13px 17px 13px 10px;
    border-radius: 4px;
    @apply bg-blue-grey-50;
    h5 {
      @apply text-blue-grey-500;
    }

    &.v-expansion-panel-title--active {
      border-radius: 4px 4px 0 0;
      min-height: 40px !important;
    }
  }

  .v-expansion-panel-text {
    border-radius: 0 0 4px 4px;
    @apply bg-infra-background;
    .v-expansion-panel-text__wrapper {
      padding: 10px;
    }
  }

  .dashed-arrow-custom {
    position: relative;

    &:before {
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translateY(-50%) rotate(270deg);
      display: flex;
      align-items: center;
      justify-content: center;
      content: '';
      background-image: url('@/assets/svg/arrow-right-gray.svg');
      width: 24px;
      height: 24px;
    }
  }

  .panel-content-row-wrapper-container {
    .panel-content-row-wrapper {
      @apply grid grid-cols-3 gap-3 px-3 py-1.5 odd:bg-white even:bg-infra-background;
    }

    .panel-content-item {
      @extend .body-3;
      @apply h-8 flex items-center;
    }

    .first-col-wrapper {
      @extend .subtitle-2;
      @apply flex items-center h-8;
    }

    .other-cols-wrapper {
      @apply flex flex-col gap-2 w-full;
    }
  }
}
</style>
