<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5544_101581)">
      <path
        d="M19.7327 4.81173L15.1875 0.266532C15.1032 0.18204 15.003 0.11501 14.8927 0.0692752C14.7824 0.0235407 14.6642 0 14.5448 0C14.4254 0 14.3072 0.0235407 14.1969 0.0692752C14.0867 0.11501 13.9865 0.18204 13.9021 0.266532L11.8177 2.35096L14.2712 4.81264L12.984 6.0962L10.5314 3.63634L9.09058 5.07808L10.6423 6.6289L9.35693 7.91519L7.8052 6.36346L6.36346 7.8052L8.82423 10.266L7.53885 11.5514L5.07808 9.09058L3.63634 10.5323L5.18716 12.0831L3.90178 13.3685L2.35096 11.8177L0.266531 13.9021C0.18204 13.9865 0.11501 14.0867 0.0692752 14.1969C0.0235407 14.3072 0 14.4254 0 14.5448C0 14.6642 0.0235407 14.7824 0.0692752 14.8927C0.11501 15.003 0.18204 15.1032 0.266531 15.1875L4.81173 19.7327C4.89596 19.8174 4.9961 19.8846 5.10639 19.9305C5.21669 19.9764 5.33497 20 5.45442 20C5.57388 20 5.69215 19.9764 5.80245 19.9305C5.91275 19.8846 6.01289 19.8174 6.09711 19.7327L19.7327 6.09711C19.8172 6.01277 19.8842 5.91259 19.93 5.80231C19.9757 5.69203 19.9992 5.57381 19.9992 5.45442C19.9992 5.33503 19.9757 5.21682 19.93 5.10654C19.8842 4.99626 19.8172 4.89608 19.7327 4.81173Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_5544_101581">
        <rect width="20" height="20" :fill="fill" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
