<template>
  <RouterLink to="/">
    <div
      class="home-icon rounded-[8px]"
      :class="isRoundedRight ? '' : 'rounded-r-none'">
      <img src="@/assets/svg/infra-logo-small.svg" />
    </div>
  </RouterLink>
</template>

<script>
export default {
  name: 'ToHome',
  props: {
    isRoundedRight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.home-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background: linear-gradient(205.52deg, #001257 19.97%, #0692bc 103.36%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
