<template>
  <div
    style="position: absolute; right: 20px; top: 0; height: 100%; width: 500px">
    <v-navigation-drawer
      fixed
      right
      hide-overlay
      persistent
      width="528"
      class="z-40 mr-6 modal_auswertung border border-l-0"
      :class="{ 'mt-16': !showSlider, 'mt-28': showSlider }"
      style="max-height: calc(100% - 5.5rem); margin-left: auto">
      <img
        src="@/assets/svg/i-add.svg"
        width="32"
        height="32"
        class="absolute top-2 right-1.5 z-20 text-white p-2.5 rotate-45 transform cursor-pointer"
        @click="closeModal()" />

      <div class="h-full overflow-visible">
        <slot v-bind="$attrs"></slot>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    showSlider: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('SET_RIGHTSCREEN_MODAL', {
        visible: false,
        type: '',
        title: '',
      });
      this.$store.commit('network/RESET_SELECTED_BUILDINGS');
    },
  },
};
</script>

<style lang="sass" scoped>
.modal-container
  height: 92%
  overflow: hidden
</style>
