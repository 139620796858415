<template>
  <v-card
    class="relative"
    :class="cardItemClasses"
    :style="`flex: 1 1 0px; height: ${small ? 180 : 240}px`"
    @click="call(cardData.method)">
    <img
      :class="`absolute top-[20px] h-auto`"
      :style="`width: ${small ? 42 : 84}px`"
      :src="iconPath"
      alt="icon" />
    <div
      class="flex flex-col items-center justify-end"
      :style="`height: ${small ? 80 : 100}px;`">
      <h4 :class="hubTitleClasses">
        {{ cardData.title }}
      </h4>
      <div :class="hubDescClasses" class="body-2">
        {{ cardData.description }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { axiosInstance } from '@/utils/axiosHelper';

export default {
  props: {
    cardData: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cardItemClasses() {
      return [
        `mx-auto
        bg-white
        flex
        flex-col
        standard-elevation-2
        cursor-pointer
        rounded-[8px]
        items-center
        justify-end
        p-5
        pt-0
        hover:scale-[1.02]
        transition-all
        duration-200`,
      ];
    },
    iconPath() {
      return new URL(`/src/assets/svg/${this.cardData.icon}`, import.meta.url);
    },
    hubTitleClasses() {
      return ['text-infra-highlight-800 mb-1 whitespace-pre-line text-center'];
    },
    hubDescClasses() {
      return ['opacity-[0.6] text-center mt-auto'];
    },
  },
  methods: {
    call(methodName) {
      this[methodName]();
    },
    downloadDatenschutz() {
      window.open(
        `${axiosInstance.defaults.baseURL}/api/documents/?fileName=Datenschutzhinweise.pdf`,
        '_blank'
      );
    },
    goToUrl() {
      this.$router.push(this.cardData.link);
    },
  },
};
</script>
