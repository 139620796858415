<template>
  <div v-if="!isTableLoading">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="search"
          single-line
          variant="outlined"
          append-inner-icon="mdi-magnify"
          placeholder="Bitte Suchbegriff eingeben"
          density="compact"
          hide-details />
      </v-col>
    </v-row>
    <v-row>
      <v-container style="max-width: 100%" class="p-3 shadow-xl">
        <v-card class="m-0.5 p-2" elevation="3">
          <v-row>
            <v-col cols="12" sm="3">
              <v-label class="text-xs"> Aktion </v-label>
              <v-select
                v-model="selectedAction"
                placeholder="Bitte auswählen"
                density="compact"
                variant="outlined"
                :items="actions" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                :disabled="!selected.length || !selectedAction"
                :loading="isActionLoading"
                style="margin-top: 21px; text-transform: unset"
                class="float-left"
                height="43"
                append-icon="mdi-arrow-right"
                color="blue"
                @click="execute">
                Ausführen
              </v-btn>
              <div class="pl-4 pt-8 float-left">
                {{ selected.length }} von {{ getUsers.length }} ausgewählt
              </div>
            </v-col>
            <v-col class="text-end pt-8" cols="12" sm="3">
              <v-btn
                height="43"
                prepend-icon="mdi-filter-outline"
                variant="text"
                @click="toggleFilterShown">
                Filter
                {{ amountActiveFilters ? `(${amountActiveFilters})` : '' }}
              </v-btn>
            </v-col>
            <!-- filter -->
            <v-col v-if="filterShown" cols="12" sm="3">
              <v-label class="text-xs">Staff Status</v-label>
              <v-select
                v-model="filter.isStaff"
                density="compact"
                variant="outlined"
                :items="staffFilterValues" />
            </v-col>
            <v-col v-if="filterShown" cols="12" sm="3">
              <v-label class="text-xs">Aktiv</v-label>
              <v-select
                v-model="filter.isActive"
                density="compact"
                variant="outlined"
                :items="activeFilterValues" />
            </v-col>
            <v-col v-if="filterShown" cols="12" sm="3">
              <v-label class="text-xs">Gruppe</v-label>
              <v-select
                v-model="filter.groups"
                density="compact"
                variant="outlined"
                :items="groupFilterValues" />
            </v-col>
          </v-row>
          <v-row>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filteredUsers"
              :search="search"
              :show-select="true"
              :on-click:row="showUserEditForm"
              density="compact"
              class="pb-3"
              item-key="id"
              items-per-page="10">
              <!-- eslint-disable-next-line -->
              <template #item.is_staff="{ item }">
                <div class="flex w-100 justify-center">
                  <img
                    v-if="item.is_staff"
                    src="@/assets/svg/check-green.svg" />
                  <c-remove v-else fill="red" width="16" height="16" />
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.is_active="{ item }">
                <div class="flex w-100 justify-center">
                  <img
                    v-if="item.is_active"
                    src="@/assets/svg/check-green.svg" />
                  <c-remove v-else fill="red" width="16" height="16" />
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.groups="{ item }">
                {{ item.groups ? item.groups : '-' }}
              </template>
            </v-data-table>
          </v-row>
        </v-card>
      </v-container>
    </v-row>
  </div>
  <div v-else>
    <skeleton-loader-list />
  </div>
  <v-dialog v-model="selectedUserId" width="700" persistent>
    <v-card class="p-5">
      <v-form v-if="!isDetailLoading">
        <!-- email -->
        <v-label class="text-xs">* E-Mail</v-label>
        <v-text-field
          v-model="username"
          :error="invalidEmail"
          :error-messages="invalidEmail"
          variant="outlined"
          density="compact" />
        <h4 class="pt-5">Persönliche Informationen</h4>
        <!-- first name -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-label class="text-xs">Vorname</v-label>
            <v-text-field
              v-model="firstName"
              variant="outlined"
              density="compact" />
          </v-col>
          <!-- last name -->
          <v-col cols="12" sm="6">
            <v-label class="text-xs">Nachname</v-label>
            <v-text-field
              v-model="lastName"
              variant="outlined"
              density="compact" />
          </v-col>
        </v-row>
        <h4 class="pt-5">Berechtigungen</h4>
        <!-- is active -->
        <v-label class="text-xs">
          Bestimmt, ob der User als aktiv behandelt wird. Dieses Häkchen
          deselektieren statt User zu löschen.
        </v-label>
        <v-checkbox
          v-model="isActive"
          density="compact"
          label="Aktiv"
          hide-details />
        <!-- is staff -->
        <v-label class="text-xs">
          Bestimmt, ob der User Zugriff auf das User-Management hat.
        </v-label>
        <v-checkbox
          v-model="isStaff"
          density="compact"
          label="Staff Status"
          hide-details />
        <!-- role -->
        <v-label class="text-xs"> * Benutzerrolle </v-label>
        <v-select
          v-model="selectedGroup"
          :error="invalidGroup"
          :error-messages="invalidGroup"
          item-title="name"
          item-value="id"
          placeholder="Bitte auswählen"
          density="compact"
          variant="outlined"
          :items="getGroups" />
        <!-- gemeinde permissions -->
        <v-label class="text-xs">
          (*) Sämtliche Gemeinden eines Landkreises, auf die der User Zugriff
          erhalten soll (optional, ersetzt Gemeindeauswahl)
        </v-label>
        <v-autocomplete
          v-model="landkreise"
          :items="getLandkreise"
          :multiple="true"
          :error="invalidGemeindenOrLandkreise"
          :error-messages="invalidGemeindenOrLandkreise"
          item-value="kreis_id"
          item-title="name"
          density="compact"
          label="Nach Landkreisen suchen"
          variant="outlined">
          <template #prepend-item>
            <v-list-item
              height="40"
              class="pt-5"
              density="compact"
              @click="() => {}">
              <v-checkbox
                v-model="allLandkreiseSelected"
                label="Alle"
                @click="selectAllLandkreise" />
            </v-list-item>
          </template>
          <template #selection="{ item, index }">
            <span v-if="index < 2">{{ item.title }}</span>
            <span v-if="index < 1 && landkreise.length > 1">,&nbsp;</span>
            <span v-if="index === 2"
              >&nbsp;und {{ landkreise.length - 2 }} weitere&nbsp;</span
            >
          </template>
        </v-autocomplete>
        <v-label class="text-xs">
          * Gemeinden, auf die der User Zugriff erhalten soll (erforderlich,
          kann durch Landkreisbündelung ersetzt werden)
        </v-label>
        <v-autocomplete
          v-model="gemeinden"
          :items="getGemeinden"
          :multiple="true"
          :error="invalidGemeindenOrLandkreise"
          :error-messages="invalidGemeindenOrLandkreise"
          item-value="ags"
          item-title="name"
          density="compact"
          label="Nach Gemeinden suchen"
          variant="outlined">
          <template #prepend-item>
            <v-list-item
              height="40"
              class="pt-5"
              density="compact"
              @click="() => {}">
              <v-checkbox
                v-model="allGemeindenSelected"
                label="Alle"
                @click="selectAllGemeinden" />
            </v-list-item>
          </template>
          <template #selection="{ item, index }">
            <span v-if="index < 2">{{ item.title }}</span>
            <span v-if="index < 1 && gemeinden.length > 1">,&nbsp;</span>
            <span v-if="index === 2"
              >&nbsp;und {{ gemeinden.length - 2 }} weitere&nbsp;</span
            >
          </template>
        </v-autocomplete>
        <br />
        <!-- buttons -->
        <v-btn
          :loading="isUpdating"
          :disabled="invalid"
          class="float-right ml-3"
          variant="flat"
          color="blue"
          @click="updateUser">
          Speichern
        </v-btn>
        <v-btn
          class="float-right"
          variant="outlined"
          @click="selectedUserId = null">
          Abbrechen
        </v-btn>
      </v-form>
      <skeleton-loader-list v-if="isDetailLoading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonLoaderList from '@/components/global/skeletonLoaderList.vue';

export default {
  components: { SkeletonLoaderList },
  data() {
    return {
      actions: [
        { title: 'Ausgewählte Benutzer deaktivieren', value: 'deactivate' },
        { title: 'Ausgewählte Benutzer aktivieren', value: 'activate' },
      ],
      selected: [],
      selectedAction: null,
      selectedUserId: null,
      username: '',
      firstName: '',
      lastName: '',
      isActive: false,
      isStaff: false,
      landkreise: [],
      gemeinden: [],
      selectedGroup: null,
      isTableLoading: true,
      isDetailLoading: true,
      isUpdating: false,
      isActionLoading: false,
      search: '',
      filterShown: false,
      filter: {
        isStaff: null,
        isActive: null,
        groups: null,
      },
      groupFilterValues: [
        {
          title: 'Alle',
          value: null,
        },
        {
          title: 'Minimal User',
          value: 'Minimal User',
        },
        {
          title: 'Normal User',
          value: 'Normal User',
        },
        {
          title: 'OEW PowerUser',
          value: 'OEW PowerUser',
        },
        {
          title: 'LBD Admin',
          value: 'LBD Admin',
        },
      ],
      activeFilterValues: [
        {
          title: 'Alle',
          value: null,
        },
        {
          title: 'Aktive User',
          value: true,
        },
        {
          title: 'Inaktive User',
          value: false,
        },
      ],
      staffFilterValues: [
        {
          title: 'Alle',
          value: null,
        },
        {
          title: 'Staff User',
          value: true,
        },
        {
          title: 'Kein Staff User',
          value: false,
        },
      ],
      headers: [
        {
          align: 'start',
          key: 'username',
          sortable: true,
          title: 'E-Mail',
        },
        {
          align: 'start',
          key: 'first_name',
          sortable: true,
          title: 'Vorname',
          width: 150,
        },
        {
          align: 'start',
          key: 'last_name',
          sortable: true,
          title: 'Nachname',
          width: 150,
        },
        {
          align: 'center',
          key: 'is_staff',
          sortable: true,
          title: 'Staff',
          width: 70,
        },
        {
          align: 'center',
          key: 'is_active',
          sortable: true,
          title: 'Aktiv',
          width: 50,
        },
        {
          align: 'start',
          key: 'groups',
          sortable: true,
          title: 'Rolle',
          width: 150,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('dashboard', [
      'getUsers',
      'getGemeinden',
      'getLandkreise',
      'getGroups',
    ]),
    ...mapGetters('auth', ['profil']),
    amountActiveFilters() {
      return Object.values(this.filter).filter((e) => e !== null).length;
    },
    allLandkreiseSelected() {
      return this.landkreise.length === this.getLandkreise.length;
    },
    allGemeindenSelected() {
      return this.gemeinden.length === this.getGemeinden.length;
    },
    filteredUsers() {
      let users = this.getUsers;
      if (this.filter.isActive !== null) {
        users = users.filter((user) => user.is_active === this.filter.isActive);
      }
      if (this.filter.isStaff !== null) {
        users = users.filter((user) => user.is_staff === this.filter.isStaff);
      }
      if (this.filter.groups !== null) {
        users = users.filter((user) => user.groups === this.filter.groups);
      }
      return users;
    },
    invalid() {
      return (
        this.invalidEmail ||
        this.invalidGemeindenOrLandkreise ||
        this.invalidGroup
      );
    },
    invalidEmail() {
      if (!this.username) {
        return 'Darf nicht leer sein.';
      }
      if (!/.+@.+\..+/.test(this.username)) {
        return 'Muss eine gültige E-Mail Adresse sein.';
      }
      return false;
    },
    invalidGemeindenOrLandkreise() {
      if (!this.landkreise.length && !this.gemeinden.length) {
        return 'Mindestens eine Gemeinde oder ein Landkreis muss ausgewählt werden';
      }
      return false;
    },
    invalidGroup() {
      if (!this.selectedGroup) {
        return 'Bitte wählen Sie eine User-Gruppe aus';
      }
      return false;
    },
  },
  mounted() {
    this.isTableLoading = true;
    this.$store.dispatch('dashboard/GET_USERS').then(() => {
      this.isTableLoading = false;
    });
  },
  methods: {
    execute() {
      this.isActionLoading = true;
      if (
        this.selectedAction === 'deactivate' ||
        this.selectedAction === 'activate'
      ) {
        let active = true;
        if (this.selectedAction === 'deactivate') {
          active = false;
        }
        this.$store
          .dispatch('dashboard/SET_ACTIVATE_USERS', {
            userIds: this.selected,
            active,
          })
          .then(() => {
            this.selected = [];
            this.isActionLoading = false;
            this.isTableLoading = true;
            this.$store.dispatch('dashboard/GET_USERS').then(() => {
              this.isTableLoading = false;
            });
          });
      }
      // if (this.selectedAction === 'password') {
      //   this.$store
      //     .dispatch('dashboard/SEND_PASSWORD_RESET_EMAIL', this.selected)
      //     .then(() => {
      //       this.selected = [];
      //       this.isActionLoading = false;
      //       this.isTableLoading = true;
      //       this.$store.dispatch('dashboard/GET_USERS').then(() => {
      //         this.isTableLoading = false;
      //       });
      //     });
      // }
    },
    toggleFilterShown() {
      this.filterShown = !this.filterShown;
    },
    showUserEditForm(e, item) {
      this.landkreise = [];
      this.selectedUserId = item.item.id;
      this.isDetailLoading = true;
      this.$store
        .dispatch('dashboard/GET_USER_DETAILS', item.item.id)
        .then((data) => {
          this.username = data.username;
          this.firstName = data.first_name;
          this.lastName = data.last_name;
          this.isStaff = data.is_staff;
          this.isActive = data.is_active;
          this.gemeinden = data.gemeinden;
          this.selectedGroup = data.groups;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isDetailLoading = false;
        });
    },
    updateUser() {
      const data = {
        landkreise: this.landkreise,
        gemeinden: this.gemeinden,
        first_name: this.firstName,
        last_name: this.lastName,
        is_active: this.isActive,
        is_staff: this.isStaff,
        username: this.username,
        group: this.selectedGroup,
      };
      this.isUpdating = true;
      this.$store
        .dispatch('dashboard/SAVE_USER', {
          id: this.selectedUserId,
          data,
        })
        .then(() => {
          this.selectedUserId = null;
          this.isTableLoading = true;
          this.$store.dispatch('dashboard/GET_USERS').then(() => {
            this.isTableLoading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    selectAllLandkreise() {
      if (this.allLandkreiseSelected) {
        this.landkreise = [];
      } else {
        this.landkreise = this.getLandkreise.map((l) => l.kreis_id);
      }
    },
    selectAllGemeinden() {
      if (this.allGemeindenSelected) {
        this.gemeinden = [];
      } else {
        this.gemeinden = this.getGemeinden.map((g) => g.ags);
      }
    },
  },
};
</script>
