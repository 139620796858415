<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2478 3.09038L20.861 10.2939C20.9395 10.3617 20.9889 10.4583 20.9983 10.5629C21.0078 10.6676 20.9766 10.7718 20.9116 10.8532C20.8465 10.9345 20.7527 10.9864 20.6505 10.9977C20.5483 11.009 20.4459 10.9787 20.3653 10.9134L12 3.91719L3.63468 10.9134C3.55412 10.9787 3.45168 11.009 3.34948 10.9977C3.24728 10.9864 3.15352 10.9345 3.08845 10.8532C3.02337 10.7718 2.9922 10.6676 3.00166 10.5629C3.01113 10.4583 3.06047 10.3617 3.13904 10.2939L5.34439 8.44942V5.00093C5.34439 4.89479 5.38564 4.79299 5.45906 4.71794C5.53248 4.64289 5.63207 4.60073 5.7359 4.60073H6.91042C7.01425 4.60073 7.11383 4.64289 7.18725 4.71794C7.26068 4.79299 7.30192 4.89479 7.30192 5.00093V6.81222L11.7522 3.09038C11.8221 3.03193 11.9097 3 12 3C12.0903 3 12.1779 3.03193 12.2478 3.09038ZM5 10.6451L12 5L19 10.6451V15.0354C18.8367 15.0121 18.6698 15 18.5 15C16.567 15 15 16.567 15 18.5C15 19.0368 15.1208 19.5454 15.3368 20H13.9444V15.7826C13.9444 15.681 13.9035 15.5834 13.8305 15.5115C13.7576 15.4396 13.6587 15.3992 13.5556 15.3992H10.4444C10.3413 15.3992 10.2424 15.4396 10.1695 15.5115C10.0965 15.5834 10.0556 15.681 10.0556 15.7826V20H5.77778C5.5715 20 5.37367 19.9192 5.22781 19.7754C5.08194 19.6316 5 19.4366 5 19.2332V10.6451ZM10.5583 12.6032C10.6313 12.6751 10.7302 12.7155 10.8333 12.7155H13.1667C13.2698 12.7155 13.3687 12.6751 13.4417 12.6032C13.5146 12.5313 13.5556 12.4338 13.5556 12.3321V10.0317C13.5556 9.93001 13.5146 9.83249 13.4417 9.76059C13.3687 9.68869 13.2698 9.6483 13.1667 9.6483H10.8333C10.7302 9.6483 10.6313 9.68869 10.5583 9.76059C10.4854 9.83249 10.4444 9.93001 10.4444 10.0317V12.3321C10.4444 12.4338 10.4854 12.5313 10.5583 12.6032ZM18.5 21.2C19.9912 21.2 21.2 19.9912 21.2 18.5C21.2 17.0089 19.9912 15.8 18.5 15.8C17.0089 15.8 15.8 17.0089 15.8 18.5C15.8 19.9912 17.0089 21.2 18.5 21.2ZM18.5001 19.4C18.9972 19.4 19.4001 18.997 19.4001 18.5C19.4001 18.0029 18.9972 17.6 18.5001 17.6C18.003 17.6 17.6001 18.0029 17.6001 18.5C17.6001 18.997 18.003 19.4 18.5001 19.4Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
