<template>
  <DashboardContentLayout>
    <h4 class="text-infra-highlight-700">
      Zeitliche Einordnung der Meilensteine auf Gemeindeebene
    </h4>
    <gantt-chart-pis
      :data="chartData"
      :data-map="dateData"
      :richtlinien="richtlinienSelected" />
  </DashboardContentLayout>
</template>

<script setup>
import { computed } from 'vue';
import dateData from '@/features/dashboard/date-data';

// import getter from store
import { useStore } from 'vuex';

const store = useStore();

// get data from store
const chartData = computed(
  () => store.getters['dashboard/getFilteredDashboardCardsData']
);

const richtlinienSelected = computed(
  () => store.getters['ticket/getRichtlinieSelected'].map((rl) => rl.value)
);
</script>
