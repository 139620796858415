<template>
  <single-page>
    <div class="flex p-4 w-full flex-col">
      <div class="flex items-center justify-start mb-5">
        <div class="flex items-center">
          <div class="w-20 h-20 mr-4 relative">
            <personal-avatar />
          </div>
        </div>
        <div class="text-[32px]">{{ profil.username }}</div>
      </div>
      <div class="border-b-2">
        <v-tabs
          v-model="tab"
          class="mb-[-2px]"
          density="compact"
          slider-color="#0692BC">
          <v-tab
            style="margin-left: 0px !important"
            value="profile"
            :class="tabStyle"
            :ripple="false"
            data-cy="profile-tab"
            >User-Profil
          </v-tab>
          <v-tab
            value="password"
            :class="tabStyle"
            :ripple="false"
            data-cy="password-tab"
            >Passwort administrieren
          </v-tab>
          <v-tab
            v-if="canManageUsers"
            value="permissions"
            :class="tabStyle"
            :ripple="false"
            data-cy="permissions-tab"
            >Berechtigungen
          </v-tab>
          <v-tab
            v-if="canManageUsers"
            value="invitations"
            :class="tabStyle"
            :ripple="false"
            data-cy="invitations-tab"
            >Einladungen
          </v-tab>
          <v-tab
            v-if="canManageUsers"
            value="usermanagement"
            :class="tabStyle"
            :ripple="false"
            data-cy="users-tab"
            >User Management
          </v-tab>
        </v-tabs>
      </div>

      <v-card-text class="px-0">
        <v-window v-model="tab" class="mt-4 pt-2">
          <v-window-item value="profile">
            <div class="flex flex-col">
              <personal-data />
            </div>
          </v-window-item>
          <v-window-item value="password">
            <div class="flex flex-col">
              <password-page />
            </div>
          </v-window-item>
          <v-window-item value="permissions">
            <div class="flex flex-col">
              <permissions-page />
            </div>
          </v-window-item>
          <v-window-item v-if="canManageUsers" value="invitations">
            <div class="flex flex-col">
              <invitation-management />
            </div>
          </v-window-item>
          <v-window-item v-if="canManageUsers" value="usermanagement">
            <div class="flex flex-col">
              <user-management />
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
  </single-page>
</template>

<script>
import { mapGetters } from 'vuex';
import UserManagement from '@/views/profile/userManagement.vue';
import InvitationManagement from '@/views/profile/invitationManagement.vue';
import PermissionsPage from '@/views/profile/permissionsPage.vue';

export default {
  components: { PermissionsPage, InvitationManagement, UserManagement },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['profil']),
    tabStyle() {
      return 'px-0 normal-case text-[18px] hover:text-infra-highlight ml-4 tracking-normal';
    },
    canManageUsers() {
      return this.$can('view', 'user');
    },
  },
};
</script>

<style lang="scss">
.change-avatar-custom {
  width: 25px;
  height: 25px;
}
</style>
