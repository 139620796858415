export const LAYER_KEY__BUILDINGS = 'buildings';
export const LAYER_KEY__ADDRESS_POINTS = 'addresspoints';

export default {
  [LAYER_KEY__BUILDINGS]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'buildings',
      router_name: 'buildings',
      key_name: 'pk',
      applyGemeindeFilter: true,
      type: 'polygon',
      subType: 'fillExtrusion',
      id: LAYER_KEY__BUILDINGS,
      sourceLayer: LAYER_KEY__BUILDINGS,
      source: LAYER_KEY__BUILDINGS,
      visibility: 'none',
      hoverPointer: true,
    },
    style: {
      default: {
        fillExtrusionColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#5c0000',
          '#888888',
        ],
        fillExtrusionHeight: ['get', 'height'],
        fillExtrusionOpacity: 1.0,
      },
    },
    legend: {
      default: {
        name: 'Gebäude',
        unit: '',
        items: [
          {
            color: '#888888',
            name: 'LOD-1 Gebäude',
            icon: 'mdi-square',
          },
        ],
      },
    },
    sidebar: {
      name: 'Gebäude',
      subtitles: [{ key: 'alkis_id' }, { key: 'street' }, { key: 'function' }],
      chips: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              title: 'Gebäudeeinheiten',
              active: true,
              items: [
                {
                  key: 'units_residential',
                  unit: '',
                  value: '0',
                },
                {
                  key: 'units_commercial',
                  unit: '',
                  value: '0',
                },
              ],
            },
          ],
        },
        /* {
          title: 'Fasern & Kabel',
          active: false,
          items: [
            {
              title: 'Fasern',
              active: false,
              items: [
                {
                  key: 'fiber_total',
                  unit: '',
                  value: '0',
                },
                {
                  key: 'fiber_active',
                  unit: '',
                  value: '0',
                },
                {
                  key: 'fiber_reserve',
                  unit: '',
                  value: '0',
                },
              ],
            },
            {
              title: 'Mikrokabel',
              active: false,
              items: [
                {
                  key: 'micro_length',
                  unit: ' m',
                  value: '0',
                  decimal: 0,
                },
                {
                  key: 'micro_total',
                  unit: '',
                  value: '0',
                  decimal: 0,
                },
              ],
            },
          ],
        }, */
      ],
    },
  },
  [LAYER_KEY__ADDRESS_POINTS]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'building',
      router_name: 'addressPoint',
      key_name: 'id',
      type: 'point',
      id: LAYER_KEY__ADDRESS_POINTS,
      source: LAYER_KEY__ADDRESS_POINTS,
      sourceLayer: LAYER_KEY__ADDRESS_POINTS,
      visibility: 'none',
    },
    style: {
      default: {
        circleColor: [
          'match',
          ['get', 'validation_result'],
          0,
          '#E91E63',
          1,
          '#2196F3',
          2,
          '#FFAC1C',
          3,
          '#009688',
          '#ffffff',
        ],
        circleStrokeWidth: 3,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'marked'], false],
          'rgb(178,7,7)',
          'rgba(255,177,0,0)',
        ],
        circleRadius: {
          stops: [
            [1, 0],
            [8, 1],
            [9, 2],
            [14, 4],
          ],
        },
      },
    },
    legend: {
      default: {
        name: 'Gebäude',
        unit: '',
        items: [
          {
            color: '#E91E63',
            name: 'Ausschlussadressen',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: '#2196F3',
            name: 'Zusätzliche Adressen',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: '#FFAC1C',
            name: 'Rest',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: '#009688',
            name: 'Förderfähige Adressen',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
