import configs from '../../../configs';
import mutations from './mutations';
import getters from './getters';

const { theme } = configs;

const { globalTheme, menuTheme, toolbarTheme } = theme;

const state = {
  // themes and layout configurations
  globalTheme,
  menuTheme,
  toolbarTheme,
  drawer: false,
  toast: {
    multiLine: false,
    show: false,
    color: 'black',
    message: '',
    timeout: 3000,
    outlined: false,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
