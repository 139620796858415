import Click from './Click';

let clicked;
let marked;

export function initClicked(map) {
  clicked = new Click(map, 'clicked', ['nvtActive', 'popActive']);
  return clicked;
}

export function getClicked() {
  return clicked;
}

export function initMarked(map) {
  marked = new Click(map, 'marked');
  return marked;
}

export function getMarked() {
  return marked;
}
