<template>
  <div class="flex items-center justify-center p-2">
    <vuetify-dialog
      v-model="showScenarioSelection"
      :persistent="true"
      :full-screen="true"
      title="Szenarien">
      <template #activatorItem>
        <div class="flex items-center gap-2 cursor-pointer">
          <div class="body-3">Szenario</div>
          <h6 class="text-blue-grey-500">Kostenannahme 2022</h6>
          <button-el
            size="m"
            :primary="false"
            :icon-button="true"
            icon="arrow-up" />
        </div>
      </template>

      <template #content>
        <div class="flex flex-col gap-5 min-h-full">
          <div class="body-1">
            Löschen und benennen Sie hier Ihre erstellten Szenarien.
          </div>
          <vuetify-data-table
            :show-expand="false"
            :headers="headers"
            :cursor-pointer-on-row="true"
            :show-pagination="false"
            :item-data="itemData"
            :show-total="true"
            @clicked-row-data="clickedRowData">
            <template #state="item">
              <div class="flex items-center">
                <v-radio
                  v-model="item.item.state"
                  :ripple="false"
                  color="primary" />
              </div>
            </template>
            <template #creation_date="item">
              <div class="flex justify-end">
                {{ creationDateFormatter(item.item.creation_date) }}
              </div>
            </template>
            <template #functions="item">
              <div class="flex font-bold justify-end">
                <img
                  class="opacity-40 hover:opacity-70 mr-4"
                  :src="'@/assets/svg/edit-pen.svg'"
                  alt="map-add" />
                <img
                  class="opacity-40 hover:opacity-70"
                  :src="'@/assets/svg/trash-can.svg'"
                  alt="map-add"
                  @click.stop="startDeleteProcess(item.item.id)" />
                <vuetify-dialog
                  v-if="deleteId === item.item.id"
                  v-model="deleteDialog"
                  :title="`Szenario &quot;${item.item.description}&quot; wirklich
                  löschen?`">
                  <template #content>
                    Dieser Vorgang kann nicht rückgängig gemacht werden.
                  </template>
                  <template #actions>
                    <button-el
                      :primary="false"
                      text="Abbrechen"
                      @click="deleteDialog = false" />
                    <button-el
                      text="Löschen"
                      :critical="true"
                      :primary="false"
                      @click="deleteScenario(item)" />
                  </template>
                </vuetify-dialog>
              </div>
            </template>
          </vuetify-data-table>
        </div>
      </template>
      <template #actions>
        <button-el
          :primary="false"
          text="Abbrechen"
          @click="showScenarioSelection = false" />
        <button-el text="Speichern" @click="showScenarioSelection = false" />
      </template>
    </vuetify-dialog>
  </div>
</template>
<script>
export default {
  name: 'GrantScenarioSelection',
  data() {
    return {
      showScenarioSelection: false,
      deleteDialog: false,
      deleteId: null,
      headers: [
        {
          title: 'Aktiv',
          key: 'state',
          width: '75px',
          sortable: false,
        },
        {
          title: 'Bezeichung',
          key: 'description',
        },
        {
          title: 'Erstellungsdatum',
          key: 'creation_date',
          align: 'end',
        },
        {
          title: '',
          key: 'functions',
          sortable: false,
          align: 'end',
          width: '125px',
        },
      ],
      itemData: [
        {
          id: 1,
          state: true,
          description: 'Kostenannahme 2022',
          creation_date: 'default',
        },
        {
          id: 2,
          state: false,
          description: 'Ausschreibungsergebnis ADK',
          creation_date: 'predefined',
        },
        {
          id: 3,
          state: false,
          description: 'Ausschreibungsergebnis Biberach',
          creation_date: 'predefined',
        },
        {
          id: 4,
          state: false,
          description: 'Benutzerdefiniertes Szenario 11.07.2023',
          creation_date: '2023-07-11',
        },
        {
          id: 5,
          state: false,
          description: 'Benutzerdefiniert 2',
          creation_date: '2023-07-12',
        },
      ],
    };
  },
  methods: {
    clickedRowData(rowData) {
      const currentID = rowData.item.selectable.id;
      this.itemData.forEach((item) => {
        if (item.id === currentID) {
          item.state = !item.state;
        } else {
          item.state = false;
        }
      });
    },
    creationDateFormatter(date) {
      if (date === 'default') {
        return 'Vordefiniert (Default)';
      } else if (date === 'predefined') {
        return 'Vordefiniert';
      } else {
        return new Date(date).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
    },
    startDeleteProcess(id) {
      this.deleteDialog = true;
      this.deleteId = id;
    },
    deleteScenario(selectedItem) {
      this.itemData = this.itemData.filter(
        (item) => item.id !== selectedItem.item.id
      );
      this.deleteDialog = false;
    },
  },
};
</script>
