<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.3897 19.9769C22.2878 19.6304 23.3414 19.8072 24.0697 20.5355C24.5385 21.0043 24.8019 21.6402 24.8019 22.3033C24.8019 22.9663 24.5385 23.6022 24.0697 24.071C23.6008 24.5399 22.965 24.8033 22.3019 24.8033C21.6389 24.8033 21.003 24.5399 20.5341 24.071C19.8058 23.3427 19.629 22.2891 19.9755 21.3911L17.9108 19.3263C17.0128 19.6728 15.9592 19.4961 15.2308 18.7677C14.5025 18.0394 14.3257 16.9858 14.6722 16.0878L12.6075 14.023C11.7095 14.3695 10.6559 14.1928 9.92755 13.4644C9.4587 12.9956 9.19531 12.3597 9.19531 11.6967C9.19531 11.0336 9.4587 10.3977 9.92755 9.9289C10.3964 9.46006 11.0323 9.19667 11.6953 9.19667C12.3584 9.19667 12.9942 9.46006 13.4631 9.9289C14.1914 10.6572 14.3682 11.7108 14.0217 12.6088L16.0864 14.6736C16.9845 14.3271 18.0381 14.5039 18.7664 15.2322C19.4947 15.9605 19.6715 17.0141 19.325 17.9121L21.3897 19.9769ZM21.5948 23.0104C21.9853 23.4009 22.6185 23.4009 23.009 23.0104C23.3995 22.6199 23.3995 21.9867 23.009 21.5962C22.6185 21.2056 21.9853 21.2056 21.5948 21.5962C21.2043 21.9867 21.2043 22.6199 21.5948 23.0104ZM17.7057 17.7071C17.3152 18.0976 16.682 18.0976 16.2915 17.7071C15.901 17.3165 15.901 16.6834 16.2915 16.2929C16.682 15.9023 17.3152 15.9023 17.7057 16.2929C18.0962 16.6834 18.0962 17.3165 17.7057 17.7071ZM10.9882 12.4038C11.3787 12.7943 12.0119 12.7943 12.4024 12.4038C12.7929 12.0132 12.7929 11.3801 12.4024 10.9896C12.0119 10.599 11.3787 10.599 10.9882 10.9896C10.5977 11.3801 10.5977 12.0132 10.9882 12.4038Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
