<template>
  <div class="flex justify-center items-center">
    <!-- ARROW-RIGHT-ICON -->
    <svg
      v-show="name === 'arrowRight'"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13.4"
      viewBox="0 0 16 13.4">
      <g transform="translate(0 0)">
        <path
          class="arrow-right"
          d="M9.3,14.7,7.9,13.3,12.2,9H0V7H12.2L7.9,2.7,9.3,1.3,16,8Z"
          transform="translate(0 -1.3)" />
      </g>
    </svg>
    <!-- /ARROW-RIGHT-ICON -->

    <!-- ARROW-LEFT-ICON -->
    <svg
      v-show="name === 'arrowLeft'"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13.4"
      viewBox="0 0 16 13.4">
      <g transform="translate(0 0)">
        <path
          class="arrow_left"
          d="M6.7,14.7l1.4-1.4L3.8,9H16V7H3.8L8.1,2.7,6.7,1.3,0,8Z"
          transform="translate(0 -1.3)" />
      </g>
    </svg>
    <!-- /ARROW-LEFT-ICON -->

    <!-- SEARCH-ICON -->
    <svg
      v-show="name === 'search'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.614"
      height="18.614"
      viewBox="0 0 18.614 18.614">
      <g transform="translate(-1 -1)">
        <line
          class="search"
          x1="5.139"
          y1="5.139"
          transform="translate(13.061 13.061)" />
        <circle
          class="search"
          cx="6.5"
          cy="6.5"
          r="6.5"
          transform="translate(2 2)" />
      </g>
    </svg>
    <!-- /SEARCH-ICON -->

    <!-- NORTH-ICON -->
    <svg
      v-show="name === 'north'"
      xmlns="http://www.w3.org/2000/svg"
      width="16.506"
      height="16.506"
      viewBox="0 0 16.506 16.506">
      <g transform="translate(-0.467 -0.467)">
        <path class="north" d="M14.155,5.838,2,2,5.838,14.155,8.4,8.4Z" />
      </g>
    </svg>
    <!-- /NORTH-ICON -->

    <!-- MINUS-ICON -->
    <svg
      v-show="name === 'minus'"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="2"
      viewBox="0 0 12 2">
      <g transform="translate(-6 -11)">
        <line class="minus" x1="10" transform="translate(7 12)" />
      </g>
    </svg>
    <!-- /MINUS-ICON -->

    <!-- PLUS-ICON -->
    <svg
      v-show="name === 'plus'"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12">
      <g transform="translate(-6 -6)">
        <line class="plus" y2="10" transform="translate(12 7)" />
        <line class="plus" x1="10" transform="translate(7 12)" />
      </g>
    </svg>
    <!-- /PLUS-ICON -->

    <!-- TIME-MACHINE -->
    <svg
      v-show="name === 'timemachine'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.347"
      height="18.334"
      viewBox="0 0 18.347 18.334">
      <use href="@/assets/svg/time-machine.svg#timemachine" />
    </svg>
    <!-- /TIME-MACHINE -->

    <!-- SETTINGS -->
    <svg
      v-show="name === 'settings'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.347"
      height="18.334"
      viewBox="0 0 18.347 18.334">
      <g transform="translate(-1 16) rotate(-90)">
        <path
          class="settings"
          d="M15,3H11a.945.945,0,0,0-1,1,.945.945,0,0,0,1,
          1h4a.945.945,0,0,0,1-1A.945.945,0,0,0,15,3Z" />
        <path
          class="settings"
          d="M5,1A3.048,3.048,0,0,0,2.2,3H1A.945.945,0,0,0,0,4,.945.945,0,0,0,1,5H2.2A3.048,
        3.048,0,0,0,5,7,2.946,2.946,0,0,0,8,4,2.946,2.946,0,0,0,5,1Z" />
        <path class="settings" d="M1,13H5a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" />
        <path
          class="settings"
          d="M15,11H13.8A2.913,2.913,0,0,0,11,9a3,3,0,0,0,0,6,3.048,
        3.048,0,0,0,2.8-2H15a1,1,0,0,0,0-2Z" />
      </g>
    </svg>
    <!-- /SETTINGS -->

    <!-- MAGNIFIER -->
    <svg
      v-show="name === 'magnifier'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.347"
      height="18.334"
      viewBox="0 0 18.347 18.334">
      <use href="@/assets/svg/magnifier-zoom-in.svg#magnifier" />
    </svg>
    <!-- /MAGNIFIER -->

    <!-- DOCUMENT/PDF -->
    <svg
      v-show="name === 'document'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.347"
      height="18.334"
      viewBox="0 0 18.347 18.334">
      <use href="@/assets/svg/document.svg#document" />
    </svg>
    <!-- /DOCUMENT/PDF -->

    <!-- LEGEND -->
    <svg
      v-show="name === 'legend'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.347"
      height="18.334"
      viewBox="0 0 18.347 18.334">
      <circle class="legend" cx="2" cy="2" r="2" />
      <circle class="legend" cx="2" cy="2" r="2" transform="translate(0 8)" />
      <rect class="legend" width="10" height="2" transform="translate(6 1)" />
      <rect class="legend" width="10" height="2" transform="translate(6 13)" />
      <rect class="legend" width="10" height="2" transform="translate(6 9)" />
      <rect class="legend" width="10" height="2" transform="translate(6 5)" />
    </svg>
    <!-- /LEGEND -->

    <!-- CLUSTER -->
    <svg
      v-show="name === 'cluster'"
      xmlns="http://www.w3.org/2000/svg"
      width="24.042"
      height="24.042"
      viewBox="0 0 24.042 24.042">
      <g transform="translate(12.021 -4.95) rotate(45)">
        <circle class="stroke" cx="2" cy="2" r="2" transform="translate(6 6)" />
        <circle
          class="stroke"
          cx="2"
          cy="2"
          r="2"
          transform="translate(14 6)" />
        <circle
          class="stroke"
          cx="2"
          cy="2"
          r="2"
          transform="translate(6 14)" />
        <circle
          class="stroke"
          cx="2"
          cy="2"
          r="2"
          transform="translate(14 14)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(0 7)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(0 15)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(22 7)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(22 15)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(7)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(15)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(7 22)" />
        <circle class="fill" cx="1" cy="1" r="1" transform="translate(15 22)" />
      </g>
    </svg>
    <!-- /CLUSTER -->

    <!-- CLUSTER ADD -->
    <svg
      v-show="name === 'cluster_add'"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22">
      <g transform="translate(-1 -1)">
        <line class="stroke" x2="12" transform="translate(6 4)" />
        <line class="stroke" y1="12" transform="translate(4 6)" />
        <line class="stroke" y2="12" transform="translate(20 6)" />
        <line class="stroke" x1="12" transform="translate(6 20)" />
        <rect class="stroke" width="4" height="4" transform="translate(2 2)" />
        <rect class="stroke" width="4" height="4" transform="translate(18 2)" />
        <rect class="stroke" width="4" height="4" transform="translate(2 18)" />
        <rect
          class="stroke"
          width="4"
          height="4"
          transform="translate(18 18)" />
      </g>
    </svg>
    <!-- /CLUSTER ADD -->

    <!-- CLUSTER REMOVE -->
    <svg
      v-show="name === 'cluster_remove'"
      xmlns="http://www.w3.org/2000/svg"
      width="20.539"
      height="20.539"
      viewBox="0 0 20.539 20.539">
      <path
        class="fill"
        d="M10.27,0a10.27,10.27,0,1,0,10.27,10.27A10.3,10.3,0,0,0,10.27,
      0Zm4.493,12.965-1.8,1.8-2.7-2.7-2.7,2.7-1.8-1.8,2.7-2.7-2.7-2.7,
      1.8-1.8,2.7,2.7,2.7-2.7,1.8,1.8-2.7,2.7Z" />
    </svg>
    <!-- /CLUSTER REMOVE -->

    <!-- MORE -->
    <svg
      v-show="name === 'more'"
      xmlns="http://www.w3.org/2000/svg"
      width="12.001"
      height="12.001"
      viewBox="0 0 12.001 12.001">
      <path
        class="fill"
        d="M6,0a6,6,0,1,0,6,6A6.018,6.018,0,0,0,6,0ZM5.25,
        8.551,4.2,7.5,5.7,6,4.2,4.5,5.25,3.45,7.8,6Z" />
    </svg>
    <!-- /MORE -->
  </div>
</template>

<script>
export default {
  name: 'MapControlBarIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
/* ARROW-RIGHT-ICON */
/* ARROW-LEFT-ICON */
.fill,
.arrow-right,
.arrow_left,
.settings,
.legend {
  fill: currentColor;
}

/* SEARCH-ICON */
/* PLUS-ICON */
/* MINUS-ICON */
/* NORTH-ICON */
.stroke,
.search,
.plus,
.minus,
.north {
  fill: none;
  stroke: currentColor;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
</style>
